
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลเครื่องตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12">
              <!-- model search -->
              <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ดูข้อมูล</b>
                            <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" v-model='dataedit[0]' readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">ประเภทของเครื่องตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" v-model='dataedit[1]' readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">หมายเลขประจำเครื่อง</label>
                                <div class="col-sm-8">
                                  <input class="form-control" type="text" v-model='dataedit[2]' readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                              <label class="col-sm-4 col-form-label">สถานะ</label>
                                <div class="col-sm-8">
                                    <select class="form-select" v-model="dataedit[3]" disabled>
                                        <option value="1">ใช้งานอยู่</option>
                                        <option value="2">ชำรุด</option>
                                        <option value="3">เลิกใช้งาน</option>
                                    </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">ข้อมูลหรือเอกสารอื่น ๆ  PDF <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                                <div class="col-sm-8">
                                  <a v-if="dataedit[4]" class="mt-1" :href="storeAuth.UrlFileTools+dataedit[4]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                  <span v-else>ไม่มีไฟล์ที่แนบ</span>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">รูปถ่ายเครื่องตรวจสภาพ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                <div class="col-sm-8">
                                  <a v-if="dataedit[5]" class="mt-1" :href="storeAuth.UrlImagesTools+dataedit[5]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                  <span v-else>ไม่มีไฟล์ที่แนบ</span>
                                </div>
                              </div>

                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                  <th>หมายเลขอ้างอิง</th>
                                  <th>เลขที่ใบอนุญาต - ชื่อสถานตรวจสภาพรถ</th>
                                  <th>จังหวัด/เขตพื้นที่ สถานตรวจสภาพรถ</th>
                                  <th>ประเภท - ชนิด แบบ/รุ่น ของเครื่องตรวจสภาพรถ</th>
                                  <th>หมายเลข</th>
                                  <th>บริษัทผู้ผลิตเครื่อง</th>
                                  <th class="none">รูปถ่าย</th>
                                  <th class="none">ไฟล์ PFD ที่แนบมา</th>
                                  <th>สถานะ</th>
                                  <th>จัดการ</th>
                                </tr>
                            </thead>
                            <template #column-9="props">
                              <div class="btn-group">
                                <button @click="geteditdata(props.cellData,props.rowData)" type="button" class="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_edit: null,
})
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const dataedit = ref([]);

onMounted(() => {
  getdata_table();
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`tools`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      let type_tools = '';
      if(res.data.datas[i].type_tools == 1){
        type_tools = 'เครื่องทดสอบห้ามล้อ';
      }else if(res.data.datas[i].type_tools == 2){
        type_tools = 'เครื่องทดสอบศูนย์ล้อ';
      }else if(res.data.datas[i].type_tools == 3){
        type_tools = 'เครื่องทดสอบโคมไฟหน้า';
      }else if(res.data.datas[i].type_tools == 4){
        type_tools = 'เครื่องวัดควันดำ-ระบบวัดความทึบแสง';
      }else if(res.data.datas[i].type_tools == 5){
        type_tools = 'เครื่องวัดควันดำ-ระบบกระดาษกรอง';
      }else if(res.data.datas[i].type_tools == 6){
        type_tools = 'เครื่องกำเนิดเสียงมาตรฐาน';
      }else if(res.data.datas[i].type_tools == 7){
        type_tools = 'เครื่องวัดความเร็วรอบเครื่องยนต์';
      }else if(res.data.datas[i].type_tools == 8){
        type_tools = 'เครื่องวิเคราะห์ก๊าซ';
      }else if(res.data.datas[i].type_tools == 9){
        type_tools = 'เครื่องวัดก๊าซรั่ว';
      }else if(res.data.datas[i].type_tools == 10){
        type_tools = 'เครื่องยกรถ';
      }else if(res.data.datas[i].type_tools == 11){
        type_tools = 'เครื่องวัดระดับเสียง';
      }else if(res.data.datas[i].type_tools == 12){
        type_tools = 'เครื่องวัดความเข้มของฟิล์มกรองแสง';
      }
      
      let status = '';
      if(res.data.datas[i].status == 1){
        status = 'ใช้งานอยู่';
      }else if(res.data.datas[i].status == 2){
        status = 'ชำรุด';
      }else{
        status = 'เลิกใช้งาน';
      }

      datatable.value.push([res.data.datas[i].id,res.data.datas[i].license_number + ' - ' + res.data.datas[i].nameaddresscar,res.data.datas[i].county_name,type_tools + ' - ชนิด ' + res.data.datas[i].type + ' แบบ/รุ่น ' + res.data.datas[i].model,res.data.datas[i].number,res.data.datas[i].approved,
      res.data.datas[i].img_tools?`<a href="${storeAuth.UrlImagesTools}${res.data.datas[i].img_tools}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      res.data.datas[i].file_tools?`<a href="${storeAuth.UrlFileTools}${res.data.datas[i].file_tools}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      status,res.data.datas[i].id]);
    }
    Swal.close()
  }else{
    Swal.close()
  }
}

async function geteditdata(id) {
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`tools`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 4,
      datas:[id]
    });
    if(res.data.status == 1){
        dataedit.value[0] = res.data.datas[0].license_number + ' ' + res.data.datas[0].nameaddresscar + ' ' + res.data.datas[0].license_id;
        dataedit.value[1] = res.data.datas[0].type_tools + ' ' + res.data.datas[0].type + ' ' + res.data.datas[0].model + ' ' + res.data.datas[0].approved + ' ' + res.data.datas[0].type_car;
        dataedit.value[2] = res.data.datas[0].number;
        dataedit.value[3] = res.data.datas[0].status;
        dataedit.value[4] = res.data.datas[0].file_tools;
        dataedit.value[5] = res.data.datas[0].img_tools;
        Swal.close();
        openModaledit()
    }else{
      Swal.close();
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ดึงข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '15%', targets: 0 },
        { width: '15%', targets: 3 },
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>