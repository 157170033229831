<template>

  <div class="container-fluid">
      <Breadcrumbs main="รายละเอียดข้อมูลการตรวจสภาพรถตามกฏหมายรถยนต์"/>

      <div v-if="datas" class="row starter-main">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
                <a :href="'/user/datacarpdfs/'+route.params.id" target="_blank" class="btn btn-pill btn-info ml-4">ไฟล์ PDF (ผลการตรวจ)</a>
                <button class="btn btn-pill btn-primary" @click="printDiv()" >ปริ้น (รายละเอียดการตรวจ)</button>
                <!--ปริ้น -->
                <div id="printableTable" style="display: none;">
                  <table style="width:100%; border: 1px solid black;border-collapse: collapse; font-family: Arial, sans-serif; font-size: 12px;">
                    <tr>
                      <th colspan="2">
                        รายละเอียดข้อมูลการตรวจสภาพรถตามกฏหมายรถยนต์<br/>
                        ทะเบียน {{ datas.CAR_LIC_ID_CHAR + ' ' + datas.CAR_LIC_ID_NUM + ' ' + datas.names }}                    
                      </th>
                      <th colspan="2">
                        <p>
                          <a :href="storeAuth.UrlImagesCar + datas.PIC_CAR" target="_blank" class="previewImage">
                            <img :src="storeAuth.UrlImagesCar + datas.PIC_CAR" width="200"  />
                          </a>
                        </p>                     
                      </th>
                    </tr>
                    <tr style="border: 1px solid black;border-collapse: collapse;">
                      <td colspan="4" style="text-align: center;font-weight: bold;">รายละเอียดการตรวจ</td>
                    </tr>
                    <tr>
                      <td>ผลการตรวจ</td>
                      <td>{{ datas.STATUS_CHECK == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ลำดับการตรวจ</td>
                      <td>{{ datas.ID }}</td>
                    </tr>
                    <tr>
                      <td>เวลาที่ตรวจ</td>
                      <td>{{ new Date(datas.SEND_DATETIME).toLocaleString('en-GB', { timeZone: 'UTC' }).replace( /,/,'' ) }}</td>
                      <td>เลขที่ใบอนุญาต</td>
                      <td>{{ datas.TRO_ID }}</td>
                    </tr>
                    <tr>
                      <td>ชื่อสถานตรวจสภาพรถ</td>
                      <td>{{ datas.TRO_NAME }}</td>
                      <td>ผู้ได้รับใบอนุญาต</td>
                      <td>{{ datas.TRO_OWNER_NAME }}</td>
                    </tr>
                    <tr>
                      <td>วันที่จดทะเบียน</td>
                      <td>{{ datas.CAR_REG_DATE_1 + ' ' + mountthai(datas.CAR_REG_DATE_2) + ' ' + datas.CAR_REG_DATE_3 }}</td>
                      <td>ประเภท รย.</td>
                      <td>{{ typecar(datas.CAR_TYPE) }}</td>
                    </tr>
                    <tr>
                      <td>ชนิดเชื้อเพลิง</td>
                      <td>{{ typefuel(datas.FUELDESC_SELECT) }}</td>
                      <td>หมายเลขเครื่องยนต์</td>
                      <td>{{ datas.ENGINE_NO }}</td>
                    </tr>
                    <tr>
                      <td>แรงห้ามล้อ เพลา 1 ช้าย</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE1L }}</td>
                      <td>แรงห้ามล้อ เพลา 1 ขวา</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE1R }}</td>
                    </tr>
                    <tr>
                      <td>แรงห้ามล้อ เพลา 2 ช้าย</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE2L }}</td>
                      <td>แรงห้ามล้อ เพลา 2 ขวา</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE2R }}</td>
                    </tr>
                    <tr>
                      <td>แรงห้ามล้อ เพลา 3 ช้าย</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE3L }}</td>
                      <td>แรงห้ามล้อ เพลา 3 ขวา</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE3R }}</td>
                    </tr>
                    <tr>
                      <td>น้ำหนักลงเพลา 4</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE4L }}</td>
                      <td>แรงห้ามล้อ เพลา 4 ขวา</td>
                      <td>{{ datas.TESTBRAKE_FORCE_AXLE4R }}</td>
                    </tr>
                    <tr>
                      <td>น้ำหนักลงเพลา 1</td>
                      <td>{{ datas.TESTBRAKE_WEIGHT_AXLE1 }}</td>
                      <td>น้ำหนักลงเพลา 2</td>
                      <td>{{ datas.TESTBRAKE_WEIGHT_AXLE2 }}</td>
                    </tr>
                    <tr>
                      <td>น้ำหนักลงเพลา 3</td>
                      <td>{{ datas.TESTBRAKE_WEIGHT_AXLE3 }}</td>
                      <td>น้ำหนักลงเพลา 4</td>
                      <td>{{ datas.TESTBRAKE_WEIGHT_AXLE4 }}</td>
                    </tr>
                    <tr>
                      <td>ผลต่าง เพลา 1</td>
                      <td>{{ datas.TESTBRAKE_DIF_AXLE1 }}</td>
                      <td>ผลต่าง เพลา 2</td>
                      <td>{{ datas.TESTBRAKE_DIF_AXLE2 }}</td>
                    </tr>
                    <tr>
                      <td>ผลต่าง เพลา 3</td>
                      <td>{{ datas.TESTBRAKE_DIF_AXLE3 }}</td>
                      <td>ผลต่าง เพลา 4</td>
                      <td>{{ datas.TESTBRAKE_DIF_AXLE4 }}</td>
                    </tr>
                    <tr>
                      <td>แรงห้ามล้อ ซ้าย</td>
                      <td>{{ datas.TESTBRAKE_HAND_VAULE_L }}</td>
                      <td>แรงห้ามล้อ ขวา</td>
                      <td>{{ datas.TESTBRAKE_HAND_VAULE_R }}</td>
                    </tr>
                    <tr>
                      <td>ประสิทธิภาพห้ามล้อ</td>
                      <td>{{ datas.TESTBRAKE_FOOT_EFFICIENCY }}</td>
                      <td>ประสิทธิภาพห้ามล้อมือ</td>
                      <td>{{ datas.TESTBRAKE_HAND_EFFICIENCY }}</td>
                    </tr>
                    <tr>
                      <td>ศูนย์ล้อ</td>
                      <td>{{ datas.TESTBRAKE_WHEEL_ALIGHTMENT }}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ผลความแตกต่าง</td>
                      <td>{{ datas.RESULT_DIFF_BRAKE == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ผลเบรค</td>
                      <td>{{ datas.RESULT_FOOT_BRAKE == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>ผลเบรคมือ</td>
                      <td>{{ datas.RESULT_HAND_BRAKE == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ผลศูนย์ล้อ</td>
                      <td>{{ datas.RESULT_WHEEL_ALIGHTMENT == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>ผลระดับเสียง</td>
                      <td>{{ datas.CHECK_VOICE == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ผลมลพิษจากไอเสีย</td>
                      <td>{{ datas.CHECK_PIPE == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>ผลแตรสัญญาณ</td>
                      <td>{{ datas.CHECK_HOOTER == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ผลเครื่องวัดความเร็ว</td>
                      <td>{{ datas.CHECK_SPEED == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>ผลโคมไฟพุ่งไกล โคมไฟพุ่งต่ำ</td>
                      <td>{{ datas.CHECK_HEAD_LIGHT == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ผลโคมไฟเลี้ยว โคมไฟป้าย โคมไฟหยุด</td>
                      <td>{{ datas.CHECK_SIGNAL_LIGHT == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</td>
                      <td>{{ datas.CHECK_PLATE_LIGHT == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</td>
                      <td>{{ datas.CHECK_WINDOWS_GLASS == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>ระบบบังคับเลี้ยวและพวงมาลัย</td>
                      <td>{{ datas.CHECK_CONTROL_SYS == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ล้อและยาง</td>
                      <td>{{ datas.CHECK_RUBBER == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>ถังเชื้อเพลิง และท่อส่ง</td>
                      <td>{{ datas.CHECK_FUEL_TANK == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>เครื่องล่าง</td>
                      <td>{{ datas.CHECK_SUSPENSION == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>สภาพตัวถังและโครงรถ</td>
                      <td>{{ datas.CHECK_BODY == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>ประตูและพื้นรถ</td>
                      <td>{{ datas.CHECK_DOOR == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>เข็มขัดนิรภัย</td>
                      <td>{{ datas.CHECK_BELT == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>เครื่องปัดน้ำฝน</td>
                      <td>{{ datas.CHECK_RAIN_FLY == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                    </tr>
                    <tr>
                      <td>อื่นๆ</td>
                      <td>{{ datas.CHECK_AUTHE == 0?'ไม่ผ่าน':'ผ่าน' }}</td>
                      <td>อื่นๆ(รายละเอียดเพิ่มเติม)</td>
                      <td>{{ datas.FAILED_REASONS }}</td>
                    </tr>
                    <tr>
                      <td>ค่าเครื่องวัดเสียง</td>
                      <td>{{ datas.VOICE_VALUE }}</td>
                      <td>ค่าไอเสีย</td>
                      <td>{{ datas.PIPE_VALUE }}</td>
                    </tr>
                    <tr v-if="(datas.CAR_TYPE != 12 && datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">
                      <td colspan="4">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</td>
                    </tr>
                    <tr v-if="(datas.CAR_TYPE != 12 && datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">
                      <td>ค่า CO</td>
                      <td>{{ datas.CO_VALUE_L1 }}</td>
                      <td>ค่า HC</td>
                      <td>{{ datas.HC_VALUE_L1 }}</td>
                    </tr>

                    <tr v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014')))">
                      <td colspan="4">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับน้ำมันอย่างเดียว)</td>
                    </tr>
                    <tr v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014')))">
                      <td>ค่า CO</td>
                      <td>{{ datas.CO_VALUE_H1 }}</td>
                      <td>ค่า HC</td>
                      <td>{{ datas.HC_VALUE_H1 }}</td>
                    </tr>

                    <tr v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8))">
                      <td colspan="4">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับก๊าซอย่างเดียว)</td>
                    </tr>
                    <tr v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8))">
                      <td>ค่า CO</td>
                      <td>{{ datas.CO_VALUE_L2 }}</td>
                      <td>ค่า HC</td>
                      <td>{{ datas.HC_VALUE_L2 }}</td>
                    </tr>

                    <tr v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014')))">
                      <td colspan="4">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับก๊าซอย่างเดียว)</td>
                    </tr>
                    <tr v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014')))">
                      <td>ค่า CO</td>
                      <td>{{ datas.CO_VALUE_H2 }}</td>
                      <td>ค่า HC</td>
                      <td>{{ datas.HC_VALUE_H2 }}</td>
                    </tr>

                    <tr v-if="(datas.CAR_TYPE != 12) && (datas.FUELDESC_SELECT == '')">
                      <td colspan="4">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</td>
                    </tr>
                    <tr v-if="(datas.CAR_TYPE != 12) && (datas.FUELDESC_SELECT == '')">
                      <td>ค่า CO</td>
                      <td>{{ datas.CO_VALUE }}</td>
                      <td>ค่า HC</td>
                      <td>{{ datas.HC_VALUE }}</td>
                    </tr>

                    <tr v-if="datas.CAR_TYPE == 12 ">
                      <td colspan="4">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</td>
                    </tr>
                    <tr v-if="datas.CAR_TYPE == 12 ">
                      <td>ค่า CO</td>
                      <td>{{ datas.CO_VALUE }}</td>
                      <td>ค่า HC</td>
                      <td>{{ datas.HC_VALUE }}</td>
                    </tr>

                    <tr>
                      <td>ผู้ตรวจที่ 1</td>
                      <td>{{ datas.USER1 }}</td>
                      <td>ผู้ตรวจที่ 2</td>
                      <td>{{ datas.USER2 }}</td>
                    </tr>
                    <tr>
                      <td>ค่าโคมไฟพุ่งไกล ด้านซ้าย</td>
                      <td>{{ datas.LIGHT_DN_L_VALUE }}</td>
                      <td>ค่าโคมไฟพุ่งไกล ด้านขวา</td>
                      <td>{{ datas.LIGHT_DN_R_VALUE }}</td>
                    </tr>
                    <tr>
                      <td>ค่าโคมไฟพุ่งต่ำ ด้านซ้าย</td>
                      <td>{{ datas.LIGHT_DN_L_LEVEL }}</td>
                      <td>ค่าโคมไฟพุ่งต่ำ ด้านขวา</td>
                      <td>{{ datas.LIGHT_DN_R_LEVEL }}</td>
                    </tr>
                    <tr>
                      <td>ตำแหน่งโคมไฟพุ่งไกล ด้านซ้าย</td>
                      <td>{{ datas.LIGHT_UP_L_VALUE }}</td>
                      <td>ตำแหน่งโคมไฟพุ่งไกล ด้านขวา</td>
                      <td>{{ datas.LIGHT_UP_R_VALUE }}</td>
                    </tr>
                    <tr>
                      <td>ตำแหน่งโคมไฟพุ่งต่ำ ด้านซ้าย</td>
                      <td>{{ datas.LIGHT_UP_L_LEVEL }}</td>
                      <td>ตำแหน่งโคมไฟพุ่งต่ำ ด้านขวา</td>
                      <td>{{ datas.LIGHT_UP_R_LEVEL }}</td>
                    </tr>
                    <tr>
                      <td>IP ADDRESS</td>
                      <td>{{ datas.PC_IP }}</td>
                      <td>MAC ADDRESS</td>
                      <td>{{ datas.PC_MAC }}</td>
                    </tr>
                    <tr>
                      <td>ชื่อ สถานตรวจสภาพรถ</td>
                      <td>{{ datas.TRO_NAME }}</td>
                      <td>ระยะทางวิ่ง</td>
                      <td>{{ datas.CAR_DISTANCE + ' ' + datas.CAR_DISTANCE_TYPE }}</td>
                    </tr>
                    <tr>
                      <td>เลขตัวถังรถ</td>
                      <td>{{ datas.CHAISSE_NO }}</td>
                      <td>ลักษณะรถ</td>
                      <td>{{ datas.CHASSIS_NO_TYPE }}</td>
                    </tr>
                    <tr>
                      <td>สีรถ</td>
                      <td>{{ datas.CAR_COLOR }}</td>
                      <td>ที่นั่งและจำนวนรถที่นั่ง</td>
                      <td>{{ datas.CAR_SEAT_NO }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="card-body" >
                <div class="row">
                  <div class="col">
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขทะเบียน</label>
                      <div class="col-sm-9">
                        <input class="form-control" :value="datas.CAR_LIC_ID_CHAR + ' ' + datas.CAR_LIC_ID_NUM + ' ' + datas.names" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลการตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.STATUS_CHECK == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลำดับการตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.ID" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เวลาที่ตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="new Date(datas.SEND_DATETIME).toLocaleString('en-GB', { timeZone: 'UTC' }).replace( /,/,'' )" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขที่ใบอนุญาต</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_ID" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชื่อสถานตรวจสภาพรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ได้รับใบอนุญาต</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_OWNER_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">วันที่จดทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_REG_DATE_1 + ' ' + mountthai(datas.CAR_REG_DATE_2) + ' ' + datas.CAR_REG_DATE_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประเภท รย.</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="typecar(datas.CAR_TYPE)" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชนิดเชื้อเพลิง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="typefuel(datas.FUELDESC_SELECT)" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">หมายเลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.ENGINE_NO" type="text" style="text-transform: uppercase;" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 1 ช้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE1L" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 1 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE1R" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 2 ช้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE2L" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 2 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE2R" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 3 ช้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE3L" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 3 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE3R" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 4 ช้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE4L" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ เพลา 4 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE4R" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">น้ำหนักลงเพลา 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">น้ำหนักลงเพลา 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">น้ำหนักลงเพลา 3</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">น้ำหนักลงเพลา 4</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง เพลา 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง เพลา 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง เพลา 3</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง เพลา 4</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_HAND_VAULE_L" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงห้ามล้อ ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_HAND_VAULE_R" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประสิทธิภาพห้ามล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_FOOT_EFFICIENCY" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประสิทธิภาพห้ามล้อมือ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_HAND_EFFICIENCY" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ศูนย์ล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TESTBRAKE_WHEEL_ALIGHTMENT" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลความแตกต่าง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.RESULT_DIFF_BRAKE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลเบรค</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.RESULT_FOOT_BRAKE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลเบรคมือ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.RESULT_HAND_BRAKE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลศูนย์ล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.RESULT_WHEEL_ALIGHTMENT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลระดับเสียง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_VOICE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลมลพิษจากไอเสีย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_PIPE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลแตรสัญญาณ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_HOOTER == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลเครื่องวัดความเร็ว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_SPEED == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลโคมไฟพุ่งไกล โคมไฟพุ่งต่ำ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_HEAD_LIGHT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลโคมไฟเลี้ยว โคมไฟป้าย โคมไฟหยุด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_SIGNAL_LIGHT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_PLATE_LIGHT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_WINDOWS_GLASS == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ระบบบังคับเลี้ยวและพวงมาลัย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_CONTROL_SYS == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ล้อและยาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_RUBBER == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ถังเชื้อเพลิง และท่อส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_FUEL_TANK == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เครื่องล่าง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_SUSPENSION == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">สภาพตัวถังและโครงรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_BODY == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประตูและพื้นรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_DOOR == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เข็มขัดนิรภัย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_BELT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เครื่องปัดน้ำฝน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_RAIN_FLY == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row"> 
                      <label class="col-sm-3 col-form-label">อื่นๆ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_AUTHE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">อื่นๆ(รายละเอียดเพิ่มเติม)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.FAILED_REASONS" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ค่าเครื่องวัดเสียง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.VOICE_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ค่าไอเสีย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PIPE_VALUE" type="text" readonly>
                      </div>
                    </div>

                    <h5 v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) )">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</h5>
                    <div class="mb-3 row" v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) )">
                      <label class="col-sm-3 col-form-label">ค่า CO</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CO_VALUE_L1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row" v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) )">
                      <label class="col-sm-3 col-form-label">ค่า HC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.HC_VALUE_L1" type="text" readonly>
                      </div>
                    </div>

                    <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับน้ำมันอย่างเดียว)</h5>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                      <label class="col-sm-3 col-form-label">ค่า CO</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CO_VALUE_H1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                      <label class="col-sm-3 col-form-label">ค่า HC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.HC_VALUE_H1" type="text" readonly>
                      </div>
                    </div>

                    <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับก๊าซอย่างเดียว)</h5>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">
                      <label class="col-sm-3 col-form-label">ค่า CO</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CO_VALUE_L2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">
                      <label class="col-sm-3 col-form-label">ค่า HC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.HC_VALUE_L2" type="text" readonly>
                      </div>
                    </div>

                    <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับก๊าซอย่างเดียว)</h5>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                      <label class="col-sm-3 col-form-label">ค่า CO</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CO_VALUE_H2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                      <label class="col-sm-3 col-form-label">ค่า HC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.HC_VALUE_H2" type="text" readonly>
                      </div>
                    </div>

                    <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == '')">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</h5>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == '')">
                      <label class="col-sm-3 col-form-label">ค่า CO</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CO_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == '')">
                      <label class="col-sm-3 col-form-label">ค่า HC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.HC_VALUE" type="text" readonly>
                      </div>
                    </div>

                    <h5 v-if="datas.CAR_TYPE == 12">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</h5>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE == 12">
                      <label class="col-sm-3 col-form-label">ค่า CO</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CO_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row" v-if="datas.CAR_TYPE == 12">
                      <label class="col-sm-3 col-form-label">ค่า HC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.HC_VALUE" type="text" readonly>
                      </div>
                    </div>
                                              
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ตรวจที่ 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.USER1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ตรวจที่ 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.USER2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ค่าโคมไฟพุ่งไกล ด้านซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_DN_L_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ค่าโคมไฟพุ่งไกล ด้านขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_DN_R_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ค่าโคมไฟพุ่งต่ำ ด้านซ้าย	</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_DN_L_LEVEL" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ค่าโคมไฟพุ่งต่ำ ด้านขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_DN_R_LEVEL" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ตำแหน่งโคมไฟพุ่งไกล ด้านซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_UP_L_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ตำแหน่งโคมไฟพุ่งไกล ด้านขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_UP_R_VALUE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ตำแหน่งโคมไฟพุ่งต่ำ ด้านซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_UP_L_LEVEL" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ตำแหน่งโคมไฟพุ่งต่ำ ด้านขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.LIGHT_UP_R_LEVEL" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">IP ADDRESS</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PC_IP" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">MAC ADDRESS</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PC_MAC" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชื่อ สถานตรวจสภาพรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ระยะทางวิ่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_DISTANCE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รูปภาพ</label>
                      <div class="col-sm-9">
                        <p>
                          <a :href="storeAuth.UrlImagesCar+datas.PIC_CAR" target="_blank" class="previewImage">
                            <img :src="storeAuth.UrlImagesCar+datas.PIC_CAR" width="200"  />
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประเภทระยะทาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_DISTANCE_TYPE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขตัวถังรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHAISSE_NO" style="text-transform: uppercase;" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รูปเบรค</label>
                      <div class="col-sm-9">
                          <!-- <p><img src="../assets/images/no-image.png" border="0" width="50"  /></p> -->
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลักษณะรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHASSIS_NO_TYPE" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">สีรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_COLOR" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ขนาดสัดส่วนของรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ที่นั่งและจำนวนรถที่นั่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_SEAT_NO" type="text" readonly>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
              <div class="card-footer text-center">
                <a href="javascript:window.open('','_self').close();" class="btn btn-pill btn-danger">ปิดหน้านี้</a>
              </div>
            </div>
          </div>
        </div>
      </div>


  </template>
  
  <script setup> 
  import { ref,onMounted,inject } from 'vue';
  import { useRoute } from 'vue-router'
  import { storeAuth } from "../../store/modules/auth";
  import axios from "../../axios";

  const route = useRoute()
  let datas = ref();
  const Swal = inject('$swal')

  onMounted( async () => {
    await getdata_table();
  })
  async function getdata_table(){
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`getcardetails`,{
      rid : route.params.id,
      uid : storeAuth.id,
      gid : storeAuth.group_id
    });
    if(res.data.status == 1){
      datas.value = res.data.datas[0];
      Swal.close();
    }else{
      Swal.close();
    }
  }
function mountthai(id) {
  if(id == 1){
    return 'มกราคม';
  }else if(id == 2){
      return 'กุมภาพันธ์';
  }else if(id == 3){
      return 'มีนาคม';
  }else if(id == 4){
      return 'เมษายน';
  }else if(id == 5){
      return 'พฤษภาคม';
  }else if(id == 6){
      return 'มิถุนายน';
  }else if(id == 7){
      return 'กรกฎาคม';
  }else if(id == 8){
      return 'สิงหาคม';
  }else if(id == 9){
      return 'กันยายน';
  }else if(id == 10){
      return 'ตุลาคม';
  }else if(id == 11){
      return 'พฤศจิกายน';
  }else if(id == 12){
      return 'ธันวาคม';
  }
}
function typecar(id) {
  if(id == 1){
    return "รย.1";
  }else if(id == 2){
      return "รย.2";
  }else if(id == 3){
      return "รย.3";
  }else if(id == 12){
      return "รย.12";
  }
  
}

function typefuel(id) {
  if(id == 1){
    return 'เบนซิน';
  }else if(id == 2){
      return 'ดีเซล';
  }else if(id == 3){
      return 'ไฟฟ้า';
  }else if(id == 4){
      return 'เบนซินไฟฟ้า';
  }else if(id == 5){
      return 'ดีเซลไฟฟ้า';
  }else if(id == 6){
      return 'ก๊าซอย่างเดียว';
  }else if(id == 7){
      return 'เบนซินสลับก๊าซ';
  }else if(id == 8){
      return 'เบนซินไฟฟ้าสลับก๊าซ';
  }else if(id == 9){
      return 'ดีเซลร่วมก๊าซ';
  }else if(id == 10){
      return 'ดีเซลไฟฟ้าร่วมก๊าซ';
  }else{
      return id;
  }
}
//  PRINT
function printDiv() {
  window.frames["print_frame"].document.body.innerHTML = document.getElementById("printableTable").innerHTML;
  window.frames["print_frame"].window.focus();
  window.frames["print_frame"].window.print();
}

  </script>
  