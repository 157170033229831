<template>
  <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
        <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
      </filter>
    </svg>
  </div>
  <router-view v-else />
</template>

<script>
export default {

  name: 'App',
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    '$route'() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000)
      this.loading = !this.loading
    }
  },
  mounted() {
    this.loading = false;

  }
}
</script>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-bs5';
@import 'datatables.net-responsive-bs5';
@import 'datatables.net-select-bs5';
div.dt-container div.dt-length select {
    width: 6.3em;
}

table.dataTable th.dt-type-numeric {
  text-align: left;
}
table.dataTable td.dt-type-numeric {
  text-align: left;
}
.table-center {
  text-align: center;
}
div.dt-container div.dt-search input{
  width: 180px;
}
.pagination {
  justify-content: center !important;
}
@media only screen and (min-width: 768px) {
  .left-col {
      float: left;
      width: 40%;
  }
  .center-col {
      float: left;
      width: 25%;
  }
  .right-col {
      float: left;
      width: 35%;
  }
}
.page-link {
  width: 50px;
  height: 50px;
}
.dt-paging-button {
  list-style:none;
}
</style>



