import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/scss/app.scss';
import "bootstrap";
import 'bootstrap/dist/js/bootstrap.bundle';
import VueFeather from 'vue-feather';
import Breadcrumbs from './components/bread_crumbs';
import axios from "./axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from "vue3-apexcharts";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Multiselect from 'vue-multiselect';
import "vue-multiselect/dist/vue-multiselect.css";
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import Storage from 'vue-ls';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { install } from "vue3-recaptcha-v2";

const options = {
  namespace: '', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

const { ls } = Storage.useStorage(options)
// get token
try {
  if(!ls.get('public_token')){
    const response = await axios.get("token");
    // localStorage.setItem('public_token', response.data)
    ls.set('public_token', response.data,10 * 60 * 60 * 1000)
  }
} catch (err) {
  console.log(err);
}

const optionsPrint = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
    ],
    styles: [
      'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
  }

createApp(App)
    .use(router)
    .use(store)
    .use(VueSweetalert2)
    .use(CkeditorPlugin)
    .use(VueHtmlToPaper, optionsPrint)
    .use(install, {
      sitekey: "6LeVlnIqAAAAAFNBtjsyuoz87P6N2yapMtkmfKx3",
      cnDomains: false,
    })
    .component(VueFeather.name, VueFeather)
    .component('Breadcrumbs', Breadcrumbs)
    .component("apexchart", VueApexCharts)
    .component('VueDatePicker', VueDatePicker)
    .component('multiselect', Multiselect)
    .component('Cropper', Cropper)
    .mount('#app')