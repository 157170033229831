
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ค้นหาผลการตรวจตามกฎหมายว่าด้วยรถยนต์ที่ยังผ่าน (ภายใน 15 วัน นับจากวันที่ตรวจไม่ผ่าน)"/>

      <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                <form class="needs-validation" novalidate="" action="" method="POST">
                    <div class="row">
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end">เลขทะเบียนรถ</label>
                        <div class="col-sm-1">
                            <input v-model='car_lic_1' class="form-control" type="text" maxlength="20">
                        </div>
                            <div class="col-sm-2">
                            <input v-model='car_lic_2' class="form-control" type="number" maxlength="4">
                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-5 col-form-label text-end">จังหวัดของทะเบียนรถ</label>
                        <div class="col-sm-5">
                            <multiselect :options="searchcounty_value" :searchable="true" v-model="car_lic_prov" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary" @click="check" type="button">ค้นหา</button>
                    </div>
                    </div>
                    <br>
                </form>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div v-if="statusdata == 1" class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                <form class="needs-validation" novalidate="" action="" method="POST">
                    <div class="row">
                        <div id="printableTable" class="col-sm-12 text-center table-responsive">
                            <table class="table" id="tblData">
                                <tr>
                                    <th><h4>พบข้อมูลการตรวจสภาพรถ เลขทะเบียน {{countdata.CAR_LIC_ID_CHAR}}- {{countdata.CAR_LIC_ID_NUM}}  ประเภทรถ : {{ type_car }} ผ่านการตรวจมาแล้วจำนวน {{ countdata.CHECK_NUMBER }} ครั้ง</h4></th>
                                </tr>
                                <tr>
                                    <th>ตรวจสภาพเมื่อวันที่ : {{countdata.SEND_DATETIME}} ที่สถานตรวจสภาพรถชื่อ : {{countdata.TRO_NAME}}</th>
                                </tr>
                                <tr>
                                    <td>รายละเอียดที่ไม่ผ่าน</td>
                                </tr>
                                <tr>
                                    <td><b class="text-danger">{{countdata.ERROR_TXT}}</b></td>
                                </tr>
                            </table>
                        </div>
                        
                    <div v-if="countdata.entry_by == nameaddresscar" class="col-md-12 text-center mt-3">
                        <router-link class="btn btn-pill btn-primary btn-air-primary" :to="'/user/inspectioncarrepeat/'+countdata.ID">ตรวจสภาพอีกครั้ง</router-link>
                    </div>
                    <div v-else class="col-md-12 text-center mt-3">
                        <div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>รายละเอียด ! </strong> เนื่องจากรายการตรวจสภาพรถรายนี้ไม่ได้ถูกตรวจจากสถานตรวจสภาพของท่าน จึงไม่สามารถนำรายการนี้มาตรวจแก้ใหม่ได้ {{countdata.TRO_ID}}-{{countdata.TRO_NAME}}</div>
                    </div>
                    </div>
                    <br>
                </form>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

export default {
    data(){
        return{
            nameaddresscar: storeAuth.id,
            car_lic_1:'',
            car_lic_2:'',
            car_lic_prov:'',
            countdata:[],
            statusdata:0,
            type_car:'',
            count_error:0,
            searchcounty_value:[{id:'',names:''}],
        }
        }, 
    methods:{
        async getsh(){
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                });
                this.$swal.showLoading()

                const res = await axios.post(`getdatash`,{
                    uid: storeAuth.id,
                    gid: storeAuth.group_id,
                    cid: storeAuth.county_id
                });
                if(res.data.status == 1){
                this.searchcounty_value = res.data.datas.datact;
                this.$swal.close()
                }
            },
        searchcounty_select({names}) {return `${names}`},
        check(){
            if(!this.car_lic_1 || !this.car_lic_2 || !this.car_lic_prov){
                this.$swal({title:'กรุณากรอกข้อมูลให้ครบทุกช่องก่อนกดปุ่ม "ค้นหา" !', 
                icon:'warning', 
                }) 
            }else{
                let data = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        cid: storeAuth.county_id,
                        tid:1,
                        datas:{
                            car_lic_1:this.car_lic_1,
                            car_lic_2:this.car_lic_2,
                            car_lic_prov:this.car_lic_prov.id,
                            nameaddresscar:this.nameaddresscar
                        }
                    }
                this.getcount(data);
            }
        },
        getcount(data){
            let url = "repeatcar"; 
            axios.post(url,data).then(rs=>{
                if(rs.data.status == 1){
                    this.countdata = rs.data.datas;
                    this.statusdata = 1;
                    if(this.countdata.CAR_TYPE){
                        if(this.countdata.CAR_TYPE == 1){
                            this.type_car = 'รย.1';
                        }else if(this.countdata.CAR_TYPE == 2){
                            this.type_car = 'รย.2';
                        }else if(this.countdata.CAR_TYPE == 3){
                            this.type_car = 'รย.3';
                        }else if(this.countdata.CAR_TYPE == 12){
                            this.type_car = 'รย.12';
                        }
                    }
                }else{
                    this.countdata = '';
                    this.statusdata = 0;
                    this.$swal({title:'ไม่พบข้อมูลรายการตรวจที่เข้าเงื่อนไขการตรวจแก้ไข', 
                    icon:'error', 
                    }) 
                }
                }).catch(err=>{
                console.log(err)
            })
        },
    }, 
    computed:{
    }, 
    watch: {}, 
    components:{}, 
    beforeCreate(){}, 
    created(){
        this.getsh()
    }, 
    beforeMount(){}, 
    mounted(){}, 
    beforeUpdate(){}, 
    updated(){}, 
    beforeDestroy(){}, 
    destroyed(){}, 
}
</script>