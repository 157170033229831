
<template>
    <div class="container-fluid">
      <Breadcrumbs main="กำหนดตำแหน่งหน้ารายงาน(รถขนส่ง)"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="alert alert-secondary dark mt-3" role="alert">
                  <p><b>ข้อเสนอแนะ</b> ในการเลือกตำแหน่งบนรูปภาพนั้นท่านสามารถย่อหรือขยายรูปภาพได้ และย่อและขยายกรอบสำหรับโฟกัสตำแหน่งได้อย่างไม่จำกัด เมื่อท่านได้ท่านตำแหน่งที่ต้องการแล้วท่านจะต้องกดปุ่ม "อัพเดทค่า" ทุกครั้งก่อนเลือกกำหนดค่าอันอื่นๆต่อไป</p>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                <div class="col-md-12">
                        <div class="row g-3 needs-validation" novalidate>
                            <div class="col-md-2 position-relative">
                                <label class="form-label">ชื่อ : {{names[controlled.idx]}}</label>
                            </div>
                            <div class="col-md-4 position-relative">
                                <label class="form-label">ผลลัพธ์ที่ได้ : {{controlled.testans}}</label>
                            </div>
                            <div class="col-md-2 text-right">
                            <button class="btn btn-info btn-block" type="button" @click="ocrall" data-bs-original-title="" title="">ทดสอบค่าทั้งหมด</button>
                            </div>
                            <div class="col-md-2 text-right">
                            <button class="btn btn-info btn-block" type="button" @click="ocr" data-bs-original-title="" title="">ทดสอบ OCR</button>
                            </div>
                            <div class="col-md-2 text-right">
                            <button class="btn btn-primary btn-block" type="button" @click="getini" data-bs-original-title="" title="">โหลดค่าเดิม</button>
                            </div>
                        </div>
                        <hr class="mt-3 mb-3"/>
                        <div class="row g-3 needs-validation" novalidate>
                            <div class="col-md-2 position-relative">
                                <label class="form-label">ตำแหน่ง X</label>
                                <input class="form-control"  type="text" v-model="controlled.x" >
                            </div>
                            <div class="col-md-2 position-relative">
                                <label class="form-label">ตำแหน่ง Y</label>
                                <input class="form-control"  type="text" v-model="controlled.y" >
                            </div>
                            <div class="col-md-2 position-relative">
                                <label class="form-label">ความกว้าง</label>
                                <input class="form-control"  type="text" v-model="controlled.width" >
                            </div>
                            <div class="col-md-2 position-relative">
                                <label class="form-label">ความสูง</label>
                                <input class="form-control"  type="text" v-model="controlled.height" >
                            </div>
                            <div class="col-md-2 position-relative">
                                <label class="form-label">เลือกหน่วย</label>
                                <div>
                                    <select class="form-control" v-model="controlled.unit">
                                        <option v-for="(item, index) in dropdown[controlled.dropdown]" :value="item" :key="index">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">เมื่อได้ค่าและตำแหน่งที่ต้องการแล้ว</label>
                                <div class="col">
                                  <button class="btn btn-success btn-block" type="button" @click="updatesuccess" data-bs-original-title="" title="">อัพเดทค่า</button>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-3 mb-5"/>
                    </div>
                    <div class="col-md-6">
                    <cropper
                          ref="cop1"
                          :src="testtif"
                          auto-zoom="1"
                          :default-position="defaultPosition"
                          :default-size="defaultSize"
                          default-boundaries="fill"
                        @change="change"></cropper>
                    </div>
                    <div class="col-md-6">
                    <div class="row col-md-12">
                        <h4>
                            กำหนดตำแหน่งสำหรับการแปลงรูปเป็นข้อความ OCR
                        </h4>
                    </div>
                    <div class="col-md-12" :key="idx"
                    v-for="(item,idx) in datas">
                    <button type="button" class="btn btn-link" @click="changectrl(idx,item)">{{names[item.idx]}}</button>
                    <span class="text-danger">{{datas_all_test[idx] ? 'ค่าที่ได้ : ':''}}{{ datas_all_test[idx] }}</span>
                    </div>
                    <div class="row mt-5">
                        <button class="btn btn-secondary btn-block" type="button"  @click="setini">ทำการตั้งค่าและเลือกสำเร็จทั้งหมดแล้ว (บันทึกข้อมูล)</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { createWorker, PSM, OEM } from "tesseract.js";

export default {
  data() {
    return {
      ocrchanged:false,
      pointer: [],
      testtif: 'http://127.0.0.1:8080/img/CalibrateValue1.tif',

      controlled: {},
      currentidx: 0,
      dropdown: {
      a: ["N", "kN", "daN"],
      b: ["m/km", "mm/m"],
      c: ["kg", "N", "kN", "daN"]
      },
      datas:[],
      names: ['แรงห้ามล้อ เพลาที่ 1 L', 'แรงห้ามล้อ เพลาที่ 1 R', 'แรงห้ามล้อ เพลาที่ 2 L', 'แรงห้ามล้อ เพลาที่ 2 R',
      'แรงห้ามล้อ เพลาที่ 3 L', 'แรงห้ามล้อ เพลาที่ 3 R', 'แรงห้ามล้อ เพลาที่ 4 L', 'แรงห้ามล้อ เพลาที่ 4 R',
      'แรงห้ามล้อ เพลาที่ 5 L', 'แรงห้ามล้อ เพลาที่ 5 R', 'ศูนย์ล้อ', 'น้ำหนักลงเพลา 1',
      'น้ำหนักลงเพลา 2', 'น้ำหนักลงเพลา 3', 'น้ำหนักลงเพลา 4', 'น้ำหนักลงเพลา 5', 'แรงห้ามล้อมือ เพลาที่ 1 L',
      'แรงห้ามล้อมือ เพลาที่ 1 R', 'แรงห้ามล้อมือ เพลาที่ 2 L', 'แรงห้ามล้อมือ เพลาที่ 2 R',
      'แรงห้ามล้อมือ เพลาที่ 3 L', 'แรงห้ามล้อมือ เพลาที่ 3 R', 'แรงห้ามล้อมือ เพลาที่ 4 L',
      'แรงห้ามล้อมือ เพลาที่ 4 R', 'แรงห้ามล้อมือ เพลาที่ 5 L', 'แรงห้ามล้อมือ เพลาที่ 5 R',
      ],
      datas_all_test:[],
    }
  },
  methods: {
    async ocrall(){
      this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()

      var startTime, endTime;
      startTime = new Date();

      const rectangles  = this.datas.map((item)=>{
          return {
            left: item.x,
            top: item.y,
            width: item.width,
            height: item.height
          }
      })
 
          const worker = await createWorker()
          await worker.load();
          await worker.loadLanguage('eng');
          await worker.initialize('eng');
          const values = [];
          for (let i = 0; i < rectangles.length; i++) {
            const { data: { text } } = await worker.recognize( this.testtif, { rectangle: rectangles[i] });
            values.push(text);
          }

          await worker.terminate();

          this.datas_all_test = values.map(r => r.replace('\n',''))
          endTime = new Date();
          var timeDiff = endTime - startTime; //in ms
          timeDiff /= 1000;
          var seconds = Math.round(timeDiff);
          console.log(seconds + " seconds");

          this.$swal.close();
     
    },
    updatesuccess(){
      this.controlled.test = 1;
      this.datas[this.currentidx] = Object.assign({}, this.controlled);
      console.log(this.datas)
    },
    getMeta(){
        var img = new Image();
        var self = this;
        img.onload = function(){
            self.w = this.width;
            self.h = this.height;
        };
        img.src = this.testtif;
    },
    async ocr() {
        let rect = {};
        rect.width = this.controlled.width;
        rect.height = this.controlled.height;
        rect.left = this.controlled.x;
        rect.top = this.controlled.y;

        const worker = await createWorker()
        const rectangle = rect;
        await worker.load();
        await worker.loadLanguage('eng');
        await worker.initialize('eng');

        const {data: {text} } = await worker.recognize(this.testtif, { rectangle });

        this.controlled.testans = text;
        await worker.terminate();
    },
    getini() {
        fetch('http://127.0.0.1:8080/api/getini', {
              method: "GET"
          })
          .then((response) => {
            response.json().then((rs) => {
              let ars = '';
              if(rs.ocr.ocrsettingtruck === undefined){
                ars = JSON.parse(rs.ocr.ocrsettingbck);
              }else{
                ars = JSON.parse(rs.ocr.ocrsettingtruck);
              }
              this.datas = [];
              for(let i=0;i<ars.length;i++) {
                  this.datas[i] = ars[i]
              }
              this.currentidx = 0;
              this.controlled = Object.assign({},this.datas[0]);
            });
        }).catch((err) => {
          this.$swal({
                title: "ไม่สำเร็จ",
                text: "เรียกข้อมูลไม่ได้ กรุณาเปิดโปรแกรม v6(หน้าจอดำ) ค้างไว้ และลองอีกครั้ง",
                icon: "error"
            });
        });
    },
    setini() {
        let data = {}
        data.ocr = {};
        data.server = {};
        data.ocr.ocrsettingtruck = this.datas;

        console.log(data)

        fetch('http://127.0.0.1:8080/api/addocrsettingtruck', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
          })
          .then((rs) => {
            console.log(rs)
            this.$swal({
              title: 'ตั้งค่าข้อมูลเรียบร้อยแล้ว !',
              text: "ระบบจะทำการรีเซ็ทค่าให้โดยอัตโนมัติ",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#664284',
              confirmButtonText: 'ตกลง'
            }).then((result) => {
              this.getini()
            })
        }).catch((err) => {
          this.$swal({
                title: "ไม่สำเร็จ",
                text: "เกิดข้อผิดพลาดในการบันทึกช้อมูล",
                icon: "error"
            });
        });
    },
    changectrl(idx, data) {
        if (idx != this.currentidx) {
          this.datas[this.currentidx] = Object.assign({}, this.controlled);
          this.currentidx = idx;
          this.controlled = Object.assign({}, data);
          this.$refs.cop1.setCoordinates({
              width: this.controlled.width,
              height: this.controlled.height,
              left: this.controlled.x,
              top: this.controlled.y })
        }
    },
  async change({ coordinates, canvas }) {
    this.controlled.width = coordinates.width
    this.controlled.height = coordinates.height
    this.controlled.x = coordinates.left
    this.controlled.y = coordinates.top
    this.pointer = coordinates
    this.controlled.testans = '-- Test OCR --'
    return;
  },
},
computed:{
  defaultPosition() {
      if(this.controlled){
          return {
            left: this.controlled.x,
            top: this.controlled.y,
          };
      } else {
            return {
              left: 100,
              top:100
            }
      }
    },
    defaultSize() {
        if(this.controlled) {
          return {
            width: this.controlled.width,
            height: this.controlled.height,
          };
        } else {
          return {
            width: 100,
            height: 50
          }
        }
    },
},
created() {
    this.getini();
    this.getMeta();
},
mounted() {
},
}
</script>