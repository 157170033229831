
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ดาวน์โหลด"/>

        <div class="row">
          <div class="col-xl-3 box-col-6 pe-0">
            <div class="file-sidebar">
              <div class="card">
                <!-- <div class="card-body" style="font-size: 18px;">
                  <ul>
                    <li>    
                      <div class="btn btn-primary"><i data-feather="airplay"></i>โปรแกรม</div>
                    </li>
                    <li>
                      <div class="btn btn-light"><i data-feather="archive"></i>ไฟล์เอกสาร</div>
                    </li>
                  </ul>
                </div> -->
                <!-- <div class="row">
                  <div class="col-sm-3 col-xs-12"> -->
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <a class="nav-link" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="false"> <i data-feather="airplay" style="vertical-align: bottom;"></i> โปรแกรม</a>
                      <a class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true"> <i data-feather="archive" style="vertical-align: bottom;"></i> ไฟล์เอกสาร</a>
                    </div>
                  <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-md-12 box-col-12">
            <div class="file-content">
              <div class="card">
                <div class="tab-content" id="v-pills-tabContent">
                  <div class="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <div class="card-body file-manager" style="font-size: 18px;">
                      <h4 class="mb-3">ไฟล์ทั้งหมด</h4>
                      <ul class="files">
                        <li class="file-box">
                          <!-- <a type="button" id="info"> -->
                          <a href="../download/program/inspection.zip">
                            <div class="file-top">  <i class="fa fa-file-archive-o txt-secondary"></i><i class="fa fa-ellipsis-v f-14 ellips"></i></div>
                            <div class="file-bottom">
                                <h5 class="mt-3">โปรแกรมInspection</h5>
                                <h6 class="mb-1">ชื่อไฟล์ : release.zip </h6>
                                <p class="mb-1"><b>ขนาด : 6.02 MB</b></p>
                                <p><b>เวอร์ชั่น : 6.0.0.9</b></p>
                            </div>
                          </a>
                        </li>
                        <li class="file-box">
                          <a href="../download/program/7z.exe">
                            <div class="file-top">  <i class="fa fa-hdd-o txt-warning"></i><i class="fa fa-ellipsis-v f-14 ellips"></i></div>
                            <div class="file-bottom">
                                <h5 class="mt-3">โปรแกรม 7z(แก้ซิบ)</h5>
                                <h6 class="mb-1">ชื่อไฟล์ : 7z.exe </h6>
                                <p class="mb-1"><b>ขนาด : 1.13 MB</b></p>
                                <p><b>เวอร์ชั่น : 19.00</b></p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                    <div class="card-body file-manager" style="font-size: 18px;">
                      <h4 class="mb-3">เอกสารทั้งหมด</h4>
                      <ul class="files">
                        <li class="file-box">
                          <a href="../download/document/v6-1.pdf" target="_blank">
                            <div class="file-top">  <i class="fa fa-file-pdf-o txt-primary"></i><i class="fa fa-ellipsis-v f-14 ellips"></i></div>
                            <div class="file-bottom">
                                <h6 class="mt-3">คู่มือใช้โปรแกรม(V6)</h6>
                                <p class="mb-1">ชื่อไฟล์ : v6-1.pdf</p>
                                <p class="mb-1"><b>ขนาด : 5.03 MB</b></p>
                                <p><b>เวอร์ชั่น : 6.0.0.1</b></p>
                            </div>
                          </a>
                        </li>
                        <li class="file-box">
                          <a href="../download/document/v6-1-2.pdf" target="_blank">
                            <div class="file-top">  <i class="fa fa-file-pdf-o txt-paimary"></i><i class="fa fa-ellipsis-v f-14 ellips"></i></div>
                            <div class="file-bottom">
                                <h6 class="mt-3">คู่มือติดตั้งโปรแกรม</h6>
                                <p class="mb-1">ชื่อไฟล์ : v6-1-2.pdf</p>
                                <p class="mb-1"><b>ขนาด : 2.20 MB</b></p>
                                <p><b>เวอร์ชั่น : 0.1</b></p>
                            </div>
                          </a>
                        </li>
                        <li class="file-box">
                          <a href="../download/document/v6-1-3.pdf" target="_blank">
                            <div class="file-top">  <i class="fa fa-file-pdf-o txt-primary"></i><i class="fa fa-ellipsis-v f-14 ellips"></i></div>
                            <div class="file-bottom">
                                <h6 class="mt-3">คู่มือวิธีลงทะเบียนผู้ตรวจ</h6>
                                <p class="mb-1">ชื่อไฟล์ : v6-1-3.pdf </p>
                                <p class="mb-1"><b>ขนาด : 911 KB</b></p>
                                <p><b>เวอร์ชั่น : 0.1</b></p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>

</script>