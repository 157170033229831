
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลผู้ตรวจสภาพรถ"/>
        <div class="col-md-12 project-list">
            <div class="card">
                <div class="mb-3 row">
                    <span>สามารถคลิกที่ ลูกศร <i class="fa fa-play" aria-hidden="true"></i> ที่คอลั่ม "หมายเลขอ้างอิง" เพื่อดูข้อมูลเพิ่มติมได้ และคลิกที่ปุ่ม <i class="fa fa-pencil-square-o" aria-hidden="true"></i> เพื่อแก้ไขหรือเปลี่ยนรหัสผ่านของข้อมูลผู้ตรวจสภาพรถนั้นๆได้</span>
                </div>
            </div>
        </div>
        <div class="row project-cards">
            <div class="col-md-12">
              <div class="modal fade" id="model-vw" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">เปลี่ยนหรือแก้ไขรหัสผ่าน</b>
                            <button type="button" @click="closeModalvw()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >รหัสผ่านใหม่ที่ต้องการเปลี่ยนหรือแก้ไข</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" v-model="editdata[1]">
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="saveedit()" class="btn btn-primary" >บันทึกข้อมูล</button>
                          <button type="button" @click="closeModalvw()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>เลขที่ประจำตัวผู้ตรวจสภาพ</th>
                                <th>ชื่อ</th>
                                <th>นามสกุล</th>
                                <th>สถานะ</th>
                                <th class="none">วันที่ได้รับอนุญาต</th>
                                <th>วันสิ้นอายุใบอนุญาต</th>
                                <th>ชื่อผู้ใช้งาน</th>
                                <th class="none">เลขที่บัตรประชาชน</th>
                                <th>สถานตรวจสภาพที่ทำงานปัจจุบัน</th>
                                <th class="none">เลขที่ใบอนุญาตของสถานตรวจสภาพที่ทำงานปัจจุบัน</th>
                                <th class="none">จังหวัดที่ผู้ตรวจสภาพรถทำงานอยู่</th>
                                <th class="none">ภาพโปรไฟล์ของผู้ตรวจสถาพรถ</th>
                                <th class="none">ภาพบัตรช่างของผู้ตรวจสถาพรถ</th> 
                                <th class="none">เบอร์โทรศัพท์</th>
                                <th class="none">ไฟล์ PDF ที่แนบมา</th>
                                <th>จัดการ</th>
                              </tr>
                            </thead>
                            <template #column-16="props">
                              <div class="btn-group">
                                <button @click="getedit(props.cellData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_vw: null,
})

function openModalvw(){ state.modal_vw.show()}
function closeModalvw(){state.modal_vw.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const editdata = ref([]);

onMounted(() => {
  getdata_table();
  state.modal_vw = new Modal('#model-vw', {});
})

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`usertechnician`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([
        res.data.datas[i].id,res.data.datas[i].inspector_num,res.data.datas[i].first_name,res.data.datas[i].last_name,res.data.datas[i].status == 0?'ระงับการใช้งาน':'ใช้งานได้ปกติ',new Date(res.data.datas[i].authorized_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),new Date(res.data.datas[i].exp_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),res.data.datas[i].username,res.data.datas[i].idcard,res.data.datas[i].nameaddresscar,res.data.datas[i].license_number,res.data.datas[i].names,
        res.data.datas[i].img_profile?`<a href="${storeAuth.UrlImagesTechProfile}${res.data.datas[i].img_profile}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
        res.data.datas[i].img_addresscar?`<a href="${storeAuth.UrlImagesTechCard}${res.data.datas[i].img_addresscar}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
        res.data.datas[i].tel,
        res.data.datas[i].file_other?`<a href="${storeAuth.UrlFileTech}${res.data.datas[i].file_other}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
        res.data.datas[i].id,
      ]);
    }
    Swal.close();
  }else{
    Swal.close()
  }
}

function getedit(id){
  editdata.value = [];
  editdata.value[0] = id
  openModalvw()
}

async function saveedit(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`usertechnician`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 2,
    datas: editdata.value
  });
  if(res.data.status == 1){
    editdata.value = [];
    closeModalvw()
    Swal.fire({
      title:'สำเร็จ',
      text:'ระบบบันทึกข้อมูลเรียบร้อยแล้ว',
      icon:'success',
    })
  }else{
    Swal.fire({
      title:'เกิดข้อผิดพลาด',
      text:'ไม่สามารถเปลี่ยนรหัสผ่านได้',
      icon:'error',
    })
}
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '10%', targets: 0 },
        { width: '10%', targets: 3 },
        { width: '20%', targets: 6 },
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>