<template>
    <div>
  
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 p-0">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                  </a>
                  <h5 class="text-center mb-4">ตรวจสอบข้อมูลผลการตรวจสภาพรถตามกฎหมายว่าด้วยรถยนต์</h5>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                        <div v-if="datas" class="row">
                            <div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เลขทะเบียน</label>
                                <div class="col-sm-8">
                                    <input class="form-control" :value="datas.CAR_LIC_ID_CHAR + ' ' + datas.CAR_LIC_ID_NUM + ' ' + datas.names" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลการตรวจ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.STATUS_CHECK == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ลำดับการตรวจ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.ID" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เวลาที่ตรวจ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="new Date(datas.SEND_DATETIME).toLocaleString('en-GB', { timeZone: 'UTC' }).replace( /,/,'' )" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เลขที่ใบอนุญาต</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TRO_ID" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ชื่อสถานตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TRO_NAME" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผู้ได้รับใบอนุญาต</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TRO_OWNER_NAME" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">วันที่จดทะเบียน</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CAR_REG_DATE_1 + ' ' + mountthai(datas.CAR_REG_DATE_2) + ' ' + datas.CAR_REG_DATE_3" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ประเภท รย.</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="typecar(datas.CAR_TYPE)" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ชนิดเชื้อเพลิง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="typefuel(datas.FUELDESC_SELECT)" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">หมายเลขเครื่องยนต์</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.ENGINE_NO" type="text" style="text-transform: uppercase;" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 1 ช้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE1L" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 1 ขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE1R" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 2 ช้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE2L" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 2 ขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE2R" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 3 ช้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE3L" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 3 ขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE3R" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 4 ช้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE4L" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ เพลา 4 ขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FORCE_AXLE4R" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">น้ำหนักลงเพลา 1</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE1" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">น้ำหนักลงเพลา 2</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE2" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">น้ำหนักลงเพลา 3</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE3" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">น้ำหนักลงเพลา 4</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_WEIGHT_AXLE4" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลต่าง เพลา 1</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE1" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลต่าง เพลา 2</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE2" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลต่าง เพลา 3</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE3" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลต่าง เพลา 4</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_DIF_AXLE4" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ ซ้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_HAND_VAULE_L" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">แรงห้ามล้อ ขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_HAND_VAULE_R" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ประสิทธิภาพห้ามล้อ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_FOOT_EFFICIENCY" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ประสิทธิภาพห้ามล้อมือ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_HAND_EFFICIENCY" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ศูนย์ล้อ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TESTBRAKE_WHEEL_ALIGHTMENT" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลความแตกต่าง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.RESULT_DIFF_BRAKE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลเบรค</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.RESULT_FOOT_BRAKE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลเบรคมือ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.RESULT_HAND_BRAKE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลศูนย์ล้อ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.RESULT_WHEEL_ALIGHTMENT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลระดับเสียง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_VOICE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลมลพิษจากไอเสีย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_PIPE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลแตรสัญญาณ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_HOOTER == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลเครื่องวัดความเร็ว</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_SPEED == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลโคมไฟพุ่งไกล โคมไฟพุ่งต่ำ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_HEAD_LIGHT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผลโคมไฟเลี้ยว โคมไฟป้าย โคมไฟหยุด</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_SIGNAL_LIGHT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_PLATE_LIGHT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_WINDOWS_GLASS == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ระบบบังคับเลี้ยวและพวงมาลัย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_CONTROL_SYS == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ล้อและยาง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_RUBBER == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ถังเชื้อเพลิง และท่อส่ง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_FUEL_TANK == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เครื่องล่าง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_SUSPENSION == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">สภาพตัวถังและโครงรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_BODY == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ประตูและพื้นรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_DOOR == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เข็มขัดนิรภัย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_BELT == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เครื่องปัดน้ำฝน</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_RAIN_FLY == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row"> 
                                <label class="col-sm-4 col-form-label">อื่นๆ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHECK_AUTHE == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">อื่นๆ(รายละเอียดเพิ่มเติม)</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.FAILED_REASONS" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ค่าเครื่องวัดเสียง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.VOICE_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ค่าไอเสีย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.PIPE_VALUE" type="text" readonly>
                                </div>
                                </div>

                                <h5 v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) )">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</h5>
                                <div class="mb-3 row" v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) )">
                                <label class="col-sm-4 col-form-label">ค่า CO</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CO_VALUE_L1" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row" v-if="(datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) )">
                                <label class="col-sm-4 col-form-label">ค่า HC</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.HC_VALUE_L1" type="text" readonly>
                                </div>
                                </div>

                                <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับน้ำมันอย่างเดียว)</h5>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                                <label class="col-sm-4 col-form-label">ค่า CO</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CO_VALUE_H1" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 1 || datas.FUELDESC_SELECT == 4 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                                <label class="col-sm-4 col-form-label">ค่า HC</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.HC_VALUE_H1" type="text" readonly>
                                </div>
                                </div>

                                <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับก๊าซอย่างเดียว)</h5>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">
                                <label class="col-sm-4 col-form-label">ค่า CO</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CO_VALUE_L2" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8)">
                                <label class="col-sm-4 col-form-label">ค่า HC</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.HC_VALUE_L2" type="text" readonly>
                                </div>
                                </div>

                                <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับก๊าซอย่างเดียว)</h5>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                                <label class="col-sm-4 col-form-label">ค่า CO</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CO_VALUE_H2" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == 6 || datas.FUELDESC_SELECT == 7 || datas.FUELDESC_SELECT == 8) && (new Date(datas.CAR_REG_DATE_1+'-'+datas.CAR_REG_DATE_2+'-'+(datas.CAR_REG_DATE_3 - 543)) >= new Date('1-1-2014'))">
                                <label class="col-sm-4 col-form-label">ค่า HC</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.HC_VALUE_H2" type="text" readonly>
                                </div>
                                </div>

                                <h5 v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == '')">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</h5>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == '')">
                                <label class="col-sm-4 col-form-label">ค่า CO</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CO_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE != 12 && (datas.FUELDESC_SELECT == '')">
                                <label class="col-sm-4 col-form-label">ค่า HC</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.HC_VALUE" type="text" readonly>
                                </div>
                                </div>

                                <h5 v-if="datas.CAR_TYPE == 12">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว)</h5>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE == 12">
                                <label class="col-sm-4 col-form-label">ค่า CO</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CO_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row" v-if="datas.CAR_TYPE == 12">
                                <label class="col-sm-4 col-form-label">ค่า HC</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.HC_VALUE" type="text" readonly>
                                </div>
                                </div>
                                                        
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผู้ตรวจที่ 1</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.USER1" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ผู้ตรวจที่ 2</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.USER2" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ค่าโคมไฟพุ่งไกล ด้านซ้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_DN_L_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ค่าโคมไฟพุ่งไกล ด้านขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_DN_R_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ค่าโคมไฟพุ่งต่ำ ด้านซ้าย	</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_DN_L_LEVEL" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ค่าโคมไฟพุ่งต่ำ ด้านขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_DN_R_LEVEL" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ตำแหน่งโคมไฟพุ่งไกล ด้านซ้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_UP_L_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ตำแหน่งโคมไฟพุ่งไกล ด้านขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_UP_R_VALUE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ตำแหน่งโคมไฟพุ่งต่ำ ด้านซ้าย</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_UP_L_LEVEL" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ตำแหน่งโคมไฟพุ่งต่ำ ด้านขวา</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.LIGHT_UP_R_LEVEL" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">IP ADDRESS</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.PC_IP" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">MAC ADDRESS</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.PC_MAC" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ชื่อ สถานตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.TRO_NAME" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ระยะทางวิ่ง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CAR_DISTANCE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">รูปภาพ</label>
                                <div class="col-sm-8">
                                    <p>
                                        <a :href="'https://api.dlt.in.th/inspection/'+datas.PIC_CAR" target="_blank" class="previewImage">
                                            <img :src="'https://api.dlt.in.th/inspection/'+datas.PIC_CAR" width="200"  />
                                        </a>
                                    </p>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ประเภทระยะทาง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CAR_DISTANCE_TYPE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">เลขตัวถังรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHAISSE_NO" style="text-transform: uppercase;" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">รูปเบรค</label>
                                <div class="col-sm-8">
                                    <!-- <p><img src="../assets/images/no-image.png" border="0" width="50"  /></p> -->
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ลักษณะรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CHASSIS_NO_TYPE" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">สีรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CAR_COLOR" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ขนาดสัดส่วนของรถ</label>
                                <div class="col-sm-8">
                                <input class="form-control" type="text" readonly>
                                </div>
                                </div>
                                <div class="mb-3 row">
                                <label class="col-sm-4 col-form-label">ที่นั่งและจำนวนรถที่นั่ง</label>
                                <div class="col-sm-8">
                                <input class="form-control" :value="datas.CAR_SEAT_NO" type="text" readonly>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <p>ไม่พบข้อมูลที่ต้องการตรวจสอบ</p>
                        </div>
                      </div>
                    </div>
                    <div class="btn-showcase text-center">
                       <router-link to="/" class="btn btn-outline-primary-2x" type="button" title="กลับสู้หน้าหลัก">กลับสู้หน้าหลัก</router-link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref,inject,onMounted } from 'vue';
  import { useRoute } from 'vue-router'
  import axios from "../../axios";

  const Swal = inject('$swal')
  const route = useRoute()

  let datas = ref('');
  let id = ref(route.params.id);
  let pass = ref(route.params.key);
  let typeinspec = ref(route.params.type);

  onMounted( async () => {
    await getdata();
  })
  async function getdata() {
    if(!id.value){
        Swal.fire({
            title: "คำเตือน",
            text: "ไม่มีข้อมูล เลขอ้างอิง",
            icon: "warning"
        });
    }else if(!pass.value){
        Swal.fire({
            title: "คำเตือน",
            text: "ไม่มีข้อมูล รหัสผ่าน",
            icon: "warning"
        });
    }else if(!typeinspec.value){
        Swal.fire({
            title: "คำเตือน",
            text: "ไม่มีข้อมูล ประเภทผลตรวจ",
            icon: "warning"
        });
    }else{
        Swal.fire({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        Swal.showLoading()

        const res = await axios.post("inspectionfront",{
            tid : typeinspec.value,
            id : id.value,
            pass : pass.value
        },
        );
        if(res.data.status == 1){
            datas.value = res.data.datas
            
            Swal.fire({
                title: "สำเร็จ",
                text: "พบข้อมูลที่ต้องการตรวจสอบ",
                icon: "success"
            });
        }else{
            datas.value = '';
            Swal.fire({
                title: "ไม่สำเร็จ",
                text: "ไม่พบข้อมูลที่ต้องการตรวจสอบ",
                icon: "error"
            });
        }
     }
  }

  function mountthai(id) {
  if(id == 1){
    return 'มกราคม';
  }else if(id == 2){
      return 'กุมภาพันธ์';
  }else if(id == 3){
      return 'มีนาคม';
  }else if(id == 4){
      return 'เมษายน';
  }else if(id == 5){
      return 'พฤษภาคม';
  }else if(id == 6){
      return 'มิถุนายน';
  }else if(id == 7){
      return 'กรกฎาคม';
  }else if(id == 8){
      return 'สิงหาคม';
  }else if(id == 9){
      return 'กันยายน';
  }else if(id == 10){
      return 'ตุลาคม';
  }else if(id == 11){
      return 'พฤศจิกายน';
  }else if(id == 12){
      return 'ธันวาคม';
  }
}
function typecar(id) {
  if(id == 1){
    return "รย.1";
  }else if(id == 2){
      return "รย.2";
  }else if(id == 3){
      return "รย.3";
  }else if(id == 12){
      return "รย.12";
  }
  
}

function typefuel(id) {
  if(id == 1){
    return 'เบนซิน';
  }else if(id == 2){
      return 'ดีเซล';
  }else if(id == 3){
      return 'ไฟฟ้า';
  }else if(id == 4){
      return 'เบนซินไฟฟ้า';
  }else if(id == 5){
      return 'ดีเซลไฟฟ้า';
  }else if(id == 6){
      return 'ก๊าซอย่างเดียว';
  }else if(id == 7){
      return 'เบนซินสลับก๊าซ';
  }else if(id == 8){
      return 'เบนซินไฟฟ้าสลับก๊าซ';
  }else if(id == 9){
      return 'ดีเซลร่วมก๊าซ';
  }else if(id == 10){
      return 'ดีเซลไฟฟ้าร่วมก๊าซ';
  }else{
      return id;
  }
}

  </script>
  