import axios from 'axios';
import Storage from 'vue-ls';

const options = {
  namespace: '', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

const { ls } = Storage.useStorage(options)

const instance = axios.create({
  // baseURL: 'http://192.168.1.237:8002/tro/auth/',
  baseURL: 'https://api.dlt.in.th/tro/auth/',
});

instance.interceptors.request.use(config => {
  // const token = localStorage.getItem('token');
  // const public_token = localStorage.getItem('public_token');
  const token = ls.get('token');
  const public_token = ls.get('public_token');
  if (token) {
    config.headers.Authorization = token;
  }
  if(public_token){
    config.headers.Publictoken = public_token;
  }
  return config;
});

export default instance;
