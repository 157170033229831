
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ข้อมูลสถานตรวจสภาพรถ"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
          <div class="card">
              <div class="needs-validation">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">Username</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" :value="data.username" disabled>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ชื่อสถานตรวจสภาพรถ</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" :value="data.nameaddresscar" disabled>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ประเภทรถที่ได้รับอนุญาต</label>
                        <div class="col-sm-9">
                            <div class="form-group m-t-15 custom-radio-ml">
                                <div class="radio radio-primary">
                                    <input id="radio1" type="radio" v-model="data.car_type" value="1" disabled>
                                    <label for="radio1">รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภทและทุกขนาดน้ำหนัก</label>
                                </div>
                                <div class="radio radio-primary">
                                    <input id="radio3" type="radio" v-model="data.car_type" value="2" disabled>
                                    <label for="radio3">รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม และรถจักรยานต์</label>
                                </div>
                                <div class="radio radio-primary">
                                    <input id="radio4" type="radio" v-model="data.car_type" value="3" disabled>
                                    <label for="radio4">รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์</label>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">เลขที่ใบอนุญาต</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" :value="data.license_number2" disabled>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ที่อยู่ของสถานตรวจสภาพรถ</label>
                        <div class="col-sm-9">
                          <textarea class="form-control" rows="3" v-model="data.addresscar" disabled></textarea>
                        </div>
                      </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">จังหวัด/เขต(ที่กำกับดูแล)</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" :value="data.names" disabled>
                        </div>
                      </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">อำเภอ</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" :value="data.district_name" disabled>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" :value="data.zipcode" disabled>
                        </div>
                      </div>
                      <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">สถานะ</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" :value="data.status == '0'? 'ระงับการใช้งาน':'ปกติ'"  disabled>
                        </div>
                      </div>
                        <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ภาพถ่ายสำนักงาน <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                        <div class="col-sm-9"> 
                          <a v-if="data.img_office" class="mt-1" :href="storeAuth.UrlImagesTroOffice+data.img_office" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                          <b v-else>ไม่มีไฟล์รูปที่แนบ</b>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ภาพถ่ายสถานตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                        <div class="col-sm-9">
                          <a v-if="data.img_addresscar" class="mt-1" :href="storeAuth.UrlImagesTro+data.img_addresscar" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                          <b v-else>ไม่มีไฟล์รูปที่แนบ</b>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ข้อมูลหรือเอกสารอื่นๆ ตามที่กรมการขนส่งทางบกกำหนดรูปแบบไฟล์ pdf <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                        <div class="col-sm-9">
                          <a v-if="data.file_other" class="mt-1" :href="storeAuth.UrlFileTro+data.file_other" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                          <b v-else>ไม่มีไฟล์ PDF ที่แนบ</b>
                        </div>
                      </div>
                      <div class="mb-3 row" >
                        <label class="col-sm-3 col-form-label">วันปฏิบัติงาน เวลา</label>
                        <div class="col-sm-9">
                          <div class="table-responsive">
                            <table class="table table-bordered checkbox-td-width">
                                <thead>
                                    <tr>
                                        <td>วัน</td>
                                        <td>เวลาเริ่มทำงาน</td>
                                        <td>เวลาสิ้นสุดการทำงาน</td>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani1" type="checkbox" v-model="working_d1" true-value="1" false-value="" disabled>จันทร์
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d1_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d1_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani2" type="checkbox" v-model="working_d2" true-value="1" false-value="" disabled>อังคาร
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d2_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d2_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani3" type="checkbox" v-model="working_d3" true-value="1" false-value="" disabled>พุธ
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d3_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d3_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani4" type="checkbox" v-model="working_d4" true-value="1" false-value="" disabled>พฤหัสบดี
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d4_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d4_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani5" type="checkbox" v-model="working_d5" true-value="1" false-value="" disabled>ศุกร์
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d5_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d5_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani6" type="checkbox" v-model="working_d6" true-value="1" false-value="" disabled>เสาร์
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d6_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d6_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="d-block" for="chk-ani">
                                          <input class="checkbox_animated" id="chk-ani7" type="checkbox" v-model="working_d7" true-value="1" false-value="" disabled>อาทิตย์
                                        </label>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d7_in" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control" type="time" v-model="working_d7_out" disabled><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                      </div>
                      <hr>
                      <br>
                        <div class="text-center">
                            <h5 class="text-primary">สามารถแก้ไขได้</h5>
                        </div>
                        <br>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">อีเมล์</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" v-model="data.email">
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          <button class="btn btn-info" type="button" @click="getLocation()">กดเพื่อรับค่า ละติจูดกับลองติจูด</button>
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ละติจูด</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" v-model="data.lat">
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">ลองติจูด</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" v-model="data.lon">
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">เบอร์โทรสาร</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" v-model="data.fax">
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">เบอร์โทรศัพท์</label>
                        <div class="col-sm-9">
                          <input class="form-control" type="text" v-model="data.tel">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="text-center">
                    <button class="btn btn-primary" @click="updateddata()">บันทึกข้อมูล</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')

const data = ref([]);

onMounted(() => {
  getdata();
})

function getLocation(){
  navigator.geolocation.getCurrentPosition((position) => {
    let lat = position.coords.latitude;
    let long = position.coords.longitude;

    data.value.lat = lat.toFixed(2);
    data.value.lon = long.toFixed(2);
  });
}

async function getdata(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`usertro`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 1,
  });
  if(res.data.status == 1){
    data.value = res.data.datas[0]
    Swal.close();
  }else{
    Swal.close();
  }
}

async function updateddata(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`usertro`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 2,
    datas:[data.value.email,data.value.lat,data.value.lon,data.value.fax,data.value.tel]
  });
  if(res.data.status == 1){
    Swal.close();
    Swal.fire({
      title: "สำเร็จ",
      text: "ระบบบันทึกข้อมูลของท่ายเรียบร้อยแล้ว",
      icon: "success"
    });
  }else{
    Swal.close();
    Swal.fire({
      title: "ไม่สำเร็จ",
      text: "เกิดช้อผิดพลาด",
      icon: "error"
    });
  }
}
</script>