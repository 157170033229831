<template>
  <div class="container-fluid">
    <div class="row project-cards">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="needs-validation">
                <div class="row">
                    <div class="col-sm-12 text-center alert alert-danger dark alert-dismissible fade show" role="alert" >
                      <p> ตรวจสอบและลงทะเบียนกล้องถ่ายรูปและอุปกรณ์ฮาร์ดแวร์ที่เชื่อมกับระบบ V6</p>
                    </div>
                    <div v-show="isGetCamera" class="row justify-content-center">
                      <div class=" col-6">
                        <div class="input-group">
                          <select class="form-select" v-model="selectedcam">
                              <option value="">เลือกไดร์กล้อง</option>
                              <option v-for = "(opcam, index) in optionscam" :key="index" :value="opcam.value">
                                {{ opcam.text }}
                              </option>
                          </select>
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-secondary" @click="stCamera">
                                <span v-if="!isCameraOpen">ทดสอบกล้อง</span>
                                <span v-else>ปิดกล้อง</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <div  v-show="isCameraOpen && isLoading" class="spinner-border text-primary mt-5" role="status">
                            <span class="sr-only">กำลังโหลดกล้อง...</span>
                        </div>
                        <div v-if="isCameraOpen" v-show="!isLoading" class="mt-3">
                            <video v-show="!isPhotoTaken" ref="camera" :width="640" :height="480" autoplay></video>
                            <canvas v-show="isPhotoTaken && !imageview" id="photoTaken" ref="canvas" width="640" height="480"></canvas>
                            <img v-show="imageview" :src="imageview" width="640" height="480" />
                        </div>
                      </div>
                    </div>
                    <div v-show="isCameraOpen" class="col-md-12 text-center mt-3">
                      <button  v-show="!isPhotoTaken" type="button" class="btn btn-primary" @click="takePhoto">ถ่ายภาพ</button>
                      <button  v-show="isPhotoTaken" type="button" class="btn btn-primary" @click="takePhoto">ถ่ายภาพอีกครั้ง</button>
                      <div v-show="imageview" class="mt-3">
                          <div class="alert alert-warning outline" role="alert">
                            <p>กรอบสีเหลืองในรูป คือ ตำแหน่งของการตรวจสอบยานพาหนะที่อยู่ในรูป ซึ่งระบบจะตรวจทั้งหมด 3 อย่าง คือ 1 รถยนต์ 2 รถกระบ 3 รถมอเตอร์ไซต์ หากเจอข้อมูลจะวงกรอบรอบตำแหน่งของยานพาหนะที่พบพร้อมทั้งตัวหนังสือที่บอกว่าเป็นยานพาหนะชนิดใด</p>
                          </div>
                          <div class="alert alert-primary outline" role="alert">
                            <p>กรอบที่ม่วงในรูป คือ ตำแหน่งของการสอบป้ายทะเบียนของยานพาหนะและตรวจสอบเลขทะเบียนที่ตรวจสอบ ซึ่งนอกจากกรอบที่ม่วงที่จะวงรอบตำแหน่งที่พบแล้ว ระบบยังจะแสดงข้อความของ เลขเลขทะเบียนที่ตรวจจับได้ พร้อมทั้งให้คำแนนภาพป้ายทะเบียนเป็นเปอร์เซ็น ทุกแย่างทุกถูกเก็บข้อมูลไว้รวมกับผลการตรวจนั้นๆ</p>
                          </div>
                          <div class="alert alert-danger outline" role="alert">
                            <p>ตัวหนังสือสีแดงในรูป คือ เวลาถ่ายรูปและเลขที่อนุญาตของสถานตรวจสภาพรถ "กก.001/2570" คือตัวอย่าง</p>
                          </div>
                        </div>
                      <hr>
                    </div>
                  <div class="col-md-12 text-center mt-3">
                      <button v-show="camerabutton" class="btn btn-pill btn-warning btn-air-warning text-dark" @click="toggleCamera" type="button">กดเพื่อตรวจสอบกล้องถ่ายรูปรถที่เชื่อมกับระบบ</button>
                      <label v-show="!camerabutton" class="btn btn-pill btn-danger btn-air-danger">เปิดกล้องไม่ได้เนื่องจากมีปัญหาในการโหลดฟังชั่น AI</label>
                      <p class="mt-4">แนะนำให้เปิดกับ <a class="_blank" style="color:red" href="https://www.mozilla.org/th/firefox/new/?gclid=CjwKEAjww_a8BRDB-O-OqZb_vRASJAA9yrc5tHYaVq1RnmMknAHY9hIRefb1Dnpk4HlkB5I8mRAj6xoCl3jw_wcB" target="_blank">Firefox</a> , <a style="color:#d6c000" class="_blank" href="https://www.google.com/intl/th/chrome/browser/" target="_blank">Chrome</a>  | ลิขสิทธิ์ {{ year }} © กรมการขนส่งทางบก </p>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref,onMounted,inject } from 'vue';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import * as tf from '@tensorflow/tfjs';
import {createWorker} from 'tesseract.js';

const Swal = inject('$swal')

onMounted(() => {
  loadModel();
})

const datacamera = ref('');
const selectedcam = ref('');
const optionscam = ref([]);
const isCameraOpen = ref(false);
const isGetCamera = ref(false);
const isLoading = ref(false);
const camera = ref()
const camerabutton = ref(false)

const canvas = ref();
const isPhotoTaken = ref(false);
const isShotPhoto = ref(false);
const imageview = ref();
const image = ref();
const imagenew = ref();
let model = null;
let label = null;
let fil = 0; // ฟิวเตอร์ ปิด 0/เปิด 1 ป้ายทะเบียน
const Modeldata = ref('/model/tro/model.json');
const dataai = ref({
        typecar_detail:'',
        klass: '',
        score: '0',
    });

function takePhoto() {
    dataai.value.typecar_detail = '';
    dataai.value.klass = '';
    dataai.value.score = 0;

    if(!isPhotoTaken.value) {
        isShotPhoto.value = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
        isShotPhoto.value = false;
        }, FLASH_TIMEOUT);

        isPhotoTaken.value = !isPhotoTaken.value;

        const contexts = canvas.value.getContext('2d');
              contexts.drawImage(camera.value, 0, 0, 640, 480);

        uploadImage();
    }else{
        isPhotoTaken.value = !isPhotoTaken.value;
        imageview.value = '';
    }

}

async function uploadImage() {
      Swal.fire({
          title: 'ระบบกำลังตรวจสอบรูป',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
      });
      Swal.showLoading()

      const getb64img = await document.getElementById("photoTaken").toDataURL("image/jpeg" , 1.0)
      const countvehicle = await processImage(getb64img)
      if(countvehicle === true){
        Swal.close()
      }else{
        Swal.fire({
            title: "คำเตือน",
            text: "ตรวจสอบรูปแล้วไม่พบยานพาหนะในรูปกรุณาถ่ายใหม่หรือปรับมุมกล้องให้ชัดเจนขึ้น",
            icon: "error"
        }); 
      }
  }

function toggleCamera() {
  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    optionscam.value = []
    const getCameraSelection = async () => {
      await navigator.mediaDevices.getUserMedia({video: true});  
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      datacamera.value = videoDevices;
      if(datacamera.value.length <= 0){
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ไม่พบข้อมูลไดร์กล้อง ลองตรวจอุปกรณ์กล้องว่ามีการเชื่อมต่อกับคอมพิวเตอร์เครื่องนี้หรือยัง จากนั้นกดปุ่ม \"กดเพื่อตรวจสอบกล้องถ่ายรูปรถที่เชื่อมกับระบบ\" อีกครั้ง",
          icon: "error"
        }); 
      }else{
        isGetCamera.value = true
        videoDevices.map(videoDevice => {
          optionscam.value.push({ text: videoDevice.label, value: videoDevice.deviceId })
        });
      }
    };
  
  getCameraSelection();

  }else{
    Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "เบราว์เซอร์ไม่รองรับการทำงานของฟังชั่น",
        icon: "error"
      }); 
  }
}

function stCamera(){
  if(isCameraOpen.value) {
      isCameraOpen.value = false;
      stopCameraStream();
  } else {
    if(!selectedcam.value){
      Swal.fire({
        title: "ตำเตือน",
        text: "ยังไม่ได้เลือกกล้องที่ต้องการทดสอบ",
        icon: "warning"
      });
    }else{
      isCameraOpen.value = true;
      createCameraElement();
    }
  }
}
function createCameraElement() {
  if(!selectedcam.value){
    Swal.fire({
        title: "ตำเตือน",
        text: "ยังไม่ได้เลือกกล้องที่ต้องการทดสอบ",
        icon: "warning"
      });
  }else{
    isLoading.value = true;
    if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: {deviceId: { exact: selectedcam.value }} })
        .then(stream => {
          isLoading.value = false;
          camera.value.srcObject = stream;
        })
        .catch(error => {
          isLoading.value = false;
          Swal.fire({
            title: "ตำเตือน",
            text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาเช็คอุปกรณ์รวมจุดเชื่อมต่อต่างๆของท่านว่าทำงานปกติหรือไม่ จากนั้นปิดโปรแกรมแล้วเปิดใหม่อีกครั้ง",
            icon: "error"
          });
        });
    }
  }
}
function stopCameraStream() {
  let tracks = camera.value.srcObject.getTracks();
      tracks.forEach(track => {
          track.stop();
      });
}


async function loadModel  () {
      Swal.fire({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
      });
      Swal.showLoading()
      try {
      const savedModel = localStorage.getItem('Tro');
      if (savedModel) {
        model = await tf.loadGraphModel('indexeddb://Tro'); 
        Swal.close();
      } else {
        // Loading Model for first time
        model = await tf.loadGraphModel(Modeldata.value);
        localStorage.setItem('Tro', true);
        model.save('indexeddb://Tro')
        Swal.close();
      }
      camerabutton.value = true
    }catch(error){
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ระบบไม่สามารถโหลดฟังชั่น AI ได้",
        icon: "error",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "ลองใหม่อีกครั้ง",
        denyButtonText: `ยกเลิกการโหลดฟังชั่น AI`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          localStorage.removeItem("Tro");
          localStorage.removeItem("Trolabel");
          loadModel();
        } else if (result.isDenied) {
          Swal.fire({
            title: "คำแนะนำ",
            html: `หากเครื่องของท่านโหลดฟังชั่น Ai ไม่สำเร็จจะทำให้ไม่สามารถใช้งานระบบในวันที่ 9 พ.ย. ได้ท่านสามารถคลิกเพื่อดูแนวทางแก้ปัญหาอื่นๆได้ที่ <a href="https://v6.inspection.dlt.go.th/download/document/v6-6-6.pdf" target="_blank">"คู่มือการแก้ไขปัญหาฟังชั่น AI เบื้องต้น"</a>`,
            icon: "warning",
          });
        }
      });
    }
  }

  async function processImage(base64) {
    const imageElement =  new Image();
    imageElement.src = base64;
    const sizeimg = await getDimensions(base64) 
    const canvas = document.createElement("canvas");
    const context = canvas.getContext('2d');
    canvas.width = sizeimg.width;
    canvas.height = sizeimg.height;
    // Draw the uploaded image onto the canvas
    context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
    // Detect vehicles using COCO-SSD
    const cocoModel = await cocoSsd.load();
    const vehiclePredictions = await cocoModel.detect(imageElement);

    // Loop through detected vehicles
    async function svehicles () {
        for await (const prediction of vehiclePredictions) {
            const [x, y, width, height] = prediction.bbox;
            const label = prediction.class;

            // Check if the detected object is a vehicle (car, truck, motorcycle)
            if (label === 'car' || label === 'truck' || label === 'motorcycle') {
                // Draw blue bounding box for vehicles
                context.beginPath();
                context.rect(x, y, width, height);
                context.lineWidth = 2;
                context.strokeStyle = '#efbf04'; // Blue border for detected vehicles
                context.fillStyle = 'rgba(0, 0, 255, 0.3)';
                // context.fill(); // ใส่สีพื้นหลังในกรอบ
                context.stroke();

                let typecar_detail = '';
                if(label === 'car'){
                    typecar_detail = 'รถยนต์';
                }else if(label === 'truck'){
                    typecar_detail = 'รถกระบะ';
                }else if(label === 'motorcycle'){
                    typecar_detail = 'รถมอเตอร์ไซต์';
                }

                dataai.value.typecar_detail = typecar_detail

                // context.font = '37px Arial';
                // context.fillStyle = '#df2700';
                // context.fillText(`${new Date().toLocaleString("en-GB").replace( /,/,"" )} กก.001/2570`, 40, 240);

                // Draw label above the bounding box (display the type of the object)
                context.font = '20px Arial';
                context.fillStyle = '#efbf04';
                context.fillText(typecar_detail, x, y > 10 ? y - 5 : 10); // Display the label above the bounding box

                  checkModel(canvas.toDataURL("image/jpeg", 1.0))
                  return true
            }
        }
    }

    const chechstatus = await svehicles() ;
    if(chechstatus === true){
        return true
    }else{
        return false
    }

}
function getDimensions(image){
    return new Promise((resolve, reject)=>{
        const img = new Image();
        img.src = image;

        img.onload = () => {
            resolve({width: img.width, height: img.height})
        }
    })
}

async function checkModel (imagedata){
    // console.log(await getDimensions(imagedata))
    const imagee =  new Image();
    imagee.src = imagedata;
    const sizeimg = await getDimensions(imagedata) 

    const width1 = sizeimg.width;
    const height1= sizeimg.height;
    const batched = tf.tidy(() => {

    const img = tf.browser.fromPixels(imagee);
    const small = tf.image.resizeBilinear(img, [640, 640]).div(255);
        return small.expandDims(0);
    })

    const result = await model.execute(batched);
    const transRes = await result.transpose([0, 2, 1]);

    const boxes = tf.tidy(() => {
        const w = transRes.slice([0, 0, 2], [-1, -1, 1]); // get width
        const h = transRes.slice([0, 0, 3], [-1, -1, 1]); // get height
        const x1 = tf.sub(transRes.slice([0, 0, 0], [-1, -1, 1]), tf.div(w, 2)); // x1
        const y1 = tf.sub(transRes.slice([0, 0, 1], [-1, -1, 1]), tf.div(h, 2)); // y1
        let object = tf.concat([x1,y1,tf.add(x1, w),tf.add(y1, h)],2).squeeze();
        return object;
    });
    const scores = tf.tidy(() => {
        const rawScores = transRes.slice([0, 0, 4], [-1, -1, 1]).squeeze(); // class scores
        return rawScores;
    });
    var detections = non_max_suppression(boxes.arraySync(),scores.arraySync());
    const boxes_plot =  shortenedCol(detections, [0,1,2,3]);
    var ratio = [];
    ratio[0]= (width1/640);
    ratio[1]= (height1/640);

    const resultURL = await crop(imagee,boxes_plot,ratio,fil);

    imageview.value = resultURL;
    
    tf.dispose(result);
    tf.dispose(transRes);
    tf.dispose(boxes);
    tf.dispose(scores);
    tf.dispose(detections);
}

    function non_max_suppression(boxes , scores, conf_thresh=0.50, iou_thresh=0.2, max_det = 300){

    // Initialize an empty list to store the selected boxes
    const selected_detections = [];

    for (let i = 0; i < scores.length; i++) {

        // Check if the box has sufficient score to be selected
        if (scores[i] < conf_thresh) {
            continue;
            }
        var box = boxes[i];
        const score = scores[i];
        let object = box;
        let addBox = true;


        // Check for overlap with previously selected boxes
        for (let j = 0; j < selected_detections.length; j++) {
            let selectedBox = selected_detections[j];

            // Calculate the intersection and union of the two boxes
            let intersectionXmin = Math.max(object[0], selectedBox[0]);
            let intersectionYmin = Math.max(object[1], selectedBox[1]);
            let intersectionXmax = Math.min(object[2], selectedBox[2]);
            let intersectionYmax = Math.min(object[3], selectedBox[3]);
            let intersectionWidth = Math.max(0, intersectionXmax - intersectionXmin);
            let intersectionHeight = Math.max(0, intersectionYmax - intersectionYmin);
            let intersectionArea = intersectionWidth * intersectionHeight;
            let boxArea = (object[2] - object[0]) * (object[3] - object[1]);
            let selectedBoxArea = (selectedBox[2] - selectedBox[0]) * (selectedBox[3] - selectedBox[1]);
            let unionArea = boxArea + selectedBoxArea - intersectionArea;

            // Calculate the IoU and check if the boxes overlap
            let iou = intersectionArea / unionArea;
            if (iou >= iou_thresh) {
                addBox = false;
                break;
        }
        }

        // Add the box to the selected boxes list if it passed the overlap check
        if (addBox) {
            const row = box.concat(score);
            selected_detections.push(row);
        }
    }

    return selected_detections
    }

    function shortenedCol(arrayofarray, indexlist) {
    return arrayofarray.map(function (array) {
        return indexlist.map(function (idx) {
            return array[idx];
        });
    });
    }

    function thresholdFilter(pixels, level) {
    if (level === undefined) {
        level = 0.5;
    }
    const thresh = Math.floor(level * 255);
    for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i];
        const g = pixels[i + 1];
        const b = pixels[i + 2];
        const gray = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        let val;
        if (gray >= thresh) {
        val = 255;
        } else {
        val = 0;
        }
        pixels[i] = pixels[i + 1] = pixels[i + 2] = val;
    }
    };

    function getARGB (data, i) {
    const offset = i * 4;
    return (
        ((data[offset + 3] << 24) & 0xff000000) |
        ((data[offset] << 16) & 0x00ff0000) |
        ((data[offset + 1] << 8) & 0x0000ff00) |
        (data[offset + 2] & 0x000000ff)
    );
    };

    function setPixels (pixels, data) {
    let offset = 0;
    for (let i = 0, al = pixels.length; i < al; i++) {
        offset = i * 4;
        pixels[offset + 0] = (data[i] & 0x00ff0000) >>> 16;
        pixels[offset + 1] = (data[i] & 0x0000ff00) >>> 8;
        pixels[offset + 2] = data[i] & 0x000000ff;
        pixels[offset + 3] = (data[i] & 0xff000000) >>> 24;
    }
    };

    let blurRadius;
    let blurKernelSize;
    let blurKernel;
    let blurMult;

    function buildBlurKernel(r) {
    let radius = (r * 3.5) | 0;
    radius = radius < 1 ? 1 : radius < 248 ? radius : 248;

    if (blurRadius !== radius) {
    blurRadius = radius;
    blurKernelSize = (1 + blurRadius) << 1;
    blurKernel = new Int32Array(blurKernelSize);
    blurMult = new Array(blurKernelSize);
    for (let l = 0; l < blurKernelSize; l++) {
        blurMult[l] = new Int32Array(256);
    }

    let bk, bki;
    let bm, bmi;

    for (let i = 1, radiusi = radius - 1; i < radius; i++) {
        blurKernel[radius + i] = blurKernel[radiusi] = bki = radiusi * radiusi;
        bm = blurMult[radius + i];
        bmi = blurMult[radiusi--];
        for (let j = 0; j < 256; j++) {
        bm[j] = bmi[j] = bki * j;
        }
    }
    bk = blurKernel[radius] = radius * radius;
    bm = blurMult[radius];

    for (let k = 0; k < 256; k++) {
        bm[k] = bk * k;
    }
    }
    }

    function blurARGB(pixels, canvas, radius) {
    const width = canvas.width;
    const height = canvas.height;
    const numPackedPixels = width * height;
    const argb = new Int32Array(numPackedPixels);
    for (let j = 0; j < numPackedPixels; j++) {
    argb[j] = getARGB(pixels, j);
    }
    let sum, cr, cg, cb, ca;
    let read, ri, ym, ymi, bk0;
    const a2 = new Int32Array(numPackedPixels);
    const r2 = new Int32Array(numPackedPixels);
    const g2 = new Int32Array(numPackedPixels);
    const b2 = new Int32Array(numPackedPixels);
    let yi = 0;
    buildBlurKernel(radius);
    let x, y, i;
    let bm;
    for (y = 0; y < height; y++) {
    for (x = 0; x < width; x++) {
        cb = cg = cr = ca = sum = 0;
        read = x - blurRadius;
        if (read < 0) {
        bk0 = -read;
        read = 0;
        } else {
        if (read >= width) {
            break;
        }
        bk0 = 0;
        }
        for (i = bk0; i < blurKernelSize; i++) {
        if (read >= width) {
            break;
        }
        const c = argb[read + yi];
        bm = blurMult[i];
        ca += bm[(c & -16777216) >>> 24];
        cr += bm[(c & 16711680) >> 16];
        cg += bm[(c & 65280) >> 8];
        cb += bm[c & 255];
        sum += blurKernel[i];
        read++;
        }
        ri = yi + x;
        a2[ri] = ca / sum;
        r2[ri] = cr / sum;
        g2[ri] = cg / sum;
        b2[ri] = cb / sum;
    }
    yi += width;
    }
    yi = 0;
    ym = -blurRadius;
    ymi = ym * width;
    for (y = 0; y < height; y++) {
    for (x = 0; x < width; x++) {
        cb = cg = cr = ca = sum = 0;
        if (ym < 0) {
        bk0 = ri = -ym;
        read = x;
        } else {
        if (ym >= height) {
            break;
        }
        bk0 = 0;
        ri = ym;
        read = x + ymi;
        }
        for (i = bk0; i < blurKernelSize; i++) {
        if (ri >= height) {
            break;
        }
        bm = blurMult[i];
        ca += bm[a2[read]];
        cr += bm[r2[read]];
        cg += bm[g2[read]];
        cb += bm[b2[read]];
        sum += blurKernel[i];
        ri++;
        read += width;
        }
        argb[x + yi] =
        ((ca / sum) << 24) |
        ((cr / sum) << 16) |
        ((cg / sum) << 8) |
        (cb / sum);
    }
    yi += width;
    ymi += width;
    ym++;
    }
    setPixels(pixels, argb);
    }

    function invertColors(pixels) {
    for (var i = 0; i < pixels.length; i+= 4) {
        pixels[i] = pixels[i] ^ 255; // Invert Red
        pixels[i+1] = pixels[i+1] ^ 255; // Invert Green
        pixels[i+2] = pixels[i+2] ^ 255; // Invert Blue
    }
    }

    function dilate(pixels, canvas) {
    let currIdx = 0;
    const maxIdx = pixels.length ? pixels.length / 4 : 0;
    const out = new Int32Array(maxIdx);
    let currRowIdx, maxRowIdx, colOrig, colOut, currLum;

    let idxRight, idxLeft, idxUp, idxDown;
    let colRight, colLeft, colUp, colDown;
    let lumRight, lumLeft, lumUp, lumDown;

    while (currIdx < maxIdx) {
    currRowIdx = currIdx;
    maxRowIdx = currIdx + canvas.width;
    while (currIdx < maxRowIdx) {
        colOrig = colOut = getARGB(pixels, currIdx);
        idxLeft = currIdx - 1;
        idxRight = currIdx + 1;
        idxUp = currIdx - canvas.width;
        idxDown = currIdx + canvas.width;

        if (idxLeft < currRowIdx) {
        idxLeft = currIdx;
        }
        if (idxRight >= maxRowIdx) {
        idxRight = currIdx;
        }
        if (idxUp < 0) {
        idxUp = 0;
        }
        if (idxDown >= maxIdx) {
        idxDown = currIdx;
        }
        colUp = getARGB(pixels, idxUp);
        colLeft = getARGB(pixels, idxLeft);
        colDown = getARGB(pixels, idxDown);
        colRight = getARGB(pixels, idxRight);

        //compute luminance
        currLum =
        77 * ((colOrig >> 16) & 0xff) +
        151 * ((colOrig >> 8) & 0xff) +
        28 * (colOrig & 0xff);
        lumLeft =
        77 * ((colLeft >> 16) & 0xff) +
        151 * ((colLeft >> 8) & 0xff) +
        28 * (colLeft & 0xff);
        lumRight =
        77 * ((colRight >> 16) & 0xff) +
        151 * ((colRight >> 8) & 0xff) +
        28 * (colRight & 0xff);
        lumUp =
        77 * ((colUp >> 16) & 0xff) +
        151 * ((colUp >> 8) & 0xff) +
        28 * (colUp & 0xff);
        lumDown =
        77 * ((colDown >> 16) & 0xff) +
        151 * ((colDown >> 8) & 0xff) +
        28 * (colDown & 0xff);

        if (lumLeft > currLum) {
        colOut = colLeft;
        currLum = lumLeft;
        }
        if (lumRight > currLum) {
        colOut = colRight;
        currLum = lumRight;
        }
        if (lumUp > currLum) {
        colOut = colUp;
        currLum = lumUp;
        }
        if (lumDown > currLum) {
        colOut = colDown;
        currLum = lumDown;
        }
        out[currIdx++] = colOut;
    }
    }
    setPixels(pixels, out);
    };

    function preprocessImage(canvas) {
    const processedImageData = canvas.getContext('2d').getImageData(0,0,canvas.width, canvas.height);
    blurARGB(processedImageData.data, canvas, 1);
    dilate(processedImageData.data, canvas);
    invertColors(processedImageData.data);
    thresholdFilter(processedImageData.data, 0.4);
    return processedImageData;
    };

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function crop(image,boxes,ratio,fil){
    let base64Image;
    const canva = document.createElement("canvas");
    canva.width = ratio[0]*640;
    canva.height = ratio[1]*640;
    const ctx2 = canva.getContext("2d");
    ctx2.drawImage(image, 0, 0);
    const selected_detections = [];
    const font = "18px sans-serif";
    ctx2.font = font;
    ctx2.textBaseline = "top";
    ctx2.strokeStyle = "#B033FF";
    ctx2.lineWidth = 2;
    let i=0;
    for(i=0;i<boxes.length;++i){
        const canvas = document.createElement("canvas");
        const scaleX = 1;
        const scaleY = 1;
        let [x1, y1, x2, y2] = boxes[i];
        x1 *= ratio[0];
        x2 *= ratio[0];
        y1 *= ratio[1];
        y2 *= ratio[1];
        const width = x2 - x1;
        const height = y2 - y1;
        canvas.width = width;
        canvas.height = height;
        const immg = new Image(width,height);
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
        image,
        x1 * scaleX,
        y1 * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        width,
        height
        );
        if(fil===1){
        const res = preprocessImage(canvas);
        ctx.putImageData(res,0,0);
        }
        immg.onload = function() {
        ctx2.drawImage(immg, 0,0,width,height,x1 * scaleX,y1 * scaleY,width * scaleX,height * scaleY);
        };
        base64Image = canvas.toDataURL("image/jpeg", 1.0);
        immg.src = base64Image;

        const worker = await createWorker('eng');
        await worker.setParameters({
            tessedit_char_whitelist: '0123456789',
        });
        await worker.recognize(base64Image)
        .catch (err => {
            console.error(err);
        })
        .then(result => {
        // Get Confidence score
        let confidence = result.data.confidence;
        // Get full output
        var text = []
        text[0] = result.data.text.replace("\n", '').slice(-4);
        text[1] = confidence;
        selected_detections.push(text);
        const klass = text[0];
        const score = text[1];
        ctx2.strokeRect(x1, y1, width, height);
        
        dataai.value.klass =  klass;
        dataai.value.score =  score;
        // Draw the label background.
        ctx2.fillStyle = "#B033FF";
        const textWidth = ctx2.measureText(klass + " - " + score + "%").width;
        const textHeight = parseInt(font, 10); // base 10
        ctx2.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

        // Draw labels
        ctx2.fillStyle = "#ffffff";
        if(klass){
            ctx2.fillText(klass + " - " + score + "%", x1 - 1, y1 - (textHeight + 2));
        }else{
            ctx2.fillText(score + "%", x1 - 1, y1 - (textHeight + 2));
        }
        })
        await worker.terminate();
    }
    // เพิ่มลายน้ำสีแดง
    ctx2.font = '37px Arial';
    ctx2.fillStyle = 'rgba(255, 0, 0, 0.4)';
    ctx2.fillText(`${new Date().toLocaleString("en-GB").replace( /,/,"" )} กก.001/2570`, 40, 240);

    // await delay(2000);
    const resutl=canva.toDataURL("image/jpeg");
    return resutl;
    }   

</script>