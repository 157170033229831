<template>
    <div>
  
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 p-0">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                  </a>
                  <h5 class="text-center mb-4">เนื้อหาประกาศจากทางกรมการขนส่งทางบก</h5>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                      <div class="card-header text-center">
                        <h4>{{ datas[1] }}</h4>
                        <small>{{ datas[0] }}</small>
                      </div>
                      <div class="card-body">
                            <div v-html="datas[2]"></div>
                      </div>
                    </div>
                    <div class="btn-showcase text-center">
                       <router-link to="/" class="btn btn-outline-primary-2x" type="button" title="กลับสู้หน้าหลัก">กลับสู้หน้าหลัก</router-link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { ref,onMounted } from 'vue';
  import { useRoute } from 'vue-router'
  import axios from "../../axios";

  const route = useRoute()
  let datas = ref([]);

  onMounted( async () => {
    await getdata_table();
  })
  async function getdata_table(){
    const res = await axios.post(`indexpostid`,{
      rid : route.params.id
    });
    if(res.data.status == 1){
      datas.value = [new Date(res.data.datas[0].created_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[0].post_title,res.data.datas[0].post_detail];
    }
  }

  </script>
  