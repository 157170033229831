
<template>
    <div class="container-fluid">
      <Breadcrumbs main="เพิ่มรายงานผลการตรวจรถตามกฎหมายว่าด้วยรถยนต์"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
          <div class="card">
              <div class="card-header">
                <div v-if="!page_status" class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>คำแนะนำ ! </strong> กรูณาเปิดโปรแกรม Inspection ก่อน หลังจากนั้น <a href="javascript:window.location.reload(true)" class="text-light">รีเฟรชหน้าเว็บไซต์</a> ใหม่อีกครั้ง <a href="javascript:window.location.reload(true)" class="btn btn-warning text-dark">หรือกดที่นี่</a></div>
                <h5>กรุณากรอกข้อมูลให้ครบถ้วน ก่อนกดบันทึก !</h5>
              </div>
              <div class="needs-validation">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                    <div class="mb-2 row">
                      <div class="col-sm-12 text-center web-camera-container">
                        <div v-show="openCameras" class="camera-button">
                            <button type="button" class="btn" :class="{ 'btn-primary' : !isCameraOpen, 'btn-danger' : isCameraOpen}" @click="toggleCamera">
                                <span v-if="!isCameraOpen">เปิดกล้อง</span>
                                <span v-else>ปิดกล้อง</span>
                            </button>
                        </div>
                        <div  v-show="isCameraOpen && isLoading" class="spinner-border text-primary mt-5" role="status">
                            <span class="sr-only">กำลังโหลดกล้อง...</span>
                        </div>
                        <div v-if="isCameraOpen" v-show="!isLoading" class="mt-3" :class="{ 'flash' : isShotPhoto }">

                            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>

                            <video v-show="!isPhotoTaken" ref="camera" :class="{ 'camera-box' : camera_setting_turn }" :width="640" :height="480" autoplay></video>
                            <canvas v-show="isPhotoTaken && !imageview" id="photoTaken" ref="canvas" width="640" height="480"></canvas>
                            <img v-show="imageview" :src="imageview" width="640" height="480" />
                        </div>

                        <div v-if="isCameraOpen && !isLoading" class="mt-3">
                            <button  v-show="!isPhotoTaken" type="button" class="btn btn-primary" @click="takePhoto">ถ่ายภาพ</button>
                            <button  v-show="isPhotoTaken" type="button" class="btn btn-primary" @click="takePhoto">ถ่ายภาพอีกครั้ง</button>
                        </div>
                      </div>
                    </div>
                    <hr>
                      <div class=" col-sm-12">
                        <div class="mb-2 row">
                            <div class="mb-2 row col-sm-6">
                                <label class="col-sm-4 col-form-label">วันที่ตรวจ</label>
                                <div class="col-sm-8">
                                <input v-model="checkdate" class="form-control" type="text" readonly>
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-6">
                                <label class="col-sm-4 col-form-label">เวลา</label>
                                <div class="col-sm-8">
                                <input v-model="checktime" class="form-control" type="text" readonly>
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-12">
                                <label class="col-sm-3 col-form-label">เลขที่ใบอนุญาต</label>
                                <div class="col-sm-9">
                                    <input v-model="tro_id" class="form-control" type="text" readonly>
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-12">
                                <label class="col-sm-3 col-form-label">ชื่อสถานตรวจสภาพรถ</label>
                                <div class="col-sm-9">
                                    <input v-model="tro_name" class="form-control" type="text" readonly>
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-12">
                                <label class="col-sm-3 col-form-label">ผู้ได้รับใบอนุญาต</label>
                                <div class="col-sm-9">
                                    <input v-model="tro_owner_name" class="form-control" type="text" readonly>
                                </div>
                            </div>
                            
                        </div>
                        <hr>
                        <div class="mb-2 row">
                            <div class="mb-2 row col-sm-7">
                                <label class="col-sm-3 col-form-label text-danger">เลขทะเบียน</label>
                                <div class="mb-2 row col-sm-9">
                                    <div class="col-sm-5">
                                        <input v-model="car_lic_id_char" class="form-control" type="text" maxlength="18">
                                    </div>
                                    <div class="col-sm-1">
                                        -
                                    </div>
                                    <div class="col-sm-6">
                                        <input v-model="car_lic_id_num" class="form-control" type="text" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" maxlength="4">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-5">
                                <label class="col-sm-3 col-form-label text-danger">จังหวัด</label>
                                <div class="col-sm-9">
                                    <multiselect :options="searchcounty_value" :searchable="true" v-model="car_lic_prov" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-12">
                                <label class="col-sm-2 col-form-label text-danger">เลขตัวถังรถ</label>
                                <div class="col-sm-9">
                                <input v-model="chaisse_no" class="form-control" style="text-transform: uppercase;" type="text">
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-4">
                                <label class="col-sm-4 col-form-label text-danger">ประเภท</label>
                                <div class="col-sm-8">
                                    <select v-model="typecar" class="form-select" @change="changedate" >
                                            <option value="null">กรุณาเลือก</option>
                                            <option v-if="storeAuths.car_type == 1 || storeAuths.car_type == 2" value="01">รย.1</option>
                                            <option v-if="storeAuths.car_type == 1 || storeAuths.car_type == 2" value="02">รย.2</option>
                                            <option v-if="storeAuths.car_type == 1 || storeAuths.car_type == 2" value="03">รย.3</option>
                                            <option v-if="storeAuths.car_type == 1 || storeAuths.car_type == 2 || storeAuths.car_type == 3" value="12">รย.12</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2 row col-sm-4" >
                                <label class="col-sm-5 col-form-label text-danger">ประเภทเครื่องยนต์</label>
                                <div class="col-sm-7">
                                      <select v-model="ry_id" class="form-select">
                                        <option value="null">กรุณาเลือก</option>
                                        <option value="1">เครื่องยนต์สันดาปภายใน</option>
                                        <option value="2">ไฮบริด/ปลั๊ก-อิน ไฮบริด</option>
                                        <option value="3">ไฟฟ้า</option>
                                    </select>
                                </div>
                            </div> 
                            <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-4 col-form-label text-danger">ชนิดเชื้อเพลิง</label>
                                    <div class="col-sm-8">
                                        <select v-model="fueldesc_select" class="form-select">
                                            <option value="null">กรุณาเลือก</option>
                                            <option value="1">เบนซิน</option>
                                            <option value="2">ดีเซล</option>
                                            <option value="3">ไฟฟ้า</option>
                                            <option value="4">เบนซินไฟฟ้า</option>
                                            <option value="5">ดีเซลไฟฟ้า</option>
                                            <option value="6">ก๊าซอย่างเดียว</option>
                                            <option value="7">เบนซินสลับก๊าซ</option>
                                            <option value="8">เบนซินไฟฟ้าสลับก๊าซ</option>
                                            <option value="9">ดีเซลร่วมก๊าซ</option>
                                            <option value="10">ดีเซลไฟฟ้าร่วมก๊าซ</option>
                                    </select>
                                    </div>
                                </div>
                            <div class="mb-2 row col-sm-6">
                                    <label class="col-sm-3 col-form-label text-danger">ยี่ห้อรถ</label>
                                    <div class="col-sm-9">
                                        <multiselect :options="searchcarband_value" :searchable="true" v-model="car_band" :custom-label="searchcarband_select" placeholder="เลือกยี่ห้อ" />
                                    </div>
                                </div>
                            <div class="mb-2 row col-sm-6">
                                <label class="col-sm-5 col-form-label text-danger">หมายเลขเครื่องยนต์</label>
                                <div class="col-sm-7">
                                    <input v-model="engine_no" class="form-control" type="text" style="text-transform: uppercase;">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <button class="btn btn-primary" @click="getsends">ตรวจสอบข้อมูลรถ</button>
                            </div>
                        </div>

                      </div>
                      
                      <hr>
                      <div class=" col-sm-12">
                            <div class="mb-2 row">
                                <div class="mb-2 row col-sm-12">
                                    <label class="col-sm-2 col-form-label text-danger">วันที่จดทะเบียน</label>
                                    <div class="mb-2 row col-sm-10">
                                        <div class="col-sm-3">
                                            <select v-model="car_reg_date_1" class="form-select">
                                                <option value="null">วันที่</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-1">
                                            /
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="col-sm-12">
                                                <select v-model="car_reg_date_2" class="form-select">
                                                    <option value="null">เดือน</option>
                                                    <option value="1">มกราคม</option>
                                                    <option value="2">กุมภาพันธ์</option>
                                                    <option value="3">มีนาคม</option>
                                                    <option value="4">เมษายน</option>
                                                    <option value="5">พฤษภาคม</option>
                                                    <option value="6">มิถุนายน</option>
                                                    <option value="7">กรกฎาคม</option>
                                                    <option value="8">สิงหาคม</option>
                                                    <option value="9">กันยายน</option>
                                                    <option value="10">ตุลาคม</option>
                                                    <option value="11">พฤศจิกายน</option>
                                                    <option value="12">ธันวาคม</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            /
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="col-sm-12">
                                                <select v-model="car_reg_date_3" class="form-select">
                                                    <option value="null">พ.ศ.</option>
                                                    <option value="2481">2481</option>
                                                    <option value="2482">2482</option>
                                                    <option value="2481">2481</option>
                                                    <option value="2484">2484</option>
                                                    <option value="2485">2485</option>
                                                    <option value="2486">2486</option>
                                                    <option value="2487">2487</option>
                                                    <option value="2488">2488</option>
                                                    <option value="2489">2489</option>
                                                    <option value="2490">2490</option>
                                                    <option value="2491">2491</option>
                                                    <option value="2492">2492</option>
                                                    <option value="2493">2493</option>
                                                    <option value="2494">2494</option>
                                                    <option value="2495">2495</option>
                                                    <option value="2496">2496</option>
                                                    <option value="2497">2497</option>
                                                    <option value="2498">2498</option>
                                                    <option value="2499">2499</option>
                                                    <option value="2500">2500</option>
                                                    <option value="2501">2501</option>
                                                    <option value="2502">2502</option>
                                                    <option value="2503">2503</option>
                                                    <option value="2504">2504</option>
                                                    <option value="2505">2505</option>
                                                    <option value="2506">2506</option>
                                                    <option value="2507">2507</option>
                                                    <option value="2508">2508</option>
                                                    <option value="2509">2509</option>
                                                    <option value="2510">2510</option>
                                                    <option value="2511">2511</option>
                                                    <option value="2512">2512</option>
                                                    <option value="2513">2513</option>
                                                    <option value="2514">2514</option>
                                                    <option value="2515">2515</option>
                                                    <option value="2516">2516</option>
                                                    <option value="2517">2517</option>
                                                    <option value="2518">2518</option>
                                                    <option value="2519">2519</option>
                                                    <option value="2520">2520</option>
                                                    <option value="2521">2521</option>
                                                    <option value="2522">2522</option>
                                                    <option value="2523">2523</option>
                                                    <option value="2524">2524</option>
                                                    <option value="2525">2525</option>
                                                    <option value="2526">2526</option>
                                                    <option value="2527">2527</option>
                                                    <option value="2528">2528</option>
                                                    <option value="2529">2529</option>
                                                    <option value="2530">2530</option>
                                                    <option value="2531">2531</option>
                                                    <option value="2532">2532</option>
                                                    <option value="2533">2533</option>
                                                    <option value="2534">2534</option>
                                                    <option value="2535">2535</option>
                                                    <option value="2536">2536</option>
                                                    <option value="2537">2537</option>
                                                    <option value="2538">2538</option>
                                                    <option value="2539">2539</option>
                                                    <option value="2540">2540</option>
                                                    <option value="2541">2541</option>
                                                    <option value="2542">2542</option>
                                                    <option value="2543">2543</option>
                                                    <option value="2544">2544</option>
                                                    <option value="2545">2545</option>
                                                    <option value="2546">2546</option>
                                                    <option value="2547">2547</option>
                                                    <option value="2548">2548</option>
                                                    <option value="2549">2549</option>
                                                    <option value="2550">2550</option>
                                                    <option value="2551">2551</option>
                                                    <option value="2552">2552</option>
                                                    <option value="2553">2553</option>
                                                    <option value="2554">2554</option>
                                                    <option value="2555">2555</option>
                                                    <option value="2556">2556</option>
                                                    <option value="2557">2557</option>
                                                    <option value="2558">2558</option>
                                                    <option value="2559">2559</option>
                                                    <option value="2560">2560</option>
                                                    <option value="2561">2561</option>
                                                    <option value="2562">2562</option>
                                                    <option value="2563">2563</option>
                                                    <option value="2564">2564</option>
                                                    <option value="2565">2565</option>
                                                    <option value="2566">2566</option>
                                                    <option value="2567">2567</option>
                                                    <option value="2568">2568</option>
                                                    <option value="2569">2569</option>
                                                    <option value="2570">2570</option>
                                                    <option value="2571">2571</option>
                                                    <option value="2572">2572</option>
                                                    <option value="2573">2573</option>
                                                    <option value="2574">2574</option>
                                                    <option value="2575">2575</option>
                                                    <option value="2576">2576</option>
                                                    <option value="2577">2577</option>
                                                    <option value="2578">2578</option>
                                                    <option value="2579">2579</option>
                                                    <option value="2580">2580</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-4 col-form-label">ระยะทางวิ่ง</label>
                                    <div class="col-sm-8">
                                        <input v-model="current_distance" class="form-control" type="number" onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-3">
                                    <div class="col-sm-12">
                                        <select v-model="car_ditance_type" class="form-select">
                                                <option value="null">หน่วย</option>
                                                <option value="km">กิโลเมตร</option>
                                                <option value="mi">ไมล์</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-3">
                                    <label class="col-sm-6 col-form-label text-danger">น้ำหนักรถ</label>
                                    <div class="col-sm-6">
                                        <input v-model="car_weight" class="form-control" type="number" onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-2">
                                        <label class="col-sm-12 col-form-label">กิโลกรัม</label>
                                </div>
                        </div>
                        <hr>
                        <div class="mb-2 row">
                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-4 col-form-label text-info">ลักษณะรถ</label>
                                    <div class="mb-2 row col-sm-8">
                                        <input v-model="carinfo.KINDDESC" class="form-control" type="text" readonly>
                                    </div>
                                </div>

                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-2 col-form-label text-info">สี</label>
                                    <div class="col-sm-10">
                                        <input v-model="carinfo.COLOR" class="form-control" type="text" readonly>
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-4 col-form-label text-info">เชื้อเพลิง</label>
                                    <div class="col-sm-8">
                                        <input v-model="carinfo.FUELDESC" class="form-control" type="text" readonly>
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-4 col-form-label">เลขถังแก๊ส</label>
                                    <div class="mb-2 row col-sm-8">
                                        <input v-model="gas_number" class="form-control" type="text">
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-8">
                                    <label class="col-sm-2 col-form-label">จำนวน</label>
                                    <div class="col-sm-3">
                                    <input v-model="axle" class="form-control" type="text" oninput="this.value = this.value.replace(/\D+/g, '')" maxlength="2">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา</label>
                                    <div class="col-sm-3">
                                        <input v-model="wheel" class="form-control" type="text" oninput="this.value = this.value.replace(/\D+/g, '')" maxlength="2">
                                    </div>
                                    <label class="col-sm-2 col-form-label">ล้อ</label>
                                </div>
                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-3 col-form-label">ยาง</label>
                                    <div class="col-sm-6">
                                        <input v-model="tire_no" class="form-control" type="text" oninput="this.value = this.value.replace(/\D+/g, '')" maxlength="2">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เส้น</label>
                                </div>
                                <div class="mb-2 row col-sm-4">
                                    <label class="col-sm-3 col-form-label">จำนวน</label>
                                    <div class="col-sm-6">
                                        <input v-model="car_seat_no" class="form-control" type="text" oninput="this.value = this.value.replace(/\D+/g, '')" maxlength="2">
                                    </div>
                                    <label class="col-sm-3 col-form-label">ที่นั่ง</label>
                                </div>
                        </div>
                        <hr>
                        <div class="mb-2 row">
                                <div class="mb-2 row col-sm-6">
                                    <label class="col-sm-4 col-form-label">ผู้ควบคุมตรวจสภาพ</label>
                                    <div class="mb-2 row col-sm-8">
                                        <input @keyup.enter="getuser1" v-model="user1.license_number" class="form-control" type="tel" placeholder="กรุณากรอกเลขใบอนุญาต 9 หลัก" maxlength="20">
                                    </div>
                                    <label class="col-sm-2 col-form-label">ชื่อผู้ใช้งาน</label>
                                    <div class="mb-2 row col-sm-4">
                                        <input v-model="user1.username" class="form-control" type="text">
                                    </div>
                                    <label class="col-sm-2 col-form-label">รหัสผ่าน</label>
                                    <div class="mb-2 row col-sm-4">
                                        <input v-model="user1.password" class="form-control" type="password">
                                    </div>
                                    <div class="d-grid mx-auto">
                                        <button  @click="getuser1" class="btn btn-primary btn-block mb-3" type="button">เข้าสู่ระบบ(ช่างคนที่ 1)</button>
                                    </div>
                                    <label class="col-sm-4 col-form-label">ชื่อ - นามสกุล</label>
                                    <div class="col-sm-8">
                                        <input  :value="userinfo1.name" class="form-control" type="text" readonly>
                                    </div>
                                    <label class="col-sm-4 col-form-label">เลขที่บัตรประชาชน</label>
                                    <div class="col-sm-8">
                                        <input :value="userinfo1.idcard" class="form-control" type="text" readonly>
                                    </div>
                                    <label class="col-sm-4 col-form-label">เลขที่บัตรประจำตัว</label>
                                    <div class="col-sm-8">
                                        <input :value="userinfo1.license_number" class="form-control" type="text" readonly>
                                    </div>
                                </div>
                                <div class="mb-2 row col-sm-6">
                                    <label class="col-sm-4 col-form-label">เจ้าหน้าที่ตรวจสภาพ</label>
                                    <div class="mb-2 row col-sm-8">
                                        <input @keyup.enter="getuser2" v-model="user2.license_number" class="form-control" type="tel" placeholder="กรุณากรอกเลขใบอนุญาต 9 หลัก"  maxlength="20">
                                    </div>
                                    <label class="col-sm-2 col-form-label">ชื่อผู้ใช้งาน</label>
                                    <div class="mb-2 row col-sm-4">
                                        <input v-model="user2.username" class="form-control" type="text">
                                    </div>
                                    <label class="col-sm-2 col-form-label">รหัสผ่าน</label>
                                    <div class="mb-2 row col-sm-4">
                                        <input v-model="user2.password" class="form-control" type="password">
                                    </div>
                                    <div class="d-grid mx-auto">
                                        <button  @click="getuser2" class="btn btn-primary btn-block mb-3" type="button">เข้าสู่ระบบ(ช่างคนที่ 2)</button>
                                    </div>
                                    <label class="col-sm-4 col-form-label">ชื่อ - นามสกุล</label>
                                    <div class="col-sm-8">
                                        <input  :value="userinfo2.name" class="form-control" type="text" readonly>
                                    </div>
                                    <label class="col-sm-4 col-form-label">เลขที่บัตรประชาชน</label>
                                    <div class="col-sm-8">
                                        <input :value="userinfo2.idcard" class="form-control" type="text" readonly>
                                    </div>
                                    <label class="col-sm-4 col-form-label">เลขที่บัตรประจำตัว</label>
                                    <div class="col-sm-8">
                                        <input :value="userinfo2.license_number" class="form-control" type="text" readonly>
                                    </div>
                                </div>
                        </div>
                        <hr>
                        <h5 v-if="!typecar || !car_reg_date_1 || !car_reg_date_2 || !car_reg_date_3 || !fueldesc_select || (typecar != 12 && !car_weight)" class='text-center text-primary'>รายการที่ตรวจจะแสดงเมื่อมีข้อมูล ประเภท รย., ประเภทเครื่องยนต์, วันที่จดทะเบียน, ชนิดเชื้อเพลิง และน้ำหนักรถ(บังคับเฉพาะ รย.1 รย.2 รย.3)</h5>
                        <h4 v-if="typecar && car_reg_date_1 && car_reg_date_2 && car_reg_date_3 && fueldesc_select && (typecar != 12 && car_weight)">รายการที่ตรวจ</h4>
                        <div v-if="typecar != 12 && typecar && car_reg_date_1 && car_reg_date_2 && car_reg_date_3 && fueldesc_select && car_weight" class="mb-2 row">
                            <div class="mb-2 row col-sm-12">
                                    <label class="col-sm-2 col-form-label">1. ระบบห้ามล้อ</label>
                                    <div class="col-sm-9">
                                        <button @click="sends" class="btn btn-primary">รับค่า</button>
                                    </div>
                                    <label class="col-sm-12 col-form-label">แรงห้ามล้อ (N)</label>
                                    <label class="col-sm-2 col-form-label">เพลา 1 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle1l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 1 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle1r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 1</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle1Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 2 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle2l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 2 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle2r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 2</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle2Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 3 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle3l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 3 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle3r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 3</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle3Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 4 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle4l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 4 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle4r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 4</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle4Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">1.1 ผลต่าง(%)</label>
                                    <label class="col-sm-10 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[0]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[0] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[0] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label">เพลา 1</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum1">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เพลา 2</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum2">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เพลา 3</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum3">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เพลา 4</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum4">
                                    </div>
                                    <label class="col-sm-4 col-form-label">1.2 ประสิทธิภาพห้ามล้อ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="varssum5">
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[1]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[1] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[1] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label">แรงห้ามล้อมือ (N)</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" :value="vars.handbrakeForce2L">
                                    </div>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" :value="vars.handbrakeForce2R">
                                    </div>
                                    <label class="col-sm-4 col-form-label">1.3 ประสิทธิภาพห้ามล้อมือ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="varssum6">
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[2]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[2] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[2] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>

                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk3" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[3] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[3] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">2. ศูนย์ล้อ</label>
                                    <div class="col-sm-4">
                                    <input type="text" class="form-control" v-model="vars.wheelAlignment"  @input="setchk3">
                                    </div>
                                    <label class="col-sm-4 col-form-label">(m/km)</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[4]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[4] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[4] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">3. ระดับเสียง</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="soundinfo.datamax">
                                    </div>
                                    <label class="col-sm-2 col-form-label">dB(A)</label>
                                    <div class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datasound1"  type="button" :disabled="!!soundinfo.data1 || fueldesc_select == 3"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datasound2" type="button" :disabled="!soundinfo.data1 || !!soundinfo.data2"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk5" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[5] ?  'text-primary' : 'text-danger']">{{ getchk5 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">4. ระบบไอเสีย</label>
                                    <div class="col-sm-8 text-left">
                                        <span v-if="!car_reg_date_1 || !car_reg_date_2 || !car_reg_date_3 || !fueldesc_select" class="text-danger">(กรุณาเลือกวันที่/เดือน/ปี , ชนิดเชื้อเพลิง)</span>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-2 offset-md-2">
                                        <label class="mb-0 col-form-label"  for="radioinline1">ควันดำ</label>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-3">
                                        <input class="form-control" type="number" v-model="varesmoke" @input="setchk5">
                                    </div>
                                    <label v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-5 col-form-label">%</label>
                                    <h6 v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว) <span v-if="cohcinfo.datamaxlo_1.co != ''" :class="[itemcheck_cohc.varssumcheck[0] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[0] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 offset-md-2">
                                        <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_1.co">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_1.hc">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_lo1_1"  type="button" :disabled="!!cohcinfo.datalo1_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_lo1_2" type="button" :disabled="!cohcinfo.datalo1_1.co || !!cohcinfo.datalo1_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>

                                    <h6 v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับน้ำมันอย่างเดียว) <span v-if="cohcinfo.datamaxhi_1.co != ''" :class="[itemcheck_cohc.varssumcheck[2] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[2] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 offset-md-2 mb-2">
                                        <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_1.co">
                                    </div>
                                    <label v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_1.hc">
                                    </div>
                                    <label v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_hi1_1"  type="button" :disabled="!!cohcinfo.datahi1_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_hi1_2" type="button" :disabled="!cohcinfo.datahi1_1.co || !!cohcinfo.datahi1_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    
                                    <h6 v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับก๊าซอย่างเดียว) <span v-if="cohcinfo.datamaxlo_2.co != ''" :class="[itemcheck_cohc.varssumcheck[1] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[1] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 offset-md-2">
                                          <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_2.co">
                                    </div>
                                    <label v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_2.hc">
                                    </div>
                                    <label v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_lo2_1"  type="button" :disabled="!!cohcinfo.datalo2_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_lo2_2" type="button" :disabled="!cohcinfo.datalo2_1.co || !!cohcinfo.datalo2_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    
                                    <h6 v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับก๊าซอย่างเดียว) <span v-if="cohcinfo.datamaxhi_2.co != ''" :class="[itemcheck_cohc.varssumcheck[3] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[3] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 offset-md-2 mb-2">
                                        <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_2.co">
                                    </div>
                                    <label v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_2.hc">
                                    </div>
                                    <label v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_hi2_1"  type="button" :disabled="!!cohcinfo.datahi2_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_hi2_2" type="button" :disabled="!cohcinfo.datahi2_1.co || !!cohcinfo.datahi2_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>


                                    <label class="col-sm-2 col-form-label" >
                                    <input class="checkbox_animated" v-model="itemcheck.varssumcheck[6]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[6] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[6] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">5. แตรสัญญาณ</label>

                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[7]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[7] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[7] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">6. มาตรวัดความเร็ว</label>
                                </div>
                                <div class="mb-2 row col-sm-12">

                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk8" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[8] ?  'text-primary' : 'text-danger']">{{ getchk8 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">7. โคมไฟ</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งไกล</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input81" >
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input82">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input83">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input84">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งต่ำ</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input85">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input86">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input87">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input88">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[9]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[9] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[9] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[10]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[10] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[10] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[11]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[11] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[11] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">10. เครื่องปัดน้ำฝน</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[12]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[12] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[12] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[13]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[13] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[13] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">12. ระบบบังคับเลี้ยวและพวงมาลัย</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[14]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[14] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[14] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">13. ล้อและยาง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[15]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[15] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[15] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">14. ถังเชื้อเพลิงและท่อส่ง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[16]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[16] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[16] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">15. เครื่องล่าง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[17]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[17] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[17] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">16. สีรถ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[18]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[18] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[18] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">17. โครงสร้างและตัวถัง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[19]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[19] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[19] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">18. ประตูและพื้นรถ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[20]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[20] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[20] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">19. ขนาดของรถ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[21]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[21] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[21] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">20. ที่นั่งและจานวนที่นั่ง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[22]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[22] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[22] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">21. เข็มขัดนิรภัย</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[23]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[23] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[23] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">22. อื่นๆ</label>
                                    <div class="col-sm-7">
                                        <input :disabled="itemcheck.varssumcheck[23] == 1" class="form-control" type="text" v-model="failed_reasons" placeholder="สามารถกรอกรายละเอียดเพิ่มได้">
                                    </div>
                                </div>
                        </div>
                        <!-- ------------------------ รย. 12 --------------------------- -->
                        <div v-if="typecar == 12 && typecar && car_reg_date_1 && car_reg_date_2 && car_reg_date_3 && fueldesc_select" class="mb-2 row">
                            <div class="mb-2 row col-sm-12">
                                    <label class="col-sm-2 col-form-label text-secondary">1. ระบบห้ามล้อ</label>
                                    <div class="col-sm-9">
                                        <button @click="sends" class="btn btn-primary">รับค่า</button>
                                    </div>
                                    <label class="col-sm-12 col-form-label text-secondary">แรงห้ามล้อ (N)</label>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 1 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 1 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 1</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 2 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 2 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 2</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 3 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 3 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 3</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 4 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 4 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 4</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">1.1 ผลต่าง(%)</label>
                                    <label class="col-sm-10 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[0]" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 1</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 2</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 3</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 4</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-4 col-form-label text-secondary">1.2 ประสิทธิภาพห้ามล้อ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label text-secondary">แรงห้ามล้อมือ (N)</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ซ้าย</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-4 col-form-label text-secondary">1.3 ประสิทธิภาพห้ามล้อมือ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ"</span>
                                    </label>

                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label text-secondary">2. ศูนย์ล้อ</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-4 col-form-label text-secondary">(m/km)</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[4]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[4] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[4] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">3. ระดับเสียง</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="soundinfo.datamax">
                                    </div>
                                    <label class="col-sm-2 col-form-label">dB(A)</label>
                                    <div class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datasound1"  type="button" :disabled="!!soundinfo.data1 || fueldesc_select == 3"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datasound2" type="button" :disabled="!soundinfo.data1 || !!soundinfo.data2"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk5" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[5] ?  'text-primary' : 'text-danger']">{{ getchk5 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">4. ระบบไอเสีย</label>
                                    <div class="col-sm-8 text-left">
                                        <span v-if="!car_reg_date_1 || !car_reg_date_2 || !car_reg_date_3 || !fueldesc_select" class="text-danger">(กรุณาเลือกวันที่/เดือน/ปี , ชนิดเชื้อเพลิง)</span>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-2 offset-md-4">
                                          <label class="mb-0 col-form-label" for="radioinline1">ควันดำ</label>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-3">
                                        <input class="form-control" type="number" v-model="varesmoke" :disabled="fueldesc_select == 3" @input="setchk5">
                                    </div>
                                    <label v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-3 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2 offset-md-4">
                                        <label class="mb-0" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-3">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamax.co">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc1"  type="button" :disabled="!!cohcinfo.data1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc2" type="button" :disabled="!cohcinfo.data1.co || !!cohcinfo.data2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2 offset-sm-4">
                                        <label class="mb-0">HC</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-3">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamax.hc">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-3 col-form-label">ppm</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input class="checkbox_animated" v-model="itemcheck.varssumcheck[6]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[6] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[6] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">5. แตรสัญญาณ</label>

                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[7]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[7] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[7] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">6. มาตรวัดความเร็ว</label>
                                </div>
                                <div class="mb-2 row col-sm-12">

                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk8" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[8] ?  'text-primary' : 'text-danger']">{{ getchk8 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">7. โคมไฟ</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งไกล</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input81" >
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input83">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">kcd</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งต่ำ</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input85">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input87">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">kcd</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[9]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[9] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[9] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[10]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[10] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[10] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input disabled="disabled"  class="checkbox_animated"  type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label text-secondary">10. เครื่องปัดน้ำฝน</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input disabled="disabled"  class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label text-secondary">11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[13]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[13] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[13] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">12. ระบบบังคับเลี้ยว</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[14]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[14] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[14] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">13. ล้อและยาง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[15]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[15] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[15] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">14. ถังเชื้อเพลิงและท่อส่ง</label>
                                      <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label text-secondary">15. เครื่องล่าง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[17]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[17] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[17] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">16. สีรถ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[18]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[18] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[18] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">17. โครงสร้างและตัวถัง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label text-secondary">18. ประตูและพื้นรถ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[20]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[20] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[20] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">19. ขนาดของรถ</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[21]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[21] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[21] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">20. ที่นั่งและจานวนที่นั่ง</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label text-secondary">21. เข็มขัดนิรภัย</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[23]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[23] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[23] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">22. อื่นๆ</label>
                                    <div class="col-sm-7">
                                        <input :disabled="itemcheck.varssumcheck[23] == 1" class="form-control" type="text" v-model="failed_reasons" placeholder="สามารถกรอกรายละเอียดเพิ่มได้">
                                    </div>
                                </div>
                        </div>

                        <div v-if="typecar && car_reg_date_1 && car_reg_date_2 && car_reg_date_3 && fueldesc_select && ((typecar == 12 && (car_weight || !car_weight)) || (typecar != 12 && car_weight))" class="mb-2 row">
                            <label class="col-sm-2 col-form-label offset-2">สรุปผลการตรวจสภาพ</label>
                            <label class="col-sm-2 col-form-label" >
                                <input disabled="disabled" class="checkbox_animated" v-model="allchk" :true-value="1" :false-value="0"  type="checkbox"><span :class="[allchk ?  'text-primary' : 'text-danger']">{{ allchk ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                            </label>
                                <label class="col-sm-2 col-form-label">วันที่/เวลา ส่งข้อมูล</label>
                            <div class="col-sm-2">
                                <input  disabled="disabled" class="form-control min-w-[200px] w-[200px]" type="text" :value="timeinsert">
                            </div>
                        </div>
                        <div v-if="allchk == 0" class="mb-2 row">
                            <label class="col-sm-8 col-form-label offset-3 text-danger">ข้อที่ยังไม่ผ่าน : {{allchkid}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="typecar && car_reg_date_1 && car_reg_date_2 && car_reg_date_3 && fueldesc_select && ((typecar == 12 && (car_weight || !car_weight)) || (typecar != 12 && car_weight))" class="card-footer">
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button class="btn-lg btn-primary" @click="checkmdminsert" type="button">ส่งข้อมูล</button>
                    <!-- <button class="btn-lg btn-light ml-4" onClick="window.location.reload();" type="button">ล้างข้อมูล</button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { createWorker, PSM, OEM, createScheduler } from "tesseract.js";
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";
import { readonly, ref, inject } from 'vue';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import * as tf from '@tensorflow/tfjs';

export default{
    setup(){
        const Swal = inject('$swal')
        const Modeldata = ref('/model/tro/model.json');
        const st_value = ref([]);
        const st_read = readonly(st_value);
        const wh_value = ref([]);
        const wh_read = readonly(wh_value);
        const imageview = ref();
        const openCameras = ref(false);
        let dataai = {
            typecar_detail:'',
            klass: '',
            score: '0',
        };

        let model = null;
        let label = null;
        let fil = 0; // ฟิวเตอร์ ปิด 0/เปิด 1 ป้ายทะเบียน

        async function loadModel  () {
            Swal.fire({
                title: 'ระบบกำลังทำงาน',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            Swal.showLoading()

            try {
                const savedModel = localStorage.getItem('Tro');
                if (savedModel) {
                model = await tf.loadGraphModel('indexeddb://Tro'); 
                } else {
                // Loading Model for first time
                model = await tf.loadGraphModel(Modeldata.value);
                localStorage.setItem('Tro', true);
                model.save('indexeddb://Tro')
                }
                openCameras.value =  true;
                Swal.close();
            }catch(error){
                Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: "ระบบไม่สามารถโหลดฟังชั่น AI ได้",
                        icon: "error",
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: "ลองใหม่อีกครั้ง",
                        denyButtonText: `ยกเลิกการโหลดฟังชั่น AI`
                    }).then( async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                        localStorage.removeItem("Tro");
                        localStorage.removeItem("Trolabel");
                        loadModel()
                        } else if (result.isDenied) {
                            Swal.fire({
                            title: "คำแนะนำ",
                            html: `หากเครื่องของท่านโหลดฟังชั่น Ai ไม่สำเร็จจะทำให้ไม่สามารถใช้งานระบบในวันที่ 9 พ.ย. ได้ท่านสามารถคลิกเพื่อดูแนวทางแก้ปัญหาอื่นๆได้ที่ <a href="https://v6.inspection.dlt.go.th/download/document/v6-6-6.pdf" target="_blank">"คู่มือการแก้ไขปัญหาฟังชั่น AI เบื้องต้น"</a>`,
                            icon: "warning",
                        });
                        }
                    });
            }

        }
        async function processImage(base64) {
            const imageElement =  new Image();
            imageElement.src = base64;
            const sizeimg = await getDimensions(base64) 
            const canvas = document.createElement("canvas");
            const context = canvas.getContext('2d');
            canvas.width = sizeimg.width;
            canvas.height = sizeimg.height;
            // Draw the uploaded image onto the canvas
            context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
            // Detect vehicles using COCO-SSD
            const cocoModel = await cocoSsd.load();
            const vehiclePredictions = await cocoModel.detect(imageElement);

            // Loop through detected vehicles
            async function svehicles () {
                for await (const prediction of vehiclePredictions) {
                    const [x, y, width, height] = prediction.bbox;
                    const label = prediction.class;

                    // Check if the detected object is a vehicle (car, truck, motorcycle)
                    if (label === 'car' || label === 'truck' || label === 'motorcycle') {
                        // Draw blue bounding box for vehicles
                        context.beginPath();
                        context.rect(x, y, width, height);
                        context.lineWidth = 2;
                        context.strokeStyle = '#efbf04'; // Blue border for detected vehicles
                        context.fillStyle = 'rgba(0, 0, 255, 0.3)';
                        // context.fill(); // ใส่สีพื้นหลังในกรอบ
                        context.stroke();

                        let typecar_detail = '';
                        if(label === 'car'){
                            typecar_detail = 'รถยนต์';
                        }else if(label === 'truck'){
                            typecar_detail = 'รถกระบะ';
                        }else if(label === 'motorcycle'){
                            typecar_detail = 'รถมอเตอร์ไซต์';
                        }

                        dataai.typecar_detail = typecar_detail

                        // Draw label above the bounding box (display the type of the object)
                        context.font = '20px Arial';
                        context.fillStyle = '#efbf04';
                        context.fillText(typecar_detail, x, y > 10 ? y - 5 : 10); // Display the label above the bounding box

                        //  checkModel(canvas.toDataURL("image/jpeg", 1.0))
                         return canvas.toDataURL("image/jpeg", 1.0)
                    }
                }
            }

            const chechstatus = await svehicles() ;
            if(chechstatus){
                return chechstatus
            }else{
                return false
            }

        }
        function getDimensions(image){
            return new Promise((resolve, reject)=>{
                const img = new Image();
                img.src = image;

                img.onload = () => {
                    resolve({width: img.width, height: img.height})
                }
            })
        }

        async function checkModel (imagedata){
            try{
                const imagee =  new Image();
                imagee.src = imagedata;
                const sizeimg = await getDimensions(imagedata) 

                const width1 = sizeimg.width;
                const height1= sizeimg.height;
                const batched = tf.tidy(() => {

                const img = tf.browser.fromPixels(imagee);
                const small = tf.image.resizeBilinear(img, [640, 640]).div(255);
                    return small.expandDims(0);
                })

                const result = await model.execute(batched);
                const transRes = await result.transpose([0, 2, 1]);
            
                const boxes = tf.tidy(() => {
                    const w = transRes.slice([0, 0, 2], [-1, -1, 1]); // get width
                    const h = transRes.slice([0, 0, 3], [-1, -1, 1]); // get height
                    const x1 = tf.sub(transRes.slice([0, 0, 0], [-1, -1, 1]), tf.div(w, 2)); // x1
                    const y1 = tf.sub(transRes.slice([0, 0, 1], [-1, -1, 1]), tf.div(h, 2)); // y1
                    let object = tf.concat([x1,y1,tf.add(x1, w),tf.add(y1, h)],2).squeeze();
                    return object;
                });
                const scores = tf.tidy(() => {
                    const rawScores = transRes.slice([0, 0, 4], [-1, -1, 1]).squeeze(); // class scores
                    return rawScores;
                });
                var detections = non_max_suppression(boxes.arraySync(),scores.arraySync());
                const boxes_plot =  shortenedCol(detections, [0,1,2,3]);
                var ratio = [];
                ratio[0]= (width1/640);
                ratio[1]= (height1/640);

                const resultURL = await crop(imagee,boxes_plot,ratio,fil);

                imageview.value = resultURL;
                
                tf.dispose(result);
                tf.dispose(transRes);
                tf.dispose(boxes);
                tf.dispose(scores);
                tf.dispose(detections);

                return true
            }catch(error){
                return false
            }
        }

            function non_max_suppression(boxes , scores, conf_thresh=0.50, iou_thresh=0.2, max_det = 300){

            // Initialize an empty list to store the selected boxes
            const selected_detections = [];

            for (let i = 0; i < scores.length; i++) {

                // Check if the box has sufficient score to be selected
                if (scores[i] < conf_thresh) {
                    continue;
                    }
                var box = boxes[i];
                const score = scores[i];
                let object = box;
                let addBox = true;


                // Check for overlap with previously selected boxes
                for (let j = 0; j < selected_detections.length; j++) {
                    let selectedBox = selected_detections[j];

                    // Calculate the intersection and union of the two boxes
                    let intersectionXmin = Math.max(object[0], selectedBox[0]);
                    let intersectionYmin = Math.max(object[1], selectedBox[1]);
                    let intersectionXmax = Math.min(object[2], selectedBox[2]);
                    let intersectionYmax = Math.min(object[3], selectedBox[3]);
                    let intersectionWidth = Math.max(0, intersectionXmax - intersectionXmin);
                    let intersectionHeight = Math.max(0, intersectionYmax - intersectionYmin);
                    let intersectionArea = intersectionWidth * intersectionHeight;
                    let boxArea = (object[2] - object[0]) * (object[3] - object[1]);
                    let selectedBoxArea = (selectedBox[2] - selectedBox[0]) * (selectedBox[3] - selectedBox[1]);
                    let unionArea = boxArea + selectedBoxArea - intersectionArea;

                    // Calculate the IoU and check if the boxes overlap
                    let iou = intersectionArea / unionArea;
                    if (iou >= iou_thresh) {
                        addBox = false;
                        break;
                }
                }

                // Add the box to the selected boxes list if it passed the overlap check
                if (addBox) {
                    const row = box.concat(score);
                    selected_detections.push(row);
                }
            }

            return selected_detections
            }

            function shortenedCol(arrayofarray, indexlist) {
            return arrayofarray.map(function (array) {
                return indexlist.map(function (idx) {
                    return array[idx];
                });
            });
            }

            function thresholdFilter(pixels, level) {
            if (level === undefined) {
                level = 0.5;
            }
            const thresh = Math.floor(level * 255);
            for (let i = 0; i < pixels.length; i += 4) {
                const r = pixels[i];
                const g = pixels[i + 1];
                const b = pixels[i + 2];
                const gray = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                let val;
                if (gray >= thresh) {
                val = 255;
                } else {
                val = 0;
                }
                pixels[i] = pixels[i + 1] = pixels[i + 2] = val;
            }
            };

            function getARGB (data, i) {
            const offset = i * 4;
            return (
                ((data[offset + 3] << 24) & 0xff000000) |
                ((data[offset] << 16) & 0x00ff0000) |
                ((data[offset + 1] << 8) & 0x0000ff00) |
                (data[offset + 2] & 0x000000ff)
            );
            };

            function setPixels (pixels, data) {
            let offset = 0;
            for (let i = 0, al = pixels.length; i < al; i++) {
                offset = i * 4;
                pixels[offset + 0] = (data[i] & 0x00ff0000) >>> 16;
                pixels[offset + 1] = (data[i] & 0x0000ff00) >>> 8;
                pixels[offset + 2] = data[i] & 0x000000ff;
                pixels[offset + 3] = (data[i] & 0xff000000) >>> 24;
            }
            };

            let blurRadius;
            let blurKernelSize;
            let blurKernel;
            let blurMult;

            function buildBlurKernel(r) {
            let radius = (r * 3.5) | 0;
            radius = radius < 1 ? 1 : radius < 248 ? radius : 248;

            if (blurRadius !== radius) {
            blurRadius = radius;
            blurKernelSize = (1 + blurRadius) << 1;
            blurKernel = new Int32Array(blurKernelSize);
            blurMult = new Array(blurKernelSize);
            for (let l = 0; l < blurKernelSize; l++) {
                blurMult[l] = new Int32Array(256);
            }

            let bk, bki;
            let bm, bmi;

            for (let i = 1, radiusi = radius - 1; i < radius; i++) {
                blurKernel[radius + i] = blurKernel[radiusi] = bki = radiusi * radiusi;
                bm = blurMult[radius + i];
                bmi = blurMult[radiusi--];
                for (let j = 0; j < 256; j++) {
                bm[j] = bmi[j] = bki * j;
                }
            }
            bk = blurKernel[radius] = radius * radius;
            bm = blurMult[radius];

            for (let k = 0; k < 256; k++) {
                bm[k] = bk * k;
            }
            }
            }

            function blurARGB(pixels, canvas, radius) {
            const width = canvas.width;
            const height = canvas.height;
            const numPackedPixels = width * height;
            const argb = new Int32Array(numPackedPixels);
            for (let j = 0; j < numPackedPixels; j++) {
            argb[j] = getARGB(pixels, j);
            }
            let sum, cr, cg, cb, ca;
            let read, ri, ym, ymi, bk0;
            const a2 = new Int32Array(numPackedPixels);
            const r2 = new Int32Array(numPackedPixels);
            const g2 = new Int32Array(numPackedPixels);
            const b2 = new Int32Array(numPackedPixels);
            let yi = 0;
            buildBlurKernel(radius);
            let x, y, i;
            let bm;
            for (y = 0; y < height; y++) {
            for (x = 0; x < width; x++) {
                cb = cg = cr = ca = sum = 0;
                read = x - blurRadius;
                if (read < 0) {
                bk0 = -read;
                read = 0;
                } else {
                if (read >= width) {
                    break;
                }
                bk0 = 0;
                }
                for (i = bk0; i < blurKernelSize; i++) {
                if (read >= width) {
                    break;
                }
                const c = argb[read + yi];
                bm = blurMult[i];
                ca += bm[(c & -16777216) >>> 24];
                cr += bm[(c & 16711680) >> 16];
                cg += bm[(c & 65280) >> 8];
                cb += bm[c & 255];
                sum += blurKernel[i];
                read++;
                }
                ri = yi + x;
                a2[ri] = ca / sum;
                r2[ri] = cr / sum;
                g2[ri] = cg / sum;
                b2[ri] = cb / sum;
            }
            yi += width;
            }
            yi = 0;
            ym = -blurRadius;
            ymi = ym * width;
            for (y = 0; y < height; y++) {
            for (x = 0; x < width; x++) {
                cb = cg = cr = ca = sum = 0;
                if (ym < 0) {
                bk0 = ri = -ym;
                read = x;
                } else {
                if (ym >= height) {
                    break;
                }
                bk0 = 0;
                ri = ym;
                read = x + ymi;
                }
                for (i = bk0; i < blurKernelSize; i++) {
                if (ri >= height) {
                    break;
                }
                bm = blurMult[i];
                ca += bm[a2[read]];
                cr += bm[r2[read]];
                cg += bm[g2[read]];
                cb += bm[b2[read]];
                sum += blurKernel[i];
                ri++;
                read += width;
                }
                argb[x + yi] =
                ((ca / sum) << 24) |
                ((cr / sum) << 16) |
                ((cg / sum) << 8) |
                (cb / sum);
            }
            yi += width;
            ymi += width;
            ym++;
            }
            setPixels(pixels, argb);
            }

            function invertColors(pixels) {
            for (var i = 0; i < pixels.length; i+= 4) {
                pixels[i] = pixels[i] ^ 255; // Invert Red
                pixels[i+1] = pixels[i+1] ^ 255; // Invert Green
                pixels[i+2] = pixels[i+2] ^ 255; // Invert Blue
            }
            }

            function dilate(pixels, canvas) {
            let currIdx = 0;
            const maxIdx = pixels.length ? pixels.length / 4 : 0;
            const out = new Int32Array(maxIdx);
            let currRowIdx, maxRowIdx, colOrig, colOut, currLum;

            let idxRight, idxLeft, idxUp, idxDown;
            let colRight, colLeft, colUp, colDown;
            let lumRight, lumLeft, lumUp, lumDown;

            while (currIdx < maxIdx) {
            currRowIdx = currIdx;
            maxRowIdx = currIdx + canvas.width;
            while (currIdx < maxRowIdx) {
                colOrig = colOut = getARGB(pixels, currIdx);
                idxLeft = currIdx - 1;
                idxRight = currIdx + 1;
                idxUp = currIdx - canvas.width;
                idxDown = currIdx + canvas.width;

                if (idxLeft < currRowIdx) {
                idxLeft = currIdx;
                }
                if (idxRight >= maxRowIdx) {
                idxRight = currIdx;
                }
                if (idxUp < 0) {
                idxUp = 0;
                }
                if (idxDown >= maxIdx) {
                idxDown = currIdx;
                }
                colUp = getARGB(pixels, idxUp);
                colLeft = getARGB(pixels, idxLeft);
                colDown = getARGB(pixels, idxDown);
                colRight = getARGB(pixels, idxRight);

                //compute luminance
                currLum =
                77 * ((colOrig >> 16) & 0xff) +
                151 * ((colOrig >> 8) & 0xff) +
                28 * (colOrig & 0xff);
                lumLeft =
                77 * ((colLeft >> 16) & 0xff) +
                151 * ((colLeft >> 8) & 0xff) +
                28 * (colLeft & 0xff);
                lumRight =
                77 * ((colRight >> 16) & 0xff) +
                151 * ((colRight >> 8) & 0xff) +
                28 * (colRight & 0xff);
                lumUp =
                77 * ((colUp >> 16) & 0xff) +
                151 * ((colUp >> 8) & 0xff) +
                28 * (colUp & 0xff);
                lumDown =
                77 * ((colDown >> 16) & 0xff) +
                151 * ((colDown >> 8) & 0xff) +
                28 * (colDown & 0xff);

                if (lumLeft > currLum) {
                colOut = colLeft;
                currLum = lumLeft;
                }
                if (lumRight > currLum) {
                colOut = colRight;
                currLum = lumRight;
                }
                if (lumUp > currLum) {
                colOut = colUp;
                currLum = lumUp;
                }
                if (lumDown > currLum) {
                colOut = colDown;
                currLum = lumDown;
                }
                out[currIdx++] = colOut;
            }
            }
            setPixels(pixels, out);
            };

            function preprocessImage(canvas) {
            const processedImageData = canvas.getContext('2d').getImageData(0,0,canvas.width, canvas.height);
            blurARGB(processedImageData.data, canvas, 1);
            dilate(processedImageData.data, canvas);
            invertColors(processedImageData.data);
            thresholdFilter(processedImageData.data, 0.4);
            return processedImageData;
            };

            function delay(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }

            async function crop(image,boxes,ratio,fil){
            let base64Image;
            const canva = document.createElement("canvas");
            canva.width = ratio[0]*640;
            canva.height = ratio[1]*640;
            const ctx2 = canva.getContext("2d");
            ctx2.drawImage(image, 0, 0);
            const selected_detections = [];
            const font = "18px sans-serif";
            ctx2.font = font;
            ctx2.textBaseline = "top";
            ctx2.strokeStyle = "#B033FF";
            ctx2.lineWidth = 2;
            let i=0;
            for(i=0;i<boxes.length;++i){
                const canvas = document.createElement("canvas");
                const scaleX = 1;
                const scaleY = 1;
                let [x1, y1, x2, y2] = boxes[i];
                x1 *= ratio[0];
                x2 *= ratio[0];
                y1 *= ratio[1];
                y2 *= ratio[1];
                const width = x2 - x1;
                const height = y2 - y1;
                canvas.width = width;
                canvas.height = height;
                const immg = new Image(width,height);
                const ctx = canvas.getContext("2d");

                ctx.drawImage(
                image,
                x1 * scaleX,
                y1 * scaleY,
                width * scaleX,
                height * scaleY,
                0,
                0,
                width,
                height
                );
                if(fil===1){
                const res = preprocessImage(canvas);
                ctx.putImageData(res,0,0);
                }
                immg.onload = function() {
                ctx2.drawImage(immg, 0,0,width,height,x1 * scaleX,y1 * scaleY,width * scaleX,height * scaleY);
                };
                base64Image = canvas.toDataURL("image/jpeg", 1.0);
                immg.src = base64Image;

                const worker = await createWorker('eng');
                await worker.setParameters({
                    tessedit_char_whitelist: '0123456789',
                });
                await worker.recognize(base64Image)
                .catch (err => {
                    console.error(err);
                })
                .then(result => {
                // Get Confidence score
                let confidence = result.data.confidence;
                // Get full output
                var text = []
                text[0] = result.data.text.replace("\n", '').slice(-4);
                text[1] = confidence;
                selected_detections.push(text);
                const klass = text[0];
                const score = text[1];
                ctx2.strokeRect(x1, y1, width, height);
                
                dataai.klass =  klass;
                dataai.score =  score;
                // Draw the label background.
                ctx2.fillStyle = "#B033FF";
                const textWidth = ctx2.measureText(klass + " - " + score + "%").width;
                const textHeight = parseInt(font, 10); // base 10
                ctx2.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

                // Draw labels
                ctx2.fillStyle = "#ffffff";
                if(klass){
                    ctx2.fillText(klass + " - " + score + "%", x1 - 1, y1 - (textHeight + 2));
                }else{
                    ctx2.fillText(score + "%", x1 - 1, y1 - (textHeight + 2));
                }
                })
                await worker.terminate();
            }

            // เพิ่มลายน้ำสีแดง
            ctx2.font = '37px Arial';
            ctx2.fillStyle = 'rgba(255, 0, 0, 0.4)';
            ctx2.fillText(`${new Date().toLocaleString("en-GB").replace( /,/,"" )} ${storeAuth.license_number}`, 40, 240);


            // await delay(2000);
            const resutl=canva.toDataURL("image/jpeg");
            return resutl;
            }   

        return {
            st_value,
            st_read,
            wh_value,
            wh_read,
            model,
            label,
            loadModel,
            processImage,
            checkModel,
            imageview,
            dataai, 
            openCameras,
        }
    },
  data(){
    return  {
        checkmdm: 1,
        storeAuths: storeAuth,
        searchcounty_value: [{id:'',names:''}],
        searchcarband_value: [{id:'',band:''}],
        ocr_type_link_capture : '',
        camera_setting_turn: '',
        page_status:'',
        settings:[],
        urlimagetif:'',
        rectangles: [],
        unit_ocr: [],
        ocrvalue:[],
        smoke_setting:'',
        entry_by:storeAuth.id,
        license_id:storeAuth.licenseid,
        car_band:null,
        camera_name:'',
        camera_deviceId:'',
        tro_id:storeAuth.license_number,
        tro_name:storeAuth.nameaddresscar,
        tro_owner_name:storeAuth.license_name,
        pc_ip:'',
        tor_use_program:'',
        pc_mac:'',
        car_lic_id_char:null,
        car_lic_id_num:null,
        car_lic_prov:null,
        car_reg_date_1:null,
        car_reg_date_2:null,
        car_reg_date_3:null,
        ry:null,
        ry_id:null,
        ry_number_id:null,
        car_weight:null,
        car_type:null,
        chaisse_no:null,
        engine_no:null,
        axle:null,
        wheel:null,
        tire_no:null,
        car_seat_no:null,
        current_distance:null,
        car_ditance_type: 'km',
        type:null,
        typecar:null,
        varesmoke:'',
        input81:'',
        input82:'',
        input83:'',
        input84:'',
        input85:'',
        input86:'',
        input87:'',
        input88:'',
        vara:null,
        vareco:null,
        varehc:null,
        tor_status:null,
        tor_exp:null,
        tor_ckexp:null,
        // serverdate: new Date("<?=date("Y-m-d H:i:s")?>"),
        checkdate:'',
        checktime:'',
        vars:{
            wheelAlignment:null,
            axle1l : null,
            axle1r : null,
            axle2l : null,
            axle2r : null,
            axle3l : null,
            axle3r : null,
            axle4l : null,
            axle4r : null,
            axle1Weight : null,
            axle2Weight : null,
            axle3Weight : null,
            axle4Weight : null,
            handbrakeForce2L : null,
            handbrakeForce2R : null,
        },
        varssum1:null,
        varssum2:null,
        varssum3:null,
        varssum4:null,
        varssum5:null,
        varssum5check:null,
        varssum6:null,
        varssum6check:null,
        b64img:'',
        itemcheck: {
        varssumcheck: []
        },
        itemcheck_cohc: {
            varssumcheck: []
        },
        user1:{
            license_number:'',
            username:'',
            password:'',
        },
        user2:{
            license_number:'',
            username:'',
            password:'',
        },
        userinfo1:{
            name:'',
            idcard:'',
            license_number:'',
        },
        userinfo2:{
            name:'',
            idcard:'',
            license_number:'',
        },
        carinfo:{
            COLOR:'',
            FUELDESC:'',
            KINDDESC:'',
            OFFLOCCODE:'',
            PLATE1:'',
            PLATE2:'',
            PLTTYPE:'',
            VEHTYPECODE:'',
        },
        fueldesc_select:null,
        gas_number:'',
        conclude:0,
        timeinsert: '',
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        inserinfo: {
            iddata:'',
        },
        soundinfo:{
            data1:'',
            data2:'',
            datamax:'',
        },
        cohcinfo:{
            data1:{
                co:'',
                hc:'',
            },
            data2:{
                co:'',
                hc:'',
            },
            datalo1_1:{
                co:'',
                hc:'',
            },
            datalo1_2:{
                co:'',
                hc:'',
            },
            datalo2_1:{
                co:'',
                hc:'',
            },
            datalo2_2:{
                co:'',
                hc:'',
            },
            datahi1_1:{
                co:'',
                hc:'',
            },
            datahi1_2:{
                co:'',
                hc:'',
            },
            datahi2_1:{
                co:'',
                hc:'',
            },
            datahi2_2:{
                co:'',
                hc:'',
            },
            datamax:{
                co:'',
                hc:'',
            },
            datamaxlo_1:{
                co:'',
                hc:'',
            },
            datamaxlo_2:{
                co:'',
                hc:'',
            },
            datamaxhi_1:{
                co:'',
                hc:'',
            },
            datamaxhi_2:{
                co:'',
                hc:'',
            },
        },
        apicheck:{
            branch:'',
            srvtype:'',
        },
        selected: {
            type: Boolean,
            default: () => false
        },
        failed_reasons:'',
        // ai

    }
},
methods:{
    searchcounty_select({names}) {return `${names}`},
    searchcarband_select({band}) {return `${band}`},

    async getsh(){
            const res = await axios.post(`getdatash`,{
            uid: storeAuth.id,
            gid: storeAuth.group_id,
            cid: storeAuth.county_id,
            dtn: new Date(),
            });
            if(res.data.status == 1){
                this.searchcounty_value = res.data.datas.datact;
                this.searchcarband_value = res.data.datas.datacb;
                this.st_value = res.data.datas.datast[0];
                this.wh_value = res.data.datas.datawh?res.data.datas.datawh:[];
                if(res.data.timenow.toFixed(3) <= 5.00 && res.data.timenow.toFixed(3) >= -5.00 ){
                    await this.loadModel();
                }else{
                    this.$swal({
                        title: "คำเตือน",
                        text: "เวลาของเครื่องที่ใช้งานไม่ตรงกับเวลาของเซิฟเวอร์ กรุณาตั้งเวลาให้เป็นปัจจุบันก่อน",
                        icon: "error"
                    }); 
                }
            }
        },
    async getini() {
        await fetch('http://127.0.0.1:8080/api/getini', {
            method: "GET"
        })
        .then((response) => {
          response.json().then((rs) => {

            this.settings = rs;
            if(rs.ocr.ocr_type !== undefined){
                this.ocr_type_link_capture = rs.ocr.ocr_type;
            }else{
                this.ocr_type_link_capture = 'b64';
            }
            if(rs.server.tf1 !== undefined){
                this.camera_setting_turn = parseInt(rs.server.tf1);
            }else{
                this.camera_setting_turn = 0;
            }
            if(this.settings.server.smokefilter == 45){
                this.smoke_setting = 'opacity';
            }else{
                this.smoke_setting = 'filter';
            }
            this.pc_mac = this.settings.macaddress;
            let ars = JSON.parse(this.settings.ocr.ocrsetting);
            for(let i=0;i<ars.length;i++) {
                this.rectangles.push({ width: ars[i].width, height: ars[i].height, left: ars[i].x, top: ars[i].y });
                this.unit_ocr.push([ars[i].unit]);
            }
            this.page_status = true;
            this.getsh()
          });
        }).catch((err) => {
            this.page_status = false;
            this.$swal({
                title:'ไม่สำเร็จ',
                text: "เรียกข้อมูลไม่ได้ กรุณาเปิดโปรแกรม v6(หน้าจอดำ) ค้างไว้ และลองอีกครั้ง",
                icon: "error"
            });
        });
    },
    async getip(){
        await fetch('https://api.ipify.org?format=json', {
              method: "GET"
          })
          .then((response) => {
            response.json().then((rs) => {
                this.pc_ip = rs.ip;
            });
        }).catch( (err) => {
            // console.log('getip error -----',err);
        });
    },
    toggleCamera() {
        if(this.st_read.check_hw == 1){
            if(this.wh_read.filter(({hw_type}) => hw_type == 1).length >= 1){
                if(this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.isShotPhoto = false;
                    this.stopCameraStream();
                } else {
                    this.isCameraOpen = true;
                    this.createCameraElement();
                }
            }else{
                this.$swal({
                    title: "ตำเตือน",
                    text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาตรวจสอบการลงทะเบียนข้อมูลของท่าน",
                    icon: "error"
                });
            }
        }else{
            navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
            devices.forEach((device) => {
                if( device.kind === 'videoinput'){
                    this.camera_name = device.label;
                    this.camera_deviceId = device.deviceId;
                }
            });
            })
            .catch((err) => {
                console.error(`${err.name}: ${err.message}`);
            });

            if(this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        }
    },

    async createCameraElement() {
        if(this.st_read.check_hw == 1){
            this.isLoading = true;
            let check_value = null
            let getdatacamera = null;

            await navigator.mediaDevices.getUserMedia({video: true});  
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            getdatacamera = videoDevices;

            for(let i = 0; i < this.wh_read.filter(({hw_type}) => hw_type == 1).length; i++){
                if(getdatacamera.filter(({label}) => label == this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_cam_lable)[0]){
                    await navigator.mediaDevices.getUserMedia({ video: {deviceId: { exact: getdatacamera.filter(({label}) => label == this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_cam_lable)[0].deviceId}} })
                    .then(stream => {
                        check_value = stream
                        this.camera_name =  this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_cam_lable;
                        this.camera_deviceId =  this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_number;
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    if(check_value != null){
                        break;
                    }
                }
            }
            if(check_value != null){
                this.isLoading = false;
                this.$refs.camera.srcObject = check_value;
            }else{
                this.isLoading = false;
                this.isCameraOpen = false;
                this.$swal({
                    title: "ตำเตือน",
                    text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาเช็คอุปกรณ์รวมจุดเชื่อมต่อต่างๆของท่านว่าทำงานปกติหรือไม่ จากนั้นปิดโปรแกรมแล้วเปิดใหม่อีกครั้ง !",
                    icon: "error"
                });
            }
                
        }else{
            this.isLoading = true;
            const constraints = (window.constraints = {
                    audio: false,
                    video: true
                });
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.isCameraOpen = false;
                    this.$swal({
                    title: "ตำเตือน",
                    text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาเช็คอุปกรณ์รวมจุดเชื่อมต่อต่างๆของท่านว่าทำงานปกติหรือไม่ จากนั้นปิดโปรแกรมแล้วเปิดใหม่อีกครั้ง !",
                    icon: "error"
                    });
                });
        }
    },

    stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach(track => {
            track.stop();
            this.b64img = '';
            this.imageview = '';
        });
    },

    takePhoto() {
        if(!this.isPhotoTaken) {
            this.isShotPhoto = true;

            const FLASH_TIMEOUT = 50;

            setTimeout(() => {
            this.isShotPhoto = false;
            }, FLASH_TIMEOUT);

            this.isPhotoTaken = !this.isPhotoTaken;

            const contexts = this.$refs.canvas.getContext('2d');
            if(this.camera_setting_turn == 1){
                contexts.scale(-1,1);
                contexts.drawImage(this.$refs.camera, -640, 0, 640, 480);
            }else{
                // img.onload = function() {
                    contexts.drawImage(this.$refs.camera, 0, 0, 640, 480);
                // }
            }

            this.uploadImage();

        }else{

            this.isPhotoTaken = !this.isPhotoTaken;
            this.b64img = '';
            this.imageview = '';
        }

    },

    async uploadImage() {
        this.$swal({
            title: 'ระบบกำลังตรวจสอบรูป',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()

        const getb64img = await document.getElementById("photoTaken").toDataURL("image/jpeg" , 1.0)
        const countvehicle = await this.processImage(getb64img)
        if(countvehicle == false){
            this.b64img = '';
            this.$swal({
                title: "คำเตือน",
                text: "ตรวจสอบรูปแล้วไม่พบยานพาหนะในรูปกรุณาถ่ายใหม่หรือปรับมุมกล้องให้ชัดเจนขึ้น",
                icon: "error"
            }); 
        }else{
            const countcheck = await this.checkModel(countvehicle)
            if(countcheck == true){
                this.b64img = this.imageview;
                this.$swal.close()
            }else{
                this.b64img = '';
                this.$swal({
                    title: "คำเตือน",
                    text: "เกิดข้อผิดพลาดในการตรวจสอบรูปภาพกรุณาลองถ่ายใหม่อีกครั้ง",
                    icon: "error"
                }); 
            }
        }
    },
    // END WEBCAM
    getuser1(){
    if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
        let license_number_count = this.user1.license_number.length;
        if(license_number_count == 9){
            let license_number_check = this.user1.license_number.slice(2, 3);
            if(license_number_check == 1){
                    if(this.user2.license_number != this.user1.license_number){
                    let data = {
                        license_number:this.user1.license_number,
                        username:this.user1.username,
                        password:this.user1.password,
                    }

                    this.getuser(data,1);
                    }else{
                    this.$swal({title:'เลขใบอนุญาตของผู้ควบคุมการตรวจสภาพรถต้องไม่ใช่คนเดียวกัน !',
                    icon:'warning',
                    })
                }
            }else{
                this.$swal({title:'เลขใบอนุญาตของผู้ควบคุมการตรวจสภาพรถไม่ถูกต้อง !',
                icon:'warning',
                })
            }
        }else{
            let data = {
                license_number:this.user1.license_number,
                username:this.user1.username,
                password:this.user1.password,
            }
            this.getuser(data,1);
        }
    }else if(this.typecar == 12){
            let license_number_count = this.user1.license_number.length;
        if(license_number_count == 9){
            let license_number_check = this.user1.license_number.slice(2, 3);
            if(license_number_check == 1 || license_number_check == 3){
                let data = {
                    license_number:this.user1.license_number,
                    username:this.user1.username,
                    password:this.user1.password,
                }

                this.getuser(data,1);
            }else{
                this.$swal({title:'เลขใบอนุญาตของผู้ควบคุมการตรวจสภาพรถไม่ถูกต้อง !',
                icon:'warning',
                })
            }
        }else{
            let data = {
                license_number:this.user1.license_number,
                username:this.user1.username,
                password:this.user1.password,
            }

            this.getuser(data,1);
        }
    }else{
            this.$swal({title:'กรุณาเลือกระเภท รย. ก่อน !',
            icon:'warning',
            })
    }
    },
    getuser2(){
        if(this.userinfo1.license_number){
        if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
            let license_number_count = this.user2.license_number.length;
            if(license_number_count == 9){
                let license_number_check = this.user2.license_number.slice(2, 3);
                if(license_number_check == 1 || license_number_check == 2){
                    if(this.user1.license_number != this.user2.license_number){
                        let data = {
                            license_number2:this.user2.license_number,
                            username:this.user2.username,
                            password:this.user2.password,
                        }
                        this.getuser(data,2);
                    }else{
                        this.$swal({title:'เลขใบอนุญาตของเจ้าหน้าที่ตรวจสภาพรถต้องไม่ใช่คนเดียวกัน !',
                        icon:'warning',
                        })
                    }
                }else{
                    this.$swal({title:'เลขใบอนุญาตของเจ้าหน้าที่ตรวจสภาพรถไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }
            }else{
                let data = {
                    license_number2:this.user2.license_number,
                    username:this.user2.username,
                    password:this.user2.password,
                }
                this.getuser(data,2);
            }
        }else if(this.typecar == 12){
            let license_number_count = this.user2.license_number.length;
            if(license_number_count == 9){
                let license_number_check = this.user2.license_number.slice(2, 3);
                if(license_number_check == 1 || license_number_check == 3 || license_number_check == 4){
                    let data = {
                        license_number2:this.user2.license_number,
                        username:this.user2.username,
                        password:this.user2.password,
                    }
                    this.getuser(data,2);
                }else{
                    this.$swal({title:'เลขใบอนุญาตของเจ้าหน้าที่ตรวจสภาพรถไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }
            }else{
                let data = {
                    license_number2:this.user2.license_number,
                    username:this.user2.username,
                    password:this.user2.password,
                }
                this.getuser(data,2);
            }
        }else{
            this.$swal({title:'กรุณาเลือกระเภท รย. ก่อน !',
                icon:'warning',
                })
        }
        }else{
            this.$swal({title:'ต้องล็อคอินผู้ควบคุมตรวจสภาพก่อน !',
            icon:'warning',
            })
        }
    },
    async getuser(data,type){ 
    const datas = {
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 1,
        datas: data
    }
    await axios.post(`adddatacar`,datas).then(rs=>{
        if(type == 1){
            if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                if(rs.data.datas.license_number == ''){ 
                    this.userinfo1 = '';
                    this.$swal({title:'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง !',
                        icon:'warning',
                    })
                }else if(rs.data.datas.license_number.slice(2, 3) == 1){
                    this.userinfo1 = '';
                    if(!this.userinfo2.license_number){
                        if(rs.data.datas.manageinspectioncenter_id != this.license_id){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพหรือเจ้าหน้าที่ตรวจสภาพไม่ได้ทำงานที่สถานตรวจสภาพนี้ !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.check_date_exp === 1){
                            this.$swal({
                                title: "คำเตือน!",
                                text: "ใบอนุญาตของท่านใกล้หมดอายุแล้ว กรุณาต่ออายุก่อนที่จะไม่สามารถเข้าสู่ระบบได้",
                                icon: "warning",
                                button: "รับทราบ",
                            });
                            this.userinfo1 = rs.data.datas; 
                        }else if(rs.data.datas.check_date_exp === 2){
                            this.$swal({
                                title: "ผิดพลาด!",
                                text: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากใบอนุญาตของท่านหมดอายุแล้วกรุณาต่ออายุก่อน !",
                                icon: "error",
                                button: "รับทราบ",
                            });   
                        }else if(rs.data.datas.check_date_exp === 3){
                            this.userinfo1 = rs.data.datas; 
                        }
                        // }else{
                        //    this.userinfo1 = rs.data; 
                        // }
                    }else{
                        if(this.userinfo2.license_number == rs.data.datas.license_number){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพและเจ้าหน้าที่ตรวจสภาพต้องไม่ใช่คนเดียวกัน !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.manageinspectioncenter_id != this.license_id){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพหรือเจ้าหน้าที่ตรวจสภาพไม่ได้ทำงานที่สถานตรวจสภาพนี้ !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.check_date_exp === 1){
                            this.$swal({
                                title: "คำเตือน!",
                                text: "ใบอนุญาตของท่านใกล้หมดอายุแล้ว กรุณาต่ออายุก่อนที่จะไม่สามารถเข้าสู่ระบบได้",
                                icon: "warning",
                                button: "รับทราบ",
                            });
                            this.userinfo1 = rs.data.datas; 
                        }else if(rs.data.datas.check_date_exp === 2){
                            this.$swal({
                                title: "ผิดพลาด!",
                                text: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากใบอนุญาตของท่านหมดอายุแล้วกรุณาต่ออายุก่อน !",
                                icon: "error",
                                button: "รับทราบ",
                            });   
                        }else if(rs.data.datas.check_date_exp === 3){
                        this.userinfo1 = rs.data.datas;
                        }
                    }
                }else{
                    this.$swal({title:'เลขใบอนุญาตหรือข้อมูลของผู้ควบคุมการตรวจสภาพรถไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }
            }else if(this.typecar == 12){
                if(rs.data.datas.license_number == ''){
                    this.userinfo1 = '';
                    this.$swal({title:'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }else if(rs.data.datas.license_number.slice(2, 3) == 1 || rs.data.datas.license_number.slice(2, 3) == 3){
                    this.userinfo1 = '';
                    if(!this.userinfo2.license_number){
                        if(rs.data.datas.manageinspectioncenter_id != this.license_id){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพหรือเจ้าหน้าที่ตรวจสภาพไม่ได้ทำงานที่สถานตรวจสภาพนี้ !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.check_date_exp === 1){
                            this.$swal({
                                title: "คำเตือน!",
                                text: "ใบอนุญาตของท่านใกล้หมดอายุแล้ว กรุณาต่ออายุก่อนที่จะไม่สามารถเข้าสู่ระบบได้",
                                icon: "warning",
                                button: "รับทราบ",
                            });
                            this.userinfo1 = rs.data.datas; 
                        }else if(rs.data.datas.check_date_exp === 2){
                            this.$swal({
                                title: "ผิดพลาด!",
                                text: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากใบอนุญาตของท่านหมดอายุแล้วกรุณาต่ออายุก่อน !",
                                icon: "error",
                                button: "รับทราบ",
                            });   
                        }else if(rs.data.datas.check_date_exp === 3){
                        this.userinfo1 = rs.data.datas; 
                        }
                    }else{
                        if(this.userinfo2.license_number == rs.data.datas.license_number){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพและเจ้าหน้าที่ตรวจสภาพต้องไม่ใช่คนเดียวกัน !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.manageinspectioncenter_id != this.license_id){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพหรือเจ้าหน้าที่ตรวจสภาพไม่ได้ทำงานที่สถานตรวจสภาพนี้ !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.check_date_exp === 1){
                            this.$swal({
                                title: "คำเตือน!",
                                text: "ใบอนุญาตของท่านใกล้หมดอายุแล้ว กรุณาต่ออายุก่อนที่จะไม่สามารถเข้าสู่ระบบได้",
                                icon: "warning",
                                button: "รับทราบ",
                            });
                            this.userinfo1 = rs.data.datas; 
                        }else if(rs.data.datas.check_date_exp === 2){
                            this.$swal({
                                title: "ผิดพลาด!",
                                text: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากใบอนุญาตของท่านหมดอายุแล้วกรุณาต่ออายุก่อน !",
                                icon: "error",
                                button: "รับทราบ",
                            });   
                        }else if(rs.data.datas.check_date_exp === 3){
                        this.userinfo1 = rs.data.datas; 
                        }
                    }
                }else{
                    this.$swal({title:'เลขใบอนุญาตหรือข้อมูลของผู้ควบคุมการตรวจสภาพรถไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }
            }
        } else if(type==2) {
            if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                this.userinfo2 = '';
                if(rs.data.datas.license_number == ''){
                    this.$swal({title:'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }else if(rs.data.datas.license_number.slice(2, 3) == 1 || rs.data.datas.license_number.slice(2, 3) == 2){
                    this.userinfo2 = '';
                    if(this.userinfo1.license_number){
                        if(rs.data.datas.manageinspectioncenter_id != this.license_id){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพหรือเจ้าหน้าที่ตรวจสภาพไม่ได้ทำงานที่สถานตรวจสภาพนี้ !',
                            icon:'error',
                            })
                        }else if(this.userinfo1.license_number == rs.data.datas.license_number){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพและเจ้าหน้าที่ตรวจสภาพต้องไม่ใช่คนเดียวกัน !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.check_date_exp === 1){
                            this.$swal({
                                title: "คำเตือน!",
                                text: "ใบอนุญาตของท่านใกล้หมดอายุแล้ว กรุณาต่ออายุก่อนที่จะไม่สามารถเข้าสู่ระบบได้",
                                icon: "warning",
                                button: "รับทราบ",
                            });
                            this.userinfo2 = rs.data.datas; 
                        }else if(rs.data.datas.check_date_exp === 2){
                            this.$swal({
                                title: "ผิดพลาด!",
                                text: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากใบอนุญาตของท่านหมดอายุแล้วกรุณาต่ออายุก่อน !",
                                icon: "error",
                                button: "รับทราบ",
                            });   
                        }else if(rs.data.datas.check_date_exp === 3){
                        this.userinfo2 = rs.data.datas; 
                        }
                    }else{
                        this.$swal({title:'ต้องล็อคอินผู้ควบคุมตรวจสภาพก่อน !',
                        icon:'warning',
                        })
                    }
                }else{
                    this.$swal({title:'เลขใบอนุญาตของเจ้าหน้าที่ตรวจสภาพรถไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }
            }else if(this.typecar == 12){
                if(rs.data.datas.license_number == ''){
                    this.userinfo2 = '';
                    this.$swal({title:'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }else if(rs.data.datas.license_number.slice(2, 3) == 1 || rs.data.datas.license_number.slice(2, 3) == 3 || rs.data.datas.license_number.slice(2, 3) == 4){
                    this.userinfo2 = '';
                    if(this.userinfo1.license_number){
                        if(rs.data.datas.manageinspectioncenter_id != this.license_id){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพหรือเจ้าหน้าที่ตรวจสภาพไม่ได้ทำงานที่สถานตรวจสภาพนี้ !',
                            icon:'error',
                            })
                        }else if(this.userinfo1.license_number == rs.data.datas.license_number){
                            this.$swal({title:'ผู้ควบคุมตรวจสภาพและเจ้าหน้าที่ตรวจสภาพต้องไม่ใช่คนเดียวกัน !',
                            icon:'error',
                            })
                        }else if(rs.data.datas.check_date_exp === 1){
                            this.$swal({
                                title: "คำเตือน!",
                                text: "ใบอนุญาตของท่านใกล้หมดอายุแล้ว กรุณาต่ออายุก่อนที่จะไม่สามารถเข้าสู่ระบบได้",
                                icon: "warning",
                                button: "รับทราบ",
                            });
                            this.userinfo2 = rs.data.datas; 
                        }else if(rs.data.datas.check_date_exp === 2){
                            this.$swal({
                                title: "ผิดพลาด!",
                                text: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากใบอนุญาตของท่านหมดอายุแล้วกรุณาต่ออายุก่อน !",
                                icon: "error",
                                button: "รับทราบ",
                            });   
                        }else if(rs.data.datas.check_date_exp === 3){
                        this.userinfo2 = rs.data.datas; 
                        }
                    }else{
                        this.$swal({title:'ต้องล็อคอินผู้ควบคุมตรวจสภาพก่อน !',
                        icon:'warning',
                        })
                    }
                }else{
                    this.$swal({title:'เลขใบอนุญาตของเจ้าหน้าที่ตรวจสภาพรถไม่ถูกต้อง !',
                    icon:'warning',
                    })
                }
            }
        }
        
    }).catch(err=>{
        console.log(err) 
    })
    },
    getsends(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังตรวจสอบข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()

        if(!this.car_band){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณาเลือกยี่ห้อรถ',
                icon:'warning',
                })
        }else if(!this.chaisse_no){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณากรอกหมายเลขตัวรถ',
                icon:'warning',
                })
        }else if(!this.engine_no){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณากรอกหมายเครื่องยนต์',
                icon:'warning',
                })
        }else if(!this.car_lic_prov){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณาเลือกจังหวัด(ทะเบียน)',
                icon:'warning',
                })
        }else if(!this.car_lic_id_char){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณากรอกหมวดทะเบียน',
                icon:'warning',
                })
        }else if(!this.car_lic_id_num){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณากรอกตัวเลขทะเบียน',
                icon:'warning',
                })
        }else if(!this.typecar){
            this.$swal({title:'กรุณากรอกข้อมูลให้ครบก่อน !',
                text:'กรุณาเลือกประเภทรถ(รย.)',
                icon:'warning',
                })
        }else{
            const datas = {
                uid: storeAuth.id,
                gid: storeAuth.group_id,
                tid: 2,
                datas: {
                    brnDesc:this.car_band.band,
                    chkIssNo:this.tro_id.substring(-6,this.tro_id.length-5),
                    chkIssYY:this.tro_id.substring(this.tro_id.length-4),
                    numBody:this.chaisse_no,
                    numEng:this.engine_no,
                    offLocCode:this.car_lic_prov.id,
                    plate1:this.car_lic_id_char,
                    plate2:this.car_lic_id_num.padStart(4, '0'),
                    vehTypeCode:this.typecar,
                }
            }
            axios.post(`adddatacar`,datas).then(rs=>{
                if(rs.data.status == 3){
                    this.checkmdm = 1;
                    this.carinfo.COLOR = '';
                    this.carinfo.FUELDESC = '';
                    this.carinfo.KINDDESC = '';
                    this.carinfo.OFFLOCCODE = '';
                    this.carinfo.PLATE1 = '';
                    this.carinfo.PLATE2 = '';
                    this.carinfo.PLTTYPE = '';
                    this.carinfo.VEHTYPECODE = '';
                    this.$swal({title:'เกิดข้อมูลผิดพลาดในการค้นหาข้อมูล !',
                    text:'หากกรอกข้อมูลครบถ้วนและถูกต้องแล้ว สถานตรวจสภาพรถสามารถดำเนินการตรวจสภาพรถและกดปุ่มส่งผลการตรวจสภาพรถเข้าระบบ v6 ได้ ทั้งนี้ ขอให้สถานตรวจสภาพรถใช้ความระมัดระวังในการกรอกข้อมูลให้ถูกต้องตรงกับใบคู่มือจดทะเบียนรถ/สำเนาใบคู่มือจดทะเบียนรถ ก่อนส่งผลการตรวจสภาพรถทุกครั้ง',
                    icon:'error',
                    })
                }else if(rs.data.status == 2){
                    this.checkmdm = 2;
                    this.carinfo.COLOR = '';
                    this.carinfo.FUELDESC = '';
                    this.carinfo.KINDDESC = '';
                    this.carinfo.OFFLOCCODE = '';
                    this.carinfo.PLATE1 = '';
                    this.carinfo.PLATE2 = '';
                    this.carinfo.PLTTYPE = '';
                    this.carinfo.VEHTYPECODE = '';
                    this.$swal({title:'ไม่สำเร็จ !',
                    text: rs.data.datas,
                    icon:'error',
                    })
                }else if(rs.data.status == 5){
                    this.checkmdm = 2;
                    this.carinfo.COLOR = '';
                    this.carinfo.FUELDESC = '';
                    this.carinfo.KINDDESC = '';
                    this.carinfo.OFFLOCCODE = '';
                    this.carinfo.PLATE1 = '';
                    this.carinfo.PLATE2 = '';
                    this.carinfo.PLTTYPE = '';
                    this.carinfo.VEHTYPECODE = '';
                    this.$swal({title:'เกิดข้อมูลผิดพลาดในการค้นหาข้อมูล !',
                    text:'ข้อมูลที่ใช้ตรวจสอบไม่ครบถ้วน',
                    icon:'error',
                    })
                }else if(rs.data.status == 4){
                    this.checkmdm = 2;
                    this.carinfo.COLOR = '';
                    this.carinfo.FUELDESC = '';
                    this.carinfo.KINDDESC = '';
                    this.carinfo.OFFLOCCODE = '';
                    this.carinfo.PLATE1 = '';
                    this.carinfo.PLATE2 = '';
                    this.carinfo.PLTTYPE = '';
                    this.carinfo.VEHTYPECODE = '';
                    this.$swal({
                        title: 'การล็อคอินของท่านหมดอายุการใช้งาน !',
                        text: "กรุณาออกจากระบบแล้วล็อคอินเข้าสู่ระบบใหม่อีกครั้ง",
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#664284',
                        confirmButtonText: 'ตกลง'
                    }).then((result) => {
                        localStorage.clear();
                        window.location.href = '/auth/login';
                    }) 
                }else{
                    this.checkmdm = 3;
                    this.carinfo = rs.data.datas
                    this.$swal({title:'พบข้อมูลที่ตรวจสอบ !',
                    icon:'success',
                    })
                }
            }).catch(err=>{
                    this.checkmdm = 1;
                    this.carinfo.COLOR = '';
                    this.carinfo.FUELDESC = '';
                    this.carinfo.KINDDESC = '';
                    this.carinfo.OFFLOCCODE = '';
                    this.carinfo.PLATE1 = '';
                    this.carinfo.PLATE2 = '';
                    this.carinfo.PLTTYPE = '';
                    this.carinfo.VEHTYPECODE = '';
                this.$swal({title:'เกิดข้อมูลผิดพลาดในการค้นหาข้อมูล !',
                    text:'หากกรอกข้อมูลครบถ้วนและถูกต้องแล้ว สถานตรวจสภาพรถสามารถดำเนินการตรวจสภาพรถและกดปุ่มส่งผลการตรวจสภาพรถเข้าระบบ v6 ได้ ทั้งนี้ ขอให้สถานตรวจสภาพรถใช้ความระมัดระวังในการกรอกข้อมูลให้ถูกต้องตรงกับใบคู่มือจดทะเบียนรถ/สำเนาใบคู่มือจดทะเบียนรถ ก่อนส่งผลการตรวจสภาพรถทุกครั้ง',
                    icon:'error',
                })
            })
        }
    },
    async sends(){
        if(this.typecar){
            if(this.settings.server.basetype == 'udp'){
            await fetch('http://127.0.0.1:8080/s', {
                method: "GET"
            })
            .then((response) => {
                response.json().then((rs) => {
                    axios.post(`checktoken`,{
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 1,
                        datas:[
                            rs.token,
                        ]
                        }).then((rsuuid) => {
                            if(rsuuid.data.status == 1){
                                if(this.settings.server.muller == 1){
                                    let arrs = rs.result.split("#")
                                    this.varss = []
                                    for(let i=0;i<arrs.length;i++) {
                                        const items = arrs[i].split("=");
                                        this.varss.push(items[1]);
                                    }
                                    if(this.varss.length == 9){
                                        if(this.varss[0] != ''){ this.vars.axle1l = this.varss[0];}else{ this.vars.axle1l = '0';}
                                        if(this.varss[1] != ''){ this.vars.axle1r = this.varss[1];}else{ this.vars.axle1r = '0';}
                                        if(this.varss[2] != ''){ this.vars.axle2l = this.varss[2];}else{ this.vars.axle2l = '0';}
                                        if(this.varss[3] != ''){ this.vars.axle2r = this.varss[3];}else{ this.vars.axle2r = '0';}
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        if(this.varss[4] != ''){ this.vars.wheelAlignment = this.varss[4];}else{ this.vars.wheelAlignment = '0';}
                                        if(this.varss[5] != ''){ this.vars.axle1Weight = this.varss[5];}else{ this.vars.axle1Weight = '0';}
                                        if(this.varss[6] != ''){ this.vars.axle2Weight = this.varss[6];}else{ this.vars.axle2Weight = '0';}
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        if(this.varss[7] != ''){ this.vars.handbrakeForce2L = this.varss[7];}else{ this.vars.handbrakeForce2L = '0';}
                                        if(this.varss[8] != ''){ this.vars.handbrakeForce2R = (parseFloat(this.varss[8])).toString();}else{ this.vars.handbrakeForce2R = '0';}
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }else if(this.varss.length == 10){
                                        this.vars.axle1l = (this.varss[1] * 10).toString();
                                        this.vars.axle1r = (this.varss[2] * 10).toString();
                                        this.vars.axle2l = (this.varss[3] * 10).toString();
                                        this.vars.axle2r = (this.varss[4] * 10).toString();
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        this.vars.wheelAlignment = "0";
                                        this.vars.axle1Weight = (this.varss[5] * 9.81).toFixed(2);
                                        this.vars.axle2Weight = (this.varss[6] * 9.81).toFixed(2);
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        this.vars.handbrakeForce2L = (this.varss[7] * 10).toString();
                                        this.vars.handbrakeForce2R = (this.varss[8] * 10).toString();
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }else if(this.varss.length == 17){
                                        if(this.varss[1] != ''){ this.vars.axle1l = this.varss[1];}else{ this.vars.axle1l = '0';}
                                        if(this.varss[2] != ''){ this.vars.axle1r = this.varss[2];}else{ this.vars.axle1r = '0';}
                                        if(this.varss[3] != ''){ this.vars.axle2l = this.varss[3];}else{ this.vars.axle2l = '0';}
                                        if(this.varss[4] != ''){ this.vars.axle2r = this.varss[4];}else{ this.vars.axle2r = '0';}
                                        if(this.varss[5] != ''){ this.vars.axle3l = this.varss[5];}else{ this.vars.axle3l = '0';}
                                        if(this.varss[6] != ''){ this.vars.axle3r = this.varss[6];}else{ this.vars.axle3r = '0';}
                                        if(this.varss[7] != ''){ this.vars.axle4l = this.varss[7];}else{ this.vars.axle4l = '0';}
                                        if(this.varss[8] != ''){ this.vars.axle4r = this.varss[8];}else{ this.vars.axle4r = '0';}
                                        if(this.varss[9] != ''){ this.vars.wheelAlignment = this.varss[9];}else{ this.vars.wheelAlignment = '0';}
                                        if(this.varss[10] != ''){ this.vars.axle1Weight = this.varss[10];}else{ this.vars.axle1Weight = '0';}
                                        if(this.varss[11] != ''){ this.vars.axle2Weight = this.varss[11];}else{ this.vars.axle2Weight = '0';}
                                        if(this.varss[12] != ''){ this.vars.axle3Weight = this.varss[12];}else{ this.vars.axle3Weight = '0';}
                                        if(this.varss[13] != ''){ this.vars.axle4Weight = this.varss[13];}else{ this.vars.axle4Weight = '0';}
                                        if(this.varss[14] != ''){ this.vars.handbrakeForce2L = this.varss[14];}else{ this.vars.handbrakeForce2L = '0';}
                                        if(this.varss[15] != ''){ this.vars.handbrakeForce2R = this.varss[15];}else{ this.vars.handbrakeForce2R = '0';}
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }else if(this.varss.length == 11){
                                        this.vars.axle1l = this.varss[1];
                                        this.vars.axle1r = this.varss[2];
                                        this.vars.axle2l = this.varss[3];
                                        this.vars.axle2r = this.varss[4];
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        this.vars.wheelAlignment = this.varss[5];
                                        this.vars.axle1Weight = this.varss[6];
                                        this.vars.axle2Weight = this.varss[7]
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        this.vars.handbrakeForce2L = this.varss[8];
                                        this.vars.handbrakeForce2R = this.varss[9];
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }else if(this.varss.length == 28){
                                        this.vars.axle1l = this.varss[1];
                                        this.vars.axle1r = this.varss[2];
                                        this.vars.axle2l = this.varss[3];
                                        this.vars.axle2r = this.varss[4];
                                        this.vars.axle3l = this.varss[5];
                                        this.vars.axle3r = this.varss[6];
                                        this.vars.axle4l = this.varss[7];
                                        this.vars.axle4r = this.varss[8];
                                        this.vars.wheelAlignment = this.varss[11];
                                        this.vars.axle1Weight = this.varss[12];
                                        this.vars.axle2Weight = this.varss[13];
                                        this.vars.axle3Weight = this.varss[14];
                                        this.vars.axle4Weight = this.varss[15];
                                        this.vars.handbrakeForce2L = this.varss[19];
                                        this.vars.handbrakeForce2R = this.varss[20];
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }else{
                                        this.vars.axle1l = this.varss[1];
                                        this.vars.axle1r = this.varss[2];
                                        this.vars.axle2l = this.varss[3];
                                        this.vars.axle2r = this.varss[4];
                                        this.vars.axle3l = this.varss[5];
                                        this.vars.axle3r = this.varss[6];
                                        this.vars.axle4l = this.varss[7];
                                        this.vars.axle4r = this.varss[8];
                                        this.vars.wheelAlignment = this.varss[9];
                                        this.vars.axle1Weight = this.varss[10];
                                        this.vars.axle2Weight = this.varss[11];
                                        this.vars.axle3Weight = this.varss[12];
                                        this.vars.axle4Weight = this.varss[13];
                                        this.vars.handbrakeForce2L = this.varss[14];
                                        this.vars.handbrakeForce2R = this.varss[15];
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }
                                }else if(this.settings.server.muller == 2){
                                    let arrs = rs.result.split("#")
                                    this.varss = []
                                    for(let i=0;i<arrs.length;i++) {
                                        const items = arrs[i].split("=");
                                        this.varss.push(items[1]);
                                    }
                                    if(this.varss.length == 11){
                                        this.vars.axle1l = (this.varss[1] * 1000).toFixed(2);
                                        this.vars.axle1r = (this.varss[2] * 1000).toFixed(2);
                                        this.vars.axle2l = (this.varss[3] * 1000).toFixed(2);
                                        this.vars.axle2r = (this.varss[4] * 1000).toFixed(2);
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        this.vars.wheelAlignment = this.varss[5];
                                        this.vars.axle1Weight = (this.varss[6] * 9.81).toFixed(2);
                                        this.vars.axle2Weight = (this.varss[7] * 9.81).toFixed(2);
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        this.vars.handbrakeForce2L = (this.varss[8] * 1000).toFixed(2);
                                        this.vars.handbrakeForce2R = (this.varss[9] * 1000).toFixed(2);
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }

                                }else if(this.settings.server.muller == 4){
                                    let arrs = rs.result.split("#")
                                    this.varss = []
                                    for(let i=0;i<arrs.length;i++) {
                                        const items = arrs[i].split("=");
                                        this.varss.push(items[1]);
                                    }
                                    if(this.varss.length == 9){
                                        if(this.varss[0] != ''){ this.vars.axle1l = this.varss[0];}else{ this.vars.axle1l = '0';}
                                        if(this.varss[1] != ''){ this.vars.axle1r = this.varss[1];}else{ this.vars.axle1r = '0';}
                                        if(this.varss[2] != ''){ this.vars.axle2l = this.varss[2];}else{ this.vars.axle2l = '0';}
                                        if(this.varss[3] != ''){ this.vars.axle2r = this.varss[3];}else{ this.vars.axle2r = '0';}
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        if(this.varss[4] != ''){ this.vars.wheelAlignment = this.varss[4];}else{ this.vars.wheelAlignment = '0';}
                                        if(this.varss[5] != ''){ this.vars.axle1Weight = this.varss[5];}else{ this.vars.axle1Weight = '0';}
                                        if(this.varss[6] != ''){ this.vars.axle2Weight = this.varss[6];}else{ this.vars.axle2Weight = '0';}
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        if(this.varss[7] != ''){ this.vars.handbrakeForce2L = this.varss[7];}else{ this.vars.handbrakeForce2L = '0';}
                                        if(this.varss[8] != ''){ this.vars.handbrakeForce2R = (parseFloat(this.varss[8])).toString();}else{ this.vars.handbrakeForce2R = '0';}
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }

                                }else{
                                    let arrs = rs.result.split("#")
                                    this.varss = []
                                    for(let i=0;i<arrs.length;i++) {
                                        const items = arrs[i].split("=");
                                        this.varss.push(items[1]);
                                    }
                                    if(this.varss.length == 10){
                                        this.vars.axle1l = this.varss[1];
                                        this.vars.axle1r = this.varss[2];
                                        this.vars.axle2l = this.varss[3];
                                        this.vars.axle2r = this.varss[4];
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        this.vars.wheelAlignment = "0";
                                        this.vars.axle1Weight = (this.varss[5] * 9.81).toFixed(2);
                                        this.vars.axle2Weight = (this.varss[6] * 9.81).toFixed(2);
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        this.vars.handbrakeForce2L = this.varss[7];
                                        this.vars.handbrakeForce2R = this.varss[8];
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    }else if(this.varss.length == 9){
                                        this.vars.axle1l = this.varss[0];
                                        this.vars.axle1r = this.varss[1];
                                        this.vars.axle2l = this.varss[2];
                                        this.vars.axle2r = this.varss[3];
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        this.vars.wheelAlignment = this.varss[4];
                                        this.vars.axle1Weight = (this.varss[5]* 9.81).toFixed(0);
                                        this.vars.axle2Weight = (this.varss[6]* 9.81).toFixed(0);
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        this.vars.handbrakeForce2L = this.varss[7];
                                        this.vars.handbrakeForce2R = (parseFloat(this.varss[8])).toString();
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }else if(this.varss.length == 11){
                                        this.vars.axle1l = this.varss[1];
                                        this.vars.axle1r = this.varss[2];
                                        this.vars.axle2l = this.varss[3];
                                        this.vars.axle2r = this.varss[4];
                                        this.vars.axle3l = "0";
                                        this.vars.axle3r = "0";
                                        this.vars.axle4l = "0";
                                        this.vars.axle4r = "0";
                                        this.vars.wheelAlignment = this.varss[5];
                                        this.vars.axle1Weight = (this.varss[6] * 9.81).toFixed(2);
                                        this.vars.axle2Weight = (this.varss[7] * 9.81).toFixed(2);
                                        this.vars.axle3Weight = "0";
                                        this.vars.axle4Weight = "0";
                                        this.vars.handbrakeForce2L = this.varss[8];
                                        this.vars.handbrakeForce2R = this.varss[9];
                                        this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum1)) this.varssum1 = 0
                                        this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum2)) this.varssum2 = 0
                                        this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum3)) this.varssum3 = 0
                                        this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                        if (isNaN(this.varssum4)) this.varssum4 = 0
                                        if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                        this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                        this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                        if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                    }
                                }
                            }else{
                                this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                    icon:'error',
                                })
                            }

                        }).catch((failureCallback) => {
                            this.$swal({
                                title:'เกิดข้อผิดพลาดในการรับค่า',
                                icon:'error',
                            })
                        });
                        
                });
            }).catch(err=>{
                this.$swal({title:'รับค่าไม่สำเร็จ !',
                icon:'error',
                })
            })
        }else if(this.settings.server.basetype == 'ocr'){
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()

            if(this.ocr_type_link_capture == 'b64'){
                // console.log('----- b64 -----');
                let ocr_type_link_capture_new = this.ocr_type_link_capture;
                // function testb64() {
                    await fetch('http://127.0.0.1:8080/img/Capture.'+ocr_type_link_capture_new, {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            axios.post(`checktoken`,{
                                uid: storeAuth.id,
                                gid: storeAuth.group_id,
                                tid: 1,
                                datas:[
                                    rs.token,
                                ]
                            }).then((rsuuid) => {
                                if(rsuuid.data.status == 1){
                                    var img = rs.result
                                    tc(img, 'base64')
                                }else{
                                    this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                        icon:'error',
                                    })
                                }
                            }).catch(err=>{
                                this.$swal({title:'เกิดข้อผิดพลาดในการรับค่า',
                                icon:'error',
                                })
                            })
                        });
                    }).catch(err=>{
                        this.$swal({title:'รับค่าไม่สำเร็จ !',
                        icon:'error',
                        })
                    })
                // }
                
                // testb64()

                //###############################################################################
                const tc = async (img, type) => {

                    // let tversion = 'v5.0.1';
                    // var startTime, endTime;
                    // startTime = new Date();

                    const scheduler = createScheduler();
                    const worker1 = await createWorker('eng');
                    

                    const rectangles = JSON.parse(JSON.stringify(this.rectangles));

                    await worker1.setParameters({
                        tessedit_char_whitelist: '0123456789+-.',
                    });
                    scheduler.addWorker(worker1);

                    const results = await Promise.all(rectangles.map((rectangle) => (
                        scheduler.addJob('recognize', img, { rectangle })
                    )));
                    var dataocr = results.map(r => r.data.text.replace('\n',''));
                    this.ocrvalue = dataocr;

                    await scheduler.terminate();

                    if(this.unit_ocr[0] == 'kN'){
                        if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 1000).toFixed(2);}
                        this.vars.axle1l.toString();
                    }else if(this.unit_ocr[0] == 'daN'){
                        if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 10).toFixed(2);}
                        this.vars.axle1l.toString();
                    }else{
                        if (!this.ocrvalue[0]){
                            this.vars.axle1l = '0'
                        }else{
                            this.vars.axle1l = Number(this.ocrvalue[0]).toFixed(2);
                        }
                        this.vars.axle1l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[1] == 'kN'){
                        if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 1000).toFixed(2);}
                        this.vars.axle1r.toString();
                    }else if(this.unit_ocr[1] == 'daN'){
                        if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 10).toFixed(2);}
                        this.vars.axle1r.toString();
                    }else{
                        if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{
                            this.vars.axle1r = Number(this.ocrvalue[1]).toFixed(2);
                        }
                        this.vars.axle1r.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[2] == 'kN'){
                        if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 1000).toFixed(2);}
                        this.vars.axle2l.toString();
                    }else if(this.unit_ocr[2] == 'daN'){
                        if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 10).toFixed(2);}
                        this.vars.axle2l.toString();
                    }else{
                        if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{
                            this.vars.axle2l = Number(this.ocrvalue[2]).toFixed(2);
                        }
                        this.vars.axle2l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[3] == 'kN'){
                        if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 1000).toFixed(2);}
                        this.vars.axle2r.toString();
                    }else if(this.unit_ocr[3] == 'daN'){
                        if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 10).toFixed(2);}
                        this.vars.axle2r.toString();
                    }else{
                        if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{
                            this.vars.axle2r = Number(this.ocrvalue[3]).toFixed(2);}
                        this.vars.axle2r.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[4] == 'kN'){
                        if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 1000).toFixed(2);}
                        this.vars.axle3l.toString();
                    }else if(this.unit_ocr[4] == 'daN'){
                        if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 10).toFixed(2);}
                        this.vars.axle3l.toString();
                    }else{
                        if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{
                            this.vars.axle3l = Number(this.ocrvalue[4]).toFixed(2);}
                        this.vars.axle3l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[5] == 'kN'){
                        if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 1000).toFixed(2);}
                        this.vars.axle3r.toString();
                    }else if(this.unit_ocr[5] == 'daN'){
                        if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 10).toFixed(2);}
                        this.vars.axle3r.toString();
                    }else{
                        if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{
                            this.vars.axle3r = Number(this.ocrvalue[5]).toFixed(2);}
                        this.vars.axle3r.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[6] == 'kN'){
                        if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 1000).toFixed(2);}
                        this.vars.axle4l.toString();
                    }else if(this.unit_ocr[6] == 'daN'){
                        if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 10).toFixed(2);}
                        this.vars.axle4l.toString();
                    }else{
                        if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{
                            this.vars.axle4l = Number(this.ocrvalue[6]).toFixed(2);}
                        this.vars.axle4l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[7] == 'kN'){
                        if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 1000).toFixed(2);}
                        this.vars.axle4r.toString();
                    }else if(this.unit_ocr[7] == 'daN'){
                        if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 10).toFixed(2);}
                        this.vars.axle4r.toString();
                    }else{
                        if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{
                            this.vars.axle4r = Number(this.ocrvalue[7]).toFixed(2);}
                        this.vars.axle4r.toString();
                    }
                    // ---------------
                    if (!this.ocrvalue[10]){ this.vars.wheelAlignment = '0'}else{this.vars.wheelAlignment = this.ocrvalue[10].replace(/\s/g, '');}
                    // ---------------
                    if(this.unit_ocr[11] == 'kg'){
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 9.81).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }else if(this.unit_ocr[11] == 'kN'){
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 1000).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }else if(this.unit_ocr[11] == 'daN'){
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 10).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }else{
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{
                            this.vars.axle1Weight = Number(this.ocrvalue[11]).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[12] == 'kg'){
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 9.81).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }else if(this.unit_ocr[12] == 'kN'){
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 1000).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }else if(this.unit_ocr[12] == 'daN'){
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 10).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }else{
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{
                            this.vars.axle2Weight = Number(this.ocrvalue[12]).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[13] == 'kg'){
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 9.81).toFixed(2);}
                        this.vars.axle3Weight.toString();
                    }else if(this.unit_ocr[13] == 'kN'){
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 1000).toFixed(2);}
                        this.vars.axle3Weight.toString();
                    }else if(this.unit_ocr[13] == 'daN'){
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 10).toFixed(2);}
                        this.vars.axle3Weight.toString();
                    }else{
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{
                            this.vars.axle3Weight = Number(this.ocrvalue[13]).toFixed(2);
                        }
                        this.vars.axle3Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[14] == 'kg'){
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 9.81).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }else if(this.unit_ocr[14] == 'kN'){
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 1000).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }else if(this.unit_ocr[14] == 'daN'){
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 10).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }else{
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{
                            this.vars.axle4Weight = Number(this.ocrvalue[14]).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[16] == 'kN'){
                        if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 1000).toFixed(2);}
                        this.vars.handbrakeForce2L.toString();
                    }else if(this.unit_ocr[16] == 'daN'){
                        if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 10).toFixed(2);}
                        this.vars.handbrakeForce2L.toString();
                    }else{
                        if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{
                            this.vars.handbrakeForce2L = Number(this.ocrvalue[16]).toFixed(2);}
                        this.vars.handbrakeForce2L.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[17] == 'kN'){
                        if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 1000).toFixed(2);}
                        this.vars.handbrakeForce2R.toString();
                    }else if(this.unit_ocr[17] == 'daN'){
                        if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 10).toFixed(2);}
                        this.vars.handbrakeForce2R.toString();
                    }else{
                        if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{
                            this.vars.handbrakeForce2R = Number(this.ocrvalue[17]).toFixed(2);}
                        this.vars.handbrakeForce2R.toString();
                    }
                    // ---------------
                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum1)) this.varssum1 = 0
                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum2)) this.varssum2 = 0
                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum3)) this.varssum3 = 0
                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum4)) this.varssum4 = 0
                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum5)) this.varssum5 = 0
                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}

                    this.$swal.close();
                    // endTime = new Date();
                    // var timeDiff = endTime - startTime; //in ms
                    // timeDiff /= 1000;
                    // var seconds = Math.round(timeDiff);
                    // console.log('ORC เวอร์ชั่น ', tversion, ' ใช้เวลา ', seconds + ' วินาที');
                
                }
            }else{

                // let tversion = 'v3.0.3';
                // var startTime, endTime;
                // startTime = new Date();

                // fetch('http://127.0.0.1:8080/img/Capture.data', {
                //     method: "GET"
                // })
                // .then((response) => {
                //     response.json().then((rs) => {
                //         var dataocr = rs.result.data.map(r => r.replace('\n',''));
                //         this.ocrvalue = dataocr;

                //         if(this.unit_ocr[0] == 'kN'){
                //             if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 1000).toFixed(2);}
                //             this.vars.axle1l.toString();
                //         }else if(this.unit_ocr[0] == 'daN'){
                //             if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 10).toFixed(2);}
                //             this.vars.axle1l.toString();
                //         }else{
                //             if (!this.ocrvalue[0]){
                //                 this.vars.axle1l = '0'
                //             }else{
                //                 this.vars.axle1l = Number(this.ocrvalue[0]).toFixed(2);
                //             }
                //             this.vars.axle1l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[1] == 'kN'){
                //             if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 1000).toFixed(2);}
                //             this.vars.axle1r.toString();
                //         }else if(this.unit_ocr[1] == 'daN'){
                //             if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 10).toFixed(2);}
                //             this.vars.axle1r.toString();
                //         }else{
                //             if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{
                //                 this.vars.axle1r = Number(this.ocrvalue[1]).toFixed(2);
                //             }
                //             this.vars.axle1r.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[2] == 'kN'){
                //             if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 1000).toFixed(2);}
                //             this.vars.axle2l.toString();
                //         }else if(this.unit_ocr[2] == 'daN'){
                //             if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 10).toFixed(2);}
                //             this.vars.axle2l.toString();
                //         }else{
                //             if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{
                //                 this.vars.axle2l = Number(this.ocrvalue[2]).toFixed(2);
                //             }
                //             this.vars.axle2l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[3] == 'kN'){
                //             if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 1000).toFixed(2);}
                //             this.vars.axle2r.toString();
                //         }else if(this.unit_ocr[3] == 'daN'){
                //             if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 10).toFixed(2);}
                //             this.vars.axle2r.toString();
                //         }else{
                //             if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{
                //                 this.vars.axle2r = Number(this.ocrvalue[3]).toFixed(2);}
                //             this.vars.axle2r.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[4] == 'kN'){
                //             if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 1000).toFixed(2);}
                //             this.vars.axle3l.toString();
                //         }else if(this.unit_ocr[4] == 'daN'){
                //             if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 10).toFixed(2);}
                //             this.vars.axle3l.toString();
                //         }else{
                //             if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{
                //                 this.vars.axle3l = Number(this.ocrvalue[4]).toFixed(2);}
                //             this.vars.axle3l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[5] == 'kN'){
                //             if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 1000).toFixed(2);}
                //             this.vars.axle3r.toString();
                //         }else if(this.unit_ocr[5] == 'daN'){
                //             if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 10).toFixed(2);}
                //             this.vars.axle3r.toString();
                //         }else{
                //             if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{
                //                 this.vars.axle3r = Number(this.ocrvalue[5]).toFixed(2);}
                //             this.vars.axle3r.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[6] == 'kN'){
                //             if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 1000).toFixed(2);}
                //             this.vars.axle4l.toString();
                //         }else if(this.unit_ocr[6] == 'daN'){
                //             if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 10).toFixed(2);}
                //             this.vars.axle4l.toString();
                //         }else{
                //             if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{
                //                 this.vars.axle4l = Number(this.ocrvalue[6]).toFixed(2);}
                //             this.vars.axle4l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[7] == 'kN'){
                //             if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 1000).toFixed(2);}
                //             this.vars.axle4r.toString();
                //         }else if(this.unit_ocr[7] == 'daN'){
                //             if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 10).toFixed(2);}
                //             this.vars.axle4r.toString();
                //         }else{
                //             if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{
                //                 this.vars.axle4r = Number(this.ocrvalue[7]).toFixed(2);}
                //             this.vars.axle4r.toString();
                //         }
                //         // ---------------
                //         if (!this.ocrvalue[10]){ this.vars.wheelAlignment = '0'}else{this.vars.wheelAlignment = this.ocrvalue[10].replace(/\s/g, '');}
                //         // ---------------
                //         if(this.unit_ocr[11] == 'kg'){
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 9.81).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }else if(this.unit_ocr[11] == 'kN'){
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 1000).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }else if(this.unit_ocr[11] == 'daN'){
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 10).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{
                //                 this.vars.axle1Weight = Number(this.ocrvalue[11]).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[12] == 'kg'){
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 9.81).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }else if(this.unit_ocr[12] == 'kN'){
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 1000).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }else if(this.unit_ocr[12] == 'daN'){
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 10).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{
                //                 this.vars.axle2Weight = Number(this.ocrvalue[12]).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[13] == 'kg'){
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 9.81).toFixed(2);}
                //             this.vars.axle3Weight.toString();
                //         }else if(this.unit_ocr[13] == 'kN'){
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 1000).toFixed(2);}
                //             this.vars.axle3Weight.toString();
                //         }else if(this.unit_ocr[13] == 'daN'){
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 10).toFixed(2);}
                //             this.vars.axle3Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{
                //                 this.vars.axle3Weight = Number(this.ocrvalue[13]).toFixed(2);
                //             }
                //             this.vars.axle3Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[14] == 'kg'){
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 9.81).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }else if(this.unit_ocr[14] == 'kN'){
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 1000).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }else if(this.unit_ocr[14] == 'daN'){
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 10).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{
                //                 this.vars.axle4Weight = Number(this.ocrvalue[14]).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[16] == 'kN'){
                //             if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 1000).toFixed(2);}
                //             this.vars.handbrakeForce2L.toString();
                //         }else if(this.unit_ocr[16] == 'daN'){
                //             if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 10).toFixed(2);}
                //             this.vars.handbrakeForce2L.toString();
                //         }else{
                //             if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{
                //                 this.vars.handbrakeForce2L = Number(this.ocrvalue[16]).toFixed(2);}
                //             this.vars.handbrakeForce2L.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[17] == 'kN'){
                //             if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 1000).toFixed(2);}
                //             this.vars.handbrakeForce2R.toString();
                //         }else if(this.unit_ocr[17] == 'daN'){
                //             if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 10).toFixed(2);}
                //             this.vars.handbrakeForce2R.toString();
                //         }else{
                //             if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{
                //                 this.vars.handbrakeForce2R = Number(this.ocrvalue[17]).toFixed(2);}
                //             this.vars.handbrakeForce2R.toString();
                //         }
                //         // ---------------
                //         this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum1)) this.varssum1 = 0
                //         this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum2)) this.varssum2 = 0
                //         this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum3)) this.varssum3 = 0
                //         this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum4)) this.varssum4 = 0
                //         if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                //         this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum5)) this.varssum5 = 0
                //         if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                //         this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                //         if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                //         if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}

                //         this.$swal.close();
                //         endTime = new Date();
                //         var timeDiff = endTime - startTime; //in ms
                //         timeDiff /= 1000;
                //         var seconds = Math.round(timeDiff);
                //         console.log('ORC เวอร์ชั่น ', tversion, ' ใช้เวลา ', seconds + ' วินาที');
                //     });
                // });

            }

            
        //###############################################################################
            }

        }else{
        this.$swal({title:'กรุณาเลือกประเภทรถก่อน !',
            icon:'warning',
        })
        }
    },
    datasound1(){
    if(!this.typecar){
            this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
            icon:'warning',
            })
    }else if(!this.ry_id){
            this.$swal({title:'กรุณาเลือกประเภทเครื่องยนต์ !',
            icon:'warning',
            })
    }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
            this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
            icon:'warning',
            })
        }else if(!this.car_weight && this.typecar != 12){
            this.$swal({title:'ประเภท รย.1,2,3 ต้องกรอกน้ำหนักรถด้วย',
            icon:'warning',
            })
        }else{
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()

        if(this.settings.server.muller == 1){
            fetch('http://127.0.0.1:8080/a', {
                method: "GET"
            })
            .then((response) => {
                response.json().then((rs) => {
                    axios.post(`checktoken`,{
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 1,
                        datas:[
                            rs.token,
                        ]
                    }).then((rsuuid) => {
                        if(rsuuid.data.status == 1){
                            var varas = rs.result.replace(/[\n\r]/g,'')
                            const regex = /([\d.]{1,10})/gm;
                            var varasa = regex.exec(varas.toString())
                            this.vara = varasa[1]
                            this.$swal.close();
                            if(this.vara != ''){
                                this.soundinfo.data1 = this.vara
                                this.$swal({text:'รับค่าระดับเสียงครั้งที่ 1',
                                    title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                })
                            }
                        }else{
                            this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                icon:'error',
                            })
                        }
                    }).catch(err=>{
                        this.$swal({title:'เกิดข้อผิดพลาดในการรับค่า',
                        icon:'error',
                        })
                    })
                })
            });
        }else{
                fetch('http://127.0.0.1:8080/a', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    axios.post(`checktoken`,{
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 1,
                        datas:[
                            rs.token,
                        ]
                    }).then((rsuuid) => {
                        if(rsuuid.data.status == 1){
                            var varas = rs.result.replace(/[\n\r]/g,'')
                            const regex = /([\d.]{1,10})/gm;
                            var varasa = regex.exec(varas.toString())
                            this.vara = varasa[1]
                            this.$swal.close();
                            if(this.vara != ''){
                                this.soundinfo.data1 = this.vara
                                this.$swal({text:'รับค่าระดับเสียงครั้งที่ 1',
                                    title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                })
                            }
                        }else{
                            this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                icon:'error',
                            })
                        }
                    }).catch(err=>{
                        this.$swal({title:'เกิดข้อผิดพลาดในการรับค่า',
                        icon:'error',
                        })
                    })
                });
            });
        }

        }
    },
    datasound2(){
    this.$swal({
        title: 'ระบบกำลังทำงาน',
        html: 'กำลังรับข้อมูล ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    this.$swal.showLoading()

    if(this.settings.server.muller == 1){
        if(this.varss  == null || this.varss.length <= 10){
            fetch('http://127.0.0.1:8080/a', {
                method: "GET"
            })
            .then((response) => {
                response.json().then((rs) => {
                    axios.post(`checktoken`,{
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 1,
                        datas:[
                            rs.token,
                        ]
                    }).then((rsuuid) => {
                        if(rsuuid.data.status == 1){
                            var varas = rs.result.replace(/[\n\r]/g,'')
                            const regex = /([\d.]{1,10})/gm;
                            var varasa = regex.exec(varas.toString())
                            this.vara = varasa[1]
                            this.$swal.close();
                            if(this.vara != ''){
                                this.soundinfo.data2 = this.vara
                                this.$swal({
                                    text: 'รับค่าระดับเสียงครั้งที่ 2',
                                    title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                    }).then((result) => {
                                    this.soundinfo.datamax = Math.max(this.soundinfo.data1,this.soundinfo.data2)
                                    if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                                        var datetosound = Date.parse("1/1/2014");
                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                        if(datetosound > dateselect){
                                            if(this.soundinfo.datamax <= 100){
                                                if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.soundinfo.datamax = ''
                                                    this.soundinfo.data1 = ''
                                                    this.soundinfo.data2 = ''
                                                    this.$swal({
                                                    title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                    icon: 'error',
                                                    })
                                                }
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 0
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else{
                                            if(this.car_weight > 2200){
                                                if(this.soundinfo.datamax <= 99){
                                                    if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                        this.itemcheck.varssumcheck[4] = 1
                                                        this.$swal({
                                                        title:'ผลการทดสอบผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck.varssumcheck[4] = 1
                                                        this.soundinfo.datamax = ''
                                                        this.soundinfo.data1 = ''
                                                        this.soundinfo.data2 = ''
                                                        this.$swal({
                                                        title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                        icon: 'error',
                                                        })
                                                    }
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 0
                                                    this.$swal(
                                                    'ผลการทดสอบไม่ผ่าน',
                                                    'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else{
                                                if(this.soundinfo.datamax <= 95){
                                                    if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                        this.itemcheck.varssumcheck[4] = 1
                                                        this.$swal({
                                                        title:'ผลการทดสอบผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck.varssumcheck[4] = 1
                                                        this.soundinfo.datamax = ''
                                                        this.soundinfo.data1 = ''
                                                        this.soundinfo.data2 = ''
                                                        this.$swal({
                                                        title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                        icon: 'error',
                                                        })
                                                    }
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 0
                                                    this.$swal(
                                                    'ผลการทดสอบไม่ผ่าน',
                                                    'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }
                                        }
                                    }else if(this.typecar == 12){
                                        var datetosound = Date.parse("1/1/2024");
                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                        if(datetosound > dateselect){
                                            if(this.soundinfo.datamax <= 95){
                                                if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.soundinfo.datamax = ''
                                                    this.soundinfo.data1 = ''
                                                    this.soundinfo.data2 = ''
                                                    this.$swal({
                                                    title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                    icon: 'error',
                                                    })
                                                }
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 0
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else{
                                            if(this.soundinfo.datamax <= 90){
                                                if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.soundinfo.datamax = ''
                                                    this.soundinfo.data1 = ''
                                                    this.soundinfo.data2 = ''
                                                    this.$swal({
                                                    title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                    icon: 'error',
                                                    })
                                                }
                                            }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                ) 
                                            }
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                })
                            }
                        }else{
                            this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                icon:'error',
                            })
                        }
                    }).catch(err=>{
                        this.$swal({title:'เกิดข้อผิดพลาดในการรับค่า',
                        icon:'error',
                        })
                    })
            });
        });

        }else{
            this.$swal.close();
            if(this.vara != ''){
                this.soundinfo.data2 = this.vara
                this.$swal({
                    text: 'รับค่าระดับเสียงครั้งที่ 2',
                    title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                    icon: 'info',
                    button: 'สรุปผลการทดสอบ',
                    }).then((result) => {
                        this.soundinfo.datamax = Math.max(this.soundinfo.data1,this.soundinfo.data2)
                        if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                            var datetosound = Date.parse("1/1/2014");
                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                            if(datetosound > dateselect){
                                if(this.soundinfo.datamax <= 100){
                                    if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                        this.itemcheck.varssumcheck[4] = 1
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 1
                                        this.soundinfo.datamax = ''
                                        this.soundinfo.data1 = ''
                                        this.soundinfo.data2 = ''
                                        this.$swal({
                                        title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                        icon: 'error',
                                        })
                                    }
                                }else{
                                    this.itemcheck.varssumcheck[4] = 0
                                    this.$swal(
                                    'ผลการทดสอบไม่ผ่าน',
                                    'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                    'error'
                                    ) 
                                }

                            }else{
                                if(this.car_weight > 2200){
                                    if(this.soundinfo.datamax <= 99){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        ) 
                                    }
                                }else{
                                    if(this.soundinfo.datamax <= 95){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        ) 
                                    }
                                }
                            }
                        }else if(this.typecar == 12){
                            var datetosound = Date.parse("1/1/2024");
                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                            if(datetosound > dateselect){
                                if(this.soundinfo.datamax <= 95){
                                    if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                        this.itemcheck.varssumcheck[4] = 1
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 1
                                        this.soundinfo.datamax = ''
                                        this.soundinfo.data1 = ''
                                        this.soundinfo.data2 = ''
                                        this.$swal({
                                        title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                        icon: 'error',
                                        })
                                    }
                                }else{
                                    this.itemcheck.varssumcheck[4] = 0
                                    this.$swal(
                                    'ผลการทดสอบไม่ผ่าน',
                                    'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                    'error'
                                    )
                                }
                            }else{
                                if(this.soundinfo.datamax <= 90){
                                    if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                        this.itemcheck.varssumcheck[4] = 1
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 1
                                        this.soundinfo.datamax = ''
                                        this.soundinfo.data1 = ''
                                        this.soundinfo.data2 = ''
                                        this.$swal({
                                        title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                        icon: 'error',
                                        })
                                    }
                                }else{
                                    this.itemcheck.varssumcheck[4] = 0
                                    this.$swal(
                                    'ผลการทดสอบไม่ผ่าน',
                                    'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                    'error'
                                    )
                                }
                            }

                        }else{
                            this.itemcheck.varssumcheck[4] = 0
                            this.$swal(
                            'ผลการทดสอบไม่ผ่าน',
                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                            'error'
                            )
                        }
                })
            }else{
                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                    icon:'warning',
                })
            }
        }
    }else{
        fetch('http://127.0.0.1:8080/a', {
            method: "GET"
        })
        .then((response) => {
            response.json().then((rs) => {
                    axios.post(`checktoken`,{
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 1,
                        datas:[
                            rs.token,
                        ]
                    }).then((rsuuid) => {
                        if(rsuuid.data.status == 1){

                        var varas = rs.result.replace(/[\n\r]/g,'')
                        const regex = /([\d.]{1,10})/gm;
                        var varasa = regex.exec(varas.toString())
                        this.vara = varasa[1]
                        this.$swal.close();
                        if(this.vara != ''){
                            this.soundinfo.data2 = this.vara
                            this.$swal({
                                text: 'รับค่าระดับเสียงครั้งที่ 2',
                                title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                this.soundinfo.datamax = Math.max(this.soundinfo.data1,this.soundinfo.data2)
                                if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                                    var datetosound = Date.parse("1/1/2014");
                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                    if(datetosound > dateselect){
                                        if(this.soundinfo.datamax <= 100){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else{
                                        if(this.car_weight > 2200){
                                            if(this.soundinfo.datamax <= 99){
                                                if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.soundinfo.datamax = ''
                                                    this.soundinfo.data1 = ''
                                                    this.soundinfo.data2 = ''
                                                    this.$swal({
                                                    title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                    icon: 'error',
                                                    })
                                                }
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 0
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else{
                                            if(this.soundinfo.datamax <= 95){
                                                if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck.varssumcheck[4] = 1
                                                    this.soundinfo.datamax = ''
                                                    this.soundinfo.data1 = ''
                                                    this.soundinfo.data2 = ''
                                                    this.$swal({
                                                    title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                    icon: 'error',
                                                    })
                                                }
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 0
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }
                                    }
                                }else if(this.typecar == 12){
                                    var datetosound = Date.parse("1/1/2024");
                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                    if(datetosound > dateselect){
                                        if(this.soundinfo.datamax <= 95){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else{
                                        if(this.soundinfo.datamax <= 90){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            ) 
                                        }
                                    }
                                }else{
                                    this.itemcheck.varssumcheck[4] = 0
                                    this.$swal(
                                    'ผลการทดสอบไม่ผ่าน',
                                    'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                    'error'
                                    )
                                }
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            })
                        }
                    }else{
                            this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                icon:'error',
                            })
                        }
                    }).catch(err=>{
                        this.$swal({title:'เกิดข้อผิดพลาดในการรับค่า',
                        icon:'error',
                        })
                    })
            });
        });

    }
    },
    datacohc1(){
        if(!this.typecar){
              this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                icon:'warning',
                })
        }else if(!this.fueldesc_select){
              this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
                icon:'warning',
                })
        }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
              this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                icon:'warning',
                })
          }else{
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังรับข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.data1.co = this.vareco
                                this.cohcinfo.data1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.data1.co = this.vareco
                                this.cohcinfo.data1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            console.log('arrs--->',arrs);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.data1.co = this.vareco
                                this.cohcinfo.data1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.data1.co = this.vareco
                                this.cohcinfo.data1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }
        }
    },
    datacohc2(){
    this.$swal({
        title: 'ระบบกำลังทำงาน',
        html: 'กำลังรับข้อมูล ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    this.$swal.showLoading()
    if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                if(this.varss  == null || this.varss.length <= 10){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.data2.co = this.vareco
                                this.cohcinfo.data2.hc = this.varehc
                                this.$swal({
                                    text: 'รับค่า CO,HC ครั้งที่ 2',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                    }).then((result) => {
                                        if(this.cohcinfo.data1.co == '0.00%'){
                                            this.cohcinfo.datamax.co= 0
                                        }else{
                                            this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                                        }
                                        this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                                        if(this.typecar == 12){
                                            var dateto11 = Date.parse("7/1/2006");
                                            var dateto12 = Date.parse("12/31/2009");
                                            var dateto2 = Date.parse("1/1/2010");

                                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                            if(dateto11 > dateselect){
                                                if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                                    this.itemcheck_cohc.varssumcheck[4] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[4] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                                if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                                    this.itemcheck_cohc.varssumcheck[4] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[4] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto2 <= dateselect){
                                                if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                                    this.itemcheck_cohc.varssumcheck[4] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[4] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }
                                        }

                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });

                }else{
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.data2.co = this.vareco
                        this.cohcinfo.data2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.data1.co == '0.00%'){
                                this.cohcinfo.datamax.co= 0
                            }else{
                                this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co) + parseFloat(this.cohcinfo.data2.co))/2).toFixed(2);
                            }
                            this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc) + parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2);
                            if(this.typecar == 12){
                                var dateto11 = Date.parse("7/1/2006");
                                var dateto12 = Date.parse("12/31/2009");
                                var dateto2 = Date.parse("1/1/2010");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if(dateto11 > dateselect){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto2 <= dateselect){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }
                            }

                        });
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        }) 
                    }
                }

            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.data2.co = this.vareco
                        this.cohcinfo.data2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.data1.co == '0.00%'){
                                this.cohcinfo.datamax.co= 0
                            }else{
                                this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                            if(this.typecar == 12){
                                var dateto11 = Date.parse("7/1/2006");
                                var dateto12 = Date.parse("12/31/2009");
                                var dateto2 = Date.parse("1/1/2010");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if(dateto11 > dateselect){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto2 <= dateselect){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }
                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.data2.co = this.vareco
                        this.cohcinfo.data2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.data1.co == '0.00%'){
                                this.cohcinfo.datamax.co= 0
                            }else{
                                this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                            if(this.typecar == 12){
                                var dateto11 = Date.parse("7/1/2006");
                                var dateto12 = Date.parse("12/31/2009");
                                var dateto2 = Date.parse("1/1/2010");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if(dateto11 > dateselect){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto2 <= dateselect){
                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }
                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.data2.co = this.vareco
                        this.cohcinfo.data2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.data1.co == '0.00%'){
                                    this.cohcinfo.datamax.co= 0
                                }else{
                                    this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                                if(this.typecar == 12){
                                    var dateto11 = Date.parse("7/1/2006");
                                    var dateto12 = Date.parse("12/31/2009");
                                    var dateto2 = Date.parse("1/1/2010");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if(dateto11 > dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto2 <= dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }
                                }

                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }

    },
    datacohc_lo1_1(){
    if(!this.typecar){
            this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
            icon:'warning',
            })
    }else if(!this.fueldesc_select){
            this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
            icon:'warning',
            })
    }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
            this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
            icon:'warning',
            })
        }else{
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_1.co = this.vareco
                            this.cohcinfo.datalo1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_1.co = this.vareco
                            this.cohcinfo.datalo1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        console.log('arrs--->',arrs);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_1.co = this.vareco
                            this.cohcinfo.datalo1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_1.co = this.vareco
                            this.cohcinfo.datalo1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }
    }
    },
    datacohc_lo2_1(){
    if(!this.typecar){
            this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
            icon:'warning',
            })
    }else if(!this.fueldesc_select){
            this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
            icon:'warning',
            })
    }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
            this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
            icon:'warning',
            })
        }else{
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_1.co = this.vareco
                            this.cohcinfo.datalo2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_1.co = this.vareco
                            this.cohcinfo.datalo2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        console.log('arrs--->',arrs);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_1.co = this.vareco
                            this.cohcinfo.datalo2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_1.co = this.vareco
                            this.cohcinfo.datalo2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }
        }
    }
    },
    datacohc_hi1_1(){
    if(!this.typecar){
            this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
            icon:'warning',
            })
    }else if(!this.fueldesc_select){
            this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
            icon:'warning',
            })
    }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
            this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
            icon:'warning',
            })
        }else{
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_1.co = this.vareco
                            this.cohcinfo.datahi1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_1.co = this.vareco
                            this.cohcinfo.datahi1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        console.log('arrs--->',arrs);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_1.co = this.vareco
                            this.cohcinfo.datahi1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_1.co = this.vareco
                            this.cohcinfo.datahi1_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }
    }
    },
    datacohc_hi2_1(){
    if(!this.typecar){
            this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
            icon:'warning',
            })
    }else if(!this.fueldesc_select){
            this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
            icon:'warning',
            })
    }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
            this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
            icon:'warning',
            })
        }else{
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_1.co = this.vareco
                            this.cohcinfo.datahi2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_1.co = this.vareco
                            this.cohcinfo.datahi2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        console.log('arrs--->',arrs);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_1.co = this.vareco
                            this.cohcinfo.datahi2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_1.co = this.vareco
                            this.cohcinfo.datahi2_1.hc = this.varehc
                            this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                icon:'info',
                                })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    })
                });
            }
        }
    }
    },
    datacohc_lo1_2(){
    this.$swal({
        title: 'ระบบกำลังทำงาน',
        html: 'กำลังรับข้อมูล ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    this.$swal.showLoading()
    if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                    if(this.varss  == null || this.varss.length <= 10){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo1_2.co = this.vareco
                                this.cohcinfo.datalo1_2.hc = this.varehc
                                this.$swal({
                                    text: 'รับค่า CO,HC ครั้งที่ 2',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                    }).then((result) => {
                                        if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                            this.cohcinfo.datamaxlo_1.co= 0
                                        }else{
                                            this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                        }
                                        this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)

                                        if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                            var dateto11 = Date.parse("11/1/1993");
                                            var dateto12 = Date.parse("12/31/2006");
                                            var dateto21 = Date.parse("1/1/2007");
                                            var dateto22 = Date.parse("12/31/2013");
                                            var dateto3 = Date.parse("1/1/2014");

                                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                            if((dateto11 > dateselect) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[0] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[0] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[0] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[0] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                                    this.itemcheck_cohc.varssumcheck[0] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[0] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[0] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[0] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto3 <= dateselect){
                                                if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[0] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[0] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }

                                        }

                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });

                }else{
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo1_2.co = this.vareco
                        this.cohcinfo.datalo1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                this.cohcinfo.datamaxlo_1.co= 0
                            }else{
                                this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co) + parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2);
                            }
                            this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc) + parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2);

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        });
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        }) 
                    }
                }

            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo1_2.co = this.vareco
                        this.cohcinfo.datalo1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                this.cohcinfo.datamaxlo_1.co= 0
                            }else{
                                this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_2.co = this.vareco
                            this.cohcinfo.datalo1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo1_2.co = this.vareco
                        this.cohcinfo.datalo1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)
                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }

    },
    datacohc_lo2_2(){
    this.$swal({
        title: 'ระบบกำลังทำงาน',
        html: 'กำลังรับข้อมูล ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    this.$swal.showLoading()
    if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                if(this.varss  == null || this.varss.length <= 10){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                    response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo2_2.co = this.vareco
                                this.cohcinfo.datalo2_2.hc = this.varehc
                                this.$swal({
                                    text: 'รับค่า CO,HC ครั้งที่ 2',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                    }).then((result) => {
                                        if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                            this.cohcinfo.datamaxlo_2.co= 0
                                        }else{
                                            this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                                        }
                                        this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)

                                        if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                            var dateto11 = Date.parse("11/1/1993");
                                            var dateto12 = Date.parse("12/31/2006");
                                            var dateto21 = Date.parse("1/1/2007");
                                            var dateto22 = Date.parse("12/31/2013");
                                            var dateto3 = Date.parse("1/1/2014");

                                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                            if((dateto11 > dateselect) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[1] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[1] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[1] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[1] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                                    this.itemcheck_cohc.varssumcheck[1] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[1] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[1] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[1] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto3 <= dateselect){
                                                if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[1] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[1] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }

                                        }

                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });

                }else{
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo2_2.co = this.vareco
                        this.cohcinfo.datalo2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                this.cohcinfo.datamaxlo_2.co= 0
                            }else{
                                this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co) + parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2);
                            }
                            this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc) + parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2);

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        });
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        }) 
                    }
                }

            }else{
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo2_2.co = this.vareco
                        this.cohcinfo.datalo2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                this.cohcinfo.datamaxlo_2.co= 0
                            }else{
                                this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                    response.json().then((rs) => {  
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo2_2.co = this.vareco
                        this.cohcinfo.datalo2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                this.cohcinfo.datamaxlo_2.co= 0
                            }else{
                                this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datalo2_2.co = this.vareco
                        this.cohcinfo.datalo2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)
                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }

    },
    datacohc_hi1_2(){
    this.$swal({
        title: 'ระบบกำลังทำงาน',
        html: 'กำลังรับข้อมูล ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    this.$swal.showLoading()
    if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                if(this.varss  == null || this.varss.length <= 10){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi1_2.co = this.vareco
                                this.cohcinfo.datahi1_2.hc = this.varehc
                                this.$swal({
                                    text: 'รับค่า CO,HC ครั้งที่ 2',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                    }).then((result) => {
                                        if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                            this.cohcinfo.datamaxhi_1.co= 0
                                        }else{
                                            this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                                        }
                                        this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)

                                        if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                            var dateto11 = Date.parse("11/1/1993");
                                            var dateto12 = Date.parse("12/31/2006");
                                            var dateto21 = Date.parse("1/1/2007");
                                            var dateto22 = Date.parse("12/31/2013");
                                            var dateto3 = Date.parse("1/1/2014");

                                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                            if((dateto11 > dateselect) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[2] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[2] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[2] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[2] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                                    this.itemcheck_cohc.varssumcheck[2] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[2] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[2] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[2] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto3 <= dateselect){
                                                if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[2] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[2] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }

                                        }

                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });

                }else{
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi1_2.co = this.vareco
                        this.cohcinfo.datahi1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                this.cohcinfo.datamaxhi_1.co= 0
                            }else{
                                this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co) + parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2);
                            }
                            this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc) + parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2);

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        });
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        }) 
                    }
                }

            }else{
                fetch('http://127.0.0.1:8080/e', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi1_2.co = this.vareco
                        this.cohcinfo.datahi1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                this.cohcinfo.datamaxhi_1.co= 0
                            }else{
                                this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi1_2.co = this.vareco
                        this.cohcinfo.datahi1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                this.cohcinfo.datamaxhi_1.co= 0
                            }else{
                                this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi1_2.co = this.vareco
                        this.cohcinfo.datahi1_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)
                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }

    },
    datacohc_hi2_2(){
    this.$swal({
        title: 'ระบบกำลังทำงาน',
        html: 'กำลังรับข้อมูล ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    this.$swal.showLoading()
    if(this.settings.server.muller == 1){
            if(this.settings.server.type == "udp"){
                if(this.varss  == null || this.varss.length <= 10){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi2_2.co = this.vareco
                                this.cohcinfo.datahi2_2.hc = this.varehc
                                this.$swal({
                                    text: 'รับค่า CO,HC ครั้งที่ 2',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                    }).then((result) => {
                                        if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                            this.cohcinfo.datamaxhi_2.co= 0
                                        }else{
                                            this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                                        }
                                        this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)

                                        if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                            var dateto11 = Date.parse("11/1/1993");
                                            var dateto12 = Date.parse("12/31/2006");
                                            var dateto21 = Date.parse("1/1/2007");
                                            var dateto22 = Date.parse("12/31/2013");
                                            var dateto3 = Date.parse("1/1/2014");

                                            var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                            if((dateto11 > dateselect) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[3] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[3] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[3] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[3] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                                    this.itemcheck_cohc.varssumcheck[3] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[3] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[3] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[3] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }else if(dateto3 <= dateselect){
                                                if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                                    this.itemcheck_cohc.varssumcheck[3] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[3] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                            }

                                        }

                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });

                }else{
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi2_2.co = this.vareco
                        this.cohcinfo.datahi2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                this.cohcinfo.datamaxhi_2.co= 0
                            }else{
                                this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co) + parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2);
                            }
                            this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc) + parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2);

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        });
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        }) 
                    }
                }

            }else{
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi2_2.co = this.vareco
                        this.cohcinfo.datahi2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                this.cohcinfo.datamaxhi_2.co= 0
                            }else{
                                this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }else{
            if(this.settings.server.type == "udp"){
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi2_2.co = this.vareco
                        this.cohcinfo.datahi2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                this.cohcinfo.datamaxhi_2.co= 0
                            }else{
                                this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                            }
                            this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)

                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                var dateto11 = Date.parse("11/1/1993");
                                var dateto12 = Date.parse("12/31/2006");
                                var dateto21 = Date.parse("1/1/2007");
                                var dateto22 = Date.parse("12/31/2013");
                                var dateto3 = Date.parse("1/1/2014");

                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                if((dateto11 > dateselect) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else if(dateto3 <= dateselect){
                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                        this.setchk5cohc()
                                        this.$swal({
                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                        icon: 'success',
                                        })
                                    }else{
                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                        this.setchk5cohc()
                                        this.$swal(
                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }

                        })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }else{
                fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                    let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                    this.vareco = arrs[1].replace(/,/g, '');
                    this.varehc = arrs[2].replace(/,/g, '');
                    this.$swal.close();
                    if(this.vareco != '' && this.varehc != ''){
                        this.cohcinfo.datahi2_2.co = this.vareco
                        this.cohcinfo.datahi2_2.hc = this.varehc
                        this.$swal({
                            text: 'รับค่า CO,HC ครั้งที่ 2',
                            title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)
                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    });
                });
            }
        }

    },
    checkmdminsert(){
        if(this.checkmdm == 1){   
            this.$swal({
                title: "คำเตือน",
                text:'ท่านไม่กดตรวจสอบข้อมูลหรือระบบไม่สามาถตรวจสอบได้ ขอให้ท่านตรวจสอบข้อมูลยี่ห้อรถ เลขตัวรถ ยี่ห้อเครื่องยนต์ และเลขเครื่องยนต์ให้ถูกต้องตามเอกสารเล่มทะเบียน หากส่งข้อมูลไม่ถูกต้องจะมีผลต่อการชำระภาษีของเจ้าของรถ',
                showDenyButton: true,
                showCancelButton: true,
                showCancelButton: false,
                confirmButtonText: "ยืนยันส่งข้อมูล",
                denyButtonText: `ยกเลิกส่งข้อมูล`
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.insertdata();
            } else if (result.isDenied) {
                this.$swal("ยกเลิกการส่งข้อมูล", "", "info");
            }
            });
        }else if(this.checkmdm == 2){
            this.$swal({
                title:'ไม่สามารถส่งข้อมูลได้ เนื่องจากพบความผิดปกติของข้อมูลรถ ขอให้เจ้าของรถติดต่อสอบถามที่สำนักงานขนส่ง !',
                icon:'error',
            })
        }else if(this.checkmdm == 3){
            this.insertdata();
        }
    },
    insertdata(){
    if(this.typecar != 12 && (!this.varssum1 || !this.vars.axle1l)){
        this.$swal({
            title:'ค่าของระบบห้ามล้อยังไม่ครบถ้วน (ข้อ 1) !',
            icon:'warning',
        })
    }else if(!this.car_lic_id_char || !this.car_lic_id_num){
        this.$swal({title:'กรุณากรอกเลขทะเบียน !',
            icon:'warning',
            })
    }else if(!this.car_lic_prov.id){
        this.$swal({title:'กรุณาเลือกจังหวัด !',
            icon:'warning',
            })
    }else if(!this.chaisse_no){
        this.$swal({title:'กรุณากรอกเลขตัวถังรถ !',
            icon:'warning',
            })
    }else if(!this.typecar){
        this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
            icon:'warning',
            })
    }else if(!this.car_band){
        this.$swal({title:'กรุณาเลือกยี่ห้อรถ !',
            icon:'warning',
            })
    }else if(!this.ry_id){
        this.$swal({title:'กรุณาเลือกประเภทเครื่องยนต์ !',
            icon:'warning',
            })
    }else if(!this.engine_no || this.engine_no.length < 3){
        this.$swal({title:'กรุณากรอกหมายเครื่องยนต์ให้ถูกต้อง !',
            icon:'warning',
            })
    }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
        this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
            icon:'warning',
            })
    }else if(this.typecar != 12 && (!this.userinfo1.license_number || !this.userinfo2.license_number)){
        this.$swal({title:'กรุณาล็อคอินผู้ควบคุมตรวจสภาพและเจ้าหน้าที่ตรวจสภาพ !',
            icon:'warning',
            })
    }else if(this.typecar != 12 && (this.userinfo1.license_number == this.userinfo2.license_number)){
        this.$swal({title:'ผู้ควบคุมตรวจสภาพและเจ้าหน้าที่ตรวจสภาพต้องไม่ใช่คนเดียวกัน !',
            icon:'warning',
            })
    }else if(this.typecar == 12 && !this.userinfo1.license_number){
        this.$swal({title:'กรุณาล็อคอินผู้ควบคุมตรวจสภาพ !',
            icon:'warning',
            })
    }else if(this.typecar != 12 && !this.vars.wheelAlignment){
        this.$swal({title:'กรุณากรอกค่าศูนย์ล้อ (ข้อ 2) !',
            icon:'warning',
            })
    }else if(!this.soundinfo.datamax && this.fueldesc_select != 3){
        this.$swal({title:'กรุณากดรับค่าระดับเสียง (ข้อ 3) !',
            icon:'warning',
            })
    }else if(!this.varesmoke && (this.fueldesc_select == 2 || this.fueldesc_select == 5 || this.fueldesc_select == 9 || this.fueldesc_select == 10)){
        this.$swal({title:'กรุณากรอกค่าควันดำ (ข้อ 4) !',
            icon:'warning',
            })
    }else if((!this.cohcinfo.datamax.co && !this.cohcinfo.datamaxlo_1.co && !this.cohcinfo.datamaxlo_2.co) && (this.fueldesc_select == 1 || this.fueldesc_select == 4 || this.fueldesc_select == 6 || this.fueldesc_select == 7 || this.fueldesc_select == 8)){
        this.$swal({title:'กรุณากดรับค่า CO, HC ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (ข้อ 4)  !',
            icon:'warning',
            })
    }else if((!this.cohcinfo.datamaxhi_1.co && !this.cohcinfo.datamaxhi_2.co) && this.typecar != 12  && Date.parse(this.car_reg_date_2+'/'+this.car_reg_date_1+'/'+(this.car_reg_date_3 - 543)) >= Date.parse('1/1/2014') && (this.fueldesc_select == 1 || this.fueldesc_select == 4 || this.fueldesc_select == 6 || this.fueldesc_select == 7 || this.fueldesc_select == 8)){
        this.$swal({title:'กรุณากดรับค่า CO, HC ขณะเครื่องยนต์เดินเบาที่รอบสูง (ข้อ 4)  !',
            icon:'warning',
            })
    }else if(this.typecar != 12 && (!this.input81 || !this.input82 || !this.input83 || !this.input84 || !this.input85 || !this.input86 || !this.input87 || !this.input88)){
        this.$swal({title:'กรุณากรอกค่าโคมไฟให้ครบถ้วน (ข้อ 7)  !',
            icon:'warning',
            })
    }else if(this.typecar == 12 && (!this.input81 || !this.input83 || !this.input85 || !this.input87)){
        this.$swal({title:'กรุณากรอกค่าโคมไฟให้ครบถ้วน (ข้อ 7) !',
            icon:'warning',
            })
    }else{
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()

        fetch('http://127.0.0.1:8080/uuid', {
            method: "GET"
        })
        .then((response) => {
            response.json().then((rs) => {
                let datas = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 3,
                        datas:[
                            new Date(),
                            this.st_read.check_hw,
                            rs.uuid,
                            this.wh_read,
                            this.st_read,
                            {
                                TESTBRAKE_FORCE_AXLE1L:this.vars.axle1l,
                                TESTBRAKE_FORCE_AXLE1R:this.vars.axle1r,
                                TESTBRAKE_FORCE_AXLE2L:this.vars.axle2l,
                                TESTBRAKE_FORCE_AXLE2R:this.vars.axle2r,
                                TESTBRAKE_FORCE_AXLE3L:this.vars.axle3l,
                                TESTBRAKE_FORCE_AXLE3R:this.vars.axle3r,
                                TESTBRAKE_FORCE_AXLE4L:this.vars.axle4l,
                                TESTBRAKE_FORCE_AXLE4R:this.vars.axle4r,
                                TESTBRAKE_WEIGHT_AXLE1:this.vars.axle1Weight,
                                TESTBRAKE_WEIGHT_AXLE2:this.vars.axle2Weight,
                                TESTBRAKE_WEIGHT_AXLE3:this.vars.axle3Weight,
                                TESTBRAKE_WEIGHT_AXLE4:this.vars.axle4Weight,
                                TESTBRAKE_HAND_VAULE_L:this.vars.handbrakeForce2L,
                                TESTBRAKE_HAND_VAULE_R:this.vars.handbrakeForce2R,
                            },
                            {
                                car_lic_id_char:this.car_lic_id_char,
                                car_lic_id_num:this.car_lic_id_num,
                                car_lic_prov:this.car_lic_prov.id
                            }
                        ]
                    }
                axios.post(`adddatacar`,datas).then((rs2) => {
                    if(rs2.data.status == 4){
                        this.$swal({
                            title: 'การล็อคอินของท่านหมดอายุการใช้งาน !',
                            text: "กรุณาออกจากระบบแล้วล็อคอินเข้าสู่ระบบใหม่อีกครั้ง",
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#664284',
                            confirmButtonText: 'ตกลง'
                        }).then((result) => {
                            localStorage.clear();
                            window.location.href = '/auth/login';
                        }) 
                    }else if(rs2.data.status == 1){
                        if(rs2.data.datas.statusdatetime == 2){
                            this.$swal({
                                title:'วันที่หรือเวลาของเครื่องไม่ตรงกับปัจจุบัน',
                                text:'กรุณาตั้งวันที่หรือเวลาที่เครื่องใหม่ และส่งข้อมูลอีกครั้ง',
                                icon:'error',
                            })
                        }else if(rs2.data.datas.statushd == 2){
                            this.$swal({
                                title:'ฮาร์ดแวร์ของเครื่องที่ส่งข้อมูลไม่ตรงตามที่ลงทะเบียนไว้',
                                icon:'error',
                            })
                        }else if(rs2.data.datas.statusRP == 2){
                            this.$swal({
                                title:'ผลเบรคมีค่าซ้ำกับรายการตรวจอื่น',
                                text:`ผลเบรคมีค่าซ้ำกับรายการตรวจที่ ${rs2.data.datas.RPdata.ID} ที่ส่งผลเข้าระบบเมื่อ ${new Date(rs2.data.datas.RPdata.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" )}`,
                                icon:'error',
                            })
                        }else{
                            this.tor_use_program = rs2.data.datas.use_program;  
                            this.tor_status = rs2.data.datas.status; 
                            this.tor_exp = rs2.data.datas.exp;
                            if(this.tor_use_program != 2 || this.tor_status != 1){
                                this.$swal({
                                    title: 'ตรอ ของท่านไม่สามารถทำรายงานการตรวจสภาพรถได้ !',
                                    text: "กรุณาติดต่อเจ้าหน้าที่เพื่อเปลี่ยนสถานะการทำงานของท่าน",
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#664284',
                                    confirmButtonText: 'ตกลง'
                                    }).then((result) => {
                                        localStorage.clear();
                                        window.location.href = '/auth/login';
                                    })
                            }else if(this.tor_exp != 1){
                                this.$swal({
                                    title: 'ตรอ ของท่านไม่สามารถทำรายงานการตรวจสภาพรถได้ !',
                                    text: "เนื่องจากใบอนุญาตสถานตรวจสภาพของท่านสิ้นอายุแล้ว",
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#664284',
                                    confirmButtonText: 'ตกลง'
                                    }).then((result) => {
                                        localStorage.clear();
                                        window.location.href = '/auth/login';
                                    })
                            }else if(this.b64img){
                                    if(rs2.data.datas.datafalse_status != 2){ 
                                        this.$swal({
                                                title: "ต้องการส่งผลการตรวจหรือไม่ ?",
                                                text: "เนื่องจากรถคันนี้มีรายการตรวจที่มีผลไม่ผ่านในวันนี้ จาก ตรอ. "+rs2.data.datas.datafalse.TRO_NAME+" เวลา "+new Date(rs2.data.datas.datafalse.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),
                                                icon: "warning",
                                                showDenyButton: true,
                                                showCancelButton: true,
                                                showCancelButton: false,
                                                confirmButtonText: "ยืนยันการส่งข้อมูล",
                                                denyButtonText: `ยกเลิกการส่ง`
                                                })
                                                .then((willfalse) => {
                                                    if (willfalse.isConfirmed) {
                                                        if(this.allchk == 1){
                                                            this.$swal({
                                                                title: 'ระบบกำลังทำงาน',
                                                                html: 'กำลังส่งข้อมูล ...',
                                                                allowEscapeKey: false,
                                                                allowOutsideClick: false,
                                                            });
                                                            this.$swal.showLoading()
                                                            let datas = {
                                                                    uid: storeAuth.id,
                                                                    gid: storeAuth.group_id,
                                                                    tid: 4,
                                                                    datas:{
                                                                        b64img:this.b64img,
                                                                        CHECK_DATETIME:this.checkdate+' '+this.checktime,
                                                                        SEND_DATETIME:this.timeinsert,
                                                                        TRO_ID:this.tro_id,
                                                                        TRO_NAME:this.tro_name,
                                                                        TRO_OWNER_NAME:this.tro_owner_name,
                                                                        CAR_LIC_ID_CHAR:this.car_lic_id_char,
                                                                        CAR_LIC_ID_NUM:this.car_lic_id_num,
                                                                        CAR_LIC_PROV:this.car_lic_prov.id,
                                                                        CAR_REG_DATE:this.car_reg_date_1+' '+this.car_reg_date_2+' '+this.car_reg_date_3,
                                                                        CAR_REG_DATE_1:this.car_reg_date_1,
                                                                        CAR_REG_DATE_2:this.car_reg_date_2,
                                                                        CAR_REG_DATE_3:this.car_reg_date_3,
                                                                        RY:null,
                                                                        RY_ID:this.ry_id,
                                                                        RY_BOOK_ID:null,
                                                                        RY_NUMBER_ID:this.car_band.band,
                                                                        CAR_TYPE:this.typecar,
                                                                        CHAISSE_NO:this.chaisse_no,
                                                                        ENGINE_NO:this.engine_no,
                                                                        AXLE:null,
                                                                        WHEEL:null,
                                                                        CURRENT_DISTANCE:this.current_distance,
                                                                        TYRE:this.type,
                                                                        FUELDESC_SELECT:this.fueldesc_select,
                                                                        TESTBRAKE_FORCE_AXLE1L:this.vars.axle1l,
                                                                        TESTBRAKE_FORCE_AXLE1R:this.vars.axle1r,
                                                                        TESTBRAKE_FORCE_AXLE2L:this.vars.axle2l,
                                                                        TESTBRAKE_FORCE_AXLE2R:this.vars.axle2r,
                                                                        TESTBRAKE_FORCE_AXLE3L:this.vars.axle3l,
                                                                        TESTBRAKE_FORCE_AXLE3R:this.vars.axle3r,
                                                                        TESTBRAKE_FORCE_AXLE4L:this.vars.axle4l,
                                                                        TESTBRAKE_FORCE_AXLE4R:this.vars.axle4r,
                                                                        TESTBRAKE_WEIGHT_AXLE1:this.vars.axle1Weight,
                                                                        TESTBRAKE_WEIGHT_AXLE2:this.vars.axle2Weight,
                                                                        TESTBRAKE_WEIGHT_AXLE3:this.vars.axle3Weight,
                                                                        TESTBRAKE_WEIGHT_AXLE4:this.vars.axle4Weight,
                                                                        TESTBRAKE_DIF_AXLE1:this.varssum1,
                                                                        TESTBRAKE_DIF_AXLE2:this.varssum2,
                                                                        TESTBRAKE_DIF_AXLE3:this.varssum3,
                                                                        TESTBRAKE_DIF_AXLE4:this.varssum4,
                                                                        TESTBRAKE_FOOT_EFFICIENCY:this.varssum5,
                                                                        TESTBRAKE_HAND_VAULE_L:this.vars.handbrakeForce2L,
                                                                        TESTBRAKE_HAND_VAULE_R:this.vars.handbrakeForce2R,
                                                                        TESTBRAKE_HAND_EFFICIENCY:this.varssum6,
                                                                        TESTBRAKE_WHEEL_ALIGHTMENT:this.vars.wheelAlignment,
                                                                        RESULT_DIFF_BRAKE:this.itemcheck.varssumcheck[0],
                                                                        RESULT_FOOT_BRAKE:this.itemcheck.varssumcheck[1],
                                                                        RESULT_HAND_BRAKE:this.itemcheck.varssumcheck[2],
                                                                        RESULT_WHEEL_ALIGHTMENT:this.itemcheck.varssumcheck[3],
                                                                        CHECK_VOICE:this.itemcheck.varssumcheck[4],
                                                                        CHECK_PIPE:this.itemcheck.varssumcheck[5],
                                                                        CHECK_HOOTER:this.itemcheck.varssumcheck[6],
                                                                        CHECK_SPEED:this.itemcheck.varssumcheck[7],
                                                                        CHECK_HEAD_LIGHT:this.itemcheck.varssumcheck[8],
                                                                        CHECK_SIGNAL_LIGHT:this.itemcheck.varssumcheck[9],
                                                                        CHECK_PLATE_LIGHT:this.itemcheck.varssumcheck[10],
                                                                        CHECK_WINDOWS_GLASS:this.itemcheck.varssumcheck[12],
                                                                        CHECK_CONTROL_SYS:this.itemcheck.varssumcheck[13],
                                                                        CHECK_RUBBER:this.itemcheck.varssumcheck[14],
                                                                        CHECK_FUEL_TANK:this.itemcheck.varssumcheck[15],
                                                                        CHECK_SUSPENSION:this.itemcheck.varssumcheck[16],
                                                                        CHECK_BODY:this.itemcheck.varssumcheck[18],
                                                                        CHECK_DOOR:this.itemcheck.varssumcheck[19],
                                                                        CHECK_BELT:this.itemcheck.varssumcheck[22],
                                                                        CHECK_RAIN_FLY:this.itemcheck.varssumcheck[11],
                                                                        CHECK_AUTHE:this.itemcheck.varssumcheck[23],
                                                                        VOICE_VALUE:this.soundinfo.datamax,
                                                                        PIPE_VALUE:this.varesmoke,
                                                                        CO_VALUE:this.cohcinfo.datamax.co,
                                                                        HC_VALUE:this.cohcinfo.datamax.hc,
                                                                        CO_VALUE_L1:this.cohcinfo.datamaxlo_1.co,
                                                                        HC_VALUE_L1:this.cohcinfo.datamaxlo_1.hc,
                                                                        CO_VALUE_L2:this.cohcinfo.datamaxlo_2.co,
                                                                        HC_VALUE_L2:this.cohcinfo.datamaxlo_2.hc,
                                                                        CO_VALUE_H1:this.cohcinfo.datamaxhi_1.co,
                                                                        HC_VALUE_H1:this.cohcinfo.datamaxhi_1.hc,
                                                                        CO_VALUE_H2:this.cohcinfo.datamaxhi_2.co,
                                                                        HC_VALUE_H2:this.cohcinfo.datamaxhi_2.hc,
                                                                        CHECK_L1:this.itemcheck_cohc.varssumcheck[0],
                                                                        CHECK_L2:this.itemcheck_cohc.varssumcheck[1],
                                                                        CHECK_H1:this.itemcheck_cohc.varssumcheck[2],
                                                                        CHECK_H2:this.itemcheck_cohc.varssumcheck[3],
                                                                        CHECK_CH12:this.itemcheck_cohc.varssumcheck[4],
                                                                        USER1:this.userinfo1.license_number,
                                                                        USER2:this.userinfo2.license_number,
                                                                        LIGHT_UP_L_VALUE:this.input81,
                                                                        LIGHT_UP_R_VALUE:this.input82,
                                                                        LIGHT_DN_L_VALUE:this.input83,
                                                                        LIGHT_DN_R_VALUE:this.input84,
                                                                        LIGHT_UP_L_LEVEL:this.input85,
                                                                        LIGHT_UP_R_LEVEL:this.input86,
                                                                        LIGHT_DN_L_LEVEL:this.input87,
                                                                        LIGHT_DN_R_LEVEL:this.input88,
                                                                        PC_IP:this.pc_ip,
                                                                        PC_MAC:this.pc_mac,
                                                                        PC_LICENSE:null,
                                                                        IS_DUPLICATED:null,
                                                                        STATUS_CHECK:this.allchk,
                                                                        IS_MULLER:this.gas_number, // gas number
                                                                        PIC_BRAKE:null,
                                                                        entry_by:this.entry_by,
                                                                        CAR_DISTANCE:this.current_distance,
                                                                        CAR_DISTANCE_TYPE:this.car_ditance_type,
                                                                        CHASSIS_NO:null,
                                                                        CHASSIS_NO_TYPE:this.carinfo.KINDDESC,
                                                                        AXLE_NO:this.axle,
                                                                        WHEEL_NO:this.wheel,
                                                                        TIRE_NO:this.tire_no,
                                                                        CAR_WEIGHT:this.car_weight,
                                                                        CAR_COLOR:this.carinfo.COLOR,
                                                                        CAR_SIZE:null,
                                                                        CAR_SEAT_NO:this.car_seat_no,
                                                                        PRB_TYPE:this.carinfo.FUELDESC,
                                                                        CAMERA_NAME:this.camera_name,
                                                                        AUTHORIZER_ID:null,
                                                                        IS_SENT:1,
                                                                        ERROR_TXT:this.allchkid.toString(),
                                                                        FAILED_REASONS:this.failed_reasons,
                                                                        ORACLE_OFF_CODE:this.carinfo.OFFLOCCODE,
                                                                        ORACLE_BR_CODE:this.camera_deviceId,
                                                                        ORACLE_TYPE:null,
                                                                        ORACLE_PLATE1:this.carinfo.PLATE1,
                                                                        ORACLE_PLATE2:this.carinfo.PLATE2,
                                                                        ORACLE_REP_DATE:null,
                                                                        ORACLE_REP_TIME:null,
                                                                        ORACLE_NUM_BODY:null,
                                                                        ORACLE_CHK_ISS_YY:null,
                                                                        ORACLE_CHK_ISS_NO:null,
                                                                        ORACLE_CHK_COMP:this.dataai.typecar_detail, // ชนิดยานพาหะนะ
                                                                        ORACLE_REF_NO1:this.dataai.klass, // ค่า ocr ป้ายทะเบียน
                                                                        TWO_FA_VALUE:this.dataai.score, // คะแนนรูปภาพ
                                                                        county_id:storeAuth.county_id,
                                                                    }
                                                                }
                                                            axios.post(`adddatacar`,datas).then(rs=>{
                                                                if(rs.data.status == 1){
                                                                    if(this.settings.server.basetype == 'ocr'){
                                                                        fetch('http://127.0.0.1:8080/rmvalue', {
                                                                            method: "GET"
                                                                        })
                                                                        .then((response) => {
                                                                            console.log(response);
                                                                        })
                                                                    }
                                                                    this.$swal.close();
                                                                    this.$swal({
                                                                        title: 'เพิ่มข้อมูลเรียบร้อยแล้ว !',
                                                                        text: "กดตกลงเพื่อเริ่มกรอกข้อมูลใหม่",
                                                                        icon: 'success',
                                                                        showCancelButton: false,
                                                                        confirmButtonColor: '#664284',
                                                                        confirmButtonText: 'ตกลง'
                                                                    }).then((result) => {
                                                                        window.open('/user/datacarpdf/'+rs.data.datas);
                                                                        location.reload();
                                                                    })
                                                                }else if(rs.data.status == 3){
                                                                    this.$swal.close();
                                                                    this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                                        icon:'error',
                                                                    })
                                                                }else{
                                                                    this.$swal({
                                                                        title: 'เกิดข้อผิดพลาดในการเชื่อมระบบ !',
                                                                        text: "กรุณาออกจากระบบแล้วล็อคอินเข้าสู่ระบบใหม่อีกครั้ง",
                                                                        icon: 'error',
                                                                        showCancelButton: false,
                                                                        confirmButtonColor: '#664284',
                                                                        confirmButtonText: 'ตกลง'
                                                                    }).then((result) => {
                                                                        localStorage.clear();
                                                                        window.location.href = '/auth/login';
                                                                    })
                                                                }
                                                            }).catch(err=>{
                                                                console.log(err)
                                                                this.$swal.close();
                                                                this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                                icon:'error',
                                                                })
                                                            })
                                                        }else{
                                                            this.$swal({
                                                                title: "ต้องการส่งผลการตรวจหรือไม่ ?",
                                                                text: "พบผลตรวจบางข้อที่ไม่ผ่าน",
                                                                icon: "warning",
                                                                showDenyButton: true,
                                                                showCancelButton: true,
                                                                showCancelButton: false,
                                                                confirmButtonText: "ยืนยันการส่งข้อมูล",
                                                                denyButtonText: `ยกเลิกการส่ง`
                                                                })
                                                                .then((result) => {
                                                                if (result.isConfirmed) {
                                                                    this.$swal({
                                                                        title: 'ระบบกำลังทำงาน',
                                                                        html: 'กำลังส่งข้อมูล ...',
                                                                        allowEscapeKey: false,
                                                                        allowOutsideClick: false,
                                                                    });
                                                                    this.$swal.showLoading()
                                                                    let datas = {
                                                                        uid: storeAuth.id,
                                                                        gid: storeAuth.group_id,
                                                                        tid: 4,
                                                                        datas:{
                                                                            b64img:this.b64img,
                                                                            CHECK_DATETIME:this.checkdate+' '+this.checktime,
                                                                            SEND_DATETIME:this.timeinsert,
                                                                            TRO_ID:this.tro_id,
                                                                            TRO_NAME:this.tro_name,
                                                                            TRO_OWNER_NAME:this.tro_owner_name,
                                                                            CAR_LIC_ID_CHAR:this.car_lic_id_char,
                                                                            CAR_LIC_ID_NUM:this.car_lic_id_num,
                                                                            CAR_LIC_PROV:this.car_lic_prov.id,
                                                                            CAR_REG_DATE:this.car_reg_date_1+' '+this.car_reg_date_2+' '+this.car_reg_date_3,
                                                                            CAR_REG_DATE_1:this.car_reg_date_1,
                                                                            CAR_REG_DATE_2:this.car_reg_date_2,
                                                                            CAR_REG_DATE_3:this.car_reg_date_3,
                                                                            RY:null,
                                                                            RY_ID:this.ry_id,
                                                                            RY_BOOK_ID:null,
                                                                            RY_NUMBER_ID:this.car_band.band,
                                                                            CAR_TYPE:this.typecar,
                                                                            CHAISSE_NO:this.chaisse_no,
                                                                            ENGINE_NO:this.engine_no,
                                                                            AXLE:null,
                                                                            WHEEL:null,
                                                                            CURRENT_DISTANCE:this.current_distance,
                                                                            TYRE:this.type,
                                                                            FUELDESC_SELECT:this.fueldesc_select,
                                                                            TESTBRAKE_FORCE_AXLE1L:this.vars.axle1l,
                                                                            TESTBRAKE_FORCE_AXLE1R:this.vars.axle1r,
                                                                            TESTBRAKE_FORCE_AXLE2L:this.vars.axle2l,
                                                                            TESTBRAKE_FORCE_AXLE2R:this.vars.axle2r,
                                                                            TESTBRAKE_FORCE_AXLE3L:this.vars.axle3l,
                                                                            TESTBRAKE_FORCE_AXLE3R:this.vars.axle3r,
                                                                            TESTBRAKE_FORCE_AXLE4L:this.vars.axle4l,
                                                                            TESTBRAKE_FORCE_AXLE4R:this.vars.axle4r,
                                                                            TESTBRAKE_WEIGHT_AXLE1:this.vars.axle1Weight,
                                                                            TESTBRAKE_WEIGHT_AXLE2:this.vars.axle2Weight,
                                                                            TESTBRAKE_WEIGHT_AXLE3:this.vars.axle3Weight,
                                                                            TESTBRAKE_WEIGHT_AXLE4:this.vars.axle4Weight,
                                                                            TESTBRAKE_DIF_AXLE1:this.varssum1,
                                                                            TESTBRAKE_DIF_AXLE2:this.varssum2,
                                                                            TESTBRAKE_DIF_AXLE3:this.varssum3,
                                                                            TESTBRAKE_DIF_AXLE4:this.varssum4,
                                                                            TESTBRAKE_FOOT_EFFICIENCY:this.varssum5,
                                                                            TESTBRAKE_HAND_VAULE_L:this.vars.handbrakeForce2L,
                                                                            TESTBRAKE_HAND_VAULE_R:this.vars.handbrakeForce2R,
                                                                            TESTBRAKE_HAND_EFFICIENCY:this.varssum6,
                                                                            TESTBRAKE_WHEEL_ALIGHTMENT:this.vars.wheelAlignment,
                                                                            RESULT_DIFF_BRAKE:this.itemcheck.varssumcheck[0],
                                                                            RESULT_FOOT_BRAKE:this.itemcheck.varssumcheck[1],
                                                                            RESULT_HAND_BRAKE:this.itemcheck.varssumcheck[2],
                                                                            RESULT_WHEEL_ALIGHTMENT:this.itemcheck.varssumcheck[3],
                                                                            CHECK_VOICE:this.itemcheck.varssumcheck[4],
                                                                            CHECK_PIPE:this.itemcheck.varssumcheck[5],
                                                                            CHECK_HOOTER:this.itemcheck.varssumcheck[6],
                                                                            CHECK_SPEED:this.itemcheck.varssumcheck[7],
                                                                            CHECK_HEAD_LIGHT:this.itemcheck.varssumcheck[8],
                                                                            CHECK_SIGNAL_LIGHT:this.itemcheck.varssumcheck[9],
                                                                            CHECK_PLATE_LIGHT:this.itemcheck.varssumcheck[10],
                                                                            CHECK_WINDOWS_GLASS:this.itemcheck.varssumcheck[12],
                                                                            CHECK_CONTROL_SYS:this.itemcheck.varssumcheck[13],
                                                                            CHECK_RUBBER:this.itemcheck.varssumcheck[14],
                                                                            CHECK_FUEL_TANK:this.itemcheck.varssumcheck[15],
                                                                            CHECK_SUSPENSION:this.itemcheck.varssumcheck[16],
                                                                            CHECK_BODY:this.itemcheck.varssumcheck[18],
                                                                            CHECK_DOOR:this.itemcheck.varssumcheck[19],
                                                                            CHECK_BELT:this.itemcheck.varssumcheck[22],
                                                                            CHECK_RAIN_FLY:this.itemcheck.varssumcheck[11],
                                                                            CHECK_AUTHE:this.itemcheck.varssumcheck[23],
                                                                            VOICE_VALUE:this.soundinfo.datamax,
                                                                            PIPE_VALUE:this.varesmoke,
                                                                            CO_VALUE:this.cohcinfo.datamax.co,
                                                                            HC_VALUE:this.cohcinfo.datamax.hc,
                                                                            CO_VALUE_L1:this.cohcinfo.datamaxlo_1.co,
                                                                            HC_VALUE_L1:this.cohcinfo.datamaxlo_1.hc,
                                                                            CO_VALUE_L2:this.cohcinfo.datamaxlo_2.co,
                                                                            HC_VALUE_L2:this.cohcinfo.datamaxlo_2.hc,
                                                                            CO_VALUE_H1:this.cohcinfo.datamaxhi_1.co,
                                                                            HC_VALUE_H1:this.cohcinfo.datamaxhi_1.hc,
                                                                            CO_VALUE_H2:this.cohcinfo.datamaxhi_2.co,
                                                                            HC_VALUE_H2:this.cohcinfo.datamaxhi_2.hc,
                                                                            CHECK_L1:this.itemcheck_cohc.varssumcheck[0],
                                                                            CHECK_L2:this.itemcheck_cohc.varssumcheck[1],
                                                                            CHECK_H1:this.itemcheck_cohc.varssumcheck[2],
                                                                            CHECK_H2:this.itemcheck_cohc.varssumcheck[3],
                                                                            CHECK_CH12:this.itemcheck_cohc.varssumcheck[4],
                                                                            USER1:this.userinfo1.license_number,
                                                                            USER2:this.userinfo2.license_number,
                                                                            LIGHT_UP_L_VALUE:this.input81,
                                                                            LIGHT_UP_R_VALUE:this.input82,
                                                                            LIGHT_DN_L_VALUE:this.input83,
                                                                            LIGHT_DN_R_VALUE:this.input84,
                                                                            LIGHT_UP_L_LEVEL:this.input85,
                                                                            LIGHT_UP_R_LEVEL:this.input86,
                                                                            LIGHT_DN_L_LEVEL:this.input87,
                                                                            LIGHT_DN_R_LEVEL:this.input88,
                                                                            PC_IP:this.pc_ip,
                                                                            PC_MAC:this.pc_mac,
                                                                            PC_LICENSE:null,
                                                                            IS_DUPLICATED:null,
                                                                            STATUS_CHECK:this.allchk,
                                                                            IS_MULLER:this.gas_number, // gas number
                                                                            PIC_BRAKE:null,
                                                                            entry_by:this.entry_by,
                                                                            CAR_DISTANCE:this.current_distance,
                                                                            CAR_DISTANCE_TYPE:this.car_ditance_type,
                                                                            CHASSIS_NO:null,
                                                                            CHASSIS_NO_TYPE:this.carinfo.KINDDESC,
                                                                            AXLE_NO:this.axle,
                                                                            WHEEL_NO:this.wheel,
                                                                            TIRE_NO:this.tire_no,
                                                                            CAR_WEIGHT:this.car_weight,
                                                                            CAR_COLOR:this.carinfo.COLOR,
                                                                            CAR_SIZE:null,
                                                                            CAR_SEAT_NO:this.car_seat_no,
                                                                            PRB_TYPE:this.carinfo.FUELDESC,
                                                                            CAMERA_NAME:this.camera_name,
                                                                            AUTHORIZER_ID:null,
                                                                            IS_SENT:1,
                                                                            ERROR_TXT:this.allchkid.toString(),
                                                                            FAILED_REASONS:this.failed_reasons,
                                                                            ORACLE_OFF_CODE:this.carinfo.OFFLOCCODE,
                                                                            ORACLE_BR_CODE:this.camera_deviceId,
                                                                            ORACLE_TYPE:null,
                                                                            ORACLE_PLATE1:this.carinfo.PLATE1,
                                                                            ORACLE_PLATE2:this.carinfo.PLATE2,
                                                                            ORACLE_REP_DATE:null,
                                                                            ORACLE_REP_TIME:null,
                                                                            ORACLE_NUM_BODY:null,
                                                                            ORACLE_CHK_ISS_YY:null,
                                                                            ORACLE_CHK_ISS_NO:null,
                                                                            ORACLE_CHK_COMP:this.dataai.typecar_detail, // ชนิดยานพาหะนะ
                                                                            ORACLE_REF_NO1:this.dataai.klass, // ค่า ocr ป้ายทะเบียน
                                                                            TWO_FA_VALUE:this.dataai.score, // คะแนนรูปภาพ
                                                                            county_id:storeAuth.county_id,
                                                                        }
                                                                    }
                                                                    axios.post(`adddatacar`,datas).then(rs=>{
                                                                        if(rs.data.status == 1){
                                                                            if(this.settings.server.basetype == 'ocr'){
                                                                                fetch('http://127.0.0.1:8080/rmvalue', {
                                                                                    method: "GET"
                                                                                })
                                                                                .then((response) => {
                                                                                    console.log(response);
                                                                                })
                                                                            }
                                                                            this.$swal.close();
                                                                            this.$swal({
                                                                                title: 'เพิ่มข้อมูลเรียบร้อยแล้ว !',
                                                                                text: "กดตกลงเพื่อเริ่มกรอกข้อมูลใหม่",
                                                                                icon: 'success',
                                                                                showCancelButton: false,
                                                                                confirmButtonColor: '#664284',
                                                                                confirmButtonText: 'ตกลง'
                                                                            }).then((result) => {
                                                                                window.open('/user/datacarpdf/'+rs.data.datas);
                                                                                location.reload();
                                                                            })
                                                                        }else if(rs.data.status == 3){
                                                                            this.$swal.close();
                                                                            this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                                                icon:'error',
                                                                            })
                                                                        }else{
                                                                            this.$swal({
                                                                                title: 'เกิดข้อผิดพลาดในการเชื่อมระบบ !',
                                                                                text: "กรุณาออกจากระบบแล้วล็อคอินเข้าสู่ระบบใหม่อีกครั้ง",
                                                                                icon: 'error',
                                                                                showCancelButton: false,
                                                                                confirmButtonColor: '#664284',
                                                                                confirmButtonText: 'ตกลง'
                                                                            }).then((result) => {
                                                                                localStorage.clear();
                                                                                window.location.href = '/auth/login';
                                                                            }) 
                                                                        }
                                                                    }).catch(err=>{
                                                                        console.log(err)
                                                                        this.$swal.close();
                                                                        this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                                        icon:'error',
                                                                        })
                                                                    })
                                                                } else {
                                                                    this.$swal({title: "ยกเลิกการส่งข้อมูล !",icon: 'info'});
                                                                }
                                                            });
                                                        }
                                                    } else {
                                                        this.$swal({title: "ยกเลิกการส่งข้อมูล !",icon: 'info'});
                                                    }
                                                });
                                    }else{
                                            if(this.allchk == 1){
                                                this.$swal({
                                                    title: 'ระบบกำลังทำงาน',
                                                    html: 'กำลังส่งข้อมูล ...',
                                                    allowEscapeKey: false,
                                                    allowOutsideClick: false,
                                                });
                                                this.$swal.showLoading()
                                                let datas = {
                                                        uid: storeAuth.id,
                                                        gid: storeAuth.group_id,
                                                        tid: 4,
                                                        datas:{
                                                            b64img:this.b64img,
                                                            CHECK_DATETIME:this.checkdate+' '+this.checktime,
                                                            SEND_DATETIME:this.timeinsert,
                                                            TRO_ID:this.tro_id,
                                                            TRO_NAME:this.tro_name,
                                                            TRO_OWNER_NAME:this.tro_owner_name,
                                                            CAR_LIC_ID_CHAR:this.car_lic_id_char,
                                                            CAR_LIC_ID_NUM:this.car_lic_id_num,
                                                            CAR_LIC_PROV:this.car_lic_prov.id,
                                                            CAR_REG_DATE:this.car_reg_date_1+' '+this.car_reg_date_2+' '+this.car_reg_date_3,
                                                            CAR_REG_DATE_1:this.car_reg_date_1,
                                                            CAR_REG_DATE_2:this.car_reg_date_2,
                                                            CAR_REG_DATE_3:this.car_reg_date_3,
                                                            RY:null,
                                                            RY_ID:this.ry_id,
                                                            RY_BOOK_ID:null,
                                                            RY_NUMBER_ID:this.car_band.band,
                                                            CAR_TYPE:this.typecar,
                                                            CHAISSE_NO:this.chaisse_no,
                                                            ENGINE_NO:this.engine_no,
                                                            AXLE:null,
                                                            WHEEL:null,
                                                            CURRENT_DISTANCE:this.current_distance,
                                                            TYRE:this.type,
                                                            FUELDESC_SELECT:this.fueldesc_select,
                                                            TESTBRAKE_FORCE_AXLE1L:this.vars.axle1l,
                                                            TESTBRAKE_FORCE_AXLE1R:this.vars.axle1r,
                                                            TESTBRAKE_FORCE_AXLE2L:this.vars.axle2l,
                                                            TESTBRAKE_FORCE_AXLE2R:this.vars.axle2r,
                                                            TESTBRAKE_FORCE_AXLE3L:this.vars.axle3l,
                                                            TESTBRAKE_FORCE_AXLE3R:this.vars.axle3r,
                                                            TESTBRAKE_FORCE_AXLE4L:this.vars.axle4l,
                                                            TESTBRAKE_FORCE_AXLE4R:this.vars.axle4r,
                                                            TESTBRAKE_WEIGHT_AXLE1:this.vars.axle1Weight,
                                                            TESTBRAKE_WEIGHT_AXLE2:this.vars.axle2Weight,
                                                            TESTBRAKE_WEIGHT_AXLE3:this.vars.axle3Weight,
                                                            TESTBRAKE_WEIGHT_AXLE4:this.vars.axle4Weight,
                                                            TESTBRAKE_DIF_AXLE1:this.varssum1,
                                                            TESTBRAKE_DIF_AXLE2:this.varssum2,
                                                            TESTBRAKE_DIF_AXLE3:this.varssum3,
                                                            TESTBRAKE_DIF_AXLE4:this.varssum4,
                                                            TESTBRAKE_FOOT_EFFICIENCY:this.varssum5,
                                                            TESTBRAKE_HAND_VAULE_L:this.vars.handbrakeForce2L,
                                                            TESTBRAKE_HAND_VAULE_R:this.vars.handbrakeForce2R,
                                                            TESTBRAKE_HAND_EFFICIENCY:this.varssum6,
                                                            TESTBRAKE_WHEEL_ALIGHTMENT:this.vars.wheelAlignment,
                                                            RESULT_DIFF_BRAKE:this.itemcheck.varssumcheck[0],
                                                            RESULT_FOOT_BRAKE:this.itemcheck.varssumcheck[1],
                                                            RESULT_HAND_BRAKE:this.itemcheck.varssumcheck[2],
                                                            RESULT_WHEEL_ALIGHTMENT:this.itemcheck.varssumcheck[3],
                                                            CHECK_VOICE:this.itemcheck.varssumcheck[4],
                                                            CHECK_PIPE:this.itemcheck.varssumcheck[5],
                                                            CHECK_HOOTER:this.itemcheck.varssumcheck[6],
                                                            CHECK_SPEED:this.itemcheck.varssumcheck[7],
                                                            CHECK_HEAD_LIGHT:this.itemcheck.varssumcheck[8],
                                                            CHECK_SIGNAL_LIGHT:this.itemcheck.varssumcheck[9],
                                                            CHECK_PLATE_LIGHT:this.itemcheck.varssumcheck[10],
                                                            CHECK_WINDOWS_GLASS:this.itemcheck.varssumcheck[12],
                                                            CHECK_CONTROL_SYS:this.itemcheck.varssumcheck[13],
                                                            CHECK_RUBBER:this.itemcheck.varssumcheck[14],
                                                            CHECK_FUEL_TANK:this.itemcheck.varssumcheck[15],
                                                            CHECK_SUSPENSION:this.itemcheck.varssumcheck[16],
                                                            CHECK_BODY:this.itemcheck.varssumcheck[18],
                                                            CHECK_DOOR:this.itemcheck.varssumcheck[19],
                                                            CHECK_BELT:this.itemcheck.varssumcheck[22],
                                                            CHECK_RAIN_FLY:this.itemcheck.varssumcheck[11],
                                                            CHECK_AUTHE:this.itemcheck.varssumcheck[23],
                                                            VOICE_VALUE:this.soundinfo.datamax,
                                                            PIPE_VALUE:this.varesmoke,
                                                            CO_VALUE:this.cohcinfo.datamax.co,
                                                            HC_VALUE:this.cohcinfo.datamax.hc,
                                                            CO_VALUE_L1:this.cohcinfo.datamaxlo_1.co,
                                                            HC_VALUE_L1:this.cohcinfo.datamaxlo_1.hc,
                                                            CO_VALUE_L2:this.cohcinfo.datamaxlo_2.co,
                                                            HC_VALUE_L2:this.cohcinfo.datamaxlo_2.hc,
                                                            CO_VALUE_H1:this.cohcinfo.datamaxhi_1.co,
                                                            HC_VALUE_H1:this.cohcinfo.datamaxhi_1.hc,
                                                            CO_VALUE_H2:this.cohcinfo.datamaxhi_2.co,
                                                            HC_VALUE_H2:this.cohcinfo.datamaxhi_2.hc,
                                                            CHECK_L1:this.itemcheck_cohc.varssumcheck[0],
                                                            CHECK_L2:this.itemcheck_cohc.varssumcheck[1],
                                                            CHECK_H1:this.itemcheck_cohc.varssumcheck[2],
                                                            CHECK_H2:this.itemcheck_cohc.varssumcheck[3],
                                                            CHECK_CH12:this.itemcheck_cohc.varssumcheck[4],
                                                            USER1:this.userinfo1.license_number,
                                                            USER2:this.userinfo2.license_number,
                                                            LIGHT_UP_L_VALUE:this.input81,
                                                            LIGHT_UP_R_VALUE:this.input82,
                                                            LIGHT_DN_L_VALUE:this.input83,
                                                            LIGHT_DN_R_VALUE:this.input84,
                                                            LIGHT_UP_L_LEVEL:this.input85,
                                                            LIGHT_UP_R_LEVEL:this.input86,
                                                            LIGHT_DN_L_LEVEL:this.input87,
                                                            LIGHT_DN_R_LEVEL:this.input88,
                                                            PC_IP:this.pc_ip,
                                                            PC_MAC:this.pc_mac,
                                                            PC_LICENSE:null,
                                                            IS_DUPLICATED:null,
                                                            STATUS_CHECK:this.allchk,
                                                            IS_MULLER:this.gas_number, // gas number
                                                            PIC_BRAKE:null,
                                                            entry_by:this.entry_by,
                                                            CAR_DISTANCE:this.current_distance,
                                                            CAR_DISTANCE_TYPE:this.car_ditance_type,
                                                            CHASSIS_NO:null,
                                                            CHASSIS_NO_TYPE:this.carinfo.KINDDESC,
                                                            AXLE_NO:this.axle,
                                                            WHEEL_NO:this.wheel,
                                                            TIRE_NO:this.tire_no,
                                                            CAR_WEIGHT:this.car_weight,
                                                            CAR_COLOR:this.carinfo.COLOR,
                                                            CAR_SIZE:null,
                                                            CAR_SEAT_NO:this.car_seat_no,
                                                            PRB_TYPE:this.carinfo.FUELDESC,
                                                            CAMERA_NAME:this.camera_name,
                                                            AUTHORIZER_ID:null,
                                                            IS_SENT:1,
                                                            ERROR_TXT:this.allchkid.toString(),
                                                            FAILED_REASONS:this.failed_reasons,
                                                            ORACLE_OFF_CODE:this.carinfo.OFFLOCCODE,
                                                            ORACLE_BR_CODE:this.camera_deviceId,
                                                            ORACLE_TYPE:null,
                                                            ORACLE_PLATE1:this.carinfo.PLATE1,
                                                            ORACLE_PLATE2:this.carinfo.PLATE2,
                                                            ORACLE_REP_DATE:null,
                                                            ORACLE_REP_TIME:null,
                                                            ORACLE_NUM_BODY:null,
                                                            ORACLE_CHK_ISS_YY:null,
                                                            ORACLE_CHK_ISS_NO:null,
                                                            ORACLE_CHK_COMP:this.dataai.typecar_detail, // ชนิดยานพาหะนะ
                                                            ORACLE_REF_NO1:this.dataai.klass, // ค่า ocr ป้ายทะเบียน
                                                            TWO_FA_VALUE:this.dataai.score, // คะแนนรูปภาพ
                                                            county_id:storeAuth.county_id
                                                        }
                                                    }
                                                axios.post(`adddatacar`,datas).then(rs=>{
                                                    if(rs.data.status == 1){
                                                        if(this.settings.server.basetype == 'ocr'){
                                                            fetch('http://127.0.0.1:8080/rmvalue', {
                                                                method: "GET"
                                                            })
                                                            .then((response) => {
                                                                console.log(response);
                                                            })
                                                        }
                                                        this.$swal.close();
                                                        this.$swal({
                                                            title: 'เพิ่มข้อมูลเรียบร้อยแล้ว !',
                                                            text: "กดตกลงเพื่อเริ่มกรอกข้อมูลใหม่",
                                                            icon: 'success',
                                                            showCancelButton: false,
                                                            confirmButtonColor: '#664284',
                                                            confirmButtonText: 'ตกลง'
                                                        }).then((result) => {
                                                            window.open('/user/datacarpdf/'+rs.data.datas);
                                                            location.reload();
                                                        })
                                                    }else if(rs.data.status == 3){
                                                        this.$swal.close();
                                                        this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                            icon:'error',
                                                        })
                                                    }else{
                                                        this.$swal({
                                                            title: 'เกิดข้อผิดพลาดในการเชื่อมระบบ !',
                                                            text: "กรุณาออกจากระบบแล้วล็อคอินเข้าสู่ระบบใหม่อีกครั้ง",
                                                            icon: 'error',
                                                            showCancelButton: false,
                                                            confirmButtonColor: '#664284',
                                                            confirmButtonText: 'ตกลง'
                                                        }).then((result) => {
                                                            localStorage.clear();
                                                            window.location.href = '/auth/login';
                                                        })
                                                    }
                                                }).catch(err=>{
                                                    this.$swal.close();
                                                    this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                        icon:'error',
                                                    })
                                                })
                                            }else{
                                                this.$swal({
                                                    title: "ต้องการส่งผลการตรวจหรือไม่ ?",
                                                    text: "พบผลตรวจบางข้อที่ไม่ผ่าน",
                                                    icon: "warning",
                                                    showDenyButton: true,
                                                    showCancelButton: true,
                                                    showCancelButton: false,
                                                    confirmButtonText: "ยืนยันการส่งข้อมูล",
                                                    denyButtonText: `ยกเลิกการส่ง`
                                                    })
                                                    .then((result) => {
                                                    if (result.isConfirmed) {
                                                        this.$swal({
                                                            title: 'ระบบกำลังทำงาน',
                                                            html: 'กำลังส่งข้อมูล ...',
                                                            allowEscapeKey: false,
                                                            allowOutsideClick: false,
                                                        });
                                                        this.$swal.showLoading()
                                                        let datas = {
                                                            uid: storeAuth.id,
                                                            gid: storeAuth.group_id,
                                                            tid: 4,
                                                            datas:{
                                                                b64img:this.b64img,
                                                                CHECK_DATETIME:this.checkdate+' '+this.checktime,
                                                                SEND_DATETIME:this.timeinsert,
                                                                TRO_ID:this.tro_id,
                                                                TRO_NAME:this.tro_name,
                                                                TRO_OWNER_NAME:this.tro_owner_name,
                                                                CAR_LIC_ID_CHAR:this.car_lic_id_char,
                                                                CAR_LIC_ID_NUM:this.car_lic_id_num,
                                                                CAR_LIC_PROV:this.car_lic_prov.id,
                                                                CAR_REG_DATE:this.car_reg_date_1+' '+this.car_reg_date_2+' '+this.car_reg_date_3,
                                                                CAR_REG_DATE_1:this.car_reg_date_1,
                                                                CAR_REG_DATE_2:this.car_reg_date_2,
                                                                CAR_REG_DATE_3:this.car_reg_date_3,
                                                                RY:null,
                                                                RY_ID:this.ry_id,
                                                                RY_BOOK_ID:null,
                                                                RY_NUMBER_ID:this.car_band.band,
                                                                CAR_TYPE:this.typecar,
                                                                CHAISSE_NO:this.chaisse_no,
                                                                ENGINE_NO:this.engine_no,
                                                                AXLE:null,
                                                                WHEEL:null,
                                                                CURRENT_DISTANCE:this.current_distance,
                                                                TYRE:this.type,
                                                                FUELDESC_SELECT:this.fueldesc_select,
                                                                TESTBRAKE_FORCE_AXLE1L:this.vars.axle1l,
                                                                TESTBRAKE_FORCE_AXLE1R:this.vars.axle1r,
                                                                TESTBRAKE_FORCE_AXLE2L:this.vars.axle2l,
                                                                TESTBRAKE_FORCE_AXLE2R:this.vars.axle2r,
                                                                TESTBRAKE_FORCE_AXLE3L:this.vars.axle3l,
                                                                TESTBRAKE_FORCE_AXLE3R:this.vars.axle3r,
                                                                TESTBRAKE_FORCE_AXLE4L:this.vars.axle4l,
                                                                TESTBRAKE_FORCE_AXLE4R:this.vars.axle4r,
                                                                TESTBRAKE_WEIGHT_AXLE1:this.vars.axle1Weight,
                                                                TESTBRAKE_WEIGHT_AXLE2:this.vars.axle2Weight,
                                                                TESTBRAKE_WEIGHT_AXLE3:this.vars.axle3Weight,
                                                                TESTBRAKE_WEIGHT_AXLE4:this.vars.axle4Weight,
                                                                TESTBRAKE_DIF_AXLE1:this.varssum1,
                                                                TESTBRAKE_DIF_AXLE2:this.varssum2,
                                                                TESTBRAKE_DIF_AXLE3:this.varssum3,
                                                                TESTBRAKE_DIF_AXLE4:this.varssum4,
                                                                TESTBRAKE_FOOT_EFFICIENCY:this.varssum5,
                                                                TESTBRAKE_HAND_VAULE_L:this.vars.handbrakeForce2L,
                                                                TESTBRAKE_HAND_VAULE_R:this.vars.handbrakeForce2R,
                                                                TESTBRAKE_HAND_EFFICIENCY:this.varssum6,
                                                                TESTBRAKE_WHEEL_ALIGHTMENT:this.vars.wheelAlignment,
                                                                RESULT_DIFF_BRAKE:this.itemcheck.varssumcheck[0],
                                                                RESULT_FOOT_BRAKE:this.itemcheck.varssumcheck[1],
                                                                RESULT_HAND_BRAKE:this.itemcheck.varssumcheck[2],
                                                                RESULT_WHEEL_ALIGHTMENT:this.itemcheck.varssumcheck[3],
                                                                CHECK_VOICE:this.itemcheck.varssumcheck[4],
                                                                CHECK_PIPE:this.itemcheck.varssumcheck[5],
                                                                CHECK_HOOTER:this.itemcheck.varssumcheck[6],
                                                                CHECK_SPEED:this.itemcheck.varssumcheck[7],
                                                                CHECK_HEAD_LIGHT:this.itemcheck.varssumcheck[8],
                                                                CHECK_SIGNAL_LIGHT:this.itemcheck.varssumcheck[9],
                                                                CHECK_PLATE_LIGHT:this.itemcheck.varssumcheck[10],
                                                                CHECK_WINDOWS_GLASS:this.itemcheck.varssumcheck[12],
                                                                CHECK_CONTROL_SYS:this.itemcheck.varssumcheck[13],
                                                                CHECK_RUBBER:this.itemcheck.varssumcheck[14],
                                                                CHECK_FUEL_TANK:this.itemcheck.varssumcheck[15],
                                                                CHECK_SUSPENSION:this.itemcheck.varssumcheck[16],
                                                                CHECK_BODY:this.itemcheck.varssumcheck[18],
                                                                CHECK_DOOR:this.itemcheck.varssumcheck[19],
                                                                CHECK_BELT:this.itemcheck.varssumcheck[22],
                                                                CHECK_RAIN_FLY:this.itemcheck.varssumcheck[11],
                                                                CHECK_AUTHE:this.itemcheck.varssumcheck[23],
                                                                VOICE_VALUE:this.soundinfo.datamax,
                                                                PIPE_VALUE:this.varesmoke,
                                                                CO_VALUE:this.cohcinfo.datamax.co,
                                                                HC_VALUE:this.cohcinfo.datamax.hc,
                                                                CO_VALUE_L1:this.cohcinfo.datamaxlo_1.co,
                                                                HC_VALUE_L1:this.cohcinfo.datamaxlo_1.hc,
                                                                CO_VALUE_L2:this.cohcinfo.datamaxlo_2.co,
                                                                HC_VALUE_L2:this.cohcinfo.datamaxlo_2.hc,
                                                                CO_VALUE_H1:this.cohcinfo.datamaxhi_1.co,
                                                                HC_VALUE_H1:this.cohcinfo.datamaxhi_1.hc,
                                                                CO_VALUE_H2:this.cohcinfo.datamaxhi_2.co,
                                                                HC_VALUE_H2:this.cohcinfo.datamaxhi_2.hc,
                                                                CHECK_L1:this.itemcheck_cohc.varssumcheck[0],
                                                                CHECK_L2:this.itemcheck_cohc.varssumcheck[1],
                                                                CHECK_H1:this.itemcheck_cohc.varssumcheck[2],
                                                                CHECK_H2:this.itemcheck_cohc.varssumcheck[3],
                                                                CHECK_CH12:this.itemcheck_cohc.varssumcheck[4],
                                                                USER1:this.userinfo1.license_number,
                                                                USER2:this.userinfo2.license_number,
                                                                LIGHT_UP_L_VALUE:this.input81,
                                                                LIGHT_UP_R_VALUE:this.input82,
                                                                LIGHT_DN_L_VALUE:this.input83,
                                                                LIGHT_DN_R_VALUE:this.input84,
                                                                LIGHT_UP_L_LEVEL:this.input85,
                                                                LIGHT_UP_R_LEVEL:this.input86,
                                                                LIGHT_DN_L_LEVEL:this.input87,
                                                                LIGHT_DN_R_LEVEL:this.input88,
                                                                PC_IP:this.pc_ip,
                                                                PC_MAC:this.pc_mac,
                                                                PC_LICENSE:null,
                                                                IS_DUPLICATED:null,
                                                                STATUS_CHECK:this.allchk,
                                                                IS_MULLER:this.gas_number, // gas number
                                                                PIC_BRAKE:null,
                                                                entry_by:this.entry_by,
                                                                CAR_DISTANCE:this.current_distance,
                                                                CAR_DISTANCE_TYPE:this.car_ditance_type,
                                                                CHASSIS_NO:null,
                                                                CHASSIS_NO_TYPE:this.carinfo.KINDDESC,
                                                                AXLE_NO:this.axle,
                                                                WHEEL_NO:this.wheel,
                                                                TIRE_NO:this.tire_no,
                                                                CAR_WEIGHT:this.car_weight,
                                                                CAR_COLOR:this.carinfo.COLOR,
                                                                CAR_SIZE:null,
                                                                CAR_SEAT_NO:this.car_seat_no,
                                                                PRB_TYPE:this.carinfo.FUELDESC,
                                                                CAMERA_NAME:this.camera_name,
                                                                AUTHORIZER_ID:null,
                                                                IS_SENT:1,
                                                                ERROR_TXT:this.allchkid.toString(),
                                                                FAILED_REASONS:this.failed_reasons,
                                                                ORACLE_OFF_CODE:this.carinfo.OFFLOCCODE,
                                                                ORACLE_BR_CODE:this.camera_deviceId,
                                                                ORACLE_TYPE:null,
                                                                ORACLE_PLATE1:this.carinfo.PLATE1,
                                                                ORACLE_PLATE2:this.carinfo.PLATE2,
                                                                ORACLE_REP_DATE:null,
                                                                ORACLE_REP_TIME:null,
                                                                ORACLE_NUM_BODY:null,
                                                                ORACLE_CHK_ISS_YY:null,
                                                                ORACLE_CHK_ISS_NO:null,
                                                                ORACLE_CHK_COMP:this.dataai.typecar_detail, // ชนิดยานพาหะนะ
                                                                ORACLE_REF_NO1:this.dataai.klass, // ค่า ocr ป้ายทะเบียน
                                                                TWO_FA_VALUE:this.dataai.score, // คะแนนรูปภาพ
                                                                county_id:storeAuth.county_id,
                                                            }
                                                        }
                                                        axios.post(`adddatacar`,datas).then(rs=>{
                                                            if(rs.data.status == 1){
                                                                if(this.settings.server.basetype == 'ocr'){
                                                                    fetch('http://127.0.0.1:8080/rmvalue', {
                                                                        method: "GET"
                                                                    })
                                                                    .then((response) => {
                                                                        console.log(response);
                                                                    })
                                                                }
                                                                this.$swal.close();
                                                                this.$swal({
                                                                    title: 'เพิ่มข้อมูลเรียบร้อยแล้ว !',
                                                                    text: "กดตกลงเพื่อเริ่มกรอกข้อมูลใหม่",
                                                                    icon: 'success',
                                                                    showCancelButton: false,
                                                                    confirmButtonColor: '#664284',
                                                                    confirmButtonText: 'ตกลง'
                                                                }).then((result) => {
                                                                    window.open('/user/datacarpdf/'+rs.data.datas);
                                                                    location.reload();
                                                                })
                                                            }else if(rs.data.status == 3){
                                                                this.$swal.close();
                                                                this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                                    icon:'error',
                                                                })
                                                            }else{
                                                                this.$swal({
                                                                    title: 'เกิดข้อผิดพลาดในการเชื่อมระบบ !',
                                                                    text: "กรุณาออกจากระบบแล้วล็อคอินเข้าสู่ระบบใหม่อีกครั้ง",
                                                                    icon: 'error',
                                                                    showCancelButton: false,
                                                                    confirmButtonColor: '#664284',
                                                                    confirmButtonText: 'ตกลง'
                                                                }).then((result) => {
                                                                    localStorage.clear();
                                                                    window.location.href = '/auth/login';
                                                                }) 
                                                            }
                                                        }).catch(err=>{
                                                            this.$swal.close();
                                                            this.$swal({title:'เพิ่มข้อมูลไม่สำเร็จ !',
                                                            icon:'error',
                                                            })
                                                        })
                                                    } else {
                                                        this.$swal({title: "ยกเลิกการส่งข้อมูล !",icon: 'info'});
                                                    }
                                                });
                                            }
                                        }
                    

                                }else{
                                    this.$swal({title:'ยังไม่ได้ถ่ายรูป หรือในรูปภาพไม่พบยานพาหนะ !',
                                    icon:'warning',
                                    })
                                }
                        }
                    }else{
                        this.$swal({title:'เกิดข้อผิดพลาด !',
                            text: "กรุณาติดต่อผู้ดูแลระบบ",
                            icon:'warning',
                        })
                    }

                }).catch( (err) => {
                    this.$swal({title:'เกิดข้อผิดพลาด !',
                        text: "กรุณาติดต่อผู้ดูแลระบบ",
                        icon:'warning',
                    })
                });

            })
        }).catch((err) => {
            this.page_status = false;
            this.$swal({
                title: "ไม่สำเร็จ",
                text: "เรียกข้อมูลฮาร์ดแวร์ไม่ได้ กรุณาเปิดโปรแกรม v6(หน้าจอดำ) ค้างไว้ และลองอีกครั้ง",
                icon: "error"
            });
        });
    }
    
    },
    setchk3(){
        if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){
            this.itemcheck.varssumcheck[3] = 1
        }else{
            this.itemcheck.varssumcheck[3] = 0
        }
    },
    setchk5() {
        this.itemcheck.varssumcheck[5] = 0
        if (this.fueldesc_select == 2 || this.fueldesc_select == 5 || this.fueldesc_select == 9 || this.fueldesc_select == 10) {
            if (this.smoke_setting == 'opacity') {
                if (this.varesmoke == '') {
                    this.itemcheck.varssumcheck[5] = 1;
                } else if (this.varesmoke > 0 && this.varesmoke <= Number(this.st_read.smoke_opacity)) {
                    this.itemcheck.varssumcheck[5] = 1;
                }
            } else {
                if (this.varesmoke == '') {
                    this.itemcheck.varssumcheck[5] = 1;
                } else if (this.varesmoke > 0 && this.varesmoke <= Number(this.st_read.smoke_filter)) {
                    this.itemcheck.varssumcheck[5] = 1;
                }
            }
        }
        return this.itemcheck.varssumcheck[5];
    },
    setchk5cohc() {
        let chk5 = 1;
        if (this.fueldesc_select == 1 || this.fueldesc_select == 4 || this.fueldesc_select == 6 || this.fueldesc_select == 7 || this.fueldesc_select == 8) {
            if (this.cohcinfo.datamax.co != '' || this.cohcinfo.datamaxlo_1.co != '' || this.cohcinfo.datamaxlo_2.co != '' || this.cohcinfo.datamaxhi_1.co != '' || this.cohcinfo.datamaxhi_2.co != '') {
                for(let i=0; i<=4; i++ ) {
                    console.log(this.itemcheck_cohc.varssumcheck[i])
                    if(this.itemcheck_cohc.varssumcheck[i] == 0 ) {
                        chk5 = 0;
                    }
                }
                this.itemcheck.varssumcheck[5] = chk5
                return this.itemcheck.varssumcheck[5];
            }
        }
    },
    getNow() {
        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date +' '+ time;
        this.timeinsert = dateTime;
    },
},
computed:{
    // checkdatetime(){
        
    // },
    checkdate(){
        if(this.typecar != '01' && this.typecar != '02' && this.typecar != '03' && this.typecar != '12'){
            return '';
        }else{
            var now  = new Date();
            this.checkdate = now.toLocaleDateString("en-CA", {timeZone: "Asia/Bangkok"})
            return this.checkdate;
        }
    },
    checktime(){
        if(this.typecar != '01' && this.typecar != '02' && this.typecar != '03' && this.typecar != '12'){
            return null;
        }else{
            var now  = new Date();
            this.checktime = now.toLocaleTimeString("en-GB", {timeZone: "Asia/Bangkok"})
            return this.checktime;
        }
    },
    getchk8(){
        if(this.typecar != 12){
            this.itemcheck.varssumcheck[8] = 0;
            let chk = 1;
            if(this.input81 == ''){
                chk = 1;
            }else if(this.input81 != '' && this.input82 != '' && this.input83 != '' && this.input84 != '' && this.input85 != '' && this.input86 != '' && this.input87 != '' && this.input88 != ''){
                if(this.input81 < 0 || this.input82 < 0 || this.input83 < 12 || this.input84 < 12 || this.input85 < 0.29  || this.input85 > 2.29 || this.input86 < 0.29  || this.input86 > 2.29 || this.input87 < 6.4  || this.input87 < 6.4 || this.input88 < 6.4  || this.input88 < 6.4 || parseFloat(this.input83) + parseFloat(this.input84) > 430.00) {
                    chk = 0;
                }
            }
            this.itemcheck.varssumcheck[8] = chk;
            return chk;
        }else{
            this.itemcheck.varssumcheck[8] = 0;
            let chk = 1;
            if(this.input81 == ''){
                chk = 1;
            }else if(this.input81 != '' && this.input83 != '' && this.input85 != '' && this.input87 != ''){
                if(this.input81 < 0 || this.input83 < 12 || this.input85 < 0.29  || this.input85 > 2.29 || this.input87 < 6.4  || this.input87 < 6.4 || parseFloat(this.input83) > 430.00) {
                    chk = 0;
                }
            }
            this.itemcheck.varssumcheck[8] = chk;
            return chk;
        }
    },
    getchk3(){
        return this.itemcheck.varssumcheck[3]
    },
    getchk5(){
        return this.itemcheck.varssumcheck[5];
    },
    allchk() {
        let chk = 1;
        if(this.typecar != 12){
            for(let i=0; i<this.itemcheck.varssumcheck.length; i++ ) {
                if(this.itemcheck.varssumcheck[i] == 0 ) {
                    chk = 0;
                }
            }
            return chk;
        }else{
            for(let i=4; i<this.itemcheck.varssumcheck.length; i++ ) {
                if(this.itemcheck.varssumcheck[i] == 0 ) {
                    chk = 0;
                }
            }
            return chk;
        }

    },
    allchkid() {
        let id = [];
        if(this.typecar != 12){
            for(let i=0; i<this.itemcheck.varssumcheck.length; i++ ) {
                if(i == 0){
                    if(this.itemcheck.varssumcheck[0] == 0 ) {
                        // id.push("1.1 ผลต่าง");
                        id.push("ผลต่าง");
                    }
                }else if(i == 1){
                    if(this.itemcheck.varssumcheck[1] == 0 ) {
                        // id.push("1.2 ประสิทธิภาพห้ามล้อ");
                        id.push("ประสิทธิภาพห้ามล้อ");
                    }
                }else if(i == 2){
                    if(this.itemcheck.varssumcheck[2] == 0 ) {
                        // id.push("1.3 ประสิทธิภาพห้ามล้อมือ");
                        id.push("ประสิทธิภาพห้ามล้อมือ");
                    }
                }else if(i == 3){
                    if(this.itemcheck.varssumcheck[3] == 0 ) {
                        // id.push("2. ศูนย์ล้อ");
                        id.push("ศูนย์ล้อ");
                    }
                }else if(i == 4){
                    if(this.itemcheck.varssumcheck[4] == 0 ) {
                        // id.push("3. ระดับเสียง");
                          id.push("ระดับเสียง");
                    }
                }else if(i == 5){
                    if(this.itemcheck.varssumcheck[5] == 0 ) {
                        // id.push("4. ระบบไอเสีย");
                        id.push("ระบบไอเสีย");
                    }
                }else if(i == 6){
                    if(this.itemcheck.varssumcheck[6] == 0 ) {
                        // id.push("5. แตรสัญญาณ");
                        id.push("แตรสัญญาณ");
                    }
                }else if(i == 7){
                    if(this.itemcheck.varssumcheck[7] == 0 ) {
                        // id.push("6. มาตรวัดความเร็ว");
                        id.push("มาตรวัดความเร็ว");

                    }
                }else if(i == 8){
                    if(this.itemcheck.varssumcheck[8] == 0 ) {
                        // id.push("7. โคมไฟ");
                        id.push("โคมไฟ");

                    }
                }else if(i == 9){
                    if(this.itemcheck.varssumcheck[9] == 0 ) {
                        // id.push("8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");
                        id.push("โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");

                    }
                }else if(i == 10){
                    if(this.itemcheck.varssumcheck[10] == 0 ) {
                        // id.push("9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");
                        id.push("โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");

                    }
                }else if(i == 11){
                    if(this.itemcheck.varssumcheck[11] == 0 ) {
                        // id.push("10. เครื่องปัดน้ำฝน");
                        id.push("เครื่องปัดน้ำฝน");

                    }
                }else if(i == 12){
                    if(this.itemcheck.varssumcheck[12] == 0 ) {
                        // id.push("11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");
                        id.push("กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");

                    }
                }else if(i == 13){
                    if(this.itemcheck.varssumcheck[13] == 0 ) {
                        // id.push("12. ระบบบังคับเลี้ยวและพวงมาลัย");
                        id.push("ระบบบังคับเลี้ยวและพวงมาลัย");

                    }
                }else if(i == 14){
                    if(this.itemcheck.varssumcheck[14] == 0 ) {
                        // id.push("13. ล้อและยาง");
                        id.push("ล้อและยาง");

                    }
                }else if(i == 15){
                    if(this.itemcheck.varssumcheck[15] == 0 ) {
                        // id.push("14. ถังเชื้อเพลิงและท่อส่ง");
                        id.push("ถังเชื้อเพลิงและท่อส่ง");

                    }
                }else if(i == 16){
                    if(this.itemcheck.varssumcheck[16] == 0 ) {
                        // id.push("15. เครื่องล่าง");
                        id.push("เครื่องล่าง");

                    }
                }else if(i == 17){
                    if(this.itemcheck.varssumcheck[17] == 0 ) {
                        // id.push("16. สีรถ");
                        id.push("สีรถ");

                    }
                }else if(i == 18){
                    if(this.itemcheck.varssumcheck[18] == 0 ) {
                        // id.push("17. โครงสร้างและตัวถัง");
                        id.push("โครงสร้างและตัวถัง");

                    }
                }else if(i == 19){
                    if(this.itemcheck.varssumcheck[19] == 0 ) {
                        // id.push("18. ประตูและพื้นรถ");
                        id.push("ประตูและพื้นรถ");

                    }
                }else if(i == 20){
                    if(this.itemcheck.varssumcheck[20] == 0 ) {
                        // id.push("19. ขนาดของรถ");
                        id.push("ขนาดของรถ");

                    }
                }else if(i == 21){
                    if(this.itemcheck.varssumcheck[21] == 0 ) {
                        // id.push("20. ที่นั่งและจานวนที่นั่ง");
                        id.push("ที่นั่งและจานวนที่นั่ง");

                    }
                }else if(i == 22){
                    if(this.itemcheck.varssumcheck[22] == 0 ) {
                        // id.push("21. เข็มขัดนิรภัย");
                        id.push("เข็มขัดนิรภัย");

                    }
                }else if(i == 23){
                    if(this.itemcheck.varssumcheck[23] == 0 ) {
                        // id.push("22. อื่นๆ");
                        id.push("อื่นๆ");

                    }
                }
            }
            return id;
        }else{
            for(let i=4; i<this.itemcheck.varssumcheck.length; i++ ) {
                if(i == 0){
                    if(this.itemcheck.varssumcheck[0] == 0 ) {
                        // id.push("1.1 ผลต่าง");
                        id.push("ผลต่าง");
                    }
                }else if(i == 1){
                    if(this.itemcheck.varssumcheck[1] == 0 ) {
                        // id.push("1.2 ประสิทธิภาพห้ามล้อ");
                        id.push("ประสิทธิภาพห้ามล้อ");

                    }
                }else if(i == 2){
                    if(this.itemcheck.varssumcheck[2] == 0 ) {
                        // id.push("1.3 ประสิทธิภาพห้ามล้อมือ");
                        id.push("ประสิทธิภาพห้ามล้อมือ");

                    }
                }else if(i == 3){
                    if(this.itemcheck.varssumcheck[3] == 0 ) {
                        // id.push("2. ศูนย์ล้อ");
                        id.push("ศูนย์ล้อ");

                    }
                }else if(i == 4){
                    if(this.itemcheck.varssumcheck[4] == 0 ) {
                        // id.push("3. ระดับเสียง");
                        id.push("ระดับเสียง");

                    }
                }else if(i == 5){
                    if(this.itemcheck.varssumcheck[5] == 0 ) {
                        // id.push("4. ระบบไอเสีย");
                        id.push("ระบบไอเสีย");

                    }
                }else if(i == 6){
                    if(this.itemcheck.varssumcheck[6] == 0 ) {
                        // id.push("5. แตรสัญญาณ");
                        id.push("แตรสัญญาณ");

                    }
                }else if(i == 7){
                    if(this.itemcheck.varssumcheck[7] == 0 ) {
                        // id.push("6. มาตรวัดความเร็ว");
                        id.push("มาตรวัดความเร็ว");

                    }
                }else if(i == 8){
                    if(this.itemcheck.varssumcheck[8] == 0 ) {
                        // id.push("7. โคมไฟ");
                        id.push("โคมไฟ");

                    }
                }else if(i == 9){
                    if(this.itemcheck.varssumcheck[9] == 0 ) {
                        // id.push("8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");
                        id.push("โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");

                    }
                }else if(i == 10){
                    if(this.itemcheck.varssumcheck[10] == 0 ) {
                        // id.push("9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");
                        id.push("โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");

                    }
                }else if(i == 11){
                    if(this.itemcheck.varssumcheck[11] == 0 ) {
                        // id.push("10. เครื่องปัดน้ำฝน");
                        id.push("เครื่องปัดน้ำฝน");

                    }
                }else if(i == 12){
                    if(this.itemcheck.varssumcheck[12] == 0 ) {
                        // id.push("11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");
                        id.push("กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");

                    }
                }else if(i == 13){
                    if(this.itemcheck.varssumcheck[13] == 0 ) {
                        // id.push("12. ระบบบังคับเลี้ยวและพวงมาลัย");
                        id.push("ระบบบังคับเลี้ยวและพวงมาลัย");

                    }
                }else if(i == 14){
                    if(this.itemcheck.varssumcheck[14] == 0 ) {
                        // id.push("13. ล้อและยาง");
                        id.push("ล้อและยาง");

                    }
                }else if(i == 15){
                    if(this.itemcheck.varssumcheck[15] == 0 ) {
                        // id.push("14. ถังเชื้อเพลิงและท่อส่ง");
                        id.push("ถังเชื้อเพลิงและท่อส่ง");

                    }
                }else if(i == 16){
                    if(this.itemcheck.varssumcheck[16] == 0 ) {
                        // id.push("15. เครื่องล่าง");
                        id.push("เครื่องล่าง");

                    }
                }else if(i == 17){
                    if(this.itemcheck.varssumcheck[17] == 0 ) {
                        // id.push("16. สีรถ");
                        id.push("สีรถ");

                    }
                }else if(i == 18){
                    if(this.itemcheck.varssumcheck[18] == 0 ) {
                        // id.push("17. โครงสร้างและตัวถัง");
                        id.push("โครงสร้างและตัวถัง");

                    }
                }else if(i == 19){
                    if(this.itemcheck.varssumcheck[19] == 0 ) {
                        // id.push("18. ประตูและพื้นรถ");
                        id.push("ประตูและพื้นรถ");

                    }
                }else if(i == 20){
                    if(this.itemcheck.varssumcheck[20] == 0 ) {
                        // id.push("19. ขนาดของรถ");
                        id.push("ขนาดของรถ");

                    }
                }else if(i == 21){
                    if(this.itemcheck.varssumcheck[21] == 0 ) {
                        // id.push("20. ที่นั่งและจานวนที่นั่ง");
                        id.push("ที่นั่งและจานวนที่นั่ง");

                    }
                }else if(i == 22){
                    if(this.itemcheck.varssumcheck[22] == 0 ) {
                        // id.push("21. เข็มขัดนิรภัย");
                        id.push("เข็มขัดนิรภัย");

                    }
                }else if(i == 23){
                    if(this.itemcheck.varssumcheck[23] == 0 ) {
                        // id.push("22. อื่นๆ");
                        id.push("อื่นๆ");

                    }
                }
            }
            return id;
        }
    },
},
watch: {},
components:{},
beforeCreate(){
},
created(){
    this.getini();
    // this.getsh();
    this.getip();
    for(let i=0;i<=5;i++){
        this.itemcheck.varssumcheck[i] = 1;
    }
    for(let i=0;i<=4;i++){
        this.itemcheck_cohc.varssumcheck[i] = 1;
    }
    this.itemcheck.varssumcheck[6] = 1;
    this.itemcheck.varssumcheck[7] = 1;
    this.itemcheck.varssumcheck[8] = 1;
    for(let i=9;i<=23;i++){
        this.itemcheck.varssumcheck[i] = 1;
    }
    // time ticker
    setInterval(this.getNow, 1000);
},
beforeMount(){},
mounted(){},
beforeUpdate(){},
updated(){},
beforeDestroy(){},
destroyed(){},
}
</script>