import {createRouter, createWebHistory} from "vue-router"
import Body from '../components/body';
import { storeAuth } from "../store/modules/auth";
import axios from "../axios";
import Storage from 'vue-ls';

const options = {
  namespace: '', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

const { ls } = Storage.useStorage(options)

import Login from '../pages/frontend/Login.vue';
import Home from '../pages/frontend/Home.vue';
import Indexpost from '../pages/frontend/Indexpost.vue';
import Indexcomment from '../pages/frontend/Comment.vue';
import Register from '../pages/frontend/Register.vue';
import Dashboard from '../pages/backend/dashboardPage.vue';
import Alert from '../pages/backend/alertPages.vue';
import inspectionReport from '../pages/backend/inspectionReport.vue';
import datacarDetail from '../pages/backend/datacardetailPage.vue';
import datacarDetails from '../pages/backend/datacardetailPages.vue'
import datacarPdf from '../pages/backend/datacarpdfPage.vue';
import datacarPdfs from '../pages/backend/datacarpdfPages.vue';
import inspectionReportTruck from '../pages/backend/inspectionReportTruck.vue';
import datatruckDetail from '../pages/backend/datatruckdetailPage.vue'
import datatruckPdf from '../pages/backend/datatruckpdfPage.vue';
import statistics114Pages from '../pages/backend/statistics114Pages.vue';
import statistics124Pages from '../pages/backend/statistics124Pages.vue';
import faqPage from '../pages/backend/faqPage.vue';
import downloadPage from '../pages/backend/downloadPage.vue';
import editpasswordPage from '../pages/backend/editpasswordPages.vue';
import cctvPages from '../pages/backend/cctvPages.vue';
import toolsPages from '../pages/backend/toolsPages.vue';
import userTechnicianPages from '../pages/backend/userTechnicianPages.vue';
import punishmentPages from '../pages/backend/punishmentPages.vue';
import inspectionPages from '../pages/backend/inspectionPages.vue';
import userHWPages from '../pages/backend/userHWPages.vue';
import userTroPages from '../pages/backend/userTroPages.vue';
import settingPages from '../pages/backend/settingPages.vue';
import settingOCRcarPages from '../pages/backend/settingOCRcarPages.vue';
import settingOCRtruckPages from '../pages/backend/settingOCRtruckPages.vue';
import InspectionTruckPages from '../pages/backend/InspectionTruckPages.vue';
import InspectionCarPages from '../pages/backend/InspectionCarPages.vue';
import TestAI from '../pages/frontend/TestAiPagesModel10mb.vue';
import InspectionCarCheckRepeatPages from '../pages/backend/InspectionCarCheckRepeatPages.vue'
import InspectionCarRepeatPages from '../pages/backend/InspectionCarRepeatPages.vue'
import InspectionCar from '../pages/frontend/InspectionCar.vue'
import InspectionTruck from '../pages/frontend/InspectionTruck.vue'
import InspectionCarLink from '../pages/frontend/InspectionCarLink.vue'
import InspectionTruckLink from '../pages/frontend/InspectionTruckLink.vue'

const routes =[
  {
    path: '/',
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ',
        }
      },

    ]
  },
  {
    path: '/testai',
    children: [
      {
        path: '',
        name: 'TestAI',
        component: TestAI,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ทดสอบ AI',
        }
      },

    ]
  },
  {
    path: '/post/:id',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'indexpost',
        component: Indexpost,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข่าวสาร',
        }
      },

    ]
  },
  {
    path: '/comment',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'indexcomment',
        component: Indexcomment,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ร้องเรียนหรือเสนอแนะ',
        }
      },

    ]
  },
  {
    path: '/register',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'Register',
        component: Register,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ลงทะเบียนผู้ตรวจสภาพรถของสถานตรวจสภาพรถ',
        }
      },

    ]
  },
  {
    path: '/inspectioncar',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'InspectionCar',
        component: InspectionCar,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ตรวจสอบข้อมูลผลการตรวจสภาพรถตามกฎหมายว่าด้วยรถยนต์',
        }
      },

    ]
  },
  {
    path: '/inspectiontruck',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'InspectionTruck',
        component: InspectionTruck,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ตรวจสอบข้อมูลผลการตรวจสภาพรถตามกฎหมายว่าด้วยรถขนส่ง',
        }
      },

    ]
  },
  {
    path: '/inspectioncarlink/:id/:key/:type',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'InspectionCarLink',
        component: InspectionCarLink,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ตรวจสอบข้อมูลผลการตรวจสภาพรถตามกฎหมายว่าด้วยรถยนต์',
        }
      },

    ]
  },
  {
    path: '/inspectiontrucklink/:id/:key/:type',
    sensitive: true,
    children: [
      {
        path: '',
        name: 'InspectionTruckLink',
        component: InspectionTruckLink,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ตรวจสอบข้อมูลผลการตรวจสภาพรถตามกฎหมายว่าด้วยรถขนส่ง',
        }
      },

    ]
  },
  {
    path: '/user',
    component: Body,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | แดชบอร์ด',
          requiresAuth:true,
        }
      },
      {
        path: 'alert',
        name: 'alert',
        component: Alert,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข่าวสาร/การแจ้งเตือน',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreport',
        name: 'inspectionreport',
        component: inspectionReport,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'datacardetail/:id',
        name: 'datacarDetail',
        component: datacarDetail,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดข้อมูลการตรวจรถตามกฎหมายรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'datacardetails/:id',
        name: 'datacarDetails',
        component: datacarDetails,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดข้อมูลการตรวจรถตามกฎหมายรถยนต์ ที่ตรวจแก้',
          requiresAuth:true,
        }
      },
      {
        path: 'datacarpdf/:id',
        name: 'datacarPdf',
        component: datacarPdf,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์ไฟล์ PDF',
          requiresAuth:true,
        }
      },
      {
        path: 'datacarpdfs/:id',
        name: 'datacarPdfs',
        component: datacarPdfs,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถยนต์ไฟล์ PDF ที่ตรวจแก้',
          requiresAuth:true,
        }
      },
      {
        path: 'datatruckpdf/:id',
        name: 'datatruckPdf',
        component: datatruckPdf,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายว่ารถขนส่งไฟล์ PDF',
          requiresAuth:true,
        }
      },
      {
        path: 'datatruckdetail/:id',
        name: 'datatruckDetail',
        component: datatruckDetail,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายละเอียดข้อมูลการตรวจรถตามกฎหมายรถขนส่ง',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectionreporttruck',
        name: 'inspectionreporttruck',
        component: inspectionReportTruck,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลการตรวจรถตามกฎหมายรถขนส่ง',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics124',
        name: 'statistics124Pages',
        component: statistics124Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(แสดงข้อมูลจากเลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ)',
          requiresAuth:true,
        }
      },
      {
        path: 'statistics114',
        name: 'statistics114Pages',
        component: statistics114Pages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | สถิติการตรวจสภาพ(แสดงข้อมูลจากเลขที่ใบอนุญาตจัดตั้ง/ชื่อสถานตรวจสภาพรถ)',
          requiresAuth:true,
        }
      },
      {
        path: 'faq',
        name: 'faqPage',
        component: faqPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | วิธีการใช้งานโปรแกรม',
          requiresAuth:true,
        }
      },
      {
        path: 'download',
        name: 'downloadPage',
        component: downloadPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ดาวน์โหลด',
          requiresAuth:true,
        }
      },
      {
        path: 'editpassword',
        name: 'editpasswordPage',
        component: editpasswordPage,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | แก้ไขรหัสผ่าน',
          requiresAuth:true,
        }
      },
      {
        path: 'cctv',
        name: 'cctvPages',
        component: cctvPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลกล้องวงจรปิดของสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'tools',
        name: 'toolsPages',
        component: toolsPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลเครื่องตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'usertechnician',
        name: 'userTechnicianPages',
        component: userTechnicianPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการตรวจสอบผู้ตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'punishment',
        name: 'punishmentPages',
        component: punishmentPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการการลงโทษสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'inspection',
        name: 'inspectionPages',
        component: inspectionPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | รายการการตรวจสอบสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'hardware',
        name: 'userHWPages',
        component: userHWPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลอุปกรณ์ฮาร์ดแวร์ทั้งหมดที่ลงทะเบียน',
          requiresAuth:true,
        }
      },
      {
        path: 'usertro',
        name: 'userTroPages',
        component: userTroPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ข้อมูลสถานตรวจสภาพรถ',
          requiresAuth:true,
        }
      },
      {
        path: 'setting',
        name: 'settingPages',
        component: settingPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | กำหนดค่ามาตรฐาน',
          requiresAuth:true,
        }
      },
      {
        path: 'settingocrcar',
        name: 'settingOCRcarPages',
        component: settingOCRcarPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | กำหนดค่ามาตรฐาน',
          requiresAuth:true,
        }
      },
      {
        path: 'settingocrtruck',
        name: 'settingOCRtruckPages',
        component: settingOCRtruckPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | กำหนดค่ามาตรฐาน',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectioncar',
        name: 'InspectionCarPages',
        component: InspectionCarPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | เพิ่มรายงานผลการตรวจรถตามกฎหมายว่าด้วยรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectioncarcheckrepeat',
        name: 'InspectionCarCheckRepeatPages',
        component: InspectionCarCheckRepeatPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | ค้นหาผลการตรวจตามกฎหมายว่าด้วยรถยนต์ที่ยังผ่าน',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectioncarrepeat/:id',
        name: 'InspectionCarRepeatPages',
        component: InspectionCarRepeatPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | แก้ไขรายการตรวจรถตามกฎหมายว่าด้วยรถยนต์',
          requiresAuth:true,
        }
      },
      {
        path: 'inspectiontruck',
        name: 'InspectionTruckPages',
        component: InspectionTruckPages,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | เพิ่มรายงานผลการตรวจรถตามกฎหมายว่าด้วยรถขนส่ง',
          requiresAuth:true,
        }
      },
    ]
  },
  {
    path: '/auth',
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: 'ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ | เข้าสู่ระบบ',
        }
      },

    ]
  },
]
const router=createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(async (to, from, next) => {
try {
  document.title = to.meta.title;
  const authenticated = await is_authenticated();
  // check login 
  if (to.meta.requiresAuth && !authenticated.status) {
    return next('/auth/login');
  }
  if ((to.path === "/auth/login") && authenticated.status) {
    return next('/user/dashboard');
  }
  return next();
} catch (err) {
  console.log('server is down');
}
});

async function is_authenticated() {
// const v6Store = localStorage.getItem('v6')
const v6Store = ls.get('v6')

if(v6Store){
  try {
    const response = await axios.post("/authenticated",{
      token : v6Store
    }); 
    // const BaseUrlImages = 'http://192.168.1.237:8002/';
    const BaseUrlImages = 'https://api.dlt.in.th/';
    if(response.data.status == 1){
      storeAuth.id = response.data.datas.id;
      storeAuth.group_id = response.data.datas.group_id;
      storeAuth.first_name = response.data.datas.first_name;
      storeAuth.last_name = response.data.datas.last_name;
      storeAuth.county_id = response.data.datas.county_id;
      storeAuth.nameaddresscar = response.data.datas.nameaddresscar;
      storeAuth.smoke_setting = response.data.datas.smoke_setting;
      storeAuth.inspector_num = response.data.datas.inspector_num;
      storeAuth.licenseid = response.data.datas.licenseid;
      storeAuth.car_type = response.data.datas.car_type;
      storeAuth.license_number = response.data.datas.license_number;
      storeAuth.license_name = response.data.datas.license_name;
      // image car and truck
      storeAuth.UrlImagesCar = BaseUrlImages+'inspection/';
      storeAuth.UrlImagesTruck = BaseUrlImages+'inspection/truck/';
      // Popup
      storeAuth.UrlImagesPopup = BaseUrlImages+'popup/';
      // Tools
      storeAuth.UrlImagesTools = BaseUrlImages+'upload/inspectionsmanageequipments/';
      storeAuth.UrlFileTools = BaseUrlImages+'upload/inspectionsmanageequipments_file/';
      // UserTro
      storeAuth.UrlImagesTroOffice = BaseUrlImages+'upload/manageinspectioncenter/office/';
      storeAuth.UrlImagesTro = BaseUrlImages+'upload/manageinspectioncenter/tro/';
      storeAuth.UrlFileTro = BaseUrlImages+'upload/manageinspectioncenter_file/';
      // UserTech
      storeAuth.UrlImagesTechProfile = BaseUrlImages+'upload/manageusers/';
      storeAuth.UrlImagesTechCard = BaseUrlImages+'upload/manageusers/card/';
      storeAuth.UrlFileTech = BaseUrlImages+'upload/manageusers_file/';
      return{
        status : true,
      }
    }
  } catch (err) {
    return{
      status : false,
    }
  }
}else{
  return{
    status : false,
  }
}
}
export default router