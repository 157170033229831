
<template>

  <div class="container-fluid">
      <Breadcrumbs main="ข่าวประชาสัมพันธ์(ภายใน)"/>

      <div class="row project-cards">
          <div class="col-md-12">
            <!-- model search -->
            <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                      <div class="modal-header">
                          <b class="text-ptt-26">รายละเอียดข่าวประชาสัมพันธ์(ภายใน)"</b>
                          <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col">
                              <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >หัวข้อประกาศ</label >
                              <div class="col-sm-10">
                                {{ dataedit[0] }}
                              </div>
                            </div>
                            <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >ประกาศเมื่อ</label >
                              <div class="col-sm-10">
                                {{ dataedit[1] }}
                              </div>
                            </div>
                            <div class="mb-2 row">
                                <label class="col-sm-2 form-label text-right" >จังหวัด</label>
                                <div class="col-sm-10">
                                  {{ dataedit[2] }}
                                </div>
                              </div>
                            <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >รายละเอียด</label>
                              <div class="col-sm-10" v-html="dataedit[3]">
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div class="modal-footer">
                        <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end model search -->
              <div class="card">
                  <div class="card-body">
                      <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                          <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>ผู้ประกาศ</th>
                                <th>หัวข้อ</th>
                                <th>วันที่ประกาศ</th>
                                <th>จังหวัด</th>
                                <th>จัดการ</th>
                              </tr>
                          </thead>
                          <template #column-5="props">
                            <div class="btn-group">
                              <button @click="getviewdata(props.cellData)" type="button" class="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button>
                            </div>
                          </template>
                      </DataTable>
                  </div>
              </div>
          </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";
    
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
  modal_edit: null,
})
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Regular.ttf',
    bold: 'Sarabun-Medium.ttf',
    italics: 'Sarabun-Italic.ttf',
    bolditalics: 'Sarabun-MediumItalic.ttf'  
  }
};
doc.defaultStyle.font = "Sarabun";
var i = 1;
}

const datatable = ref([]);
const dataedit = ref([]);

onMounted(() => {
  getdata_table();
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`alert`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].first_name + " " + res.data.datas[i].last_name,res.data.datas[i].alert_name,new Date(res.data.datas[i].alert_date).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].names?res.data.datas[i].names:'ทุกจังหวัด',res.data.datas[i].id]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

async function getviewdata(id,data) {
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const resdata = await axios.post(`alert`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 4,
      datas: [id],
    });
    if(resdata.data.status == 1){
      console.log(resdata.data.datas[0])
          dataedit.value[0] = resdata.data.datas[0].alert_name
          dataedit.value[1] = new Date(resdata.data.datas[0].alert_date).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" )
          dataedit.value[2] = resdata.data.datas[0].county == 0?'ทุกจังหวัด':resdata.data.datas[0].county
          dataedit.value[3] = resdata.data.datas[0].alert_detail
          Swal.close();
          openModaledit()
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "แก้ไขข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  
}

const options = reactive({
dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
buttons: [
    {   
        extend: 'copyHtml5',
        text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
        titleAttr: 'Copy'
    },
    {
        extend: 'excelHtml5',
        text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
        titleAttr: 'Excel'
    },
    {
        extend: 'csvHtml5',
        text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
        titleAttr: 'CSV'
    },
    {
        extend: 'pdfHtml5',
        text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
        titleAttr: 'PDF',
        customize: function ( doc ) {
          processDoc(doc);
        }
    }
],
pageLength: 10,
responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
// select: {
//       style: 'multi',
//       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
//   },
lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
order: [[0, 'desc']],
language: dataTextTH,
columnDefs: [
      { width: '13%', targets: 0 },
      { width: '40%', targets: 2 },
      // {
      //     data: null,
      //     render: function (data) {
      //       return '';
      //     },
      //     targets: -1
      // },
  ]
});



</script>