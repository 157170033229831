<template>
  <div>
    <footer class="footer" :class="this.footer=='footer-dark'?'footer-dark':''">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">แสดงผลได้ดีใน <a class="_blank" style="color:red" href="https://www.mozilla.org/th/firefox/new/?gclid=CjwKEAjww_a8BRDB-O-OqZb_vRASJAA9yrc5tHYaVq1RnmMknAHY9hIRefb1Dnpk4HlkB5I8mRAj6xoCl3jw_wcB" target="_blank">Firefox</a> , <a style="color:#d6c000" class="_blank" href="https://www.google.com/intl/th/chrome/browser/" target="_blank">Chrome</a> , <a style="color:#0067b8" class="_blank" href="https://www.microsoft.com/th-th/edge" target="_blank">Internet Explorer(IE) เปลี่ยนเป็น EDGE แทน</a>  | ลิขสิทธิ์ {{ year }} © กรมการขนส่งทางบก </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import json from '../data/menu';
  import { mapGetters } from 'vuex'
  export default {
    name: 'footerpage',
    data() {
      return {
        customizer: false,
        data:
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(json)),
        year:new Date().getFullYear() ,
      };
    },
    computed:{
          ...mapGetters({
            footer: 'layout/footer'
          })
        }
  };
</script>
