
<template>

  <div class="container-fluid">
      <Breadcrumbs main="แก้ไขรหัสผ่าน"/>

      <div class="row starter-main">
        <div class="col-md-12">
          <div class="card">
              <div class="card-header">
                  <h5>กรุณากรอกข้อมูลให้ครบถ้วน ก่อนกดบันทึกข้อมูล</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="validationCustom01">รหัสผ่านเดิม</label>
                    <input class="form-control" id="validationCustom01" type="password" v-model="password">
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="validationCustom02">รหัสผ่านใหม่</label>
                    <input class="form-control" id="validationCustom02" type="password" v-model="newpassword">
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="validationCustomUsername">ยืนยันรหัสผ่านใหม่</label>
                    <input class="form-control" id="validationCustom02" type="password" v-model="comfirmpassword">
                  </div>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <div class="checkbox p-0">
                        <input class="form-check-input" id="invalidCheck" type="checkbox" v-model="confirmsetting">
                        <label class="form-check-label" for="invalidCheck">กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง</label>
                        </div>
                        <div class="invalid-feedback">กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง</div>
                    </div>
                </div>
                <button class="btn btn-primary" type="button" @click="savedata">บันทึกข้อมูล</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script setup>
import { ref,inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')

const password = ref();
const newpassword = ref();
const comfirmpassword = ref();
const confirmsetting = ref();

async function savedata() {

  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  if(!confirmsetting.value){
      Swal.fire({
          title: "คำเตือน",
          text: "กรุณาติ๊กถูกที่ \"กรุณากดยืนยันการแก้ไขข้อมูล ก่อนกดปุ่มบันทึกทุกครั้ง\" เพื่อยืนยันก่อน",
          icon: "warning"
      });
  }else{
      if(!password.value || !newpassword.value || !comfirmpassword.value){
          Swal.fire({
              title: "คำเตือน",
              text: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
              icon: "warning"
          });
      }else if(comfirmpassword.value != newpassword.value){
        Swal.fire({
              title: "คำเตือน",
              text: "ข้อมูลช่องรหัสใหม่ไม่เหมือนกับช่องยืนยันรหัสผ่านใหม่",
              icon: "warning"
          });
      }else{
          const res = await axios.post(`setting`,{
              uid: storeAuth.id,
              gid: storeAuth.group_id,
              tid: 5,
              password : password.value,
              newpassword : newpassword.value
          });
          if(res.data.status == 1){
              confirmsetting.value = '';
              Swal.close();
              Swal.fire({
                title: "สำเร็จ",
                text: "แก้ไขรหัสผ่านเรียบร้อยแล้ว กรุณาเข้าระบบใหม่อีกครั้ง ",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ตกลง"
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.clear();
                  window.location.href = '/';
                }
              });
          }else if(res.data.status == 2){
            Swal.fire({
                  title: "ไม่สำเร็จ",
                  text: "รหัสผ่านเดิมที่ใช้ยืนยันไม่ถูกต้อง",
                  icon: "error"
              }); 
          }else{
              Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  text: "บันทึกข้อมูลไม่สำเร็จ",
                  icon: "error"
              }); 
          }
      }
  }
}


</script>

<style>
</style>