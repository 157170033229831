<template>
  <li class="onhover-dropdown">
    <a class="text-dark" @click="FullScreen()" data-original-title="" title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize"><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path></svg></a>
    <!-- <div class="notification-box">
      <svg>
        <use href="@/assets/svg/icon-sprite.svg#notification" @click="notification_open()"></use>
      </svg>
      <span class="badge rounded-pill badge-secondary">4 </span>
    </div>
    <div
      class="onhover-show-div notification-dropdown"
      :class="{ active: notification }"
    >
      <h6 class="f-18 mb-0 dropdown-title">Notitications</h6>
      <ul>
        <li class="b-l-primary border-4">
          <p>Delivery processing <span class="font-danger">10 min.</span></p>
        </li>
        <li class="b-l-success border-4">
          <p>Order Complete<span class="font-success">1 hr</span></p>
        </li>
        <li class="b-l-info border-4">
          <p>Tickets Generated<span class="font-info">3 hr</span></p>
        </li>
        <li class="b-l-warning border-4">
          <p>Delivery Complete<span class="font-warning">6 hr</span></p>
        </li>
        <li><a class="f-w-700" href="#">Check all</a></li>
      </ul>
    </div> -->
  </li>
</template>

<script>

  export default {
    name: 'Notifications',
    data() {
      return {
        notification: false,
      };
    },
    methods: {
      // notification_open() {
      //   this.notification = !this.notification;
      // },
      FullScreen() {
          if ((document.fullScreenElement && document.fullScreenElement !== null) ||
              (!document.mozFullScreen && !document.webkitIsFullScreen)) {
              if (document.documentElement.requestFullScreen) {
                  document.documentElement.requestFullScreen();
              } else if (document.documentElement.mozRequestFullScreen) {
                  document.documentElement.mozRequestFullScreen();
              } else if (document.documentElement.webkitRequestFullScreen) {
                  document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
              }
          } else {
              if (document.cancelFullScreen) {
                  document.cancelFullScreen();
              } else if (document.mozCancelFullScreen) {
                  document.mozCancelFullScreen();
              } else if (document.webkitCancelFullScreen) {
                  document.webkitCancelFullScreen();
              }
          }
      }
    },
  };
</script>
