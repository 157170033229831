
<template>

    <div class="container-fluid">
        <Breadcrumbs main="วิธีการใช้งานโปรแกรมและคำถามที่พบบ่อย"/>

        <div class="container-fluid">
          <div class="faq-wrap">
            <div class="row">
              <div class="col-xl-4 xl-100 box-col-6">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="media faq-widgets">
                      <div class="media-body">
                        <h5>สรุปการใช้งาน</h5>
                        <p>ขั้นตอนการทำงานนั้น เริ่มจากการตั้งค่าข้อมูลตรวจสภาพรถ(ในครั้งแรก) > จากนั้นเข้าไปที่เมนูบันทึกข้อมูลเพื่อกรอกรายละเอียด รายงานผลการตรวจ > เช็คข้อมูลรายงานผลการตรวจสภาพย้อนหลังได้ที่เมนู "ข้อมูลการตรวจสภาพ".</p>
                      </div><i data-feather="check-square"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 xl-50 col-sm-6 box-col-6">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="media faq-widgets">
                      <div class="media-body">
                        <h5>คำเตือน</h5>
                        <p>การใช้งานทุกหน้าของระบบ V6 นั้นให้ท่านต้องคำนึงถึงข้อมูลและความถูกต้องของข้อมูลนั้นๆ เพื่อป้องกันการเกิดข้อผิดพลาดตามมา และหากเราตรวจพบข้อมูลที่เกิดการทุจริต ท่านจะมีความผิดตามกฎหมาย สามารถอ่านเพิ่มได้ที่ <a class="text-warning" href="https://www.dlt.go.th/th/dlt-anti-corruption/" target="_blank">dlt.go.th</a>.</p>
                      </div><i data-feather="alert-triangle"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 xl-50 col-sm-6 box-col-12">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="media faq-widgets">
                      <div class="media-body">
                        <h5>ข้อมูลการติดต่อผู้ดูแลระบบ</h5>
                        <p>ที่อยู่ : กรมการขนส่งทางบก 1032 ถนนพหลโยธิน แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900.<br>
                        โทรศัพท์ (หมายเลขกลาง) : 0-2271-8888 โทรสาร : 0-2271-8805 (เฉพาะวันเละเวลาราชการเท่านั้น)</p>
                      </div><i data-feather="phone-call"></i>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-12">
                <div class="header-faq">
                  <h5 class="mb-0">วิดีโอสอนการใช้งานต่างๆของโปรแกรม V6</h5>
                </div>
                <div class="row default-according style-1 faq-accordion" id="accordionocv1" style="font-size: 18px;">
                  <div class=" col-md-12">
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseiconv1" aria-expanded="false" aria-controls="collapseicon"><i data-feather="video"></i> วิดีโอสอนการตั้งค่าข้อมูลตรวจสภาพรถ โปรแกรม Inspection V6</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseiconv1" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                        <div class="card-body">
                          <div class="videoWrapper">
                            <iframe width="100%" src="https://www.youtube.com/embed/LHN1KUtYfew" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="header-faq">
                  <h5 class="mb-0">วิธีการใช้งานเมนูต่างๆ</h5>
                </div>
                <div class="row default-according style-1 faq-accordion" id="accordionoc" style="font-size: 18px;">
                  <div class=" col-md-12">
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon" aria-expanded="false" aria-controls="collapseicon"><i data-feather="clipboard"></i> เมนู "แดชบอร์ด"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon" aria-labelledby="collapseicon" data-bs-parent="#accordionoc">
                        <div class="card-body">เป็นเมนูที่สรุปรายงานผลการตรวจประจำวันโดยจะแยกตามกฎหมายว่าด้วยรถยนต์และรถขนส่งทางบก โดยจะแสดงรายการสุปเป็นระยะเวลา 7 วันล่าสุดที่ผ่านมา.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon2" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i> เมนู "ข่าวสาร/การแจ้งเตือน"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon2" data-bs-parent="#accordionoc">
                        <div class="card-body">เมนูแจ้งข่าวสารจากทางกรมการขนส่งทางบกและทางจังหวัดนั้นๆ ที่สถานตรวจสภาพรถของท่านได้ขึ้นทะเบียน.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon3" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "บันทึกข้อมูลตรวจสภาพ > รายงานผลการตรวจรถตามกฎหมายว่าด้วยรถยนต์"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon3" data-bs-parent="#accordionoc">
                        <div class="card-body">เมนูตรวจสภาพรถประเภท รย.1 รย.2 รย.3 รย.12 โดยการบันทึกข้อมูลจะต้องเป็นไปตามเงื่อนไขที่กำหนดเพื่อให้การตรวจนั้นมีสถานะที่ผ่าน โดยการดึงข้อมูลผลการตรวจนั้นจะเกี่ยวข้องกับเมนู "ตั้งค่าข้อมูลตรวจสภาพ"</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon4" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "บันทึกข้อมูลตรวจสภาพ > รายงานผลการตรวจรถยนต์(ที่ไม่ผ่าน)"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon4" data-bs-parent="#accordionoc">
                        <div class="card-body">หากรายงานผลการตรวจรถตามกฎหมายว่าด้วยรถยนต์เคยมีการตรวจมาแล้วภายใน 15 วันล่าสุดที่ผ่านมา แล้วมีผลการตรวจที่ไม่ผ่าน สามารถค้นหาข้อมูลเพื่อนำตรวจมาอีกครั้งโดยมีค่าใช้จ่ายครึ่งราคาจากปกติ *** รายตรวจแก้นั้นจะสามารถแก้ได้เฉพาะที่มีการตรวจก่อนหน้ามาจาก ตรอ. นั้นๆเท่านั้น จะไม่สามารถดึงรายการตรวจที่ไม่ผ่านของ ตรอ. อื่นมาตรวจใหม่ได้</div>
                      </div>
                    </div>
                        <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon5" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "บันทึกข้อมูลตรวจสภาพ > รายงานผลการตรวจรถตามกฎหมายว่าด้วยการขนส่งทางบก"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon5" data-bs-parent="#accordionoc">
                        <div class="card-body">เมนูตรวจสภาพรถประเภท รย.1 รย.2 รย.3 รย.12 โดยการบันทึกข้อมูงจะต้องเป็นไปตามเงื่อนไขที่กำหนดเพื่อให้การตรวจนั้นมีสถานะที่ผ่าน โดยการดึงข้อมูลผลการตรวจนั้นจะเกี่ยวข้องกับเมนู "ตั้งค่าข้อมูลตรวจสภาพ"</div>
                      </div>
                    </div>
                      <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon6" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "บันทึกข้อมูลตรวจสภาพ > รายงานผลการตรวจรถขนส่งทางบก(ที่ไม่ผ่าน)"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon6" data-bs-parent="#accordionoc">
                        <div class="card-body">หากรายงานผลการตรวจรถตามกฎหมายว่าด้วยการขนส่งทางบกเคยมีการตรวจมาแล้วภายใน 15 วันล่าสุดที่ผ่านมา แล้วมีผลการตรวจที่ไม่ผ่าน สามารถค้นหาข้อมูลเพื่อนำตรวจอีกครั้งโดยมีค่าใช้งานครึ่งนึงจากปกติ *** รายตรวจแก้นั้นจะสามารถแก้ได้เฉพาะที่มีการตรวจก่อนหน้ามาจาก ตรอ. นั้นๆเท่านั้น จะไม่สามารถดึงรายการตรวจที่ไม่ผ่านของ ตรอ. อื่นมาตรวจใหม่ได้</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon7" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลการตรวจสภาพ > รถตามกฎหมายรถยนต์"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon7" data-bs-parent="#accordionoc">
                        <div class="card-body">เป็นราบการตรวจข้อมูลของรถตามกฎหมายรถยนต์ 1000 รายการล่าสุดของวันที่ปัจจุบัน โดยสามารถค้นหารายการย้อนหลังได้ และยังสามารถดูและปริ้นรายละเอียดของรายการตรวจสภาพที่ต้องการย้อนหลังได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon8" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลการตรวจสภาพ > รถตามกฎหมายขนส่ง"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon8" data-bs-parent="#accordionoc">
                        <div class="card-body">เป็นรายการตรวจข้อมูลของรถตามกฎหมายว่าด้วยขนส่งทางบก 1000 รายการล่าสุดของวันที่ปัจจุบัน โดยสามารถค้นหารายการย้อนหลังได้ และยังสามารถดูและปริ้นรายละเอียดของรายการตรวจสภาพที่ต้องการย้อนหลังได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon15" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "สถิติการตรวจสภาพรถ > สถิติตามกฎหมายรถยนต์"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon15" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถเรียกดูสถิติของรายการตรวจสภาพรถตามกฎหมายรถยนต์ได้ โดยสามารถเลือกวันที่ ที่ท่านต้องการได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon16" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "สถิติการตรวจสภาพรถ > สถิติตามกฎหมายขนส่ง"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon16" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถเรียกดูสถิติของรายการตรวจสภาพรถตามกฎหมายขนส่งได้ โดยสามารถเลือกวันที่ ที่ท่านต้องการได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon10" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลผู้ตรวจสภาพรถ > ข้อมูลผู้ตรวจสภาพรถของสถานตรวจสภาพรถ"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon10" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถดูข้อมูลผู้ตรวจสภาพรถของสถานตรวจสภาพรถ ของสถานตรวจสภาพของท่านได้ที่เมนูนี้ หากมีข้อมูลที่ไม่ครบถ้วนหรือไม่ถูกต้อง ผ่านสามารถแจ้งมายังขนส่งในพื้นที่หรือเขตที่ดูแล เพื่อให้เพิ่มหรือแก้ไขข้อมูลได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon10" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลเครื่องตรวจสภาพรถ > ข้อมูลเครื่องตรวจสภาพรถของสถานตรวจสภาพรถ"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon10" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถดูข้อมูลเครื่องตรวจสภาพรถของสถานตรวจสภาพรถ ของสถานตรวจสภาพของท่านได้ที่เมนูนี้ หากมีข้อมูลที่ไม่ครบถ้วนหรือไม่ถูกต้อง ผ่านสามารถแจ้งมายังขนส่งในพื้นที่หรือเขตที่ดูแล เพื่อให้เพิ่มหรือแก้ไขข้อมูลได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon10" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลระบบกล้อง CCTV > แก้ไข/ลบ ข้อมูลระบบกล้อง CCTV"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon10" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถดูข้อมูลกล้อง CCTV ของสถานตรวจสภาพของท่านได้ที่เมนูนี้ หากมีข้อมูลที่ไม่ครบถ้วนหรือไม่ถูกต้อง ผ่านสามารถแจ้งมายังขนส่งในพื้นที่หรือเขตที่ดูแล เพื่อให้เพิ่มหรือแก้ไขข้อมูลได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon11" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ตั้งค่าข้อมูลตรวจสภาพ > กำหนดค่ามาตรฐาน"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon11" data-bs-parent="#accordionoc">
                        <div class="card-body">เป็นเมนูที่สำคัญสำหรับกำหนดค่าต่างๆใช้งาน ซึ่งจะมีผลกับ เมนู "บันทึกข้อมูลตรวจสภาพ > รายงานผลการตรวจรถตามกฎหมายว่าด้วยรถยนต์" หรือ เมนู "บันทึกข้อมูลตรวจสภาพ > รายงานผลการตรวจรถตามกฎหมายว่าด้วยรถขนส่ง" ท่านต้องกรอกข้อมูลตามรายการให้ถูกต้องและครบถ้วน ก่อนกดบันทุกข้อมูลทุกครั้ง.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon12" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ตั้งค่าข้อมูลตรวจสภาพ > กำหนดตำแหน่ง OCR"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon12" data-bs-parent="#accordionoc">
                        <div class="card-body">ในการตั้งค่าตำแหน่ง OCR นั้นท่านต้องคลิกในรายการที่ต้องการกำหนดค่า จากนั้นก็เลือกตำแหน่งที่ต้องการโดยสังเกตผลลัพธ์ที่ได้ในตำแหน่งนั้นๆ จากได้ค่าและตำแหน่งที่ต้องการแล้วก็ทำรายการต่อไปจนครบ ก็สามารถกดบันทึก้อมูลที่ปุ่มด้านล่างสุด เป็นอันเสร็จการตั้งค่าตำแหน่ง OCR หน้ารายงานสำหรับรถยนต์หรือรถขนส่ง.</div>
                      </div>
                    </div>
                    <!-- <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon13" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ตั้งค่าข้อมูลตรวจสภาพ > กำหนดตำแหน่งหน้ารายงานสำหรับรถขนส่ง"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon13" data-bs-parent="#accordionoc">
                        <div class="card-body">ในการตั้งค่าตำแหน่ง OCR นั้นท่านต้องคลิกในรายการที่ต้องการกำหนดค่า > จากนั้นก็เลือกตำแหน่งที่ต้องการโดยสังเกตผลลัพธ์ที่ได้ในตำแหน่งนั้นๆ จากได้ค่าและตำแหน่งที่ต้องการแล้วก็ทำรายการต่อไปจนครบ เป็นอันเสร็จการตั้งค่าตำแหน่ง OCR หน้ารายงานสำหรับรถขนส่ง.</div>
                      </div>
                    </div> -->
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon14" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="clipboard"></i>เมนู "ข้อมูลส่วนตัว > เปลี่ยนรหัสผ่าน"</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon14" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถเปลี่ยนรหัสผ่านการเข้าใช้งานของสถานตรวจสภาพรถของท่านได้ที่ ไม่สามารถเปลี่ยนรหัสผ่านของ "ผู้ควบคุมตรวจสภาพ" และ "เจ้าหน้าที่ตรวจสภาพ" ได้.</div>
                      </div>
                    </div>
                    <div class="faq-title">
                      <h6>คำถามที่พบบ่อย</h6>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon20" aria-expanded="false"><i data-feather="help-circle"></i> หากข้อมูลของสถานตรวจสภาพรถของท่านมีข้อผิดพลาดจะแก้ไขได้หรือไม่ ?</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon20" aria-labelledby="collapseicon20" data-bs-parent="#accordionoc">
                        <div class="card-body">หากท่านพบข้อผิดพลาดของข้อมูลต่างๆ ท่านสามารถติดต่อมายังขนส่งในพื้นที่ ที่กำกับดูแลอยู่เพื่อให้แก้ไข ข้อมูลนั้นๆได้.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon21" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="help-circle"></i> สามารถติดต่อผู้ดูแลได้จากช่องทางไหนบ้าง ?</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon21" data-bs-parent="#accordionoc">
                        <div class="card-body">การติดต่อมีหลายช่องทาง ดังนี้<br>
                          ช่องทางที่ 1 เบื้องต้นท่านสามารถติดต่อไปยังผู้ผลิตเครื่องตรวจสภาพรถในแต่ละยี่ห้อตามการใช้งาน นั้นๆก่อนได้ <br>
                          ช่องทางที่ 2 ท่านสามารถติดต่อไปยังสำนักงานขนส่งจังหวัดหรือเขตพื้นที่ ที่กำกับดูแล สถานตรวจสภาพของท่าน <br>
                          ช่องทางที่ 3 กรมการขนส่งทางบอกโดยตรงดังนี้ ที่อยู่ : กรมการขนส่งทางบก 1032 ถนนพหลโยธิน แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900.<br>
                          โทรศัพท์ (หมายเลขกลาง) : 0-2271-8888 โทรสาร : 0-2271-8805 (เฉพาะวันเละเวลาราชการเท่านั้น)<br>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon21" aria-expanded="false" aria-controls="collapseicon2"><i data-feather="help-circle"></i> การแสดงผลหน้าจอดูยาก ข้อความไม่เรียงกันในแถวเดียวกัน ช่องกรอกข้อมูลอยู่ผิดตำแหน่ง ?</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon21" data-bs-parent="#accordionoc">
                        <div class="card-body">ท่านสามารถปรับการแสดงผลหน้าจอได้ตามขนาดหน้าจอของเครื่องที่ใช้อยู่ได้โดยลักษณะและตำแหน่งต่างๆบนหน้าเว็บไซต์จะแสดงผลได้ดีที่ขนาดหน้าจอ 1680 x 1050 และ การ Zoom ของ เว็บเบราว์เซอร์ อยู่ที่ 90% .</div>
                      </div>
                    </div>
                    <div class="faq-title">
                      <h6>ข้อเสนอแนะ</h6>
                    </div>
                      <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon30" aria-expanded="false"><i data-feather="alert-circle"></i> ก่อนการใช้งานโปรแกรมบนเว็บไซต์ V6 ควรเปิดโปรแกรม inspection ที่เครื่องค้างไว้ทุกครั้ง</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon30" aria-labelledby="collapseicon30" data-bs-parent="#accordionoc">
                        <div class="card-body">เพื่อป้องกันความผิดพลาดในการทำงานของโปรแกรมท่านจำเป็นต้องเปิดโปรแกรม inspection(หน้าจอดำ) ที่เครื่องคอมพิวเตอร์ของท่านไว้ก่อนทุกครั้งโดยการดับเบิ้ลคลิกโปรแกรม inspection.exe ที่หน้าเดสก์ท็อป.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon31" aria-expanded="false"><i data-feather="alert-circle"></i> ในกรณีกดรับค่าจากการ OCR รูปค่าเบรคแล้วค่าไม่มา !</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon31" aria-labelledby="collapseicon31" data-bs-parent="#accordionoc">
                        <div class="card-body">ในบางครั้งรูปที่มีข้อมูลอยู่นั้นอาจจะทำให้การ OCR เพื่อดึงค่าไม่ทำงานท่านควรเข้าไปที่เมนู "ตั้งค่าข้อมูลตรวจสภาพ > กำหนดตำแหน่ง OCR" แล้วทำการตั้งค่าตำแหน่งค่าที่เกิดข้อผิดพลาดใหม่อีกครั้ง โดยสังเกตจากผลลัพธ์ตัวอย่างที่แสดง เมื่อได้ผลลัพธ์ที่ต้องการถูกต้องแล้วก็กลับไปดึงค่าที่หน้าตรวจสภาพใหม่อีกครั้ง.</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon32" aria-expanded="false"><i data-feather="alert-circle"></i> ระบบจะทำงานได้ดีเมื่อใช้เว็บเบราว์เซอร์ดังต่อไปนี้ !</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon32" aria-labelledby="collapseicon32" data-bs-parent="#accordionoc">
                        <div class="card-body">
                        Internet Explorer 9  ขึ้นไป หรือ<br>
                        <b class="text-warning">Firefox</b> เวอร์ชั่นล่าสุด >>> <a class="_blank" href="https://www.mozilla.org/th/firefox/new/?gclid=CjwKEAjww_a8BRDB-O-OqZb_vRASJAA9yrc5tHYaVq1RnmMknAHY9hIRefb1Dnpk4HlkB5I8mRAj6xoCl3jw_wcB" target="_blank">ดาวน์โหลดที่นี่</a> <br>
                        <b class="text-danger">Chrome</b> เวอร์ชั่นล่าสุด >>> <a class="_blank" href="https://www.google.com/intl/th/chrome/browser/" target="_blank">ดาวน์โหลดที่นี่</a>
                        </div>
                      </div>
                    </div>
                    <div class="faq-title">
                      <h6>วิธีการติดตั้งโปรแกรม Inspection</h6>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed collapsible" data-bs-toggle="collapse" data-bs-target="#collapseicon40" aria-expanded="false"><i data-feather="alert-circle"></i> หลังจากโหลดโปรแกรม Inspection จากเมนู ดาวน์โหลด แล้ว ขั้นตอนการติดตั้งมีดังต่อไปนี้ (หากในเครื่องคอมของท่านยังไม่มีโปรแกรมแก้ซิบ ให้ท่านโหลดโปรแกรม แก้ซิบได้ที่เมนู ดาว์โหลด และติดตั้งก่อน)</button>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseicon40" aria-labelledby="collapseicon40" data-bs-parent="#accordionoc">
                        <div class="card-body">
                        1. แตกซิพออกจะได้โฟล์เดอร์ที่ชื่อว่า release โดยจะมีโฟลเดอร์ย่อยที่ชื่อ Inspection และ Printer Path<br>
                        2. นำโฟลเดอร์ที่ชื่อ Inspection ไปไว้ในตำแหน่งใดของเครื่องคอมพิวเตอร์ของท่านได้ เพื่อให้ท่านสามารถกดเปิดโปรแกรมได้ง่าย (ระวังไฟล์ setting.conf ด้วยต้องอยู่ในโฟลเดอร์เดียวกับ Inspection.exe) <br>
                        3. <b class="text-info">(ในกรณีที่เครื่องรับค่าผลเบรคของสถานตรวจสาพของท่านส่งค่าเป็นรูปท่านต้องเตรียมไฟล์สำหรับ OCR)</b> นำโฟลเดอร์ที่ชื่อ Printer Path นำไปไว้ที่ไดร์ C และนำไฟล์รูปตัวอย่างรูปผลเบรคที่เครื่องสถานตรวจสภาพของท่านมีมาตั้งชื่อตามไฟล์ที่ชื่อไฟล์ที่ไปคื่อชื่อ CalibrateValue.tif , Capture.tif , Capture1.tif ตามลำดับเพื่อนำไปใช้ในหน้าตั้งค่าตำแหน่ง OCR รูปภาพ <br>
                        <b class="text-danger">*** เสร็จขั้นตอนการติดตั้งแล้ว ทุกครั้งที่ท่านจะเข้าใจงานโปรแกรม V6 บนเว็บไซต์นั้นท่านต้องเปิดโปรแกรมที่ชื่อว่า Inspection.exe ทิ้งไว้ก่อนทุกครั้ง ***</b>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

  </div>
    <!-- </div> -->
</template>

<script setup>

</script>