<script setup> 
import { ref,onMounted,inject } from 'vue';
import { useRoute } from 'vue-router';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";
import pdfMake from 'pdfmake';
import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js';
import htmlToPdfmake from "html-to-pdfmake";
import LogoimageLink from "../../assets/images/dlt/logo/v5-logo.png";
import QRCode from "qrcode";

const route = useRoute()
let datas = ref([]);
const Swal = inject('$swal')

onMounted( async () => {
  await getdata_table();
  createPDF();
})
async function getdata_table(){
  const res = await axios.post(`gettruckdetail`,{
    rid : route.params.id,
    uid : storeAuth.id,
    gid : storeAuth.group_id
  });
  if(res.data.status == 1){
    datas.value = res.data.datas[0];
  }
}

async function getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}

function formatCurrentDate() {
    const today = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const formattedDate = formatter.format(today);
    return formattedDate;
}


function typecar(id) {
  if(id == 1){
    return 'รถโดยสารมาตรฐาน ' + datas.value.CAR_TYPE_OPTION_1_1+datas.value.CAR_TYPE_OPTION_1_2;
  }else if(id == 2){
      return 'รถบรรทุก ลักษณะ ' + datas.value.CAR_TYPE_OPTION_2;
  }
}

async function createPDF() {
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  var imgge64 = '';
  await getBase64ImageFromUrl(LogoimageLink)
  .then(result => imgge64 = result)
  .catch(err => console.error(err));

  if(imgge64 && datas.value){
    let QRbase64 = await new Promise((resolve, reject) => {
        QRCode.toDataURL("https://v6inspection.dlt.go.th/inspectiontrucklink/" + datas.value.ID + "/" + datas.value.SECRET_KEY + "/3", function (err, code) {
            if (err) {
                reject(reject);
                return;
            }
            resolve(code);
        });
    });

    let daenext = datas.value.STATUS_CHECK == 0?'' : new Date(new Date(new Date(datas.value.SEND_DATETIME).setDate(new Date(datas.value.SEND_DATETIME).getDate() - 1)).setMonth(new Date(datas.value.SEND_DATETIME).getMonth() + 3)).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/')
    
    var htmlDetails = htmlToPdfmake(`
    <span style="text-align: center;font-size: 14px;">ส่วนที่ 1</span><img style="text-align: right;" src="${imgge64}" width="130" height="130">
    <div class="top">
      <span style="font-size: 16px;"><b>รายงานผลการตรวจสภาพรถตามกฎหมายว่าด้วยการขนส่งทางบก</b></span>
    </div>
    <div>
      <span><u class="texttop">เลขที่อ้างอิง</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>${datas.value.ID}</b></span>
    </div>
    <div class="texttop">
      วันเวลาที่ส่งผลการตรวจ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><b>${new Date(datas.value.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" )}</b></span>
    </div>
    <div class="texttop">
      ได้ตรวจสภาพจากสถานตรวจสภาพรถ&nbsp;&nbsp;&nbsp;&nbsp;<spa>${datas.value.TRO_NAME}</spa>
    </div>
    <div class="texttop">
      เลขที่ใบอนุญาตจัดตั้ง&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${datas.value.TRO_ID}</span>
    </div>
    <div class="texttop">
      หมายเลขทะเบียน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${datas.value.CAR_LIC_ID_CHAR}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${datas.value.CAR_LIC_ID_NUM}</span>
    </div>
    <div class="texttop">
      จังหวัด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${datas.value.names}</span>
    </div>
    <div class="texttop">
      ประเภท&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${typecar(datas.value.CAR_TYPE)}</span>
    </div>
    <div class="texttop">
      สรุปผลการตรวจสภาพรถ&nbsp;&nbsp;&nbsp;<span>${ datas.value.STATUS_CHECK == 0?'ไม่ผ่าน เพราะ ':'ผ่าน' }</span><span>${datas.value.FAILED_REASONS}</span>
    </div>
    <p>
      วันที่พิมพ์&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><b>${formatCurrentDate()}</b></span>
    </p>
    <span style="text-align: right;font-size: 14px;">สำหรับสถานตรวจสภาพรถ</span>
    ------------------------------------------------------------------------------------------------------------------------------
    <span style="font-size: 14px;text-align: center;">ส่วนที่ 2</span>
    <div style="text-align: right;font-size: 14px;" class="texthead2">สำหรับผู้นำรถเข้ารับการตรวจสภาพ</div>
    <img src='${QRbase64}' width="160" height="160"  style="text-align: right;">
    <div class="top2">
      <span style="font-size: 16px;"><b>รายงานผลการตรวจสภาพรถตามกฎหมายว่าด้วยการขนส่งทางบก</b></span>
    </div>
    <div>
      <span><u class="texttop">เลขที่อ้างอิง</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><b>${datas.value.ID}</b></span>
    </div>
    <div class="texttop">
      <span><u>รหัสผ่าน</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>${datas.value.SECRET_KEY}</b></span>
    </div>
    <div class="texttop">
      <span>วันเวลาที่ส่งผลการตรวจ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>${new Date(datas.value.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" )}</b></span>
    </div>
    <div class="texttop">
      <span>ได้ตรวจสภาพจากสถานตรวจสภาพรถ&nbsp;&nbsp;&nbsp;&nbsp;${datas.value.TRO_NAME}</span>
    </div>
    <div class="texttop">
      <span>เลขที่ใบอนุญาตจัดตั้ง&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${datas.value.TRO_ID}</span>
    </div>
    <div class="texttop">
      <span>หมายเลขทะเบียน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${datas.value.CAR_LIC_ID_CHAR}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${datas.value.CAR_LIC_ID_NUM}</span>
    </div>
    <div class="texttop">
      <span>จังหวัด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${datas.value.names}</span>
    </div>
    <div class="texttop">
      <span>ประเภท&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${typecar(datas.value.CAR_TYPE)}</span>
    </div>
    <div class="texttop">
      สรุปผลการตรวจสภาพรถ&nbsp;&nbsp;&nbsp<span>${ datas.value.STATUS_CHECK == 0?'ไม่ผ่าน เพราะ ':'ผ่าน' }</span><span>${datas.value.FAILED_REASONS}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${ datas.value.STATUS_CHECK == 0?'':'ผลการรับรองให้ใช้ได้จนถึงวันที่' } <span>${daenext}</span>
    </div>
    <span style="font-size: 12px;">
    <u><b>หมายเหตุ</b></u>
    <ul>
      <li>สามารถตรวจสอบข้อมูลทางออนไลน์ได้จาก<b style="font-size: 16px;"> https://v6inspection.dlt.go.th/inspectiontruck </b><br>โดยใช้เลขที่อ้างอิงและรหัสผ่าน หรือสแกน QR Code ด้านขวามือ</li>
      <li>กรณีตรวจสภาพรถ แล้วไม่สามารถชำระภาษีออนไลน์ได้ กรุณาสแกน QR Code ที่ใบรายงานผลการตรวจสภาพรถ หากพบว่า<br>ข้อมูล หมายเลขทะเบียน จังหวัดที่จดทะเบียน ประเภทรถ หมายเลขตัวถัง หมายเลขเครื่องยนต์ ไม่ถูกต้องให้นำรถเข้าตรวจ<br>สภาพกับ ตรอ. แห่งเดิมอีกครั้ง</li>
      <li>หากมีข้อขัดข้อง สอบถามหรือให้ช่วยดำเนินการติดต่อได้ที่ สำนักงานขนส่งพื้นที่/จังหวัด โทร. </span><b style="font-size: 16px;">${datas.value.phone} </b>หรือ กรณี<br>สำนักงานขนส่งพื้นที่/จังหวัด ไม่สามารถดำเนินการแก้ไขได้ ติดต่อสำนักวิศวกรรมยานยนต์ โทร. 0-2271-8613-14<br>หรือกรณีไม่สามารถชำระภาษีออนไลน์ได้ ติดต่อศูนย์เทคโนโลยีสารสนเทศ โทร. 0-2271-8420</li>
    </ul>
    </span>
    <p>วันที่พิมพ์&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><b>${formatCurrentDate()}</b></span></p>
    `,{
      tableAutoSize:true
    });

    const docDefinition = {
      content: [htmlDetails],
      styles:{
        'top':{
          marginTop: -100,
        },
        'headtext':{
          bold:true 
        },
        'texttop':{
          marginTop: -12,
        },
        'texthead2':{
          marginTop: -29,
        },
        'top2':{
          marginTop: -138,
        }
      },
      defaultStyle: {
        font: 'Sarabun'
      },
    }

    pdfMake.createPdf(docDefinition).open({}, window)
    Swal.close();
  }else{
    Swal.close();
  }
}
</script>
  