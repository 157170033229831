<template>
    <div>
  
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 p-0">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                  </a>
                  <h5 class="text-center mb-4">ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ</h5>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                      <div class="card-header text-center">
                        <h4>ร้องเรียนหรือเสนอแนะการทำงานได้ที่นี่</h4>
                        <small>กรุณากรอกข้อมูลให้ครบถ้วนก่อนกดปุ่ม "ส่งข้อความ"</small>
                      </div>
                      <div class="login-main"> 
                        <div class="theme-form"> 
                            <div class="form-group">
                                <label class="col-form-label pt-0">ข้อมูลผู้ส่ง</label>
                                    <div class="row g-2 mb-1">
                                    <div class="col-12">
                                        <input class="form-control" v-model="datas.created_name" type="text" required placeholder="ชื่อผู้ส่งข้อความ">
                                    </div>
                                </div>
                                <div class="row g-2">
                                    <div class="col-6">
                                        <input class="form-control" v-model="datas.created_tel" type="text" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required placeholder="เบอร์โทรผู้ส่งข้อความ">
                                    </div>
                                    <div class="col-6">
                                        <input class="form-control" v-model="datas.created_email" type="email" required placeholder="อีเมลผู้ส่งข้อความ">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">ข้อเสนอแนะหรือเรื่องที่ต้องการร้องเรียน</label>
                                <input class="form-control mb-1" v-model="datas.comment_title" type="text" required placeholder="หัวข้อ">
                                <select class="form-control mb-1" v-model="datas.comment_type" required>
                                    <option value="0">กรุณาเลือก</option>
                                    <option value="1">ข้อเสนอแนะ</option>
                                    <option value="2">ร้องเรียน</option>
                                </select>
                                <textarea class="form-control" v-model="datas.comment_detail" cols="30" rows="3" required></textarea>
                                <div class="col-sm-12 mt-2">
                                    <div class="ribbon-wrapper card">
                                        <div class="card-body">
                                            <div class="ribbon ribbon-clip ribbon-primary">เพิ่มเติม</div>
                                            <p>ขอให้ผู้ร้องเรียนอธิบายลำดับเหตุการณ์และข้อมูลสำคัญเพื่อประกอบการร้องเรียนให้ครบถ้วนชัดเจนเข้าใจง่าย เช่น หมายเลขโทรศัพท์ของผู้ร้อง สถานที่จัดส่งเอกสารถึงผู้ร้อง รายการเอกสารหลักฐานทั้งหมดของผู้ร้อง วันเวลาที่นำรถเข้ารับบริการ หมายเลขทะเบียนรถ เลขที่หนังสือรับรองการตรวจและทดสอบ ชื่อสถานที่ตรวจและทดสอบ เป็นต้น และทำหนังสือพร้อมแนบเอกสารพยานหลักฐานที่เกี่ยวข้อง จัดส่งถึงสำนักวิศวกรรมยานยนต์ กรมการขนส่งทางบก(สำนักวิศวกรรมยานยนต์ กรมการขนส่งทางบก 1032 ถนนพหลโยธิน แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900 <br> โทร 0 2271 8604).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0 text-center">
                                <div class="ccol d-flex justify-content-center mb-4">
                                    <RecaptchaV2
                                      @load-callback="handleLoadCallback"
                                    />
                                </div>
                                <button class="btn btn-primary" @click="addcomment">ส่งข้อความ</button>
                                <router-link to="/" class="btn btn-outline-secondary-2x ms-2" type="button" title="กลับสู้หน้าหลัก">กลับสู้หน้าหลัก</router-link>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { ref,inject } from 'vue';
  import axios from "../../axios";
  import { RecaptchaV2 } from "vue3-recaptcha-v2";

  const Swal = inject('$swal')

  const datas = ref({
    comment_type : 0,
  });
  let RecaptchaCheck = '';

  async function addcomment(){
    if(RecaptchaCheck){
      Swal.fire({
            title: 'ระบบกำลังส่งข้อมูล',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        Swal.showLoading()

        if(!datas.value.created_name){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกชื่อผู้ส่ง`,
              icon: "warning",
          });
        }else if(!datas.value.comment_detail){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกเนื้อหาที่ต้องการส่ง`,
              icon: "warning",
          });
        }else{
          let data = {
            datas : datas.value
          }
          const res = await axios.post(`comment`, data);
          if(res.data.status == 1){
            Swal.fire({
              title: "สำเร็จ",
              text: `ส่งข้อมูลเรียบร้อยแล้ว`,
              icon: "success",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "ตกลง",
            }).then((result) => {
              location.reload();
            });
          }else{
            Swal.fire({
                title: "ไม่สำเร็จ",
                text: `เกิดข้อผิดพลาดในการส่งข้อมูล`,
                icon: "error",
            });
          }
        }
    }else{
      Swal.fire({
          title: "คำเตือน",
          text: `กรุณาคลิกเพื่อยืนยันว่าคุณไม่ใช่บอท`,
          icon: "warning",
      });
    }
  }
  const handleLoadCallback = (response) => {
    RecaptchaCheck = response
  };

  </script>
  