
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลอุปกรณ์ฮาร์ดแวร์ทั้งหมดที่ลงทะเบียน"/>

        <div class="row project-cards">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="needs-validation">
                    <div class="row">
                        <div class="col-sm-12 text-center alert alert-danger dark alert-dismissible fade show" role="alert" >
                          <p> ตรวจสอบและลงทะเบียนกล้องถ่ายรูปและอุปกรณ์ฮาร์ดแวร์ที่เชื่อมกับระบบ V6</p>
                        </div>
                        <div v-show="isGetCamera" class="row justify-content-center">
                          <div class=" col-6">
                            <div class="input-group">
                              <select class="form-select" v-model="selectedcam">
                                  <option value="">เลือกไดร์กล้อง</option>
                                  <option v-for = "(opcam, index) in optionscam" :key="index" :value="opcam.value">
                                    {{ opcam.text }}
                                  </option>
                              </select>
                              <div class="input-group-append">
                                <button type="button" class="btn btn-outline-secondary" @click="stCamera">
                                    <span v-if="!isCameraOpen">ทดสอบกล้อง</span>
                                    <span v-else>ปิดกล้อง</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="text-center">
                            <div  v-show="isCameraOpen && isLoading" class="spinner-border text-primary mt-5" role="status">
                                <span class="sr-only">กำลังโหลดกล้อง...</span>
                            </div>
                            <div v-if="isCameraOpen" v-show="!isLoading" class="mt-3">
                                <video ref="camera" :width="320" :height="240" autoplay></video>
                            </div>
                          </div>
                        </div>
                        <div v-show="isGetCamera" class="col-md-12 text-center mt-3">

                              <button class="btn btn-pill btn-primary" @click="regcamera">ลงทะเบียนข้อมูลกล้อง</button>
                              <hr>
                        </div>
                      <div class="col-md-12 text-center mt-3">
                          <button class="btn btn-pill btn-warning btn-air-warning text-dark" @click="toggleCamera" type="button">กดเพื่อตรวจสอบกล้องถ่ายรูปรถที่เชื่อมกับระบบ</button>
                          <button class="btn btn-pill btn-info btn-air-info text-dark" @click="toggleHw" type="button">กดเพื่อตรวจสอบเครื่องคอมพิวเตอร์ที่เขื่อมกับระบบ</button>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row project-cards">
            <div class="col-md-12">
              <div class="modal fade" id="model-vw" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ดูข้อมูล</b>
                            <button type="button" @click="closeModalvw()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >หมายเลขอ้างอิง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[0]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >สถานตรวจสภาพ</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[1]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ประเภทฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[2]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >หมายเลขฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[3]" readonly>
                                </div>
                              </div>
                              <div v-if="datavw[9] == 1" class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ชื่อไดร์กล้อง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[4]" readonly>
                                </div>
                              </div>
                              <div v-if="datavw[9] == 1" class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >หมายเลขกลุ่มกล้อง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[5]" readonly>
                                </div>
                              </div>
                              <div v-if="datavw[9] == 1" class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ประเภทของไดร์กล้อง</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[6]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >ลงทะเบียนเมื่อ</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[7]" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label" >สถานะฮาร์ดแวร์</label>
                                <div class="col-sm-8" >
                                    <input type="text" class="form-control" :value="datavw[8]" readonly>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="closeModalvw()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>ชื่อสถานตรวจสภาพรภ</th>
                                <th>ประเภทฮาร์ดแวร์</th>
                                <th>ชื่อกล้อง</th>
                                <th>หมายเลขฮาร์ดแวร์</th>
                                <th class="none">ลงทะเบียนเมื่อ</th>
                                <th>สถานะ</th>
                                <th>จัดการ</th>
                              </tr>
                            </thead>
                            <template #column-7="props">
                              <div class="btn-group">
                                <button @click="getdata(props.cellData)" type="button" class="btn btn-info"><i class="fa fa-search"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_sh: null,
    modal_vw: null,
})

function openModalvw(){ state.modal_vw.show()}
function closeModalvw(){state.modal_vw.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const datavw = ref([]);
const datacamera = ref('');
const selectedcam = ref('');
const optionscam = ref([]);
const isCameraOpen = ref(false);
const isGetCamera = ref(false);
const isLoading = ref(false);
const camera = ref()

onMounted(() => {
  getdata_table();
  state.modal_vw = new Modal('#model-vw', {});
})

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`hw`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      let hwType = '';
      if(res.data.datas[i].hw_type == 1){ hwType = 'กล้อง'}else if(res.data.datas[i].hw_type == 2){hwType = 'ซีพียู(UUID)';}else if(res.data.datas[i].hw_type == 3){ hwType = 'ฮาร์ดดิสก์'}else if(res.data.datas[i].hw_type == 4){ hwType = 'ไบออส'}
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].license_number + ' / ' +res.data.datas[i].nameaddresscar,hwType,res.data.datas[i].hw_cam_lable,res.data.datas[i].hw_number,new Date(res.data.datas[i].entry_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].status == 2?'ยังไม่ได้รับอนุญาต':'ได้รับอนุญาตแล้ว',res.data.datas[i].id]);
    }
    Swal.close();
  }else{
    Swal.close()
  }
}

async function getdata(id) {
  datavw.value = [];
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  
  const resData = await axios.post(`hw`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 4,
    datas:[id]
  });
  if(resData.data.status == 1){
    datavw.value[0] = resData.data.datas[0].id;
    datavw.value[1] = resData.data.datas[0].license_number + ' / ' + resData.data.datas[0].nameaddresscar;
    if(resData.data.datas[0].hw_type == 1){
      datavw.value[2] = 'กล้อง';
    }else if(resData.data.datas[0].hw_type == 2){
      datavw.value[2] = 'ซีพียู(UUID)';
    }else if(resData.data.datas[0].hw_type == 3){
      datavw.value[2] = 'ฮาร์ดดิสก์';
    }else if(resData.data.datas[0].hw_type == 4){
      datavw.value[2] = 'ไบออส';
    }
    datavw.value[3] = resData.data.datas[0].hw_number;
    datavw.value[4] = resData.data.datas[0].hw_cam_lable;
    datavw.value[5] = resData.data.datas[0].hw_cam_number_group;
    datavw.value[6] = resData.data.datas[0].hw_cam_type;
    datavw.value[7] = new Date(resData.data.datas[0].entry_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" );
    datavw.value[8] = resData.data.datas[0].status == 1?'ได้รับอนุญาตแล้ว':'ยังไม่ได้รับอนุญาต';
    datavw.value[9] = resData.data.datas[0].hw_type;
    Swal.close();
    openModalvw()
  }else{
    Swal.close();
  }    
}


const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});


function toggleCamera() {
  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    const getCameraSelection = async () => {
      await navigator.mediaDevices.getUserMedia({video: true});  
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      datacamera.value = videoDevices;
      if(datacamera.value.length <= 0){
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ไม่พบข้อมูลไดร์กล้อง ลองตรวจอุปกรณ์กล้องว่ามีการเชื่อมต่อกับคอมพิวเตอร์เครื่องนี้หรือยัง จากนั้นกดปุ่ม \"กดเพื่อตรวจสอบกล้องถ่ายรูปรถที่เชื่อมกับระบบ\" อีกครั้ง",
          icon: "error"
        }); 
      }else{
        isGetCamera.value = true
        videoDevices.map(videoDevice => {
          optionscam.value.push({ text: videoDevice.label, value: videoDevice.deviceId })
        });
      }
    };
  
  getCameraSelection();

  }else{
    Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "เบราว์เซอร์ไม่รองรับการทำงานของฟังชั่น",
        icon: "error"
      }); 
  }
}
function stCamera(){
  if(isCameraOpen.value) {
      isCameraOpen.value = false;
      stopCameraStream();
  } else {
    if(!selectedcam.value){
      Swal.fire({
        title: "ตำเตือน",
        text: "ยังไม่ได้เลือกกล้องที่ต้องการทดสอบ",
        icon: "warning"
      });
    }else{
      isCameraOpen.value = true;
      createCameraElement();
    }
  }
}
function createCameraElement() {
  if(!selectedcam.value){
    Swal.fire({
        title: "ตำเตือน",
        text: "ยังไม่ได้เลือกกล้องที่ต้องการทดสอบ",
        icon: "warning"
      });
  }else{
    isLoading.value = true;
    if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: {deviceId: { exact: selectedcam.value }} })
        .then(stream => {
          isLoading.value = false;
          camera.value.srcObject = stream;
        })
        .catch(error => {
          isLoading.value = false;
          Swal.fire({
            title: "ตำเตือน",
            text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาเช็คอุปกรณ์รวมจุดเชื่อมต่อต่างๆของท่านว่าทำงานปกติหรือไม่ จากนั้นปิดโปรแกรมแล้วเปิดใหม่อีกครั้ง",
            icon: "error"
          });
        });
    }
  }
}
function stopCameraStream() {
let tracks = camera.value.srcObject.getTracks();
        tracks.forEach(track => {
            track.stop();
        });
}
function regcamera(){
  if(datacamera.value.length >= 1){
    const datas = datacamera.value.filter(device => device.deviceId == selectedcam.value);
    if(selectedcam.value){
      Swal.fire({
          title: 'ข้อมูลกล้อง',
          html: `รูปแบบไดร์ : ${datas[0].kind} <br> ชื่อไดร์ : ${datas[0].label} <br> ไอดีไดร์ : ${datas[0].deviceId} <br> ไอดีกลุ่มไดร์ : ${datas[0].groupId}`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'ลงทะเบียนข้อมูลกล้อง',
          cancelButtonText: 'ปิด',
      }).then((result) => {
          if (result.isConfirmed) {
            let datasi = {
              uid: storeAuth.id,
              gid: storeAuth.group_id,
              tid: 2,
              datas:[datas[0].kind,datas[0].label,datas[0].deviceId,datas[0].groupId]
            }
            axios.post(`hw`,datasi).then((rsci) => {
              if(rsci.data.status == 1){
                getdata_table();
                Swal.fire({
                  title: "สำเร็จ",
                  text: "ระบบบันทึกข้อมูลของท่ายเรียบร้อยแล้ว",
                  icon: "success"
                });
              }else if(rsci.data.status == 4){
                Swal.fire({
                  title: "ไม่สำเร็จ",
                  text: "มีข้อมูลของท่านถูกลงทะเบียนแล้ว (ซ้ำ)",
                  icon: "error"
                });
              }else{
                Swal.fire({
                  title: "ไม่สำเร็จ",
                  text: "เกิดช้อผิดพลาด",
                  icon: "error"
                });
              }
            });
          }
      })
    }else{
      Swal.fire({
        title: "ตำเตือน",
        text: "ยังไม่ได้เลือกกล้องที่ต้องการลงทะเบียนก่อน",
        icon: "warning"
      });
    }
  }else{
    Swal.fire({
      title: "ตำเตือน",
      text: "ยังไม่ได้เลือกกล้องที่ต้องการลงทะเบียนก่อน",
      icon: "warning"
    });
  }
  
}
async function toggleHw() {
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    fetch('http://127.0.0.1:8080/uuid', {
      method: "GET"
    })
    .then((response) => {
      response.json().then((data) => {
        let datas = {
          uid: storeAuth.id,
          gid: storeAuth.group_id,
          tid: 31,
          datas:[data.uuid]
        }
        axios.post(`hw`,datas).then((rsc) => {
          if(rsc.data.status == 1){
            if(rsc.data.datas.length <= 0){
              Swal.fire({
                title: "ไม่สำเร็จ",
                text: "เรียกข้อมูลไม่ได้",
                icon: "error"
              });
            }else{
              let datastype = '';
              for (let index = 0; index < rsc.data.datas.length; index++) {
                if(rsc.data.datas[index][0] == 2){
                  datastype += `UUID : ${rsc.data.datas[index][1]}` ; 
                }else if(rsc.data.datas[index][0] == 3){
                  datastype += `<br> HDD : ${rsc.data.datas[index][1]}` ; 
                }else if(rsc.data.datas[index][0] == 4){
                  datastype += `<br> BIOS : ${rsc.data.datas[index][1]}` ; 
                }
              }
              Swal.fire({
                    title: 'ข้อมูลฮาร์ดแวร์ของเครื่อง',
                    html: `${datastype}`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'ลงทะเบียนข้อมูลฮาร์ดแวร์',
                    cancelButtonText: 'ปิด',
                }).then((result) => {
                    if (result.isConfirmed) {
                      let datasi = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 32,
                        datas:rsc.data.datas
                      }
                      axios.post(`hw`,datasi).then((rsci) => {
                        if(rsci.data.status == 1){
                          getdata_table();
                          Swal.fire({
                            title: "สำเร็จ",
                            text: "ระบบบันทึกข้อมูลของท่ายเรียบร้อยแล้ว",
                            icon: "success"
                          });
                        }else if(rsci.data.status == 4){
                          Swal.fire({
                            title: "ไม่สำเร็จ",
                            text: "มีข้อมูลของท่านถูกลงทะเบียนแล้ว (ซ้ำ)",
                            icon: "error"
                          });
                        }else{
                          Swal.fire({
                            title: "ไม่สำเร็จ",
                            text: "เกิดช้อผิดพลาด",
                            icon: "error"
                          });
                        }
                      });
                    }
                })
            }
          }else{
            Swal.fire({
              title: "ไม่สำเร็จ",
              text: "เกิดช้อผิดพลาด",
              icon: "error"
            });
          }
        });
      
      });
    })
    .catch((err) => {
        Swal.fire({
          title: "ไม่สำเร็จ",
          text: "เรียกข้อมูลไม่ได้",
          icon: "error"
        });
    });
}

</script>