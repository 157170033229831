
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลการตรวจรถตามกฎหมายรถยนต์"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="text-end">
                            <button @click="getsh()" class="btn btn-primary"><i class="fa fa-search"></i> ค้นหา</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model data log -->
              <div v-if="datas_log" class="modal fade" id="model-dl" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">รายการตรวจสภาพรถก่อนหน้านี้ของทะเบียน {{ datas_log.tb  }}</b>
                            <button type="button" @click="closeModaldl()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col"> 
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center">จำนวนครั้งที่ตรวจ</th>
                                    <th scope="col">วันที่ / เวลา</th>
                                    <th scope="col">ผลการตรวจ</th>
                                    <th scope="col">จัดการ</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th colspan="4" class="text-center text-info">รายงานการตรวจหลัก</th>
                                  </tr>
                                  <tr>
                                    <th scope="row" class="text-center">{{ datas_log.dt?datas_log.dt.ID:'' }}</th>
                                    <td>{{ datas_log.dt?new Date(datas_log.dt.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ):'' }}</td>
                                    <td>{{ datas_log.dt?datas_log.dt.STATUS_CHECK == 0? "ไม่ผ่าน" : "ผ่าน":'' }}</td>
                                    <td>
                                      <div class="m-b-30">
                                          <div class="btn-group">
                                            <a :href="datas_log.dt?'/user/datacardetail/'+datas_log.dt.ID:''" target="_blank" class="btn btn-success" title="ดูรายละเอียดรายกาารตรวจสภาพรถ"><i class="fa fa-search"></i></a>
                                            <a :href="datas_log.dt?'/user/datacarpdf/'+datas_log.dt.ID:''" target="_blank" class="btn btn-info" data-bs-original-title="" title="ปริ้นหนังสือรับรองผลกาารตรวจสภาพรถ"><i class="fa fa-print"></i></a>
                                          </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th colspan="4" class="text-center text-warning">รายงานการตรวจซ้ำทั้งหมด</th>
                                  </tr>
                                  <tr v-for="(datalg,i) in datas_log.dl" :key="i">
                                    <th scope="row" class="text-center">{{ datalg.ID }}</th>
                                    <td>{{ datalg.SEND_DATETIME?new Date(datalg.SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ):'' }}</td>
                                    <td>{{ datalg.STATUS_CHECK == 0? "ไม่ผ่าน": "ผ่าน" }}</td>
                                    <td>
                                      <div class="m-b-30">
                                          <div class="btn-group">
                                              <a :href="'/user/datacardetails/'+datalg.ID" target="_blank" class="btn btn-success" data-bs-original-title="" title="ดูรายละเอียดรายกาารตรวจสภาพรถ"><i class="fa fa-search"></i></a>
                                              <a :href="'/user/datacarpdfs/'+datalg.ID" target="_blank" class="btn btn-info" data-bs-original-title="" title="ปริ้นหนังสือรับรองผลกาารตรวจสภาพรถ"><i class="fa fa-print"></i></a>
                                          </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                                
                            </div>
                        </div>
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="closeModaldl()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model data log -->
              <!-- model search -->
              <div class="modal fade" id="model-sh" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ค้นหาข้อมูลเพิ่มเติม</b>
                            <button type="button" @click="closeModalsh()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col"> 
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >วันที่ส่งข้อมูลเข้าระบบ</label>
                                    <div class="col-sm-8">
                                        <VueDatePicker locale="th" v-model="searchdatefrom" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label" >ถึงวันที่</label>
                                    <div class="col-sm-8">
                                        <VueDatePicker locale="th" v-model="searchdateto" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                                    </div>
                                </div>
                                
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >อักษรเลขทะเบียน</label>
                                    <div class="col-sm-8">
                                      <input v-model='searchid' class="form-control" type="text" maxlength="30">
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >หมายเลขเลขทะเบียน</label>
                                    <div class="col-sm-8">
                                    <input v-model='searchnum' class="form-control" type="text" maxlength="4">
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >จังหวัด</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="searchcounty_value" :searchable="true" v-model="searchcounty" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <hr>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >สถานะการตรวจ</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="status_check_value" :searchable="true" v-model="status_check" :custom-label="status_check_select" placeholder="เลือกสถานะการตรวจ" />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right">สถานะการส่งข้อมูลเข้า MDM</label>
                                    <div class="col-sm-8">
                                        <multiselect :options="status_check_sent_value" :searchable="true" v-model="status_check_sent" :custom-label="status_check_sent_select" placeholder="เลือกสถานะการส่งข้อมูลเข้า MDM"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-primary" role="alert">
                            <p><b>หมายเหตุ</b></p>
                            <p>- ในการค้นหาสามารถกรอกอ้างใดอ้างหนึ่งก็ได้ยกเว้นวันที่ส่งข้อมูลเข้าระบบ หากต้องการค้นหาวันช่วงวันที่ต้องเลือกวันที่ทั้ง 2 ช่อง</p>
                        </div>
                            
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="search()" class="btn btn-primary">ค้นหาข้อมูล</button>
                            <button type="button" @click="closeModalsh()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                    <th>ลำดับการตรวจ</th>
                                    <th>เลขทะเบียน</th>
                                    <th>ผลการตรวจ</th>
                                    <th>จำนวนครั้งที่ตรวจ</th> 
                                    <th>เวลาที่ส่งรายงาน</th>
                                    <th>เลขที่ใบอนุญาต</th>
                                    <th>ชื่อ สถานตรวจสภาพรถ</th>
                                    <th>รูปภาพ</th>  
                                    <th>สถานะการส่งข้อมูล</th>
                                    <th>จัดการ</th>
                                </tr>
                            </thead>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_sh: null,
    modal_dl: null,
})
function openModalsh(){ state.modal_sh.show()}
function closeModalsh(){state.modal_sh.hide()}
function openModaldl(){ state.modal_dl.show()}
function closeModaldl(){state.modal_dl.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const searchdatefrom = ref('');
const searchdateto = ref('');
const searchid = ref('');
const searchnum = ref('');
const searchcounty = ref('');
const status_check = ref('');
const status_check_sent = ref('');
const dataselect = ref();
const datas_log = ref({
  tb:'',
  dt:{},
  dl:[]
});

const status_check_value = ref([{	value: '1', name: 'ผ่าน' },{ value: '2', name: 'ไม่ผ่าน' }])
function status_check_select({name}) {return `${name}`}
const status_check_sent_value = ref([{	value: '0', name: 'ส่งข้อมูลแล้ว' },{ value: '1', name: 'ส่งข้อมูลไม่สำเร็จ' },{ value: '2', name: 'ยังไม่ส่งข้อมูล(รอ)' }])
function status_check_sent_select({name}) {return `${name}`}

const searchcounty_value = ref([{id:'',names:''}]);
function searchcounty_select({names}) {return `${names}`}

async function getsh(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`getdatash`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      cid: storeAuth.county_id
    });
    if(res.data.status == 1){
      searchcounty_value.value = res.data.datas.datact;
      Swal.close()
      openModalsh()
    }
}


onMounted(() => {
  getdata_table();
  state.modal_sh = new Modal('#model-sh', {});
  state.modal_dl = new Modal('#model-dl', {});
  dataselect.value.dt.on('click', 'button', function (e) {
    let data = dataselect.value.dt.row(e.target.closest('tr')).data();
    get_data_log(data)
  })
})

async function get_data_log(data){
  datas_log.value = {tb:'',dt:{},dl:[]};
  const res = await axios.post(`inspectionreportlog`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    dtid: data[0]
  });
  if(res.data.status == 1){
    datas_log.value.tb = data[1];
    datas_log.value.dt = res.data.datadt[0];
    datas_log.value.dl = res.data.datadl;
    // console.log(datas_log.value.dt)
  openModaldl()
  }
}

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`inspectionreport`,{
    uid: storeAuth.id, 
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {

      let send_mdm_status = '';
      if(res.data.datas[i].IS_SENT == 0 || res.data.datas[i].mdmerror == 'again'){
        send_mdm_status = "ส่งข้อมูลแล้ว";
      }else if(res.data.datas[i].IS_SENT == 1 && res.data.datas[i].CRON_JOB_STATUS == 1 && res.data.datas[i].mdmerror != 'again'){
        send_mdm_status = "ส่งข้อมูลไม่สำเร็จ";
      }else{
        send_mdm_status = "ยังไม่ส่งข้อมูล(รอ)";
      }

      datatable.value.push([res.data.datas[i].ID,res.data.datas[i].CAR_LIC_ID_CHAR + ' ' + res.data.datas[i].CAR_LIC_ID_NUM + ' ' +res.data.datas[i].names,res.data.datas[i].STATUS_CHECK == 1?"ผ่าน":'ไม่ผ่าน',res.data.datas[i].CHECK_NUMBER,new Date(res.data.datas[i].SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].TRO_ID,res.data.datas[i].TRO_NAME,
      `<a href="${storeAuth.UrlImagesCar}${res.data.datas[i].PIC_CAR}" target="_blank"><img src="${storeAuth.UrlImagesCar}${res.data.datas[i].PIC_CAR}" width="58"></a>`,
      send_mdm_status
    ]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'DESC']],
  language: dataTextTH,
  columnDefs: [
        // { width: '12%', targets: 0 },
        { width: '18%', targets: 1 },
        {
            data: null,
            render: function (data) {
              return '<div class="btn-group"><a href="/user/datacardetail/'+ data[0] +'" target="_blank" class="btn btn-success"><i class="fa fa-search"></i></a><a href="/user/datacarpdf/'+ data[0] +'" target="_blank" class="btn btn-primary" data-bs-original-title="" title=""><i class="fa fa-print"></i></a></div>';
            },
            targets: -1
        },
        {
          data: null,
            render: function (data) {
              if(data[3] > 1){
                return "<button type=\"button\" class=\"btn btn-info\">"+ data[3] +"</button>";
              }else{
                return data[3];
              }
            },
            targets: 3
        }
    ]
});

async function search(){

  if(!searchdatefrom.value && !searchdateto.value && !searchid.value && !searchnum.value && !searchcounty.value && !status_check.value && !status_check_sent.value){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณเลือกหรือกรอกข้อมูลที่ต้องการค้นหาเพิ่มเติมก่อน",
      icon: "warning"
    });
  }else if(!searchdatefrom.value && searchdateto.value){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกวันที่เพิ่มข้อมูล(เริ่มต้น)ด้วย",
      icon: "warning"
    });
  }else{

    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    let searchdatefroms = '';
    let searchdatetos = '';
    if(searchdatefrom.value){
      const days = searchdatefrom.value.getDate() <= 9?'0'+searchdatefrom.value.getDate():searchdatefrom.value.getDate();
      const months = searchdatefrom.value.getMonth() + 1;
      const years = searchdatefrom.value.getFullYear();
      searchdatefroms = `${years}-${months <= 9?'0'+months:months}-${days}`;
    }
    if(searchdateto.value){
      const dayt = searchdateto.value.getDate() <= 9?'0'+searchdateto.value.getDate():searchdateto.value.getDate();
      const montht = searchdateto.value.getMonth() + 1;
      const yeart = searchdateto.value.getFullYear();
      searchdatetos = `${yeart}-${montht <= 9?'0'+montht:montht}-${dayt}`;
    }

    const res = await axios.post(`inspectionreportsh`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      s1: searchdatefroms,
      s2: searchdatetos,
      s3: searchid.value,
      s4: searchnum.value,
      s5: searchcounty.value.id,
      s6: status_check.value.value,
      s10: status_check_sent.value.value,
    });
    if(res.data.status == 1){
      datatable.value = [];
      for (let i = 0; i < res.data.datas.length; i++) {

        let send_mdm_status = '';
        if(res.data.datas[i].IS_SENT == 0 || res.data.datas[i].mdmerror == 'again'){
          send_mdm_status = "ส่งข้อมูลแล้ว";
        }else if(res.data.datas[i].IS_SENT == 1 && res.data.datas[i].CRON_JOB_STATUS == 1 && res.data.datas[i].mdmerror != 'again'){
          send_mdm_status = "ส่งข้อมูลไม่สำเร็จ";
        }else{
          send_mdm_status = "ยังไม่ส่งข้อมูล(รอ)";
        }

        datatable.value.push([res.data.datas[i].ID,res.data.datas[i].CAR_LIC_ID_CHAR + ' ' + res.data.datas[i].CAR_LIC_ID_NUM + ' ' +res.data.datas[i].names,res.data.datas[i].STATUS_CHECK == 1?"ผ่าน":'ไม่ผ่าน',res.data.datas[i].CHECK_NUMBER,new Date(res.data.datas[i].SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].TRO_ID,res.data.datas[i].TRO_NAME,
        `<a href="${storeAuth.UrlImagesCar}${res.data.datas[i].PIC_CAR}" target="_blank"><img src="${storeAuth.UrlImagesCar}${res.data.datas[i].PIC_CAR}" width="58"></a>`,
        send_mdm_status
      ]);
      }
      Swal.close()
      closeModalsh()
    }
  }
}

</script>