<template>
    <div>
  
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 p-0">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                  </a>
                  <h5 class="text-center mb-4">ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ</h5>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                      <div class="card-header text-center">
                        <u>ประชาสัมพันธ์ หรือข้อมูลข่าวสารต่างๆ ที่เกี่ยวข้องกับการตรวจสภาพรถ สำหรับประชาชนทั่วไป</u>
                      </div>
                      <div class="card-body">
                          <div class="item">
                            <div class="table-responsive product-list">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th class="text-center">โพสเมื่อ</th>
                                    <th class="text-center">หัวข้อ</th>
                                    <th class="text-center">เปิดอ่าน</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(datatables,i) in datatable" :key="i">
                                        <td class="text-center">{{ datatables[0] }}</td>
                                        <td >{{ datatables[1] }}</td>
                                        <td class="text-center"><RouterLink :to="'/post/'+datatables[2]"><i class="fa fa-search"></i> อ่านเพิ่มเติม</RouterLink></td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="btn-showcase text-center">
                      <router-link to="/inspectioncar" class="btn btn-info" type="button" title="เช็คข้อมูลผลการตรวจและทดสอบ">เช็คข้อมูลผลการตรวจรถว่าด้วยรถยนต์</router-link>
                      <router-link to="/inspectiontruck"  class="btn btn-info" type="button" title="เช็คข้อมูลผลการตรวจและทดสอบ">เช็คข้อมูลผลการตรวจรถว่าด้วยการขนส่งทางบก</router-link>
                      <router-link to="/comment" class="btn btn-secondary" type="button" title="ร้องเรียนหรือมีข้อเสนอแนะ">ร้องเรียนหรือมีข้อเสนอแนะ</router-link>
                      <router-link to="/auth/login" class="btn btn-outline-primary-2x" type="button" title="เข้าสู่ระบบ">เข้าสู่ระบบ</router-link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { ref,onMounted } from 'vue';
  import axios from "../../axios";

  const datatable = ref([]);

  onMounted(() => {
    getdata_table();
  })

  async function getdata_table(){
    const res = await axios.post(`indexpost`);
    if(res.data.status == 1){
      if(res.data.datas){
        for (let i = 0; i < res.data.datas.length; i++) {
          datatable.value.push([new Date(res.data.datas[i].created_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].post_title,res.data.datas[i].id]);
        }
      }
    }
  }

  </script>
  