
<template>
    <div class="container-fluid">
      <Breadcrumbs main="แก้ไขรายการตรวจรถตามกฎหมายว่าด้วยรถยนต์"/>

      <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
            <div class="card">
                <div class="card-header text-center ">
                    <div v-if="!page_status" class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>คำแนะนำ ! </strong> กรูณาเปิดโปรแกรม Inspection ก่อน หลังจากนั้น <a href="javascript:window.location.reload(true)" class="text-light">รีเฟรชหน้าเว็บไซต์</a> ใหม่อีกครั้ง <a href="javascript:window.location.reload(true)" class="btn btn-warning text-dark">หรือกดที่นี่</a></div>
                    <h3>การตรวจสภาพครั้งที่ {{check_num}} ของทะเบียน {{car_lic_id_char}} - {{car_lic_id_num}} {{ c_names }} ประเภทรถ รย.{{typecar}}</h3>
                </div>
                <div class="needs-validation">
                <div class="card-body">
                    <div class="row">
                    <div class="col">
                        <div class="mb-2 row">
                            <div class="col-sm-12 text-center web-camera-container">
                                <div v-show="openCameras" class="camera-button">
                                    <button type="button" class="btn" :class="{ 'btn-primary' : !isCameraOpen, 'btn-danger' : isCameraOpen}" @click="toggleCamera">
                                        <span v-if="!isCameraOpen">เปิดกล้อง</span>
                                        <span v-else>ปิดกล้อง</span>
                                    </button>
                                </div>
                                <div  v-show="isCameraOpen && isLoading" class="spinner-border text-primary mt-5" role="status">
                                    <span class="sr-only">กำลังโหลดกล้อง...</span>
                                </div>
                                <div v-if="isCameraOpen" v-show="!isLoading" class="mt-3" :class="{ 'flash' : isShotPhoto }">

                                    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>

                                    <video v-show="!isPhotoTaken" ref="camera" :class="{ 'camera-box' : camera_setting_turn }" :width="640" :height="480" autoplay></video>
                                    <canvas v-show="isPhotoTaken && !imageview" id="photoTaken" ref="canvas" width="640" height="480"></canvas>
                                    <img v-show="imageview" :src="imageview" width="640" height="480" id="imageviews" />
                                </div>

                                <div v-if="isCameraOpen && !isLoading" class="mt-3">
                                    <button  v-show="!isPhotoTaken" type="button" class="btn btn-primary" @click="takePhoto">ถ่ายภาพ</button>
                                    <button  v-show="isPhotoTaken" type="button" class="btn btn-primary" @click="takePhoto">ถ่ายภาพอีกครั้ง</button>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div v-if="!fueldesc_select" class=" col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-5 col-form-label text-danger text-end">ชนิดเชื้อเพลิง (กรุณาเลือกชนิดเลือกก่อนที่จะทำการตรวจ)</label>
                                <div class="col-sm-4">
                                    <!-- <input v-model="carinfo.FUELDESC" class="form-control" type="text" readonly> -->
                                    <select v-model="fueldesc_select" class="form-select" >
                                        <option value="">กรุณาเลือก</option>
                                        <option value="1">เบนซิน</option>
                                        <option value="2">ดีเซล</option>
                                        <option value="3">ไฟฟ้า</option>
                                        <option value="4">เบนซินไฟฟ้า</option>
                                        <option value="5">ดีเซลไฟฟ้า</option>
                                        <option value="6">ก๊าซอย่างเดียว</option>
                                        <option value="7">เบนซินสลับก๊าซ</option>
                                        <option value="8">เบนซินไฟฟ้าสลับก๊าซ</option>
                                        <option value="9">ดีเซลร่วมก๊าซ</option>
                                        <option value="10">ดีเซลไฟฟ้าร่วมก๊าซ</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h5 class="mt-4">รายการที่ตรวจ <b class="text-danger">(ตรวจเฉพาะรายการที่ไม่ผ่านเท่านั้น)</b></h5>
                        <div v-if="typecar != 12" class="form-group row">
                            <div class="form-group row col-sm-12">
                                    <label class="col-sm-2 col-form-label">1. ระบบห้ามล้อ</label>
                                    <div class="col-sm-9">
                                        <button @click="sends" class="btn btn-primary">รับค่า</button>
                                    </div>
                                    <label class="col-sm-12 col-form-label">แรงห้ามล้อ (N)</label>
                                    <label class="col-sm-2 col-form-label">เพลา 1 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle1l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 1 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle1r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 1</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle1Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 2 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle2l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 2 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle2r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 2</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle2Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 3 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle3l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 3 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle3r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 3</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle3Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 4 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle4l">
                                    </div>
                                    <label class="col-sm-2 col-form-label">เพลา 4 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle4r">
                                    </div>
                                    <label class="col-sm-2 col-form-label">แรงลงเพลา 4</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" :value="vars.axle4Weight">
                                    </div>
                                    <label class="col-sm-2 col-form-label">1.1 ผลต่าง(%)</label>
                                    <label class="col-sm-10 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[0]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[0] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[0] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label">เพลา 1</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum1">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เพลา 2</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum2">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เพลา 3</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum3">
                                    </div>
                                    <label class="col-sm-3 col-form-label">เพลา 4</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control" :value="varssum4">
                                    </div>
                                    <label class="col-sm-4 col-form-label">1.2 ประสิทธิภาพห้ามล้อ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="varssum5">
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[1]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[1] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[1] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label">แรงห้ามล้อมือ (N)</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" :value="vars.handbrakeForce2L">
                                    </div>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" :value="vars.handbrakeForce2R">
                                    </div>
                                    <label class="col-sm-4 col-form-label">1.3 ประสิทธิภาพห้ามล้อมือ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="varssum6">
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[2]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[2] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[2] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                            
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk3" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[3] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[3] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">2. ศูนย์ล้อ</label>
                                    <div class="col-sm-4">
                                    <input type="text" class="form-control" v-model="vars.wheelAlignment"  @input="setchk3">
                                    </div>
                                    <label class="col-sm-4 col-form-label">(m/km)</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[4]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[4] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[4] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">3. ระดับเสียง</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="soundinfo.datamax">
                                    </div>
                                    <label class="col-sm-2 col-form-label">dB(A)</label>
                                    <div class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datasound1"  type="button" :disabled="!!soundinfo.data1 || ry_id == 3"  style="font-size: 18px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datasound2" type="button" :disabled="!soundinfo.data1 || !!soundinfo.data2"  style="font-size: 18px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk5" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[5] ?  'text-primary' : 'text-danger']">{{ getchk5 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">4. ระบบไอเสีย</label>
                                    <div class="col-sm-8 text-left">
                                        <span v-if="!car_reg_date_1 || !car_reg_date_2 || !car_reg_date_3 || !fueldesc_select" class="text-danger">(กรุณาเลือกวันที่/เดือน/ปี , ชนิดเชื้อเพลิง)</span>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-2 offset-md-2">
                                        <label class="mb-0 col-form-label"  for="radioinline1">ควันดำ</label>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-3">
                                        <input class="form-control" type="number" v-model="varesmoke" @input="setchk5">
                                    </div>
                                    <label v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-5 col-form-label">%</label>
                                    <h6 v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับน้ำมันอย่างเดียว) <span v-if="cohcinfo.datamaxlo_1.co != ''" :class="[itemcheck_cohc.varssumcheck[0] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[0] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 offset-md-2">
                                        <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_1.co">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_1.hc">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_lo1_1"  type="button" :disabled="!!cohcinfo.datalo1_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_lo1_2" type="button" :disabled="!cohcinfo.datalo1_1.co || !!cohcinfo.datalo1_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>

                                    <h6 v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับน้ำมันอย่างเดียว) <span v-if="cohcinfo.datamaxhi_1.co != ''" :class="[itemcheck_cohc.varssumcheck[2] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[2] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 offset-md-2 mb-2">
                                        <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_1.co">
                                    </div>
                                    <label v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_1.hc">
                                    </div>
                                    <label v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="(fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_hi1_1"  type="button" :disabled="!!cohcinfo.datahi1_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_hi1_2" type="button" :disabled="!cohcinfo.datahi1_1.co || !!cohcinfo.datahi1_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    
                                    <h6 v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (สำหรับก๊าซอย่างเดียว) <span v-if="cohcinfo.datamaxlo_2.co != ''" :class="[itemcheck_cohc.varssumcheck[1] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[1] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 offset-md-2">
                                            <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_2.co">
                                    </div>
                                    <label v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxlo_2.hc">
                                    </div>
                                    <label v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_lo2_1"  type="button" :disabled="!!cohcinfo.datalo2_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_lo2_2" type="button" :disabled="!cohcinfo.datalo2_1.co || !!cohcinfo.datalo2_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    
                                    <h6 v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-10 offset-md-2 mb-2 mt-2">ขณะเครื่องยนต์เดินเบาที่รอบสูง (สำหรับก๊าซอย่างเดียว) <span v-if="cohcinfo.datamaxhi_2.co != ''" :class="[itemcheck_cohc.varssumcheck[3] ?  'text-primary' : 'text-danger']">{{ itemcheck_cohc.varssumcheck[3] ? "ผ่าน" : "ไม่ผ่าน" }}</span></h6>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 offset-md-2 mb-2">
                                        <label class="mb-0 col-form-label" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_2.co">
                                    </div>
                                    <label v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1">
                                        <label class="mb-0 col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;HC</label>
                                    </div>
                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamaxhi_2.hc">
                                    </div>
                                    <label v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-1 col-form-label">ppm</label>

                                    <div v-if="(fueldesc_select == 6 || fueldesc_select == 7 || fueldesc_select == 8) && Date.parse(car_reg_date_2+'/'+car_reg_date_1+'/'+(car_reg_date_3 - 543)) >= Date.parse('1/1/2014')" class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc_hi2_1"  type="button" :disabled="!!cohcinfo.datahi2_1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc_hi2_2" type="button" :disabled="!cohcinfo.datahi2_1.co || !!cohcinfo.datahi2_2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label" >
                                    <input class="checkbox_animated" v-model="itemcheck.varssumcheck[6]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[6] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[6] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">5. แตรสัญญาณ</label>
                                
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[7]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[7] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[7] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">6. มาตรวัดความเร็ว</label>
                                </div>
                                <div class="form-group row col-sm-12">
                                
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk8" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[8] ?  'text-primary' : 'text-danger']">{{ getchk8 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">7. โคมไฟ</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งไกล</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input81" >
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input82">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input83">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input84">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งต่ำ</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input85">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input86">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input87">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input88">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[9]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[9] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[9] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[10]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[10] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[10] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[11]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[11] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[11] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">10. เครื่องปัดน้ำฝน</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[12]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[12] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[12] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[13]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[13] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[13] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">12. ระบบบังคับเลี้ยวและพวงมาลัย</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[14]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[14] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[14] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">13. ล้อและยาง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[15]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[15] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[15] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">14. ถังเชื้อเพลิงและท่อส่ง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[16]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[16] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[16] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">15. เครื่องล่าง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[17]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[17] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[17] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">16. สีรถ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[18]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[18] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[18] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">17. โครงสร้างและตัวถัง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[19]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[19] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[19] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">18. ประตูและพื้นรถ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[20]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[20] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[20] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">19. ขนาดของรถ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[21]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[21] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[21] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">20. ที่นั่งและจานวนที่นั่ง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[22]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[22] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[22] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">21. เข็มขัดนิรภัย</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[23]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[23] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[23] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">22. อื่นๆ</label>
                                    <div class="col-sm-7">
                                        <input :disabled="itemcheck.varssumcheck[23] == 1" class="form-control" type="text" v-model="failed_reasons" placeholder="สามารถกรอกรายละเอียดเพิ่มได้">
                                    </div>
                                </div>
                        </div>
                        <!-- ------------------------ รย. 12 --------------------------- -->
                        <div v-else class="form-group row">      
                            <div class="form-group row col-sm-12">
                                    <label class="col-sm-2 col-form-label text-secondary">1. ระบบห้ามล้อ</label>
                                    <div class="col-sm-9">
                                        <button @click="sends" class="btn btn-primary">รับค่า</button>
                                    </div>
                                    <label class="col-sm-12 col-form-label text-secondary">แรงห้ามล้อ (N)</label>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 1 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 1 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 1</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 2 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 2 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 2</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 3 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 3 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 3</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 4 ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">เพลา 4 ขวา</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">แรงลงเพลา 4</label>
                                    <div class="col-sm-2">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">1.1 ผลต่าง(%)</label>
                                    <label class="col-sm-10 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[0]" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 1</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 2</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 3</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-3 col-form-label text-secondary">เพลา 4</label>
                                    <div class="col-sm-3">
                                    <input readonly type="number" class="form-control">
                                    </div>
                                    <label class="col-sm-4 col-form-label text-secondary">1.2 ประสิทธิภาพห้ามล้อ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-3 col-form-label text-secondary">แรงห้ามล้อมือ (N)</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ซ้าย</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-3">
                                    <input readonly type="text" class="form-control" >
                                    </div>
                                    <label class="col-sm-4 col-form-label text-secondary">1.3 ประสิทธิภาพห้ามล้อมือ (%)</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-4 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ"</span>
                                    </label>
                            
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label text-secondary">2. ศูนย์ล้อ</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control">
                                    </div>
                                    <label class="col-sm-4 col-form-label text-secondary">(m/km)</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="itemcheck.varssumcheck[4]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[4] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[4] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">3. ระดับเสียง</label>
                                    <div class="col-sm-4">
                                    <input readonly type="text" class="form-control" :value="soundinfo.datamax">
                                    </div>
                                    <label class="col-sm-2 col-form-label">dB(A)</label>
                                    <div class="col-sm-2">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datasound1"  type="button" :disabled="!!soundinfo.data1"  style="font-size: 18px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datasound2" type="button" :disabled="!soundinfo.data1 || !!soundinfo.data2"  style="font-size: 18px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk5" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[5] ?  'text-primary' : 'text-danger']">{{ getchk5 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">4. ระบบไอเสีย</label>
                                    <div class="col-sm-8 text-left">
                                        <span v-if="!car_reg_date_1 || !car_reg_date_2 || !car_reg_date_3 || !fueldesc_select" class="text-danger">(กรุณาเลือกวันที่/เดือน/ปี , ชนิดเชื้อเพลิง)</span>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-2 offset-md-4">
                                            <label class="mb-0 col-form-label" for="radioinline1">ควันดำ</label>
                                    </div>
                                    <div v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-3">
                                        <input class="form-control" type="number" v-model="varesmoke" :disabled="fueldesc_select == 3" @input="setchk5">
                                    </div>
                                    <label v-if="fueldesc_select == 2 ||  fueldesc_select == 5 ||  fueldesc_select == 9 ||  fueldesc_select == 10" class="col-sm-3 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2 offset-md-4">
                                        <label class="mb-0" for="radioinline2">CO</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-3">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamax.co">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1 col-form-label">%</label>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-1">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button class="btn btn-primary" @click="datacohc1"  type="button" :disabled="!!cohcinfo.data1.co"  style="font-size: 20px;z-index: 3;">1</button>
                                            <button class="btn btn-primary" @click="datacohc2" type="button" :disabled="!cohcinfo.data1.co || !!cohcinfo.data2.co"  style="font-size: 20px;z-index: 3;">2</button>
                                        </div>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-2 offset-sm-4">
                                        <label class="mb-0">HC</label>
                                    </div>
                                    <div v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-3">
                                        <input readonly type="text" class="form-control" :value="cohcinfo.datamax.hc">
                                    </div>
                                    <label v-if="fueldesc_select == 1 || fueldesc_select == 4 || fueldesc_select == 7 || fueldesc_select == 8" class="col-sm-3 col-form-label">ppm</label>
                                    <label class="col-sm-2 col-form-label" >
                                    <input class="checkbox_animated" v-model="itemcheck.varssumcheck[6]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[6] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[6] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">5. แตรสัญญาณ</label>
                                
                                    <label class="col-sm-2 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[7]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[7] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[7] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-10 col-form-label">6. มาตรวัดความเร็ว</label>
                                </div>      
                                <div class="form-group row col-sm-12">
                                    
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" v-model="getchk8" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[8] ?  'text-primary' : 'text-danger']">{{ getchk8 ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">7. โคมไฟ</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งไกล</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input81" >
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input83">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">kcd</label>
                                    <label class="col-sm-12 col-form-label">แสงพุ่งต่ำ</label>
                                    <label class="col-sm-2 col-form-label">ทิศทาง</label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input85">
                                    </div>
                                    <label class="col-sm-2 col-form-label">องศา</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">องศา</label>
                                    <label class="col-sm-12 col-form-label">ความสว่าง</label>
                                    <label class="col-sm-2 col-form-label"></label>
                                    <label class="col-sm-1 col-form-label">ซ้าย</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" v-model="input87">
                                    </div>
                                    <label class="col-sm-2 col-form-label">kcd</label>
                                    <label class="col-sm-1 col-form-label text-secondary">ขวา</label>
                                    <div class="col-sm-2">
                                    <input class="form-control" type="text" oninput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')" disabled="disabled">
                                    </div>
                                    <label class="col-sm-2 col-form-label text-secondary">kcd</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[9]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[9] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[9] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[10]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[10] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[10] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input disabled="disabled"  class="checkbox_animated"  type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label text-secondary">10. เครื่องปัดน้ำฝน</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input disabled="disabled"  class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label text-secondary">11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[13]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[13] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[13] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">12. ระบบบังคับเลี้ยว</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[14]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[14] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[14] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">13. ล้อและยาง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[15]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[15] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[15] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">14. ถังเชื้อเพลิงและท่อส่ง</label>
                                        <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label text-secondary">15. เครื่องล่าง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[17]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[17] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[17] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">16. สีรถ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[18]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[18] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[18] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">17. โครงสร้างและตัวถัง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label text-secondary">18. ประตูและพื้นรถ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[20]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[20] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[20] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">19. ขนาดของรถ</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[21]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[21] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[21] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label">20. ที่นั่งและจานวนที่นั่ง</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input disabled="disabled" class="checkbox_animated" type="checkbox"><span class="text-secondary">ไม่ตรวจ</span>
                                    </label>
                                    <label class="col-sm-9 col-form-label text-secondary">21. เข็มขัดนิรภัย</label>
                                    <label class="col-sm-2 offset-sm-1 col-form-label" >
                                    <input  class="checkbox_animated" v-model="itemcheck.varssumcheck[23]" :true-value="1" :false-value="0"  type="checkbox"><span :class="[itemcheck.varssumcheck[23] ?  'text-primary' : 'text-danger']">{{ itemcheck.varssumcheck[23] ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                                    </label>
                                    <label class="col-sm-2 col-form-label">22. อื่นๆ</label>
                                    <div class="col-sm-7">
                                        <input :disabled="itemcheck.varssumcheck[23] == 1" class="form-control" type="text" v-model="failed_reasons" placeholder="สามารถกรอกรายละเอียดเพิ่มได้">
                                    </div>
                                </div>
                        </div>
                        
                        <div class="form-group row mt-4">
                            <label class="col-sm-2 col-form-label offset-2">สรุปผลการตรวจสภาพ</label>
                            <label class="col-sm-2 col-form-label" >
                                <input disabled="disabled" class="checkbox_animated" v-model="allchk" :true-value="1" :false-value="0"  type="checkbox"><span :class="[allchk ?  'text-primary' : 'text-danger']">{{ allchk ? "ผ่าน" : "ไม่ผ่าน" }}</span>
                            </label>        
                                <label class="col-sm-2 col-form-label">วันที่/เวลา ส่งข้อมูล</label>
                            <div class="col-sm-2">
                                <input  disabled="disabled" class="form-control" type="text" :value="timeinsert">
                            </div>
                        </div>
                        <div v-if="allchk == 0" class="form-group row">
                            <label class="col-sm-8 col-form-label offset-3 text-danger">ข้อที่ยังไม่ผ่าน : {{allchkid}}</label>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-grid gap-2 col-6 mx-auto">
                        <button class="btn-lg btn-primary" @click="insertdata" type="button">ส่งข้อมูล</button>
                        <!-- <button class="btn-lg btn-light ml-4" onClick="window.location.reload();" type="button">ล้างข้อมูล</button> -->
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { createWorker, PSM, OEM, createScheduler } from "tesseract.js";
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";
import { readonly, ref, inject } from 'vue';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import * as tf from '@tensorflow/tfjs';
import { useRoute } from 'vue-router';

export default {
    setup(){
        const route = useRoute()
        const iddata = ref(route.params.id);
        const Swal = inject('$swal')
        const Modeldata = ref('/model/tro/model.json');
        const st_value = ref([]);
        const st_read = readonly(st_value);
        const wh_value = ref([]);
        const wh_read = readonly(wh_value);
        const imageview = ref('');
        const openCameras = ref(false);
        let dataai = {
            typecar_detail:'',
            klass: '',
            score: '0',
        };
        let model = null;
        let label = null;
        let fil = 0; // ฟิวเตอร์ ปิด 0/เปิด 1 ป้ายทะเบียน

        async function loadModel  () {
            Swal.fire({
                title: 'ระบบกำลังทำงาน',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            Swal.showLoading()

            try {
                const savedModel = localStorage.getItem('Tro');
                if (savedModel) {
                model = await tf.loadGraphModel('indexeddb://Tro'); 
                } else {
                // Loading Model for first time
                model = await tf.loadGraphModel(Modeldata.value);
                localStorage.setItem('Tro', true);
                model.save('indexeddb://Tro')
                }
                openCameras.value =  true;
                Swal.close();
            }catch(error){
                Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: "ระบบไม่สามารถโหลดฟังชั่น AI ได้",
                        icon: "error",
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: "ลองใหม่อีกครั้ง",
                        denyButtonText: `ยกเลิกการโหลดฟังชั่น AI`
                    }).then( async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                        localStorage.removeItem("Tro");
                        localStorage.removeItem("Trolabel");
                        loadModel()
                        } else if (result.isDenied) {
                            Swal.fire({
                            title: "คำแนะนำ",
                            html: `หากเครื่องของท่านโหลดฟังชั่น Ai ไม่สำเร็จจะทำให้ไม่สามารถใช้งานระบบในวันที่ 9 พ.ย. ได้ท่านสามารถคลิกเพื่อดูแนวทางแก้ปัญหาอื่นๆได้ที่ <a href="https://v6.inspection.dlt.go.th/download/document/v6-6-6.pdf" target="_blank">"คู่มือการแก้ไขปัญหาฟังชั่น AI เบื้องต้น"</a>`,
                            icon: "warning",
                        });
                        }
                    });
            }

        }
        async function processImage(base64) {
            const imageElement =  new Image();
            imageElement.src = base64;
            const sizeimg = await getDimensions(base64) 
            const canvas = document.createElement("canvas");
            const context = canvas.getContext('2d');
            canvas.width = sizeimg.width;
            canvas.height = sizeimg.height;
            // Draw the uploaded image onto the canvas
            context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
            // Detect vehicles using COCO-SSD
            const cocoModel = await cocoSsd.load();
            const vehiclePredictions = await cocoModel.detect(imageElement);

            // Loop through detected vehicles
            async function svehicles () {
                for await (const prediction of vehiclePredictions) {
                    const [x, y, width, height] = prediction.bbox;
                    const label = prediction.class;

                    // Check if the detected object is a vehicle (car, truck, motorcycle)
                    if (label === 'car' || label === 'truck' || label === 'motorcycle') {
                        // Draw blue bounding box for vehicles
                        context.beginPath();
                        context.rect(x, y, width, height);
                        context.lineWidth = 2;
                        context.strokeStyle = '#efbf04'; // Blue border for detected vehicles
                        context.fillStyle = 'rgba(0, 0, 255, 0.3)';
                        // context.fill(); // ใส่สีพื้นหลังในกรอบ
                        context.stroke();

                        let typecar_detail = '';
                        if(label === 'car'){
                            typecar_detail = 'รถยนต์';
                        }else if(label === 'truck'){
                            typecar_detail = 'รถกระบะ';
                        }else if(label === 'motorcycle'){
                            typecar_detail = 'รถมอเตอร์ไซต์';
                        }

                        dataai.typecar_detail = typecar_detail

                        // Draw label above the bounding box (display the type of the object)
                        context.font = '20px Arial';
                        context.fillStyle = '#efbf04';
                        context.fillText(typecar_detail, x, y > 10 ? y - 5 : 10); // Display the label above the bounding box

                        //  checkModel(canvas.toDataURL("image/jpeg", 1.0))
                         return canvas.toDataURL("image/jpeg", 1.0)
                    }
                }
            }

            const chechstatus = await svehicles() ;
            if(chechstatus){
                return chechstatus
            }else{
                return false
            }

        }
        function getDimensions(image){
            return new Promise((resolve, reject)=>{
                const img = new Image();
                img.src = image;

                img.onload = () => {
                    resolve({width: img.width, height: img.height})
                }
            })
        }

        async function checkModel(imagedata){
            try{
                const imagee =  new Image();
                imagee.src = imagedata;
                const sizeimg = await getDimensions(imagedata) 

                const width1 = sizeimg.width;
                const height1= sizeimg.height;
                const batched = tf.tidy(() => {

                const img = tf.browser.fromPixels(imagee);
                const small = tf.image.resizeBilinear(img, [640, 640]).div(255);
                    return small.expandDims(0);
                })

                const result = await model.execute(batched);
                const transRes = await result.transpose([0, 2, 1]);
            
                const boxes = tf.tidy(() => {
                    const w = transRes.slice([0, 0, 2], [-1, -1, 1]); // get width
                    const h = transRes.slice([0, 0, 3], [-1, -1, 1]); // get height
                    const x1 = tf.sub(transRes.slice([0, 0, 0], [-1, -1, 1]), tf.div(w, 2)); // x1
                    const y1 = tf.sub(transRes.slice([0, 0, 1], [-1, -1, 1]), tf.div(h, 2)); // y1
                    let object = tf.concat([x1,y1,tf.add(x1, w),tf.add(y1, h)],2).squeeze();
                    return object;
                });
                const scores = tf.tidy(() => {
                    const rawScores = transRes.slice([0, 0, 4], [-1, -1, 1]).squeeze(); // class scores
                    return rawScores;
                });
                var detections = non_max_suppression(boxes.arraySync(),scores.arraySync());
                const boxes_plot =  shortenedCol(detections, [0,1,2,3]);
                var ratio = [];
                ratio[0]= (width1/640);
                ratio[1]= (height1/640);

                const resultURL = await crop(imagee,boxes_plot,ratio,fil);

                imageview.value = resultURL;
                
                tf.dispose(result);
                tf.dispose(transRes);
                tf.dispose(boxes);
                tf.dispose(scores);
                tf.dispose(detections);

                return true
            }catch(error){
                return false
            }
        }

            function non_max_suppression(boxes , scores, conf_thresh=0.50, iou_thresh=0.2, max_det = 300){

            // Initialize an empty list to store the selected boxes
            const selected_detections = [];

            for (let i = 0; i < scores.length; i++) {

                // Check if the box has sufficient score to be selected
                if (scores[i] < conf_thresh) {
                    continue;
                    }
                var box = boxes[i];
                const score = scores[i];
                let object = box;
                let addBox = true;


                // Check for overlap with previously selected boxes
                for (let j = 0; j < selected_detections.length; j++) {
                    let selectedBox = selected_detections[j];

                    // Calculate the intersection and union of the two boxes
                    let intersectionXmin = Math.max(object[0], selectedBox[0]);
                    let intersectionYmin = Math.max(object[1], selectedBox[1]);
                    let intersectionXmax = Math.min(object[2], selectedBox[2]);
                    let intersectionYmax = Math.min(object[3], selectedBox[3]);
                    let intersectionWidth = Math.max(0, intersectionXmax - intersectionXmin);
                    let intersectionHeight = Math.max(0, intersectionYmax - intersectionYmin);
                    let intersectionArea = intersectionWidth * intersectionHeight;
                    let boxArea = (object[2] - object[0]) * (object[3] - object[1]);
                    let selectedBoxArea = (selectedBox[2] - selectedBox[0]) * (selectedBox[3] - selectedBox[1]);
                    let unionArea = boxArea + selectedBoxArea - intersectionArea;

                    // Calculate the IoU and check if the boxes overlap
                    let iou = intersectionArea / unionArea;
                    if (iou >= iou_thresh) {
                        addBox = false;
                        break;
                }
                }

                // Add the box to the selected boxes list if it passed the overlap check
                if (addBox) {
                    const row = box.concat(score);
                    selected_detections.push(row);
                }
            }

            return selected_detections
            }

            function shortenedCol(arrayofarray, indexlist) {
            return arrayofarray.map(function (array) {
                return indexlist.map(function (idx) {
                    return array[idx];
                });
            });
            }

            function thresholdFilter(pixels, level) {
            if (level === undefined) {
                level = 0.5;
            }
            const thresh = Math.floor(level * 255);
            for (let i = 0; i < pixels.length; i += 4) {
                const r = pixels[i];
                const g = pixels[i + 1];
                const b = pixels[i + 2];
                const gray = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                let val;
                if (gray >= thresh) {
                val = 255;
                } else {
                val = 0;
                }
                pixels[i] = pixels[i + 1] = pixels[i + 2] = val;
            }
            };

            function getARGB (data, i) {
            const offset = i * 4;
            return (
                ((data[offset + 3] << 24) & 0xff000000) |
                ((data[offset] << 16) & 0x00ff0000) |
                ((data[offset + 1] << 8) & 0x0000ff00) |
                (data[offset + 2] & 0x000000ff)
            );
            };

            function setPixels (pixels, data) {
            let offset = 0;
            for (let i = 0, al = pixels.length; i < al; i++) {
                offset = i * 4;
                pixels[offset + 0] = (data[i] & 0x00ff0000) >>> 16;
                pixels[offset + 1] = (data[i] & 0x0000ff00) >>> 8;
                pixels[offset + 2] = data[i] & 0x000000ff;
                pixels[offset + 3] = (data[i] & 0xff000000) >>> 24;
            }
            };

            let blurRadius;
            let blurKernelSize;
            let blurKernel;
            let blurMult;

            function buildBlurKernel(r) {
            let radius = (r * 3.5) | 0;
            radius = radius < 1 ? 1 : radius < 248 ? radius : 248;

            if (blurRadius !== radius) {
            blurRadius = radius;
            blurKernelSize = (1 + blurRadius) << 1;
            blurKernel = new Int32Array(blurKernelSize);
            blurMult = new Array(blurKernelSize);
            for (let l = 0; l < blurKernelSize; l++) {
                blurMult[l] = new Int32Array(256);
            }

            let bk, bki;
            let bm, bmi;

            for (let i = 1, radiusi = radius - 1; i < radius; i++) {
                blurKernel[radius + i] = blurKernel[radiusi] = bki = radiusi * radiusi;
                bm = blurMult[radius + i];
                bmi = blurMult[radiusi--];
                for (let j = 0; j < 256; j++) {
                bm[j] = bmi[j] = bki * j;
                }
            }
            bk = blurKernel[radius] = radius * radius;
            bm = blurMult[radius];

            for (let k = 0; k < 256; k++) {
                bm[k] = bk * k;
            }
            }
            }

            function blurARGB(pixels, canvas, radius) {
            const width = canvas.width;
            const height = canvas.height;
            const numPackedPixels = width * height;
            const argb = new Int32Array(numPackedPixels);
            for (let j = 0; j < numPackedPixels; j++) {
            argb[j] = getARGB(pixels, j);
            }
            let sum, cr, cg, cb, ca;
            let read, ri, ym, ymi, bk0;
            const a2 = new Int32Array(numPackedPixels);
            const r2 = new Int32Array(numPackedPixels);
            const g2 = new Int32Array(numPackedPixels);
            const b2 = new Int32Array(numPackedPixels);
            let yi = 0;
            buildBlurKernel(radius);
            let x, y, i;
            let bm;
            for (y = 0; y < height; y++) {
            for (x = 0; x < width; x++) {
                cb = cg = cr = ca = sum = 0;
                read = x - blurRadius;
                if (read < 0) {
                bk0 = -read;
                read = 0;
                } else {
                if (read >= width) {
                    break;
                }
                bk0 = 0;
                }
                for (i = bk0; i < blurKernelSize; i++) {
                if (read >= width) {
                    break;
                }
                const c = argb[read + yi];
                bm = blurMult[i];
                ca += bm[(c & -16777216) >>> 24];
                cr += bm[(c & 16711680) >> 16];
                cg += bm[(c & 65280) >> 8];
                cb += bm[c & 255];
                sum += blurKernel[i];
                read++;
                }
                ri = yi + x;
                a2[ri] = ca / sum;
                r2[ri] = cr / sum;
                g2[ri] = cg / sum;
                b2[ri] = cb / sum;
            }
            yi += width;
            }
            yi = 0;
            ym = -blurRadius;
            ymi = ym * width;
            for (y = 0; y < height; y++) {
            for (x = 0; x < width; x++) {
                cb = cg = cr = ca = sum = 0;
                if (ym < 0) {
                bk0 = ri = -ym;
                read = x;
                } else {
                if (ym >= height) {
                    break;
                }
                bk0 = 0;
                ri = ym;
                read = x + ymi;
                }
                for (i = bk0; i < blurKernelSize; i++) {
                if (ri >= height) {
                    break;
                }
                bm = blurMult[i];
                ca += bm[a2[read]];
                cr += bm[r2[read]];
                cg += bm[g2[read]];
                cb += bm[b2[read]];
                sum += blurKernel[i];
                ri++;
                read += width;
                }
                argb[x + yi] =
                ((ca / sum) << 24) |
                ((cr / sum) << 16) |
                ((cg / sum) << 8) |
                (cb / sum);
            }
            yi += width;
            ymi += width;
            ym++;
            }
            setPixels(pixels, argb);
            }

            function invertColors(pixels) {
            for (var i = 0; i < pixels.length; i+= 4) {
                pixels[i] = pixels[i] ^ 255; // Invert Red
                pixels[i+1] = pixels[i+1] ^ 255; // Invert Green
                pixels[i+2] = pixels[i+2] ^ 255; // Invert Blue
            }
            }

            function dilate(pixels, canvas) {
            let currIdx = 0;
            const maxIdx = pixels.length ? pixels.length / 4 : 0;
            const out = new Int32Array(maxIdx);
            let currRowIdx, maxRowIdx, colOrig, colOut, currLum;

            let idxRight, idxLeft, idxUp, idxDown;
            let colRight, colLeft, colUp, colDown;
            let lumRight, lumLeft, lumUp, lumDown;

            while (currIdx < maxIdx) {
            currRowIdx = currIdx;
            maxRowIdx = currIdx + canvas.width;
            while (currIdx < maxRowIdx) {
                colOrig = colOut = getARGB(pixels, currIdx);
                idxLeft = currIdx - 1;
                idxRight = currIdx + 1;
                idxUp = currIdx - canvas.width;
                idxDown = currIdx + canvas.width;

                if (idxLeft < currRowIdx) {
                idxLeft = currIdx;
                }
                if (idxRight >= maxRowIdx) {
                idxRight = currIdx;
                }
                if (idxUp < 0) {
                idxUp = 0;
                }
                if (idxDown >= maxIdx) {
                idxDown = currIdx;
                }
                colUp = getARGB(pixels, idxUp);
                colLeft = getARGB(pixels, idxLeft);
                colDown = getARGB(pixels, idxDown);
                colRight = getARGB(pixels, idxRight);

                //compute luminance
                currLum =
                77 * ((colOrig >> 16) & 0xff) +
                151 * ((colOrig >> 8) & 0xff) +
                28 * (colOrig & 0xff);
                lumLeft =
                77 * ((colLeft >> 16) & 0xff) +
                151 * ((colLeft >> 8) & 0xff) +
                28 * (colLeft & 0xff);
                lumRight =
                77 * ((colRight >> 16) & 0xff) +
                151 * ((colRight >> 8) & 0xff) +
                28 * (colRight & 0xff);
                lumUp =
                77 * ((colUp >> 16) & 0xff) +
                151 * ((colUp >> 8) & 0xff) +
                28 * (colUp & 0xff);
                lumDown =
                77 * ((colDown >> 16) & 0xff) +
                151 * ((colDown >> 8) & 0xff) +
                28 * (colDown & 0xff);

                if (lumLeft > currLum) {
                colOut = colLeft;
                currLum = lumLeft;
                }
                if (lumRight > currLum) {
                colOut = colRight;
                currLum = lumRight;
                }
                if (lumUp > currLum) {
                colOut = colUp;
                currLum = lumUp;
                }
                if (lumDown > currLum) {
                colOut = colDown;
                currLum = lumDown;
                }
                out[currIdx++] = colOut;
            }
            }
            setPixels(pixels, out);
            };

            function preprocessImage(canvas) {
            const processedImageData = canvas.getContext('2d').getImageData(0,0,canvas.width, canvas.height);
            blurARGB(processedImageData.data, canvas, 1);
            dilate(processedImageData.data, canvas);
            invertColors(processedImageData.data);
            thresholdFilter(processedImageData.data, 0.4);
            return processedImageData;
            };

            function delay(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }

            async function crop(image,boxes,ratio,fil){
            let base64Image;
            const canva = document.createElement("canvas");
            canva.width = ratio[0]*640;
            canva.height = ratio[1]*640;
            const ctx2 = canva.getContext("2d");
            ctx2.drawImage(image, 0, 0);
            const selected_detections = [];
            const font = "18px sans-serif";
            ctx2.font = font;
            ctx2.textBaseline = "top";
            ctx2.strokeStyle = "#B033FF";
            ctx2.lineWidth = 2;
            let i=0;
            for(i=0;i<boxes.length;++i){
                const canvas = document.createElement("canvas");
                const scaleX = 1;
                const scaleY = 1;
                let [x1, y1, x2, y2] = boxes[i];
                x1 *= ratio[0];
                x2 *= ratio[0];
                y1 *= ratio[1];
                y2 *= ratio[1];
                const width = x2 - x1;
                const height = y2 - y1;
                canvas.width = width;
                canvas.height = height;
                const immg = new Image(width,height);
                const ctx = canvas.getContext("2d");

                ctx.drawImage(
                image,
                x1 * scaleX,
                y1 * scaleY,
                width * scaleX,
                height * scaleY,
                0,
                0,
                width,
                height
                );
                if(fil===1){
                const res = preprocessImage(canvas);
                ctx.putImageData(res,0,0);
                }
                immg.onload = function() {
                ctx2.drawImage(immg, 0,0,width,height,x1 * scaleX,y1 * scaleY,width * scaleX,height * scaleY);
                };
                base64Image = canvas.toDataURL("image/jpeg", 1.0);
                immg.src = base64Image;

                const worker = await createWorker('eng');
                await worker.setParameters({
                    tessedit_char_whitelist: '0123456789',
                });
                await worker.recognize(base64Image)
                .catch (err => {
                    console.error(err);
                })
                .then(result => {
                // Get Confidence score
                let confidence = result.data.confidence;
                // Get full output
                var text = []
                text[0] = result.data.text.replace("\n", '').slice(-4);
                text[1] = confidence;
                selected_detections.push(text);
                const klass = text[0];
                const score = text[1];
                ctx2.strokeRect(x1, y1, width, height);
                
                dataai.klass =  klass;
                dataai.score =  score;
                // Draw the label background.
                ctx2.fillStyle = "#B033FF";
                const textWidth = ctx2.measureText(klass + " - " + score + "%").width;
                const textHeight = parseInt(font, 10); // base 10
                ctx2.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

                // Draw labels
                ctx2.fillStyle = "#ffffff";
                if(klass){
                    ctx2.fillText(klass + " - " + score + "%", x1 - 1, y1 - (textHeight + 2));
                }else{
                    ctx2.fillText(score + "%", x1 - 1, y1 - (textHeight + 2));
                }
                })
                await worker.terminate();
            }

            // เพิ่มลายน้ำสีแดง
            ctx2.font = '37px Arial';
            ctx2.fillStyle = 'rgba(255, 0, 0, 0.4)';
            ctx2.fillText(`${new Date().toLocaleString("en-GB").replace( /,/,"" )} ${storeAuth.license_number}`, 40, 240);


            // await delay(2000);
            const resutl=canva.toDataURL("image/jpeg");
            return resutl;
            }   

        return {
            iddata,
            st_value,
            st_read,
            wh_value,
            wh_read,
            model,
            label,
            loadModel,
            processImage,
            checkModel,
            imageview,
            dataai, 
            openCameras,
        }
    },
    data(){      
        return  {
            storeAuths: storeAuth,
            camera_setting_turn: '',
            ocr_type_link_capture : '',
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            b64img:'',
            page_status:'',
            settings:[],
            urlimagetif:'',
            rectangles: [],
            unit_ocr: [],
            ocrvalue:[],
            smoke_setting:'',
            check_num:'',
            entry_by:storeAuth.id,
            pc_ip:null,
            pc_mac:null,
            car_lic_id_char:'',
            car_lic_id_num:'',
            car_lic_prov:null,
            car_reg_date_1:'', 
            car_reg_date_2:'', 
            car_reg_date_3:'', 
            ry:null, 
            ry_id:'',
            ry_number_id:null, 
            tire_no:null,
            car_weight:'',
            car_seat_no:null,
            type:null, 
            typecar:'',
            radio1:'',
            varesmoke:'',
            input81:'',
            input82:'',
            input83:'',
            input84:'',
            input85:'',
            input86:'',
            input87:'',
            input88:'',
            vara:'',
            vareco:'',
            varehc:'',
            vars:{
                wheelAlignment:'',
                axle1l : '',
                axle1r : '',
                axle2l : '',
                axle2r : '',
                axle3l : '',
                axle3r : '',
                axle4l : '',
                axle4r : '',
                axle1Weight : '',
                axle2Weight : '',
                axle3Weight : '',
                axle4Weight : '',
                handbrakeForce2L : '',
                handbrakeForce2R : '',
            },
            varssum1:'',
            varssum2:'',
            varssum3:'',
            varssum4:'',
            varssum5:'',
            varssum5check:null,
            varssum6:'',
            varssum6check:null,
            servertime: '',
            itemcheck: {
            varssumcheck: []
            },
            conclude:0,
            timeinsert: '',
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            link: '#',
            inserinfo: {
                iddata:'',
            },
            soundinfo:{
                data1:'',
                data2:'',
                datamax:'',
            },
            fueldesc_select:'',
            itemcheck_cohc: {
                varssumcheck: []
            },
            cohcinfo:{
                data1:{
                    co:'',
                    hc:'',
                },
                data2:{
                    co:'',
                    hc:'',
                },
                datalo1_1:{
                    co:'',
                    hc:'',
                },
                datalo1_2:{
                    co:'',
                    hc:'',
                },
                datalo2_1:{
                    co:'',
                    hc:'',
                },
                datalo2_2:{
                    co:'',
                    hc:'',
                },
                datahi1_1:{
                    co:'',
                    hc:'',
                },
                datahi1_2:{
                    co:'',
                    hc:'',
                },
                datahi2_1:{
                    co:'',
                    hc:'',
                },
                datahi2_2:{
                    co:'',
                    hc:'',
                },
                datamax:{
                    co:'',
                    hc:'',
                },
                datamaxlo_1:{
                    co:'',
                    hc:'',
                },
                datamaxlo_2:{
                    co:'',
                    hc:'',
                },
                datamaxhi_1:{
                    co:'',
                    hc:'',
                },
                datamaxhi_2:{
                    co:'',
                    hc:'',
                },
            },
            apicheck:{
                branch:'',
                srvtype:'',
            },
            failed_reasons:'',
            c_names:'',
            
        }
    },
    methods:{   
        async getsh(){
            const res = await axios.post(`repeatcar`,{
            uid: storeAuth.id,
            gid: storeAuth.group_id,
            cid: storeAuth.county_id,
            tid: 2,
            dtn: new Date(),
            datas: this.iddata
            });
            if(res.data.status == 1){
                this.c_names = res.data.datas.datais.names;
                this.check_num = (res.data.datas.datais.CHECK_NUMBER + 1);
                this.car_lic_id_char = res.data.datas.datais.CAR_LIC_ID_CHAR;
                this.car_lic_id_num = res.data.datas.datais.CAR_LIC_ID_NUM;
                this.car_reg_date_1 = res.data.datas.datais.CAR_REG_DATE_1; 
                this.car_reg_date_2 = res.data.datas.datais.CAR_REG_DATE_2;
                this.car_reg_date_3 = res.data.datas.datais.CAR_REG_DATE_3;
                this.ry_id = res.data.datas.datais.RY_ID;
                this.car_weight = res.data.datas.datais.CAR_WEIGHT;
                this.typecar = res.data.datas.datais.CAR_TYPE;
                this.radio1 = (!res.data.datas.datais.PIPE_VALUE)?'cohccheck':'smokecheck';
                this.varesmoke = res.data.datas.datais.PIPE_VALUE;
                this.input81 = res.data.datas.datais.LIGHT_UP_L_VALUE;
                this.input82 = res.data.datas.datais.LIGHT_UP_R_VALUE;
                this.input83 = res.data.datas.datais.LIGHT_DN_L_VALUE;
                this.input84 = res.data.datas.datais.LIGHT_DN_R_VALUE;
                this.input85 = res.data.datas.datais.LIGHT_UP_L_LEVEL;
                this.input86 = res.data.datas.datais.LIGHT_UP_R_LEVEL;
                this.input87 = res.data.datas.datais.LIGHT_DN_L_LEVEL;
                this.input88 = res.data.datas.datais.LIGHT_DN_R_LEVEL;
                this.vara = res.data.datas.datais.VOICE_VALUE;
                this.vareco = res.data.datas.datais.CO_VALUE;
                this.varehc = res.data.datas.datais.HC_VALUE;
                this.vars.wheelAlignment = res.data.datas.datais.TESTBRAKE_WHEEL_ALIGHTMENT;
                this.vars.axle1l = res.data.datas.datais.TESTBRAKE_FORCE_AXLE1L;
                this.vars.axle1r = res.data.datas.datais.TESTBRAKE_FORCE_AXLE1R;
                this.vars.axle2l = res.data.datas.datais.TESTBRAKE_FORCE_AXLE2L;
                this.vars.axle2r = res.data.datas.datais.TESTBRAKE_FORCE_AXLE2R;
                this.vars.axle3l = res.data.datas.datais.TESTBRAKE_FORCE_AXLE3L;
                this.vars.axle3r = res.data.datas.datais.TESTBRAKE_FORCE_AXLE3R;
                this.vars.axle4l = res.data.datas.datais.TESTBRAKE_FORCE_AXLE4L;
                this.vars.axle4r = res.data.datas.datais.TESTBRAKE_FORCE_AXLE4R;
                this.vars.axle1Weight = res.data.datas.datais.TESTBRAKE_WEIGHT_AXLE1;
                this.vars.axle2Weight = res.data.datas.datais.TESTBRAKE_WEIGHT_AXLE2;
                this.vars.axle3Weight = res.data.datas.datais.TESTBRAKE_WEIGHT_AXLE3;
                this.vars.axle4Weight = res.data.datas.datais.TESTBRAKE_WEIGHT_AXLE4;
                this.vars.handbrakeForce2L = res.data.datas.datais.TESTBRAKE_HAND_VAULE_L;
                this.vars.handbrakeForce2R = res.data.datas.datais.TESTBRAKE_HAND_VAULE_R;
                this.varssum1 = res.data.datas.datais.TESTBRAKE_DIF_AXLE1;
                this.varssum2 = res.data.datas.datais.TESTBRAKE_DIF_AXLE2;
                this.varssum3 = res.data.datas.datais.TESTBRAKE_DIF_AXLE3;
                this.varssum4 = res.data.datas.datais.TESTBRAKE_DIF_AXLE4;
                this.varssum5 = res.data.datas.datais.TESTBRAKE_FOOT_EFFICIENCY;
                this.varssum6 = res.data.datas.datais.TESTBRAKE_HAND_EFFICIENCY;
                this.soundinfo.datamax = res.data.datas.datais.VOICE_VALUE;
                this.fueldesc_select = res.data.datas.datais.FUELDESC_SELECT;
                this.cohcinfo.datamax.co = res.data.datas.datais.CO_VALUE;
                this.cohcinfo.datamax.hc = res.data.datas.datais.HC_VALUE;
                this.cohcinfo.datamaxlo_1.co = (res.data.datas.datais.CO_VALUE != '' &&  res.data.datas.datais.CO_VALUE_L1 == '')?res.data.datas.datais.CO_VALUE:res.data.datas.datais.CO_VALUE_L1;
                this.cohcinfo.datamaxlo_1.hc = (res.data.datas.datais.HC_VALUE != '' &&  res.data.datas.datais.HC_VALUE_L1 == '')?res.data.datas.datais.HC_VALUE:res.data.datas.datais.HC_VALUE_L1;
                this.cohcinfo.datamaxlo_2.co = res.data.datas.datais.CO_VALUE_L2;
                this.cohcinfo.datamaxlo_2.hc = res.data.datas.datais.HC_VALUE_L2;
                this.cohcinfo.datamaxhi_1.co = res.data.datas.datais.CO_VALUE_H1;
                this.cohcinfo.datamaxhi_1.hc = res.data.datas.datais.HC_VALUE_H1;
                this.cohcinfo.datamaxhi_2.co = res.data.datas.datais.CO_VALUE_H2;
                this.cohcinfo.datamaxhi_2.hc = res.data.datas.datais.HC_VALUE_H2;
                this.failed_reasons = res.data.datas.datais.FAILED_REASONS;

                this.itemcheck.varssumcheck[0] = res.data.datas.datais.RESULT_DIFF_BRAKE == 1?'1':res.data.datas.datais.RESULT_DIFF_BRAKE;
                this.itemcheck.varssumcheck[1] = res.data.datas.datais.RESULT_FOOT_BRAKE == 1?'1':res.data.datas.datais.RESULT_FOOT_BRAKE;
                this.itemcheck.varssumcheck[2] = res.data.datas.datais.RESULT_HAND_BRAKE == 1?'1':res.data.datas.datais.RESULT_HAND_BRAKE;
                this.itemcheck.varssumcheck[3] = res.data.datas.datais.RESULT_WHEEL_ALIGHTMENT == 1?'1':res.data.datas.datais.RESULT_WHEEL_ALIGHTMENT;
                this.itemcheck.varssumcheck[4] = res.data.datas.datais.CHECK_VOICE == 1?'1':res.data.datas.datais.CHECK_VOICE;
                this.itemcheck.varssumcheck[5] = res.data.datas.datais.CHECK_PIPE == 1?'1':res.data.datas.datais.CHECK_PIPE;
                this.itemcheck.varssumcheck[6] = res.data.datas.datais.CHECK_HOOTER == 1?'1':res.data.datas.datais.CHECK_HOOTER;
                this.itemcheck.varssumcheck[7] = res.data.datas.datais.CHECK_SPEED == 1?'1':res.data.datas.datais.CHECK_SPEED;
                this.itemcheck.varssumcheck[8] = res.data.datas.datais.CHECK_HEAD_LIGHT == 1?'1':res.data.datas.datais.CHECK_HEAD_LIGHT;
                this.itemcheck.varssumcheck[9] = res.data.datas.datais.CHECK_SIGNAL_LIGHT == 1?'1':res.data.datas.datais.CHECK_SIGNAL_LIGHT;
                this.itemcheck.varssumcheck[10] = res.data.datas.datais.CHECK_PLATE_LIGHT == 1?'1':res.data.datas.datais.CHECK_PLATE_LIGHT;
                this.itemcheck.varssumcheck[11] = res.data.datas.datais.CHECK_RAIN_FLY == 1?'1':res.data.datas.datais.CHECK_RAIN_FLY;
                this.itemcheck.varssumcheck[12] = res.data.datas.datais.CHECK_WINDOWS_GLASS == 1?'1':res.data.datas.datais.CHECK_WINDOWS_GLASS;
                this.itemcheck.varssumcheck[13] = res.data.datas.datais.CHECK_CONTROL_SYS == 1?'1':res.data.datas.datais.CHECK_CONTROL_SYS;
                this.itemcheck.varssumcheck[14] = res.data.datas.datais.CHECK_RUBBER == 1?'1':res.data.datas.datais.CHECK_RUBBER;
                this.itemcheck.varssumcheck[15] = res.data.datas.datais.CHECK_FUEL_TANK == 1?'1':res.data.datas.datais.CHECK_FUEL_TANK;
                this.itemcheck.varssumcheck[16] = res.data.datas.datais.CHECK_SUSPENSION == 1?'1':res.data.datas.datais.CHECK_SUSPENSION;
                this.itemcheck.varssumcheck[17] = 1;
                this.itemcheck.varssumcheck[18] = res.data.datas.datais.CHECK_BODY == 1?'1':res.data.datas.datais.CHECK_BODY;
                this.itemcheck.varssumcheck[19] = res.data.datas.datais.CHECK_DOOR == 1?'1':res.data.datas.datais.CHECK_DOOR;
                this.itemcheck.varssumcheck[20] = 1;
                this.itemcheck.varssumcheck[21] = 1;
                this.itemcheck.varssumcheck[22] = res.data.datas.datais.CHECK_BELT == 1?'1':res.data.datas.datais.CHECK_BELT;
                this.itemcheck.varssumcheck[23] = res.data.datas.datais.CHECK_AUTHE == 1?'1':res.data.datas.datais.CHECK_AUTHE;
                this.itemcheck_cohc.varssumcheck[0] = res.data.datas.datais.CHECK_PIPE == 1?'1':res.data.datas.datais.CHECK_L1;
                this.itemcheck_cohc.varssumcheck[1] = res.data.datas.datais.CHECK_PIPE == 1?'1':res.data.datas.datais.CHECK_L2;
                this.itemcheck_cohc.varssumcheck[2] = res.data.datas.datais.CHECK_PIPE == 1?'1':res.data.datas.datais.CHECK_H1;
                this.itemcheck_cohc.varssumcheck[3] = res.data.datas.datais.CHECK_PIPE == 1?'1':res.data.datas.datais.CHECK_H2;
                this.itemcheck_cohc.varssumcheck[4] = res.data.datas.datais.CHECK_PIPE == 1?'1':res.data.datas.datais.CHECK_CH12;
                this.st_value = res.data.datas.datast[0];
                this.wh_value = res.data.datas.datawh?res.data.datas.datawh:[];
                if(res.data.timenow.toFixed(3) <= 5.00 && res.data.timenow.toFixed(3) >= -5.00 ){
                    await this.loadModel();
                }else{
                    this.$swal({
                        title: "คำเตือน",
                        text: "เวลาของเครื่องที่ใช้งานไม่ตรงกับเวลาของเซิฟเวอร์ กรุณาตั้งเวลาให้เป็นปัจจุบันก่อน",
                        icon: "error"
                    }); 
                }
            }
        },
        async getini() {
            await fetch('http://127.0.0.1:8080/api/getini', {
                method: "GET"
            })
            .then((response) => {
            response.json().then((rs) => {

                this.settings = rs;
                if(rs.ocr.ocr_type !== undefined){
                    this.ocr_type_link_capture = rs.ocr.ocr_type;
                }else{
                    this.ocr_type_link_capture = 'b64';
                }
                if(rs.server.tf1 !== undefined){
                    this.camera_setting_turn = parseInt(rs.server.tf1);
                }else{
                    this.camera_setting_turn = 0;
                }
                if(this.settings.server.smokefilter == 45){
                    this.smoke_setting = 'opacity';
                }else{
                    this.smoke_setting = 'filter';
                }
                this.pc_mac = this.settings.macaddress;
                let ars = JSON.parse(this.settings.ocr.ocrsetting);
                for(let i=0;i<ars.length;i++) {
                    this.rectangles.push({ width: ars[i].width, height: ars[i].height, left: ars[i].x, top: ars[i].y });
                    this.unit_ocr.push([ars[i].unit]);
                }
                this.page_status = true;
                this.getsh()
            });
            }).catch((err) => {
                this.page_status = false;
                this.$swal({
                    title: "ไม่สำเร็จ",
                    text: "เรียกข้อมูลไม่ได้ กรุณาเปิดโปรแกรม v6(หน้าจอดำ) ค้างไว้ และไปหน้าค้นหาเพื่อตรวจแก้อีกครั้ง",
                    icon: "error"
                });
            });
        },   
        toggleCamera() {
            if(this.st_read.check_hw == 1){
                if(this.wh_read.filter(({hw_type}) => hw_type == 1).length >= 1){
                    if(this.isCameraOpen) {
                        this.isCameraOpen = false;
                        this.isPhotoTaken = false;
                        this.isShotPhoto = false;
                        this.stopCameraStream();
                    } else {
                        this.isCameraOpen = true;
                        this.createCameraElement();
                    }
                }else{
                    this.$swal({
                        title: "ตำเตือน",
                        text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาตรวจสอบการลงทะเบียนข้อมูลของท่าน",
                        icon: "error"
                    });
                }
            }else{
                navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                devices.forEach((device) => {
                    if( device.kind === 'videoinput'){
                        this.camera_name = device.label;
                        this.camera_deviceId = device.deviceId;
                    }
                });
                })
                .catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                });

                if(this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.isShotPhoto = false;
                    this.stopCameraStream();
                } else {
                    this.isCameraOpen = true;
                    this.createCameraElement();
                }
            }
        },

        async createCameraElement() {
            if(this.st_read.check_hw == 1){
                this.isLoading = true;
                let check_value = null;
                let getdatacamera = null;

                await navigator.mediaDevices.getUserMedia({video: true});  
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                getdatacamera = videoDevices;

                for(let i = 0; i < this.wh_read.filter(({hw_type}) => hw_type == 1).length; i++){
                    // await navigator.mediaDevices.getUserMedia({ video: {deviceId: { exact: this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_number}} })
                    await navigator.mediaDevices.getUserMedia({ video: {deviceId: { exact: getdatacamera.filter(({label}) => label == this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_cam_lable)[0].deviceId}} })
                    .then(stream => {
                        check_value = stream
                        this.camera_name =  this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_cam_lable;
                        this.camera_deviceId =  this.wh_read.filter(({hw_type}) => hw_type == 1)[i].hw_number;
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    if(check_value != null){
                        break;
                    }
                }
                if(check_value != null){
                    this.isLoading = false;
                    this.$refs.camera.srcObject = check_value;
                }else{
                    this.isLoading = false;
                    this.isCameraOpen = false;
                    this.$swal({
                        title: "ตำเตือน",
                        text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาเช็คอุปกรณ์รวมจุดเชื่อมต่อต่างๆของท่านว่าทำงานปกติหรือไม่ จากนั้นปิดโปรแกรมแล้วเปิดใหม่อีกครั้ง !",
                        icon: "error"
                    });
                }
                    
            }else{
                this.isLoading = true;
                const constraints = (window.constraints = {
                        audio: false,
                        video: true
                    });
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        this.isLoading = false;
                        this.$refs.camera.srcObject = stream;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.isCameraOpen = false;
                        this.$swal({
                        title: "ตำเตือน",
                        text: "ไม่สามารถเรียกใช้งานกล้องได้ กรุณาเช็คอุปกรณ์รวมจุดเชื่อมต่อต่างๆของท่านว่าทำงานปกติหรือไม่ จากนั้นปิดโปรแกรมแล้วเปิดใหม่อีกครั้ง !",
                        icon: "error"
                        });
                    });
            }
        },

        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
                this.b64img = '';
                this.imageview = '';
            });
        },

        takePhoto() {
            if(!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                this.isShotPhoto = false;
                }, FLASH_TIMEOUT);

                this.isPhotoTaken = !this.isPhotoTaken;

                const contexts = this.$refs.canvas.getContext('2d');
                if(this.camera_setting_turn == 1){
                    contexts.scale(-1,1);
                    contexts.drawImage(this.$refs.camera, -640, 0, 640, 480);
                }else{
                    // img.onload = function() {
                        contexts.drawImage(this.$refs.camera, 0, 0, 640, 480);
                    // }
                }

                this.uploadImage();

            }else{

                this.isPhotoTaken = !this.isPhotoTaken;
                this.b64img = '';
                this.imageview = '';
            }

        },

        async uploadImage() {
            this.$swal({
                title: 'ระบบกำลังตรวจสอบรูป',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            const getb64img = await document.getElementById("photoTaken").toDataURL("image/jpeg" , 1.0)
            const countvehicle = await this.processImage(getb64img)
            if(countvehicle == false){
                this.b64img = '';
                this.$swal({
                    title: "คำเตือน",
                    text: "ตรวจสอบรูปแล้วไม่พบยานพาหนะในรูปกรุณาถ่ายใหม่หรือปรับมุมกล้องให้ชัดเจนขึ้น",
                    icon: "error"
                }); 
            }else{
                const countcheck = await this.checkModel(countvehicle)
                if(countcheck == true){
                    this.b64img = this.imageview;
                    this.$swal.close()
                }else{
                    this.b64img = '';
                    this.$swal({
                        title: "คำเตือน",
                        text: "เกิดข้อผิดพลาดในการตรวจสอบรูปภาพกรุณาลองถ่ายใหม่อีกครั้ง",
                        icon: "error"
                    }); 
                }
            }
        },
        // END WEBCAM
        async sends(){
        if(this.typecar){
            if(this.settings.server.basetype == 'udp'){
            await fetch('http://127.0.0.1:8080/s', {
                method: "GET"
            })
            .then((response) => {
                response.json().then((rs) => {
                    let datas = {
                        uid: storeAuth.id,
                        gid: storeAuth.group_id,
                        tid: 1,
                        datas:[
                            rs.token,
                        ]
                    }
                    axios.post(`checktoken`,datas).then((rsuuid) => {
                        if(rsuuid.data.status == 1){
                            if(this.settings.server.muller == 1){
                                let arrs = rs.result.split("#")
                                this.varss = []
                                for(let i=0;i<arrs.length;i++) {
                                    const items = arrs[i].split("=");
                                    this.varss.push(items[1]);
                                }
                                if(this.varss.length == 9){
                                    if(this.varss[0] != ''){ this.vars.axle1l = this.varss[0];}else{ this.vars.axle1l = '0';}
                                    if(this.varss[1] != ''){ this.vars.axle1r = this.varss[1];}else{ this.vars.axle1r = '0';}
                                    if(this.varss[2] != ''){ this.vars.axle2l = this.varss[2];}else{ this.vars.axle2l = '0';}
                                    if(this.varss[3] != ''){ this.vars.axle2r = this.varss[3];}else{ this.vars.axle2r = '0';}
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    if(this.varss[4] != ''){ this.vars.wheelAlignment = this.varss[4];}else{ this.vars.wheelAlignment = '0';}
                                    if(this.varss[5] != ''){ this.vars.axle1Weight = this.varss[5];}else{ this.vars.axle1Weight = '0';}
                                    if(this.varss[6] != ''){ this.vars.axle2Weight = this.varss[6];}else{ this.vars.axle2Weight = '0';}
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    if(this.varss[7] != ''){ this.vars.handbrakeForce2L = this.varss[7];}else{ this.vars.handbrakeForce2L = '0';}
                                    if(this.varss[8] != ''){ this.vars.handbrakeForce2R = (parseFloat(this.varss[8])).toString();}else{ this.vars.handbrakeForce2R = '0';}
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }else if(this.varss.length == 10){
                                    this.vars.axle1l = (this.varss[1] * 10).toString();
                                    this.vars.axle1r = (this.varss[2] * 10).toString();
                                    this.vars.axle2l = (this.varss[3] * 10).toString();
                                    this.vars.axle2r = (this.varss[4] * 10).toString();
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    this.vars.wheelAlignment = "0";
                                    this.vars.axle1Weight = (this.varss[5] * 9.81).toFixed(2);
                                    this.vars.axle2Weight = (this.varss[6] * 9.81).toFixed(2);
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    this.vars.handbrakeForce2L = (this.varss[7] * 10).toString();
                                    this.vars.handbrakeForce2R = (this.varss[8] * 10).toString();
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }else if(this.varss.length == 17){
                                    if(this.varss[1] != ''){ this.vars.axle1l = this.varss[1];}else{ this.vars.axle1l = '0';}
                                    if(this.varss[2] != ''){ this.vars.axle1r = this.varss[2];}else{ this.vars.axle1r = '0';}
                                    if(this.varss[3] != ''){ this.vars.axle2l = this.varss[3];}else{ this.vars.axle2l = '0';}
                                    if(this.varss[4] != ''){ this.vars.axle2r = this.varss[4];}else{ this.vars.axle2r = '0';}
                                    if(this.varss[5] != ''){ this.vars.axle3l = this.varss[5];}else{ this.vars.axle3l = '0';}
                                    if(this.varss[6] != ''){ this.vars.axle3r = this.varss[6];}else{ this.vars.axle3r = '0';}
                                    if(this.varss[7] != ''){ this.vars.axle4l = this.varss[7];}else{ this.vars.axle4l = '0';}
                                    if(this.varss[8] != ''){ this.vars.axle4r = this.varss[8];}else{ this.vars.axle4r = '0';}
                                    if(this.varss[9] != ''){ this.vars.wheelAlignment = this.varss[9];}else{ this.vars.wheelAlignment = '0';}
                                    if(this.varss[10] != ''){ this.vars.axle1Weight = this.varss[10];}else{ this.vars.axle1Weight = '0';}
                                    if(this.varss[11] != ''){ this.vars.axle2Weight = this.varss[11];}else{ this.vars.axle2Weight = '0';}
                                    if(this.varss[12] != ''){ this.vars.axle3Weight = this.varss[12];}else{ this.vars.axle3Weight = '0';}
                                    if(this.varss[13] != ''){ this.vars.axle4Weight = this.varss[13];}else{ this.vars.axle4Weight = '0';}
                                    if(this.varss[14] != ''){ this.vars.handbrakeForce2L = this.varss[14];}else{ this.vars.handbrakeForce2L = '0';}
                                    if(this.varss[15] != ''){ this.vars.handbrakeForce2R = this.varss[15];}else{ this.vars.handbrakeForce2R = '0';}
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }else if(this.varss.length == 11){
                                    this.vars.axle1l = this.varss[1];
                                    this.vars.axle1r = this.varss[2];
                                    this.vars.axle2l = this.varss[3];
                                    this.vars.axle2r = this.varss[4];
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    this.vars.wheelAlignment = this.varss[5];
                                    this.vars.axle1Weight = this.varss[6];
                                    this.vars.axle2Weight = this.varss[7]
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    this.vars.handbrakeForce2L = this.varss[8];
                                    this.vars.handbrakeForce2R = this.varss[9];
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }else if(this.varss.length == 28){
                                    this.vars.axle1l = this.varss[1];
                                    this.vars.axle1r = this.varss[2];
                                    this.vars.axle2l = this.varss[3];
                                    this.vars.axle2r = this.varss[4];
                                    this.vars.axle3l = this.varss[5];
                                    this.vars.axle3r = this.varss[6];
                                    this.vars.axle4l = this.varss[7];
                                    this.vars.axle4r = this.varss[8];
                                    this.vars.wheelAlignment = this.varss[11];
                                    this.vars.axle1Weight = this.varss[12];
                                    this.vars.axle2Weight = this.varss[13];
                                    this.vars.axle3Weight = this.varss[14];
                                    this.vars.axle4Weight = this.varss[15];
                                    this.vars.handbrakeForce2L = this.varss[19];
                                    this.vars.handbrakeForce2R = this.varss[20];
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }else{
                                    this.vars.axle1l = this.varss[1];
                                    this.vars.axle1r = this.varss[2];
                                    this.vars.axle2l = this.varss[3];
                                    this.vars.axle2r = this.varss[4];
                                    this.vars.axle3l = this.varss[5];
                                    this.vars.axle3r = this.varss[6];
                                    this.vars.axle4l = this.varss[7];
                                    this.vars.axle4r = this.varss[8];
                                    this.vars.wheelAlignment = this.varss[9];
                                    this.vars.axle1Weight = this.varss[10];
                                    this.vars.axle2Weight = this.varss[11];
                                    this.vars.axle3Weight = this.varss[12];
                                    this.vars.axle4Weight = this.varss[13];
                                    this.vars.handbrakeForce2L = this.varss[14];
                                    this.vars.handbrakeForce2R = this.varss[15];
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }
                            }else if(this.settings.server.muller == 2){
                                let arrs = rs.result.split("#")
                                this.varss = []
                                for(let i=0;i<arrs.length;i++) {
                                    const items = arrs[i].split("=");
                                    this.varss.push(items[1]);
                                }
                                if(this.varss.length == 11){
                                    this.vars.axle1l = (this.varss[1] * 1000).toFixed(2);
                                    this.vars.axle1r = (this.varss[2] * 1000).toFixed(2);
                                    this.vars.axle2l = (this.varss[3] * 1000).toFixed(2);
                                    this.vars.axle2r = (this.varss[4] * 1000).toFixed(2);
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    this.vars.wheelAlignment = this.varss[5];
                                    this.vars.axle1Weight = (this.varss[6] * 9.81).toFixed(2);
                                    this.vars.axle2Weight = (this.varss[7] * 9.81).toFixed(2);
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    this.vars.handbrakeForce2L = (this.varss[8] * 1000).toFixed(2);
                                    this.vars.handbrakeForce2R = (this.varss[9] * 1000).toFixed(2);
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }

                            }else if(this.settings.server.muller == 4){
                                let arrs = rs.result.split("#")
                                this.varss = []
                                for(let i=0;i<arrs.length;i++) {
                                    const items = arrs[i].split("=");
                                    this.varss.push(items[1]);
                                }
                                if(this.varss.length == 9){
                                    if(this.varss[0] != ''){ this.vars.axle1l = this.varss[0];}else{ this.vars.axle1l = '0';}
                                    if(this.varss[1] != ''){ this.vars.axle1r = this.varss[1];}else{ this.vars.axle1r = '0';}
                                    if(this.varss[2] != ''){ this.vars.axle2l = this.varss[2];}else{ this.vars.axle2l = '0';}
                                    if(this.varss[3] != ''){ this.vars.axle2r = this.varss[3];}else{ this.vars.axle2r = '0';}
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    if(this.varss[4] != ''){ this.vars.wheelAlignment = this.varss[4];}else{ this.vars.wheelAlignment = '0';}
                                    if(this.varss[5] != ''){ this.vars.axle1Weight = this.varss[5];}else{ this.vars.axle1Weight = '0';}
                                    if(this.varss[6] != ''){ this.vars.axle2Weight = this.varss[6];}else{ this.vars.axle2Weight = '0';}
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    if(this.varss[7] != ''){ this.vars.handbrakeForce2L = this.varss[7];}else{ this.vars.handbrakeForce2L = '0';}
                                    if(this.varss[8] != ''){ this.vars.handbrakeForce2R = (parseFloat(this.varss[8])).toString();}else{ this.vars.handbrakeForce2R = '0';}
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }

                            }else{
                                let arrs = rs.result.split("#")
                                this.varss = []
                                for(let i=0;i<arrs.length;i++) {
                                    const items = arrs[i].split("=");
                                    this.varss.push(items[1]);
                                }
                                if(this.varss.length == 10){
                                    this.vars.axle1l = this.varss[1];
                                    this.vars.axle1r = this.varss[2];
                                    this.vars.axle2l = this.varss[3];
                                    this.vars.axle2r = this.varss[4];
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    this.vars.wheelAlignment = "0";
                                    this.vars.axle1Weight = (this.varss[5] * 9.81).toFixed(2);
                                    this.vars.axle2Weight = (this.varss[6] * 9.81).toFixed(2);
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    this.vars.handbrakeForce2L = this.varss[7];
                                    this.vars.handbrakeForce2R = this.varss[8];
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                }else if(this.varss.length == 9){
                                    this.vars.axle1l = this.varss[0];
                                    this.vars.axle1r = this.varss[1];
                                    this.vars.axle2l = this.varss[2];
                                    this.vars.axle2r = this.varss[3];
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    this.vars.wheelAlignment = this.varss[4];
                                    this.vars.axle1Weight = (this.varss[5]* 9.81).toFixed(0);
                                    this.vars.axle2Weight = (this.varss[6]* 9.81).toFixed(0);
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    this.vars.handbrakeForce2L = this.varss[7];
                                    this.vars.handbrakeForce2R = (parseFloat(this.varss[8])).toString();
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }else if(this.varss.length == 11){
                                    this.vars.axle1l = this.varss[1];
                                    this.vars.axle1r = this.varss[2];
                                    this.vars.axle2l = this.varss[3];
                                    this.vars.axle2r = this.varss[4];
                                    this.vars.axle3l = "0";
                                    this.vars.axle3r = "0";
                                    this.vars.axle4l = "0";
                                    this.vars.axle4r = "0";
                                    this.vars.wheelAlignment = this.varss[5];
                                    this.vars.axle1Weight = (this.varss[6] * 9.81).toFixed(2);
                                    this.vars.axle2Weight = (this.varss[7] * 9.81).toFixed(2);
                                    this.vars.axle3Weight = "0";
                                    this.vars.axle4Weight = "0";
                                    this.vars.handbrakeForce2L = this.varss[8];
                                    this.vars.handbrakeForce2R = this.varss[9];
                                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum1)) this.varssum1 = 0
                                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum2)) this.varssum2 = 0
                                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum3)) this.varssum3 = 0
                                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                                    if (isNaN(this.varssum4)) this.varssum4 = 0
                                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}
                                }
                            }
                        }else{
                            this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                icon:'error',
                            })
                        }
                    }).catch(err=>{
                        this.$swal({title:'เชื่อมกับเซิฟเวอร์ไม่ได้',
                        icon:'error',
                        })
                    })
                });
            }).catch(err=>{
                this.$swal({title:'รับค่าไม่สำเร็จ !',
                icon:'error',
                })
            })
        }else if(this.settings.server.basetype == 'ocr'){
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กรุณารอสักครู่ ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()

            if(this.ocr_type_link_capture == 'b64'){
                // console.log('----- b64 -----');
                let ocr_type_link_capture_new = this.ocr_type_link_capture;
                // function testb64() {
                    await fetch('http://127.0.0.1:8080/img/Capture.'+ocr_type_link_capture_new, {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let datas = {
                                uid: storeAuth.id,
                                gid: storeAuth.group_id,
                                tid: 1,
                                datas:[
                                    rs.token,
                                ]
                            }
                            axios.post(`checktoken`,datas).then((rsuuid) => {
                                if(rsuuid.data.status == 1){
                                    var img = rs.result
                                    tc(img, 'base64')
                                }else{
                                    this.$swal({title:'ยืนยันค่าไม่สำเร็จ',
                                        icon:'error',
                                    })
                                }
                            }).catch(err=>{
                                this.$swal({title:'เชื่อมกับเซิฟเวอร์ไม่ได้',
                                icon:'error',
                                })
                            })
                        });
                    }).catch(err=>{
                        this.$swal({title:'รับค่าไม่สำเร็จ !',
                        icon:'error',
                        })
                    })
                // }
                
                // testb64()

                //###############################################################################
                const tc = async (img, type) => {

                    // let tversion = 'v5.0.1';
                    // var startTime, endTime;
                    // startTime = new Date();

                    const scheduler = createScheduler();
                    const worker1 = await createWorker('eng');
                    

                    const rectangles = JSON.parse(JSON.stringify(this.rectangles));

                    await worker1.setParameters({
                        tessedit_char_whitelist: '0123456789+-.',
                    });
                    scheduler.addWorker(worker1);

                    const results = await Promise.all(rectangles.map((rectangle) => (
                        scheduler.addJob('recognize', img, { rectangle })
                    )));
                    var dataocr = results.map(r => r.data.text.replace('\n',''));
                    this.ocrvalue = dataocr;

                    await scheduler.terminate();

                    if(this.unit_ocr[0] == 'kN'){
                        if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 1000).toFixed(2);}
                        this.vars.axle1l.toString();
                    }else if(this.unit_ocr[0] == 'daN'){
                        if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 10).toFixed(2);}
                        this.vars.axle1l.toString();
                    }else{
                        if (!this.ocrvalue[0]){
                            this.vars.axle1l = '0'
                        }else{
                            this.vars.axle1l = Number(this.ocrvalue[0]).toFixed(2);
                        }
                        this.vars.axle1l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[1] == 'kN'){
                        if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 1000).toFixed(2);}
                        this.vars.axle1r.toString();
                    }else if(this.unit_ocr[1] == 'daN'){
                        if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 10).toFixed(2);}
                        this.vars.axle1r.toString();
                    }else{
                        if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{
                            this.vars.axle1r = Number(this.ocrvalue[1]).toFixed(2);
                        }
                        this.vars.axle1r.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[2] == 'kN'){
                        if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 1000).toFixed(2);}
                        this.vars.axle2l.toString();
                    }else if(this.unit_ocr[2] == 'daN'){
                        if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 10).toFixed(2);}
                        this.vars.axle2l.toString();
                    }else{
                        if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{
                            this.vars.axle2l = Number(this.ocrvalue[2]).toFixed(2);
                        }
                        this.vars.axle2l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[3] == 'kN'){
                        if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 1000).toFixed(2);}
                        this.vars.axle2r.toString();
                    }else if(this.unit_ocr[3] == 'daN'){
                        if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 10).toFixed(2);}
                        this.vars.axle2r.toString();
                    }else{
                        if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{
                            this.vars.axle2r = Number(this.ocrvalue[3]).toFixed(2);}
                        this.vars.axle2r.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[4] == 'kN'){
                        if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 1000).toFixed(2);}
                        this.vars.axle3l.toString();
                    }else if(this.unit_ocr[4] == 'daN'){
                        if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 10).toFixed(2);}
                        this.vars.axle3l.toString();
                    }else{
                        if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{
                            this.vars.axle3l = Number(this.ocrvalue[4]).toFixed(2);}
                        this.vars.axle3l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[5] == 'kN'){
                        if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 1000).toFixed(2);}
                        this.vars.axle3r.toString();
                    }else if(this.unit_ocr[5] == 'daN'){
                        if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 10).toFixed(2);}
                        this.vars.axle3r.toString();
                    }else{
                        if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{
                            this.vars.axle3r = Number(this.ocrvalue[5]).toFixed(2);}
                        this.vars.axle3r.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[6] == 'kN'){
                        if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 1000).toFixed(2);}
                        this.vars.axle4l.toString();
                    }else if(this.unit_ocr[6] == 'daN'){
                        if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 10).toFixed(2);}
                        this.vars.axle4l.toString();
                    }else{
                        if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{
                            this.vars.axle4l = Number(this.ocrvalue[6]).toFixed(2);}
                        this.vars.axle4l.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[7] == 'kN'){
                        if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 1000).toFixed(2);}
                        this.vars.axle4r.toString();
                    }else if(this.unit_ocr[7] == 'daN'){
                        if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 10).toFixed(2);}
                        this.vars.axle4r.toString();
                    }else{
                        if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{
                            this.vars.axle4r = Number(this.ocrvalue[7]).toFixed(2);}
                        this.vars.axle4r.toString();
                    }
                    // ---------------
                    if (!this.ocrvalue[10]){ this.vars.wheelAlignment = '0'}else{this.vars.wheelAlignment = this.ocrvalue[10].replace(/\s/g, '');}
                    // ---------------
                    if(this.unit_ocr[11] == 'kg'){
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 9.81).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }else if(this.unit_ocr[11] == 'kN'){
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 1000).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }else if(this.unit_ocr[11] == 'daN'){
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 10).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }else{
                        if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{
                            this.vars.axle1Weight = Number(this.ocrvalue[11]).toFixed(2);}
                        this.vars.axle1Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[12] == 'kg'){
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 9.81).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }else if(this.unit_ocr[12] == 'kN'){
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 1000).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }else if(this.unit_ocr[12] == 'daN'){
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 10).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }else{
                        if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{
                            this.vars.axle2Weight = Number(this.ocrvalue[12]).toFixed(2);}
                        this.vars.axle2Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[13] == 'kg'){
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 9.81).toFixed(2);}
                        this.vars.axle3Weight.toString();
                    }else if(this.unit_ocr[13] == 'kN'){
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 1000).toFixed(2);}
                        this.vars.axle3Weight.toString();
                    }else if(this.unit_ocr[13] == 'daN'){
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 10).toFixed(2);}
                        this.vars.axle3Weight.toString();
                    }else{
                        if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{
                            this.vars.axle3Weight = Number(this.ocrvalue[13]).toFixed(2);
                        }
                        this.vars.axle3Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[14] == 'kg'){
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 9.81).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }else if(this.unit_ocr[14] == 'kN'){
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 1000).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }else if(this.unit_ocr[14] == 'daN'){
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 10).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }else{
                        if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{
                            this.vars.axle4Weight = Number(this.ocrvalue[14]).toFixed(2);}
                        this.vars.axle4Weight.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[16] == 'kN'){
                        if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 1000).toFixed(2);}
                        this.vars.handbrakeForce2L.toString();
                    }else if(this.unit_ocr[16] == 'daN'){
                        if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 10).toFixed(2);}
                        this.vars.handbrakeForce2L.toString();
                    }else{
                        if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{
                            this.vars.handbrakeForce2L = Number(this.ocrvalue[16]).toFixed(2);}
                        this.vars.handbrakeForce2L.toString();
                    }
                    // ---------------
                    if(this.unit_ocr[17] == 'kN'){
                        if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 1000).toFixed(2);}
                        this.vars.handbrakeForce2R.toString();
                    }else if(this.unit_ocr[17] == 'daN'){
                        if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 10).toFixed(2);}
                        this.vars.handbrakeForce2R.toString();
                    }else{
                        if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{
                            this.vars.handbrakeForce2R = Number(this.ocrvalue[17]).toFixed(2);}
                        this.vars.handbrakeForce2R.toString();
                    }
                    // ---------------
                    this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum1)) this.varssum1 = 0
                    this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum2)) this.varssum2 = 0
                    this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum3)) this.varssum3 = 0
                    this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum4)) this.varssum4 = 0
                    if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                    this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                    if (isNaN(this.varssum5)) this.varssum5 = 0
                    if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                    this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                    if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                    if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}

                    this.$swal.close();
                    // endTime = new Date();
                    // var timeDiff = endTime - startTime; //in ms
                    // timeDiff /= 1000;
                    // var seconds = Math.round(timeDiff);
                    // console.log('ORC เวอร์ชั่น ', tversion, ' ใช้เวลา ', seconds + ' วินาที');
                
                }
            }else{

                // let tversion = 'v3.0.3';
                // var startTime, endTime;
                // startTime = new Date();

                // fetch('http://127.0.0.1:8080/img/Capture.data', {
                //     method: "GET"
                // })
                // .then((response) => {
                //     response.json().then((rs) => {
                //         var dataocr = rs.result.data.map(r => r.replace('\n',''));
                //         this.ocrvalue = dataocr;

                //         if(this.unit_ocr[0] == 'kN'){
                //             if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 1000).toFixed(2);}
                //             this.vars.axle1l.toString();
                //         }else if(this.unit_ocr[0] == 'daN'){
                //             if (!this.ocrvalue[0]){ this.vars.axle1l = '0'}else{this.vars.axle1l = (this.ocrvalue[0] * 10).toFixed(2);}
                //             this.vars.axle1l.toString();
                //         }else{
                //             if (!this.ocrvalue[0]){
                //                 this.vars.axle1l = '0'
                //             }else{
                //                 this.vars.axle1l = Number(this.ocrvalue[0]).toFixed(2);
                //             }
                //             this.vars.axle1l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[1] == 'kN'){
                //             if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 1000).toFixed(2);}
                //             this.vars.axle1r.toString();
                //         }else if(this.unit_ocr[1] == 'daN'){
                //             if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{this.vars.axle1r = (this.ocrvalue[1] * 10).toFixed(2);}
                //             this.vars.axle1r.toString();
                //         }else{
                //             if (!this.ocrvalue[1]){ this.vars.axle1r = '0'}else{
                //                 this.vars.axle1r = Number(this.ocrvalue[1]).toFixed(2);
                //             }
                //             this.vars.axle1r.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[2] == 'kN'){
                //             if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 1000).toFixed(2);}
                //             this.vars.axle2l.toString();
                //         }else if(this.unit_ocr[2] == 'daN'){
                //             if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{this.vars.axle2l = (this.ocrvalue[2] * 10).toFixed(2);}
                //             this.vars.axle2l.toString();
                //         }else{
                //             if (!this.ocrvalue[2]){ this.vars.axle2l = '0'}else{
                //                 this.vars.axle2l = Number(this.ocrvalue[2]).toFixed(2);
                //             }
                //             this.vars.axle2l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[3] == 'kN'){
                //             if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 1000).toFixed(2);}
                //             this.vars.axle2r.toString();
                //         }else if(this.unit_ocr[3] == 'daN'){
                //             if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{this.vars.axle2r = (this.ocrvalue[3] * 10).toFixed(2);}
                //             this.vars.axle2r.toString();
                //         }else{
                //             if (!this.ocrvalue[3]){ this.vars.axle2r = '0'}else{
                //                 this.vars.axle2r = Number(this.ocrvalue[3]).toFixed(2);}
                //             this.vars.axle2r.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[4] == 'kN'){
                //             if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 1000).toFixed(2);}
                //             this.vars.axle3l.toString();
                //         }else if(this.unit_ocr[4] == 'daN'){
                //             if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{this.vars.axle3l = (this.ocrvalue[4] * 10).toFixed(2);}
                //             this.vars.axle3l.toString();
                //         }else{
                //             if (!this.ocrvalue[4]){ this.vars.axle3l = '0'}else{
                //                 this.vars.axle3l = Number(this.ocrvalue[4]).toFixed(2);}
                //             this.vars.axle3l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[5] == 'kN'){
                //             if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 1000).toFixed(2);}
                //             this.vars.axle3r.toString();
                //         }else if(this.unit_ocr[5] == 'daN'){
                //             if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{this.vars.axle3r = (this.ocrvalue[5] * 10).toFixed(2);}
                //             this.vars.axle3r.toString();
                //         }else{
                //             if (!this.ocrvalue[5]){ this.vars.axle3r = '0'}else{
                //                 this.vars.axle3r = Number(this.ocrvalue[5]).toFixed(2);}
                //             this.vars.axle3r.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[6] == 'kN'){
                //             if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 1000).toFixed(2);}
                //             this.vars.axle4l.toString();
                //         }else if(this.unit_ocr[6] == 'daN'){
                //             if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{this.vars.axle4l = (this.ocrvalue[6] * 10).toFixed(2);}
                //             this.vars.axle4l.toString();
                //         }else{
                //             if (!this.ocrvalue[6]){ this.vars.axle4l = '0'}else{
                //                 this.vars.axle4l = Number(this.ocrvalue[6]).toFixed(2);}
                //             this.vars.axle4l.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[7] == 'kN'){
                //             if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 1000).toFixed(2);}
                //             this.vars.axle4r.toString();
                //         }else if(this.unit_ocr[7] == 'daN'){
                //             if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{this.vars.axle4r = (this.ocrvalue[7] * 10).toFixed(2);}
                //             this.vars.axle4r.toString();
                //         }else{
                //             if (!this.ocrvalue[7]){ this.vars.axle4r = '0'}else{
                //                 this.vars.axle4r = Number(this.ocrvalue[7]).toFixed(2);}
                //             this.vars.axle4r.toString();
                //         }
                //         // ---------------
                //         if (!this.ocrvalue[10]){ this.vars.wheelAlignment = '0'}else{this.vars.wheelAlignment = this.ocrvalue[10].replace(/\s/g, '');}
                //         // ---------------
                //         if(this.unit_ocr[11] == 'kg'){
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 9.81).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }else if(this.unit_ocr[11] == 'kN'){
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 1000).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }else if(this.unit_ocr[11] == 'daN'){
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{this.vars.axle1Weight = (this.ocrvalue[11] * 10).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[11]){ this.vars.axle1Weight = '0'}else{
                //                 this.vars.axle1Weight = Number(this.ocrvalue[11]).toFixed(2);}
                //             this.vars.axle1Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[12] == 'kg'){
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 9.81).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }else if(this.unit_ocr[12] == 'kN'){
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 1000).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }else if(this.unit_ocr[12] == 'daN'){
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{this.vars.axle2Weight = (this.ocrvalue[12] * 10).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[12]){ this.vars.axle2Weight = '0'}else{
                //                 this.vars.axle2Weight = Number(this.ocrvalue[12]).toFixed(2);}
                //             this.vars.axle2Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[13] == 'kg'){
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 9.81).toFixed(2);}
                //             this.vars.axle3Weight.toString();
                //         }else if(this.unit_ocr[13] == 'kN'){
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 1000).toFixed(2);}
                //             this.vars.axle3Weight.toString();
                //         }else if(this.unit_ocr[13] == 'daN'){
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{this.vars.axle3Weight = (this.ocrvalue[13] * 10).toFixed(2);}
                //             this.vars.axle3Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[13]){ this.vars.axle3Weight = '0'}else{
                //                 this.vars.axle3Weight = Number(this.ocrvalue[13]).toFixed(2);
                //             }
                //             this.vars.axle3Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[14] == 'kg'){
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 9.81).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }else if(this.unit_ocr[14] == 'kN'){
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 1000).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }else if(this.unit_ocr[14] == 'daN'){
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{this.vars.axle4Weight = (this.ocrvalue[14] * 10).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }else{
                //             if (!this.ocrvalue[14]){ this.vars.axle4Weight = '0'}else{
                //                 this.vars.axle4Weight = Number(this.ocrvalue[14]).toFixed(2);}
                //             this.vars.axle4Weight.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[16] == 'kN'){
                //             if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 1000).toFixed(2);}
                //             this.vars.handbrakeForce2L.toString();
                //         }else if(this.unit_ocr[16] == 'daN'){
                //             if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{this.vars.handbrakeForce2L = (this.ocrvalue[16] * 10).toFixed(2);}
                //             this.vars.handbrakeForce2L.toString();
                //         }else{
                //             if (!this.ocrvalue[16]){ this.vars.handbrakeForce2L = '0'}else{
                //                 this.vars.handbrakeForce2L = Number(this.ocrvalue[16]).toFixed(2);}
                //             this.vars.handbrakeForce2L.toString();
                //         }
                //         // ---------------
                //         if(this.unit_ocr[17] == 'kN'){
                //             if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 1000).toFixed(2);}
                //             this.vars.handbrakeForce2R.toString();
                //         }else if(this.unit_ocr[17] == 'daN'){
                //             if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{this.vars.handbrakeForce2R = (this.ocrvalue[17] * 10).toFixed(2);}
                //             this.vars.handbrakeForce2R.toString();
                //         }else{
                //             if (!this.ocrvalue[17]){ this.vars.handbrakeForce2R = '0'}else{
                //                 this.vars.handbrakeForce2R = Number(this.ocrvalue[17]).toFixed(2);}
                //             this.vars.handbrakeForce2R.toString();
                //         }
                //         // ---------------
                //         this.varssum1 = (((Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle1l.replace(/,/g, '')) , parseFloat(this.vars.axle1r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum1)) this.varssum1 = 0
                //         this.varssum2 = (((Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle2l.replace(/,/g, '')) , parseFloat(this.vars.axle2r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum2)) this.varssum2 = 0
                //         this.varssum3 = (((Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle3l.replace(/,/g, '')) , parseFloat(this.vars.axle3r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum3)) this.varssum3 = 0
                //         this.varssum4 = (((Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, ''))) - Math.min(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) / Math.max(parseFloat(this.vars.axle4l.replace(/,/g, '')) , parseFloat(this.vars.axle4r.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum4)) this.varssum4 = 0
                //         if(this.varssum1 > 25 || this.varssum2 > 25 || this.varssum3 > 25 || this.varssum4 > 25){this.itemcheck.varssumcheck[0] = 0 }else{ this.itemcheck.varssumcheck[0] = 1 }
                //         this.varssum5 =  (((parseFloat(this.vars.axle1l.replace(/,/g, '')) + parseFloat(this.vars.axle1r.replace(/,/g, '')) + parseFloat(this.vars.axle2l.replace(/,/g, '')) + parseFloat(this.vars.axle2r.replace(/,/g, '')) + parseFloat(this.vars.axle3l.replace(/,/g, '')) + parseFloat(this.vars.axle3r.replace(/,/g, '')) + parseFloat(this.vars.axle4l.replace(/,/g, '')) + parseFloat(this.vars.axle4r.replace(/,/g, '')) ) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                //         if (isNaN(this.varssum5)) this.varssum5 = 0
                //         if(this.varssum5 >= 50){ this.itemcheck.varssumcheck[1] = 1 }else{ this.itemcheck.varssumcheck[1] = 0 }
                //         this.varssum6 =  (((parseFloat(this.vars.handbrakeForce2L.replace(/,/g, '')) + parseFloat(this.vars.handbrakeForce2R.replace(/,/g, ''))) / (parseFloat(this.vars.axle1Weight.replace(/,/g, '')) + parseFloat(this.vars.axle2Weight.replace(/,/g, '')) + parseFloat(this.vars.axle3Weight.replace(/,/g, '')) + parseFloat(this.vars.axle4Weight.replace(/,/g, '')))) * 100).toFixed(2)
                //         if(this.varssum6 >= 20){ this.itemcheck.varssumcheck[2] = 1 }else{ this.itemcheck.varssumcheck[2] = 0 }
                //         if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){ this.itemcheck.varssumcheck[3] = 1 }else{ this.itemcheck.varssumcheck[3] = 0}

                //         this.$swal.close();
                //         endTime = new Date();
                //         var timeDiff = endTime - startTime; //in ms
                //         timeDiff /= 1000;
                //         var seconds = Math.round(timeDiff);
                //         console.log('ORC เวอร์ชั่น ', tversion, ' ใช้เวลา ', seconds + ' วินาที');
                //     });
                // });

            }

            
        //###############################################################################
            }

        }else{
        this.$swal({title:'กรุณาเลือกประเภทรถก่อน !',
            icon:'warning',
        })
        }
        },
        datasound1(){
        if(!this.typecar){
                this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                icon:'warning',
                })
        }else if(!this.ry_id){
                this.$swal({title:'กรุณาเลือกประเภทเครื่องยนต์ !',
                icon:'warning',
                })
        }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
                this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                icon:'warning',
                })
            }else if(!this.car_weight && this.typecar != 12){
                this.$swal({title:'ประเภท รย.1,2,3 ต้องกรอกน้ำหนักรถด้วย',
                icon:'warning',
                })
            }else{
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังรับข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()

            if(this.settings.server.muller == 1){

                fetch('http://127.0.0.1:8080/a', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                        var varas = rs.result.replace(/[\n\r]/g,'')
                        const regex = /([\d.]{1,10})/gm;
                        var varasa = regex.exec(varas.toString())
                        this.vara = varasa[1]
                        this.$swal.close();
                        if(this.vara != ''){
                            this.soundinfo.data1 = this.vara
                            this.$swal({text:'รับค่าระดับเสียงครั้งที่ 1',
                                title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            })
                        }
                    })
                });
            }else{
                    fetch('http://127.0.0.1:8080/a', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                        var varas = rs.result.replace(/[\n\r]/g,'')
                        const regex = /([\d.]{1,10})/gm;
                        var varasa = regex.exec(varas.toString())
                        this.vara = varasa[1]
                        this.$swal.close();
                        if(this.vara != ''){
                            this.soundinfo.data1 = this.vara
                            this.$swal({text:'รับค่าระดับเสียงครั้งที่ 1',
                                title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                                icon:'info',
                            })
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            })
                        }
                    });
                });
            }

            }
        },
        datasound2(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()

        if(this.settings.server.muller == 1){
            if(this.varss  == null || this.varss.length <= 10){
                fetch('http://127.0.0.1:8080/a', {
                    method: "GET"
                })
                .then((response) => {
                    response.json().then((rs) => {
                    var varas = rs.result.replace(/[\n\r]/g,'')
                    const regex = /([\d.]{1,10})/gm;
                    var varasa = regex.exec(varas.toString())
                    this.vara = varasa[1]
                    this.$swal.close();
                    if(this.vara != ''){
                        this.soundinfo.data2 = this.vara
                        this.$swal({
                            text: 'รับค่าระดับเสียงครั้งที่ 2',
                            title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                            this.soundinfo.datamax = Math.max(this.soundinfo.data1,this.soundinfo.data2)
                            if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                                var datetosound = Date.parse("1/1/2014");
                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                if(datetosound > dateselect){
                                    if(this.soundinfo.datamax <= 100){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else{
                                    if(this.car_weight > 2200){
                                        if(this.soundinfo.datamax <= 99){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else{
                                        if(this.soundinfo.datamax <= 95){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }
                                }
                            }else if(this.typecar == 12){
                                var datetosound = Date.parse("1/1/2024");
                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                if(datetosound > dateselect){
                                    if(this.soundinfo.datamax <= 95){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else{
                                    if(this.soundinfo.datamax <= 90){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                    this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        ) 
                                    }
                                }
                            }else{
                                this.itemcheck.varssumcheck[4] = 0
                                this.$swal(
                                'ผลการทดสอบไม่ผ่าน',
                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                'error'
                                )
                            }
                        })
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        })
                    }
                });
            });

            }else{
                this.$swal.close();
                if(this.vara != ''){
                    this.soundinfo.data2 = this.vara
                    this.$swal({
                        text: 'รับค่าระดับเสียงครั้งที่ 2',
                        title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                        icon: 'info',
                        button: 'สรุปผลการทดสอบ',
                        }).then((result) => {
                            this.soundinfo.datamax = Math.max(this.soundinfo.data1,this.soundinfo.data2)
                            if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                                var datetosound = Date.parse("1/1/2014");
                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                if(datetosound > dateselect){
                                    if(this.soundinfo.datamax <= 100){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        ) 
                                    }

                                }else{
                                    if(this.car_weight > 2200){
                                        if(this.soundinfo.datamax <= 99){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            ) 
                                        }
                                    }else{
                                        if(this.soundinfo.datamax <= 95){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            ) 
                                        }
                                    }
                                }
                            }else if(this.typecar == 12){
                                var datetosound = Date.parse("1/1/2024");
                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                if(datetosound > dateselect){
                                    if(this.soundinfo.datamax <= 95){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else{
                                    if(this.soundinfo.datamax <= 90){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }

                            }else{
                                this.itemcheck.varssumcheck[4] = 0
                                this.$swal(
                                'ผลการทดสอบไม่ผ่าน',
                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                'error'
                                )
                            }
                    })
                }else{
                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                        icon:'warning',
                    })
                }
            }
        }else{
            fetch('http://127.0.0.1:8080/a', {
                method: "GET"
            })
            .then((response) => {
                response.json().then((rs) => {
                    var varas = rs.result.replace(/[\n\r]/g,'')
                    const regex = /([\d.]{1,10})/gm;
                    var varasa = regex.exec(varas.toString())
                    this.vara = varasa[1]
                    this.$swal.close();
                    if(this.vara != ''){
                        this.soundinfo.data2 = this.vara
                        this.$swal({
                            text: 'รับค่าระดับเสียงครั้งที่ 2',
                            title: "ค่าระดับเสียงที่ได้ "+this.vara+" dB(A)",
                            icon: 'info',
                            button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                            this.soundinfo.datamax = Math.max(this.soundinfo.data1,this.soundinfo.data2)
                            if(this.typecar == 1 || this.typecar == 2 || this.typecar == 3){
                                var datetosound = Date.parse("1/1/2014");
                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                if(datetosound > dateselect){
                                    if(this.soundinfo.datamax <= 100){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else{
                                    if(this.car_weight > 2200){
                                        if(this.soundinfo.datamax <= 99){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else{
                                        if(this.soundinfo.datamax <= 95){
                                            if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck.varssumcheck[4] = 1
                                                this.soundinfo.datamax = ''
                                                this.soundinfo.data1 = ''
                                                this.soundinfo.data2 = ''
                                                this.$swal({
                                                title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                                icon: 'error',
                                                })
                                            }
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 0
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }
                                }
                            }else if(this.typecar == 12){
                                var datetosound = Date.parse("1/1/2024");
                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));
                                if(datetosound > dateselect){
                                    if(this.soundinfo.datamax <= 95){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                        this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        )
                                    }
                                }else{
                                    if(this.soundinfo.datamax <= 90){
                                        if((parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) <= 2 && (parseFloat(this.soundinfo.data1) - parseFloat(this.soundinfo.data2)) >= -2){
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck.varssumcheck[4] = 1
                                            this.soundinfo.datamax = ''
                                            this.soundinfo.data1 = ''
                                            this.soundinfo.data2 = ''
                                            this.$swal({
                                            title:'การทดสอบระดับเสียง 2 ครั้ง มีค่าต่างกันเกิน 2 dB(A) กรุณารับค่าเสียงใหม่อีกครั้ง !',
                                            icon: 'error',
                                            })
                                        }
                                    }else{
                                    this.itemcheck.varssumcheck[4] = 0
                                        this.$swal(
                                        'ผลการทดสอบไม่ผ่าน',
                                        'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                        'error'
                                        ) 
                                    }
                                }
                            }else{
                                this.itemcheck.varssumcheck[4] = 0
                                this.$swal(
                                'ผลการทดสอบไม่ผ่าน',
                                'ค่าระดับเสียงเกินกว่าที่เกณฑ์กำหนด',
                                'error'
                                )
                            }
                        })
                    }else{
                        this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                            icon:'warning',
                        })
                    }
                });
            });

        }
        },
        datacohc1(){
            if(!this.typecar){
                this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                    icon:'warning',
                    })
            }else if(!this.fueldesc_select){
                this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
                    icon:'warning',
                    })
            }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
                this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                    icon:'warning',
                    })
            }else{
                this.$swal({
                    title: 'ระบบกำลังทำงาน',
                    html: 'กำลังรับข้อมูล ...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                });
                this.$swal.showLoading()
                if(this.settings.server.muller == 1){
                    if(this.settings.server.type == "udp"){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.data1.co = this.vareco
                                    this.cohcinfo.data1.hc = this.varehc
                                    this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                        icon:'info',
                                    })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            })
                        });
                    }else{
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.data1.co = this.vareco
                                    this.cohcinfo.data1.hc = this.varehc
                                    this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                        icon:'info',
                                    })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });
                    }
                }else{
                    if(this.settings.server.type == "udp"){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                console.log('arrs--->',arrs);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.data1.co = this.vareco
                                    this.cohcinfo.data1.hc = this.varehc
                                    this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                        icon:'info',
                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            })
                        });
                    }else{
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.data1.co = this.vareco
                                    this.cohcinfo.data1.hc = this.varehc
                                    this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.data1.co+" HC "+this.cohcinfo.data1.hc,
                                        icon:'info',
                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });
                    }
                }
            }
        },
        datacohc2(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    if(this.varss  == null || this.varss.length <= 10){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.data2.co = this.vareco
                                    this.cohcinfo.data2.hc = this.varehc
                                    this.$swal({
                                        text: 'รับค่า CO,HC ครั้งที่ 2',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                                        icon: 'info',
                                        button: 'สรุปผลการทดสอบ',
                                        }).then((result) => {
                                            if(this.cohcinfo.data1.co == '0.00%'){
                                                this.cohcinfo.datamax.co= 0
                                            }else{
                                                this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                                            }
                                            this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                                            if(this.typecar == 12){
                                                var dateto11 = Date.parse("7/1/2006");
                                                var dateto12 = Date.parse("12/31/2009");
                                                var dateto2 = Date.parse("1/1/2010");

                                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                                if(dateto11 > dateselect){
                                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto2 <= dateselect){
                                                    if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                                        this.itemcheck_cohc.varssumcheck[4] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[4] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }
                                            }

                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });

                    }else{
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.data2.co = this.vareco
                            this.cohcinfo.data2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.data1.co == '0.00%'){
                                    this.cohcinfo.datamax.co= 0
                                }else{
                                    this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co) + parseFloat(this.cohcinfo.data2.co))/2).toFixed(2);
                                }
                                this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc) + parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2);
                                if(this.typecar == 12){
                                    var dateto11 = Date.parse("7/1/2006");
                                    var dateto12 = Date.parse("12/31/2009");
                                    var dateto2 = Date.parse("1/1/2010");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if(dateto11 > dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto2 <= dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }
                                }

                            });
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    }

                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.data2.co = this.vareco
                            this.cohcinfo.data2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.data1.co == '0.00%'){
                                    this.cohcinfo.datamax.co= 0
                                }else{
                                    this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                                if(this.typecar == 12){
                                    var dateto11 = Date.parse("7/1/2006");
                                    var dateto12 = Date.parse("12/31/2009");
                                    var dateto2 = Date.parse("1/1/2010");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if(dateto11 > dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto2 <= dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }
                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.data2.co = this.vareco
                            this.cohcinfo.data2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.data1.co == '0.00%'){
                                    this.cohcinfo.datamax.co= 0
                                }else{
                                    this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                                if(this.typecar == 12){
                                    var dateto11 = Date.parse("7/1/2006");
                                    var dateto12 = Date.parse("12/31/2009");
                                    var dateto2 = Date.parse("1/1/2010");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if(dateto11 > dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto2 <= dateselect){
                                        if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                            this.itemcheck_cohc.varssumcheck[4] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[4] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }
                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.data2.co = this.vareco
                            this.cohcinfo.data2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.data2.co+" HC "+this.cohcinfo.data2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                    if(this.cohcinfo.data1.co == '0.00%'){
                                        this.cohcinfo.datamax.co= 0
                                    }else{
                                        this.cohcinfo.datamax.co = ((parseFloat(this.cohcinfo.data1.co)+parseFloat(this.cohcinfo.data2.co))/2).toFixed(2)
                                    }
                                    this.cohcinfo.datamax.hc = ((parseFloat(this.cohcinfo.data1.hc)+parseFloat(this.cohcinfo.data2.hc))/2).toFixed(2)
                                    if(this.typecar == 12){
                                        var dateto11 = Date.parse("7/1/2006");
                                        var dateto12 = Date.parse("12/31/2009");
                                        var dateto2 = Date.parse("1/1/2010");

                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                        if(dateto11 > dateselect){
                                            if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 4.5 && this.cohcinfo.datamax.hc <= 10000){
                                                this.itemcheck_cohc.varssumcheck[4] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[4] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto11 <= dateselect && dateselect <= dateto12){
                                            if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 3.5 && this.cohcinfo.datamax.hc <= 2000){
                                                this.itemcheck_cohc.varssumcheck[4] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[4] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto2 <= dateselect){
                                            if(this.cohcinfo.datamax.co >= 0 && this.cohcinfo.datamax.hc >= 0 && this.cohcinfo.datamax.co <= 2.5 && this.cohcinfo.datamax.hc <= 1000){
                                                this.itemcheck_cohc.varssumcheck[4] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[4] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }
                                    }

                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }

        },
        datacohc_lo1_1(){
        if(!this.typecar){
                this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                icon:'warning',
                })
        }else if(!this.fueldesc_select){
                this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
                icon:'warning',
                })
        }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
                this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                icon:'warning',
                })
            }else{
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังรับข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo1_1.co = this.vareco
                                this.cohcinfo.datalo1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo1_1.co = this.vareco
                                this.cohcinfo.datalo1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            console.log('arrs--->',arrs);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo1_1.co = this.vareco
                                this.cohcinfo.datalo1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo1_1.co = this.vareco
                                this.cohcinfo.datalo1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_1.co+" HC "+this.cohcinfo.datalo1_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }
        }
        },
        datacohc_lo2_1(){
        if(!this.typecar){
                this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                icon:'warning',
                })
        }else if(!this.fueldesc_select){
                this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
                icon:'warning',
                })
        }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
                this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                icon:'warning',
                })
            }else{
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังรับข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo2_1.co = this.vareco
                                this.cohcinfo.datalo2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo2_1.co = this.vareco
                                this.cohcinfo.datalo2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            console.log('arrs--->',arrs);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo2_1.co = this.vareco
                                this.cohcinfo.datalo2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo2_1.co = this.vareco
                                this.cohcinfo.datalo2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_1.co+" HC "+this.cohcinfo.datalo2_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }
            }
        }
        },
        datacohc_hi1_1(){
        if(!this.typecar){
                this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                icon:'warning',
                })
        }else if(!this.fueldesc_select){
                this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
                icon:'warning',
                })
        }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
                this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                icon:'warning',
                })
            }else{
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังรับข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi1_1.co = this.vareco
                                this.cohcinfo.datahi1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi1_1.co = this.vareco
                                this.cohcinfo.datahi1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            console.log('arrs--->',arrs);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi1_1.co = this.vareco
                                this.cohcinfo.datahi1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi1_1.co = this.vareco
                                this.cohcinfo.datahi1_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_1.co+" HC "+this.cohcinfo.datahi1_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }
        }
        },
        datacohc_hi2_1(){
        if(!this.typecar){
                this.$swal({title:'กรุณาเลือกประเภท(รย.) !',
                icon:'warning',
                })
        }else if(!this.fueldesc_select){
                this.$swal({title:'กรุณาเลือกชนิดเชื้อเพลิง !',
                icon:'warning',
                })
        }else if(!this.car_reg_date_1 || !this.car_reg_date_2 || !this.car_reg_date_3){
                this.$swal({title:'กรุณาเลือกวันที่จดทะเบียน !',
                icon:'warning',
                })
            }else{
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังรับข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi2_1.co = this.vareco
                                this.cohcinfo.datahi2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi2_1.co = this.vareco
                                this.cohcinfo.datahi2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                    icon:'info',
                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            console.log('arrs--->',arrs);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi2_1.co = this.vareco
                                this.cohcinfo.datahi2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datahi2_1.co = this.vareco
                                this.cohcinfo.datahi2_1.hc = this.varehc
                                this.$swal({text:'รับค่า CO,HC ครั้งที่ 1',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_1.co+" HC "+this.cohcinfo.datahi2_1.hc,
                                    icon:'info',
                                    })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        })
                    });
                }
            }
        }
        },
        datacohc_lo1_2(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                        if(this.varss  == null || this.varss.length <= 10){
                            fetch('http://127.0.0.1:8080/e', {
                                method: "GET"
                            })
                            .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.datalo1_2.co = this.vareco
                                    this.cohcinfo.datalo1_2.hc = this.varehc
                                    this.$swal({
                                        text: 'รับค่า CO,HC ครั้งที่ 2',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                        icon: 'info',
                                        button: 'สรุปผลการทดสอบ',
                                        }).then((result) => {
                                            if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                                this.cohcinfo.datamaxlo_1.co= 0
                                            }else{
                                                this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                            }
                                            this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)

                                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                                var dateto11 = Date.parse("11/1/1993");
                                                var dateto12 = Date.parse("12/31/2006");
                                                var dateto21 = Date.parse("1/1/2007");
                                                var dateto22 = Date.parse("12/31/2013");
                                                var dateto3 = Date.parse("1/1/2014");

                                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                                if((dateto11 > dateselect) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto3 <= dateselect){
                                                    if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[0] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[0] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }

                                            }

                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });

                    }else{
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_2.co = this.vareco
                            this.cohcinfo.datalo1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co) + parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2);
                                }
                                this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc) + parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2);

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            });
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    }

                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                    response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_2.co = this.vareco
                            this.cohcinfo.datalo1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[0] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[0] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                            let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                            this.vareco = arrs[1].replace(/,/g, '');
                            this.varehc = arrs[2].replace(/,/g, '');
                            this.$swal.close();
                            if(this.vareco != '' && this.varehc != ''){
                                this.cohcinfo.datalo1_2.co = this.vareco
                                this.cohcinfo.datalo1_2.hc = this.varehc
                                this.$swal({
                                    text: 'รับค่า CO,HC ครั้งที่ 2',
                                    title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                    icon: 'info',
                                    button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                    if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                        this.cohcinfo.datamaxlo_1.co= 0
                                    }else{
                                        this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                    }
                                    this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)

                                    if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                        var dateto11 = Date.parse("11/1/1993");
                                        var dateto12 = Date.parse("12/31/2006");
                                        var dateto21 = Date.parse("1/1/2007");
                                        var dateto22 = Date.parse("12/31/2013");
                                        var dateto3 = Date.parse("1/1/2014");

                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                        if((dateto11 > dateselect) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                    this.itemcheck_cohc.varssumcheck[0] = 1
                                                    this.setchk5cohc()
                                                    this.$swal({
                                                    title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                    icon: 'success',
                                                    })
                                                }else{
                                                    this.itemcheck_cohc.varssumcheck[0] = 0
                                                    this.setchk5cohc()
                                                    this.$swal(
                                                    'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                    'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                    'error'
                                                    )
                                                }
                                        }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto3 <= dateselect){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }

                                    }

                                })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo1_2.co = this.vareco
                            this.cohcinfo.datalo1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo1_2.co+" HC "+this.cohcinfo.datalo1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                    if(this.cohcinfo.datalo1_2.co == '0.00%'){
                                        this.cohcinfo.datamaxlo_1.co= 0
                                    }else{
                                        this.cohcinfo.datamaxlo_1.co = ((parseFloat(this.cohcinfo.datalo1_1.co)+parseFloat(this.cohcinfo.datalo1_2.co))/2).toFixed(2)
                                    }
                                    this.cohcinfo.datamaxlo_1.hc = ((parseFloat(this.cohcinfo.datalo1_1.hc)+parseFloat(this.cohcinfo.datalo1_2.hc))/2).toFixed(2)
                                    if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                        var dateto11 = Date.parse("11/1/1993");
                                        var dateto12 = Date.parse("12/31/2006");
                                        var dateto21 = Date.parse("1/1/2007");
                                        var dateto22 = Date.parse("12/31/2013");
                                        var dateto3 = Date.parse("1/1/2014");

                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                        if((dateto11 > dateselect) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 4.5 && this.cohcinfo.datamaxlo_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 1.5 && this.cohcinfo.datamaxlo_1.hc <= 200){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.5 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto3 <= dateselect){
                                            if(this.cohcinfo.datamaxlo_1.co >= 0 && this.cohcinfo.datamaxlo_1.hc >= 0 && this.cohcinfo.datamaxlo_1.co <= 0.3 && this.cohcinfo.datamaxlo_1.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[0] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[0] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }

                                    }

                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }

        },
        datacohc_lo2_2(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    if(this.varss  == null || this.varss.length <= 10){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                        response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.datalo2_2.co = this.vareco
                                    this.cohcinfo.datalo2_2.hc = this.varehc
                                    this.$swal({
                                        text: 'รับค่า CO,HC ครั้งที่ 2',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                                        icon: 'info',
                                        button: 'สรุปผลการทดสอบ',
                                        }).then((result) => {
                                            if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                                this.cohcinfo.datamaxlo_2.co= 0
                                            }else{
                                                this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                                            }
                                            this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)

                                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                                var dateto11 = Date.parse("11/1/1993");
                                                var dateto12 = Date.parse("12/31/2006");
                                                var dateto21 = Date.parse("1/1/2007");
                                                var dateto22 = Date.parse("12/31/2013");
                                                var dateto3 = Date.parse("1/1/2014");

                                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                                if((dateto11 > dateselect) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto3 <= dateselect){
                                                    if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[1] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[1] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }

                                            }

                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });

                    }else{
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_2.co = this.vareco
                            this.cohcinfo.datalo2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co) + parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2);
                                }
                                this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc) + parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2);

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            });
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    }

                }else{
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_2.co = this.vareco
                            this.cohcinfo.datalo2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                        response.json().then((rs) => {  
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_2.co = this.vareco
                            this.cohcinfo.datalo2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxlo_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[1] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[1] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datalo2_2.co = this.vareco
                            this.cohcinfo.datalo2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datalo2_2.co+" HC "+this.cohcinfo.datalo2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                    if(this.cohcinfo.datalo2_2.co == '0.00%'){
                                        this.cohcinfo.datamaxlo_2.co= 0
                                    }else{
                                        this.cohcinfo.datamaxlo_2.co = ((parseFloat(this.cohcinfo.datalo2_1.co)+parseFloat(this.cohcinfo.datalo2_2.co))/2).toFixed(2)
                                    }
                                    this.cohcinfo.datamaxlo_2.hc = ((parseFloat(this.cohcinfo.datalo2_1.hc)+parseFloat(this.cohcinfo.datalo2_2.hc))/2).toFixed(2)
                                    if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                        var dateto11 = Date.parse("11/1/1993");
                                        var dateto12 = Date.parse("12/31/2006");
                                        var dateto21 = Date.parse("1/1/2007");
                                        var dateto22 = Date.parse("12/31/2013");
                                        var dateto3 = Date.parse("1/1/2014");

                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                        if((dateto11 > dateselect) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[1] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[1] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 4.5 && this.cohcinfo.datamaxlo_2.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[1] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[1] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 1.5 && this.cohcinfo.datamaxlo_2.hc <= 200){
                                                this.itemcheck_cohc.varssumcheck[1] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[1] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                            if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.5 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[1] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[1] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto3 <= dateselect){
                                            if(this.cohcinfo.datamaxlo_2.co >= 0 && this.cohcinfo.datamaxlo_2.hc >= 0 && this.cohcinfo.datamaxlo_2.co <= 0.3 && this.cohcinfo.datamaxlo_2.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[1] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[1] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบต่ำ(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }

                                    }

                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }

        },
        datacohc_hi1_2(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    if(this.varss  == null || this.varss.length <= 10){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.datahi1_2.co = this.vareco
                                    this.cohcinfo.datahi1_2.hc = this.varehc
                                    this.$swal({
                                        text: 'รับค่า CO,HC ครั้งที่ 2',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                                        icon: 'info',
                                        button: 'สรุปผลการทดสอบ',
                                        }).then((result) => {
                                            if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                                this.cohcinfo.datamaxhi_1.co= 0
                                            }else{
                                                this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                                            }
                                            this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)

                                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                                var dateto11 = Date.parse("11/1/1993");
                                                var dateto12 = Date.parse("12/31/2006");
                                                var dateto21 = Date.parse("1/1/2007");
                                                var dateto22 = Date.parse("12/31/2013");
                                                var dateto3 = Date.parse("1/1/2014");

                                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                                if((dateto11 > dateselect) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto3 <= dateselect){
                                                    if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[2] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[2] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }

                                            }

                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });

                    }else{
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_2.co = this.vareco
                            this.cohcinfo.datahi1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co) + parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2);
                                }
                                this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc) + parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2);

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            });
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    }

                }else{
                    fetch('http://127.0.0.1:8080/e', {
                        method: "GET"
                    })
                    .then((response) => {
                        response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_2.co = this.vareco
                            this.cohcinfo.datahi1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[2] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[2] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_2.co = this.vareco
                            this.cohcinfo.datahi1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_1.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[2] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[2] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi1_2.co = this.vareco
                            this.cohcinfo.datahi1_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi1_2.co+" HC "+this.cohcinfo.datahi1_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                    if(this.cohcinfo.datahi1_2.co == '0.00%'){
                                        this.cohcinfo.datamaxhi_1.co= 0
                                    }else{
                                        this.cohcinfo.datamaxhi_1.co = ((parseFloat(this.cohcinfo.datahi1_1.co)+parseFloat(this.cohcinfo.datahi1_2.co))/2).toFixed(2)
                                    }
                                    this.cohcinfo.datamaxhi_1.hc = ((parseFloat(this.cohcinfo.datahi1_1.hc)+parseFloat(this.cohcinfo.datahi1_2.hc))/2).toFixed(2)
                                    if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                        var dateto11 = Date.parse("11/1/1993");
                                        var dateto12 = Date.parse("12/31/2006");
                                        var dateto21 = Date.parse("1/1/2007");
                                        var dateto22 = Date.parse("12/31/2013");
                                        var dateto3 = Date.parse("1/1/2014");

                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                        if((dateto11 > dateselect) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[2] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[2] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 4.5 && this.cohcinfo.datamaxhi_1.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[2] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[2] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 1.5 && this.cohcinfo.datamaxhi_1.hc <= 200){
                                                this.itemcheck_cohc.varssumcheck[2] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[2] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                            if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.5 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[2] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[2] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto3 <= dateselect){
                                            if(this.cohcinfo.datamaxhi_1.co >= 0 && this.cohcinfo.datamaxhi_1.hc >= 0 && this.cohcinfo.datamaxhi_1.co <= 0.2 && this.cohcinfo.datamaxhi_1.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[2] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[2] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(เชื้อเพลิง) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }

                                    }

                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }

        },
        datacohc_hi2_2(){
        this.$swal({
            title: 'ระบบกำลังทำงาน',
            html: 'กำลังรับข้อมูล ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        this.$swal.showLoading()
        if(this.settings.server.muller == 1){
                if(this.settings.server.type == "udp"){
                    if(this.varss  == null || this.varss.length <= 10){
                        fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                                let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                                this.vareco = arrs[1].replace(/,/g, '');
                                this.varehc = arrs[2].replace(/,/g, '');
                                this.$swal.close();
                                if(this.vareco != '' && this.varehc != ''){
                                    this.cohcinfo.datahi2_2.co = this.vareco
                                    this.cohcinfo.datahi2_2.hc = this.varehc
                                    this.$swal({
                                        text: 'รับค่า CO,HC ครั้งที่ 2',
                                        title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                                        icon: 'info',
                                        button: 'สรุปผลการทดสอบ',
                                        }).then((result) => {
                                            if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                                this.cohcinfo.datamaxhi_2.co= 0
                                            }else{
                                                this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                                            }
                                            this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)

                                            if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                                var dateto11 = Date.parse("11/1/1993");
                                                var dateto12 = Date.parse("12/31/2006");
                                                var dateto21 = Date.parse("1/1/2007");
                                                var dateto22 = Date.parse("12/31/2013");
                                                var dateto3 = Date.parse("1/1/2014");

                                                var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                                if((dateto11 > dateselect) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }else if(dateto3 <= dateselect){
                                                    if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                                        this.itemcheck_cohc.varssumcheck[3] = 1
                                                        this.setchk5cohc()
                                                        this.$swal({
                                                        title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                        icon: 'success',
                                                        })
                                                    }else{
                                                        this.itemcheck_cohc.varssumcheck[3] = 0
                                                        this.setchk5cohc()
                                                        this.$swal(
                                                        'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                        'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                        'error'
                                                        )
                                                    }
                                                }

                                            }

                                        })
                                }else{
                                    this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                        icon:'warning',
                                    }) 
                                }
                            });
                        });

                    }else{
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_2.co = this.vareco
                            this.cohcinfo.datahi2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co) + parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2);
                                }
                                this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc) + parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2);

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            });
                        }else{
                            this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                icon:'warning',
                            }) 
                        }
                    }

                }else{
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_2.co = this.vareco
                            this.cohcinfo.datahi2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }else{
                if(this.settings.server.type == "udp"){
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_2.co = this.vareco
                            this.cohcinfo.datahi2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                            }).then((result) => {
                                if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                    this.cohcinfo.datamaxhi_2.co= 0
                                }else{
                                    this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                                }
                                this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)

                                if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                    var dateto11 = Date.parse("11/1/1993");
                                    var dateto12 = Date.parse("12/31/2006");
                                    var dateto21 = Date.parse("1/1/2007");
                                    var dateto22 = Date.parse("12/31/2013");
                                    var dateto3 = Date.parse("1/1/2014");

                                    var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                    if((dateto11 > dateselect) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }else if(dateto3 <= dateselect){
                                        if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                            this.itemcheck_cohc.varssumcheck[3] = 1
                                            this.setchk5cohc()
                                            this.$swal({
                                            title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                            icon: 'success',
                                            })
                                        }else{
                                            this.itemcheck_cohc.varssumcheck[3] = 0
                                            this.setchk5cohc()
                                            this.$swal(
                                            'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                            'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                            'error'
                                            )
                                        }
                                    }

                                }

                            })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }else{
                    fetch('http://127.0.0.1:8080/e', {
                            method: "GET"
                        })
                        .then((response) => {
                            response.json().then((rs) => {
                        let arrs = rs.result.match(/:[\s-]{0,9}([0-9,.]+)[\s]{0,9}%.*:[\s-]{0,9}([0-9,.]+).*/);
                        this.vareco = arrs[1].replace(/,/g, '');
                        this.varehc = arrs[2].replace(/,/g, '');
                        this.$swal.close();
                        if(this.vareco != '' && this.varehc != ''){
                            this.cohcinfo.datahi2_2.co = this.vareco
                            this.cohcinfo.datahi2_2.hc = this.varehc
                            this.$swal({
                                text: 'รับค่า CO,HC ครั้งที่ 2',
                                title: "ค่าที่ได้ CO "+this.cohcinfo.datahi2_2.co+" HC "+this.cohcinfo.datahi2_2.hc,
                                icon: 'info',
                                button: 'สรุปผลการทดสอบ',
                                }).then((result) => {
                                    if(this.cohcinfo.datahi2_2.co == '0.00%'){
                                        this.cohcinfo.datamaxhi_2.co= 0
                                    }else{
                                        this.cohcinfo.datamaxhi_2.co = ((parseFloat(this.cohcinfo.datahi2_1.co)+parseFloat(this.cohcinfo.datahi2_2.co))/2).toFixed(2)
                                    }
                                    this.cohcinfo.datamaxhi_2.hc = ((parseFloat(this.cohcinfo.datahi2_1.hc)+parseFloat(this.cohcinfo.datahi2_2.hc))/2).toFixed(2)
                                    if(this.typecar ==1 || this.typecar == 2 || this.typecar == 3){
                                        var dateto11 = Date.parse("11/1/1993");
                                        var dateto12 = Date.parse("12/31/2006");
                                        var dateto21 = Date.parse("1/1/2007");
                                        var dateto22 = Date.parse("12/31/2013");
                                        var dateto3 = Date.parse("1/1/2014");

                                        var dateselect = Date.parse(this.car_reg_date_2+"/"+this.car_reg_date_1+"/"+(this.car_reg_date_3 - 543));

                                        if((dateto11 > dateselect) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[3] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[3] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto21 > dateselect) && (this.typecar == 2 || this.typecar == 3)){
                                            if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 4.5 && this.cohcinfo.datamaxhi_2.hc <= 600){
                                                this.itemcheck_cohc.varssumcheck[3] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[3] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if((dateto11 <= dateselect && dateselect <= dateto12) && this.typecar == 1){
                                            if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 1.5 && this.cohcinfo.datamaxhi_2.hc <= 200){
                                                this.itemcheck_cohc.varssumcheck[3] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[3] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto21 <= dateselect && dateselect <= dateto22){
                                            if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.5 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[3] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[3] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }else if(dateto3 <= dateselect){
                                            if(this.cohcinfo.datamaxhi_2.co >= 0 && this.cohcinfo.datamaxhi_2.hc >= 0 && this.cohcinfo.datamaxhi_2.co <= 0.2 && this.cohcinfo.datamaxhi_2.hc <= 100){
                                                this.itemcheck_cohc.varssumcheck[3] = 1
                                                this.setchk5cohc()
                                                this.$swal({
                                                title:'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ผ่าน',
                                                icon: 'success',
                                                })
                                            }else{
                                                this.itemcheck_cohc.varssumcheck[3] = 0
                                                this.setchk5cohc()
                                                this.$swal(
                                                'ผลการทดสอบเดินเบาที่รอบสูง(ก๊าซธรรมชาติ) ไม่ผ่าน',
                                                'ค่า CO HC เกินกว่าที่เกณฑ์กำหนด',
                                                'error'
                                                )
                                            }
                                        }

                                    }

                                })
                            }else{
                                this.$swal({title:'ไม่สามารถรับค่าได้กรุณาลองใหม่อีกครั้ง !',
                                    icon:'warning',
                                }) 
                            }
                        });
                    });
                }
            }

        },
         insertdata(){
            this.$swal({
                title: 'ระบบกำลังทำงาน',
                html: 'กำลังส่งข้อมูล ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
            this.$swal.showLoading()
            if(this.b64img){
                if(this.typecar != 12 && (!this.varssum1 || !this.vars.axle1l)){
                   this.$swal({title:'ค่าของระบบห้ามล้อยังไม่ครบถ้วน (ข้อ 1) !', 
                        icon:'warning', 
                        }) 
                }else if(this.typecar != 12 && !this.vars.wheelAlignment){
                   this.$swal({title:'กรุณากรอกค่าศูนย์ล้อ (ข้อ 2) !', 
                        icon:'warning', 
                        }) 
                }else if(!this.soundinfo.datamax){
                   this.$swal({title:'กรุณากดรับค่าระดับเสียง (ข้อ 3) !', 
                        icon:'warning', 
                        }) 
                }else if(!this.varesmoke && (this.fueldesc_select == 2 || this.fueldesc_select == 5 || this.fueldesc_select == 9 || this.fueldesc_select == 10)){
                   this.$swal({title:'กรุณากรอกค่าควันดำ (ข้อ 4) !', 
                        icon:'warning', 
                        }) 
                }else if((!this.cohcinfo.datamax.co && !this.cohcinfo.datamaxlo_1.co && !this.cohcinfo.datamaxlo_2.co) && (this.fueldesc_select == 1 || this.fueldesc_select == 4 || this.fueldesc_select == 6 || this.fueldesc_select == 7 || this.fueldesc_select == 8)){
                   this.$swal({title:'กรุณากดรับค่า CO, HC ขณะเครื่องยนต์เดินเบาที่รอบต่ำ (ข้อ 4)  !',
                        icon:'warning',
                        })
                }else if((!this.cohcinfo.datamaxhi_1.co && !this.cohcinfo.datamaxhi_2.co) && this.typecar != 12  && Date.parse(this.car_reg_date_2+'/'+this.car_reg_date_1+'/'+(this.car_reg_date_3 - 543)) >= Date.parse('1/1/2014') && (this.fueldesc_select == 1 || this.fueldesc_select == 4 || this.fueldesc_select == 6 || this.fueldesc_select == 7 || this.fueldesc_select == 8)){
                   this.$swal({title:'กรุณากดรับค่า CO, HC ขณะเครื่องยนต์เดินเบาที่รอบสูง (ข้อ 4)  !',
                        icon:'warning',
                        })
                }else if(this.typecar != 12 && (!this.input81 || !this.input82 || !this.input83 || !this.input84 || !this.input85 || !this.input86 || !this.input87 || !this.input88)){
                   this.$swal({title:'กรุณากรอกค่าโคมไฟให้ครบถ้วน (ข้อ 7)  !', 
                        icon:'warning', 
                        }) 
                }else if(this.typecar == 12 && (!this.input81 || !this.input83 || !this.input85 || !this.input87)){
                   this.$swal({title:'กรุณากรอกค่าโคมไฟให้ครบถ้วน (ข้อ 7) !', 
                        icon:'warning', 
                        }) 
                }else if(this.car_lic_id_char && this.car_lic_id_num && this.car_reg_date_1 && this.car_reg_date_2 && this.car_reg_date_3){
                    let datas = {
                            uid: storeAuth.id,
                            gid: storeAuth.group_id,
                            tid: 3,
                            datas:{
                                DATA_ID:this.iddata,
                                CHECK_NUMBER:this.check_num,
                                SEND_DATETIME:this.timeinsert,
                                CAR_LIC_ID_CHAR:this.car_lic_id_char, 
                                CAR_LIC_ID_NUM:this.car_lic_id_num.padStart(4, '0'), 
                                CAR_LIC_PROV:this.car_lic_prov, 
                                CAR_REG_DATE:this.car_reg_date_1+' '+this.car_reg_date_2+' '+this.car_reg_date_3,
                                CAR_REG_DATE_1:this.car_reg_date_1, 
                                CAR_REG_DATE_2:this.car_reg_date_2, 
                                CAR_REG_DATE_3:this.car_reg_date_3, 
                                CAR_TYPE:this.typecar,
                                TYRE:this.type, 
                                FUELDESC_SELECT:this.fueldesc_select,
                                TESTBRAKE_FORCE_AXLE1L:this.vars.axle1l, 
                                TESTBRAKE_FORCE_AXLE1R:this.vars.axle1r, 
                                TESTBRAKE_FORCE_AXLE2L:this.vars.axle2l, 
                                TESTBRAKE_FORCE_AXLE2R:this.vars.axle2r, 
                                TESTBRAKE_FORCE_AXLE3L:this.vars.axle3l, 
                                TESTBRAKE_FORCE_AXLE3R:this.vars.axle3r, 
                                TESTBRAKE_FORCE_AXLE4L:this.vars.axle4l, 
                                TESTBRAKE_FORCE_AXLE4R:this.vars.axle4r, 
                                TESTBRAKE_WEIGHT_AXLE1:this.vars.axle1Weight, 
                                TESTBRAKE_WEIGHT_AXLE2:this.vars.axle2Weight, 
                                TESTBRAKE_WEIGHT_AXLE3:this.vars.axle3Weight,  
                                TESTBRAKE_WEIGHT_AXLE4:this.vars.axle4Weight, 
                                TESTBRAKE_DIF_AXLE1:this.varssum1, 
                                TESTBRAKE_DIF_AXLE2:this.varssum2, 
                                TESTBRAKE_DIF_AXLE3:this.varssum3, 
                                TESTBRAKE_DIF_AXLE4:this.varssum4, 
                                TESTBRAKE_FOOT_EFFICIENCY:this.varssum5, 
                                TESTBRAKE_HAND_VAULE_L:this.vars.handbrakeForce2L, 
                                TESTBRAKE_HAND_VAULE_R:this.vars.handbrakeForce2R, 
                                TESTBRAKE_HAND_EFFICIENCY:this.varssum6, 
                                TESTBRAKE_WHEEL_ALIGHTMENT:this.vars.wheelAlignment, 
                                RESULT_DIFF_BRAKE:this.itemcheck.varssumcheck[0], 
                                RESULT_FOOT_BRAKE:this.itemcheck.varssumcheck[1], 
                                RESULT_HAND_BRAKE:this.itemcheck.varssumcheck[2], 
                                RESULT_WHEEL_ALIGHTMENT:this.itemcheck.varssumcheck[3], 
                                CHECK_VOICE:this.itemcheck.varssumcheck[4], 
                                CHECK_PIPE:this.itemcheck.varssumcheck[5], 
                                CHECK_HOOTER:this.itemcheck.varssumcheck[6], 
                                CHECK_SPEED:this.itemcheck.varssumcheck[7], 
                                CHECK_HEAD_LIGHT:this.itemcheck.varssumcheck[8], 
                                CHECK_SIGNAL_LIGHT:this.itemcheck.varssumcheck[9], 
                                CHECK_PLATE_LIGHT:this.itemcheck.varssumcheck[10], 
                                CHECK_WINDOWS_GLASS:this.itemcheck.varssumcheck[12], 
                                CHECK_CONTROL_SYS:this.itemcheck.varssumcheck[13], 
                                CHECK_RUBBER:this.itemcheck.varssumcheck[14], 
                                CHECK_FUEL_TANK:this.itemcheck.varssumcheck[15], 
                                CHECK_SUSPENSION:this.itemcheck.varssumcheck[16], 
                                CHECK_BODY:this.itemcheck.varssumcheck[18], 
                                CHECK_DOOR:this.itemcheck.varssumcheck[19], 
                                CHECK_BELT:this.itemcheck.varssumcheck[22], 
                                CHECK_RAIN_FLY:this.itemcheck.varssumcheck[11], 
                                CHECK_AUTHE:this.itemcheck.varssumcheck[23], 
                                VOICE_VALUE:this.vara, 
                                PIPE_VALUE:this.varesmoke, 
                                CO_VALUE:this.cohcinfo.datamax.co,
                                HC_VALUE:this.cohcinfo.datamax.hc,
                                CO_VALUE_L1:this.cohcinfo.datamaxlo_1.co,
                                HC_VALUE_L1:this.cohcinfo.datamaxlo_1.hc,
                                CO_VALUE_L2:this.cohcinfo.datamaxlo_2.co,
                                HC_VALUE_L2:this.cohcinfo.datamaxlo_2.hc,
                                CO_VALUE_H1:this.cohcinfo.datamaxhi_1.co,
                                HC_VALUE_H1:this.cohcinfo.datamaxhi_1.hc,
                                CO_VALUE_H2:this.cohcinfo.datamaxhi_2.co,
                                HC_VALUE_H2:this.cohcinfo.datamaxhi_2.hc,
                                CHECK_L1:this.itemcheck_cohc.varssumcheck[0],
                                CHECK_L2:this.itemcheck_cohc.varssumcheck[1],
                                CHECK_H1:this.itemcheck_cohc.varssumcheck[2],
                                CHECK_H2:this.itemcheck_cohc.varssumcheck[3],
                                CHECK_CH12:this.itemcheck_cohc.varssumcheck[4],
                                LIGHT_UP_L_VALUE:this.input81, 
                                LIGHT_UP_R_VALUE:this.input82, 
                                LIGHT_DN_L_VALUE:this.input83, 
                                LIGHT_DN_R_VALUE:this.input84, 
                                LIGHT_UP_L_LEVEL:this.input85, 
                                LIGHT_UP_R_LEVEL:this.input86, 
                                LIGHT_DN_L_LEVEL:this.input87, 
                                LIGHT_DN_R_LEVEL:this.input88, 
                                STATUS_CHECK:this.allchk, 
                                entry_by:this.entry_by,  
                                IS_SENT:1,
                                ERROR_TXT:this.allchkid.toString(), 
                                FAILED_REASONS:this.failed_reasons,
                                b64img:this.b64img,
                            }
                        };
                        axios.post(`repeatcar`,datas).then(rs=>{
                            // console.log(data);
                            if(rs.data.status == 1){
                                this.$swal({
                                    title: 'บันทึกข้อมูลเรียบร้อยแล้ว !',
                                    text: "กดตกลงเพื่อดูรายละเอียดที่บันทึก",
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#664284',
                                    confirmButtonText: 'ตกลง'
                                }).then((result) => {
                                    window.open('/user/datacarpdf/'+this.iddata);
                                    this.$router.push('/user/inspectioncarcheckrepeat');
                                    // window.location.href = '/user/inspectioncarcheckrepeat'
                                })
                            }else{
                                this.$swal({title:'บันทึกข้อมูลไม่สำเร็จ !', 
                                    icon:'error', 
                                }) 
                            }
                        }).catch(err=>{
                            console.log(err)
                            this.$swal({title:'บันทึกข้อมูลไม่สำเร็จ !', 
                                icon:'error', 
                            }) 
                        })
                }else{
                   this.$swal({title:'เกิดข้อผิดพลาด !', 
                        text: "กรุณาติดต่อผู้ดูแลระบบ",
                        icon:'warning', 
                        }) 
                }
            }else{
               this.$swal({title:'ยังไม่ได้ถ่ายรูป หรือในรูปภาพไม่พบยานพาหนะ !',
                    icon:'warning',
                    })
            }
         },
        setchk3(){
            if(this.vars.wheelAlignment >= -5 && this.vars.wheelAlignment <=5 ){
                this.itemcheck.varssumcheck[3] = 1;
            }else{
                this.itemcheck.varssumcheck[3] = 0;
            }
        },
        setchk5() {
            this.itemcheck.varssumcheck[5] = 0
            if (this.fueldesc_select == 2 || this.fueldesc_select == 5 || this.fueldesc_select == 9 || this.fueldesc_select == 10) {
                if (this.smoke_setting == 'opacity') {
                    if (this.varesmoke == '') {
                        this.itemcheck.varssumcheck[5] = 1;
                    } else if (this.varesmoke > 0 && this.varesmoke <= this.st_read.smoke_opacity) {
                        this.itemcheck.varssumcheck[5] = 1;
                    }
                } else {
                    if (this.varesmoke == '') {
                        this.itemcheck.varssumcheck[5] = 1;
                    } else if (this.varesmoke > 0 && this.varesmoke <= this.st_read.smoke_filter) {
                        this.itemcheck.varssumcheck[5] = 1;
                    }
                }
            }
            return this.itemcheck.varssumcheck[5];
        },
        setchk5cohc() {
            let chk5 = 1;
            if (this.fueldesc_select == 1 || this.fueldesc_select == 4 || this.fueldesc_select == 6 || this.fueldesc_select == 7 || this.fueldesc_select == 8) {
                if (this.cohcinfo.datamax.co != '' || this.cohcinfo.datamaxlo_1.co != '' || this.cohcinfo.datamaxlo_2.co != '' || this.cohcinfo.datamaxhi_1.co != '' || this.cohcinfo.datamaxhi_2.co != '') {
                    for(let i=0; i<=4; i++ ) {
                        console.log(this.itemcheck_cohc.varssumcheck[i])
                        if(this.itemcheck_cohc.varssumcheck[i] == 0 ) {
                            chk5 = 0;
                        }
                    }
                    this.itemcheck.varssumcheck[5] = chk5
                    return this.itemcheck.varssumcheck[5];
                }
            }
        },
        getNow() {
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            this.timeinsert = dateTime;
        },
    },
    computed:{
        getimage64view(){
            if(this.imageview){
                return true
            }
        },
        getchk8(){
            if(this.typecar != 12){
                this.itemcheck.varssumcheck[8] = 0;
                let chk = 1;
                if(this.input81 == ''){
                    chk = 1;
                }else if(this.input81 != '' && this.input82 != '' && this.input83 != '' && this.input84 != '' && this.input85 != '' && this.input86 != '' && this.input87 != '' && this.input88 != ''){
                    if(this.input81 < 0 || this.input82 < 0 || this.input83 < 12 || this.input84 < 12 || this.input85 < 0.29  || this.input85 > 2.29 || this.input86 < 0.29  || this.input86 > 2.29 || this.input87 < 6.4  || this.input87 < 6.4 || this.input88 < 6.4  || this.input88 < 6.4 || parseFloat(this.input83) + parseFloat(this.input84) > 430.00) {
                        chk = 0;
                    }
                }
                this.itemcheck.varssumcheck[8] = chk;
                return chk;
            }else{
                this.itemcheck.varssumcheck[8] = 0;
                let chk = 1;
                if(this.input81 == ''){
                    chk = 1;
                }else if(this.input81 != '' && this.input83 != '' && this.input85 != '' && this.input87 != ''){
                    if(this.input81 < 0 || this.input83 < 12 || this.input85 < 0.29  || this.input85 > 2.29 || this.input87 < 6.4  || this.input87 < 6.4 || parseFloat(this.input83) > 430.00) {
                        chk = 0;
                    }
                }
                this.itemcheck.varssumcheck[8] = chk;
                return chk;
            }
        },
        getchk3(){
            return this.itemcheck.varssumcheck[3]
        },
        getchk5(){
            return this.itemcheck.varssumcheck[5];
        },
        
        allchk() {
            let chk = 1;
            if(this.typecar != 12){
                for(let i=0; i<this.itemcheck.varssumcheck.length; i++ ) {
                    if(this.itemcheck.varssumcheck[i] == 0 ) {
                        chk = 0;
                    }
                }
                return chk;
            }else{
                for(let i=4; i<this.itemcheck.varssumcheck.length; i++ ) {
                    if(this.itemcheck.varssumcheck[i] == 0 ) {
                        chk = 0;
                    }
                }
                return chk;
            }
        },
        allchkid() {
            let id = [];
            if(this.typecar != 12){
                for(let i=0; i<this.itemcheck.varssumcheck.length; i++ ) {
                    if(i == 0){
                        if(this.itemcheck.varssumcheck[0] == 0 ) {
                            // id.push("1.1 ผลต่าง");
                            id.push("ผลต่าง");
                        }
                    }else if(i == 1){
                        if(this.itemcheck.varssumcheck[1] == 0 ) {
                            // id.push("1.2 ประสิทธิภาพห้ามล้อ");
                            id.push("ประสิทธิภาพห้ามล้อ");
                        }
                    }else if(i == 2){
                        if(this.itemcheck.varssumcheck[2] == 0 ) {
                            // id.push("1.3 ประสิทธิภาพห้ามล้อมือ");
                            id.push("ประสิทธิภาพห้ามล้อมือ");
                        }
                    }else if(i == 3){
                        if(this.itemcheck.varssumcheck[3] == 0 ) {
                            // id.push("2. ศูนย์ล้อ");
                            id.push("ศูนย์ล้อ");
                        }
                    }else if(i == 4){
                        if(this.itemcheck.varssumcheck[4] == 0 ) {
                            // id.push("3. ระดับเสียง");
                             id.push("ระดับเสียง");
                        }
                    }else if(i == 5){
                        if(this.itemcheck.varssumcheck[5] == 0 ) {
                            // id.push("4. ระบบไอเสีย");
                            id.push("ระบบไอเสีย");
                        }
                    }else if(i == 6){
                        if(this.itemcheck.varssumcheck[6] == 0 ) {
                            // id.push("5. แตรสัญญาณ");
                            id.push("แตรสัญญาณ");
                        }
                    }else if(i == 7){
                        if(this.itemcheck.varssumcheck[7] == 0 ) {
                            // id.push("6. มาตรวัดความเร็ว");
                            id.push("มาตรวัดความเร็ว");

                        }
                    }else if(i == 8){
                        if(this.itemcheck.varssumcheck[8] == 0 ) {
                            // id.push("7. โคมไฟ");
                            id.push("โคมไฟ");

                        }
                    }else if(i == 9){
                        if(this.itemcheck.varssumcheck[9] == 0 ) {
                            // id.push("8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");
                            id.push("โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");

                        }
                    }else if(i == 10){
                        if(this.itemcheck.varssumcheck[10] == 0 ) {
                            // id.push("9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");
                            id.push("โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");

                        }
                    }else if(i == 11){
                        if(this.itemcheck.varssumcheck[11] == 0 ) {
                            // id.push("10. เครื่องปัดน้ำฝน");
                            id.push("เครื่องปัดน้ำฝน");

                        }
                    }else if(i == 12){
                        if(this.itemcheck.varssumcheck[12] == 0 ) {
                            // id.push("11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");
                            id.push("กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");

                        }
                    }else if(i == 13){
                        if(this.itemcheck.varssumcheck[13] == 0 ) {
                            // id.push("12. ระบบบังคับเลี้ยวและพวงมาลัย");
                            id.push("ระบบบังคับเลี้ยวและพวงมาลัย");

                        }
                    }else if(i == 14){
                        if(this.itemcheck.varssumcheck[14] == 0 ) {
                            // id.push("13. ล้อและยาง");
                            id.push("ล้อและยาง");

                        }
                    }else if(i == 15){
                        if(this.itemcheck.varssumcheck[15] == 0 ) {
                            // id.push("14. ถังเชื้อเพลิงและท่อส่ง");
                            id.push("ถังเชื้อเพลิงและท่อส่ง");

                        }
                    }else if(i == 16){
                        if(this.itemcheck.varssumcheck[16] == 0 ) {
                            // id.push("15. เครื่องล่าง");
                            id.push("เครื่องล่าง");

                        }
                    }else if(i == 17){
                        if(this.itemcheck.varssumcheck[17] == 0 ) {
                            // id.push("16. สีรถ");
                            id.push("สีรถ");

                        }
                    }else if(i == 18){
                        if(this.itemcheck.varssumcheck[18] == 0 ) {
                            // id.push("17. โครงสร้างและตัวถัง");
                            id.push("โครงสร้างและตัวถัง");

                        }
                    }else if(i == 19){
                        if(this.itemcheck.varssumcheck[19] == 0 ) {
                            // id.push("18. ประตูและพื้นรถ");
                            id.push("ประตูและพื้นรถ");

                        }
                    }else if(i == 20){
                        if(this.itemcheck.varssumcheck[20] == 0 ) {
                            // id.push("19. ขนาดของรถ");
                            id.push("ขนาดของรถ");

                        }
                    }else if(i == 21){
                        if(this.itemcheck.varssumcheck[21] == 0 ) {
                            // id.push("20. ที่นั่งและจานวนที่นั่ง");
                            id.push("ที่นั่งและจานวนที่นั่ง");

                        }
                    }else if(i == 22){
                        if(this.itemcheck.varssumcheck[22] == 0 ) {
                            // id.push("21. เข็มขัดนิรภัย");
                            id.push("เข็มขัดนิรภัย");

                        }
                    }else if(i == 23){
                        if(this.itemcheck.varssumcheck[23] == 0 ) {
                            // id.push("22. อื่นๆ");
                            id.push("อื่นๆ");

                        }
                    }
                }
                return id;
            }else{
                for(let i=4; i<this.itemcheck.varssumcheck.length; i++ ) {
                    // console.log('this.itemcheck.varssumcheck[0]--->',this.itemcheck.varssumcheck[0])
                    if(i == 0){
                        if(this.itemcheck.varssumcheck[0] == 0 ) {
                            // id.push("1.1 ผลต่าง");
                            id.push("ผลต่าง");
                        }
                    }else if(i == 1){
                        if(this.itemcheck.varssumcheck[1] == 0 ) {
                            // id.push("1.2 ประสิทธิภาพห้ามล้อ");
                            id.push("ประสิทธิภาพห้ามล้อ");

                        }
                    }else if(i == 2){
                        if(this.itemcheck.varssumcheck[2] == 0 ) {
                            // id.push("1.3 ประสิทธิภาพห้ามล้อมือ");
                            id.push("ประสิทธิภาพห้ามล้อมือ");

                        }
                    }else if(i == 3){
                        if(this.itemcheck.varssumcheck[3] == 0 ) {
                            // id.push("2. ศูนย์ล้อ");
                            id.push("ศูนย์ล้อ");

                        }
                    }else if(i == 4){
                        if(this.itemcheck.varssumcheck[4] == 0 ) {
                            // id.push("3. ระดับเสียง");
                            id.push("ระดับเสียง");

                        }
                    }else if(i == 5){
                        if(this.itemcheck.varssumcheck[5] == 0 ) {
                            // id.push("4. ระบบไอเสีย");
                            id.push("ระบบไอเสีย");

                        }
                    }else if(i == 6){
                        if(this.itemcheck.varssumcheck[6] == 0 ) {
                            // id.push("5. แตรสัญญาณ");
                            id.push("แตรสัญญาณ");

                        }
                    }else if(i == 7){
                        if(this.itemcheck.varssumcheck[7] == 0 ) {
                            // id.push("6. มาตรวัดความเร็ว");
                            id.push("มาตรวัดความเร็ว");

                        }
                    }else if(i == 8){
                        if(this.itemcheck.varssumcheck[8] == 0 ) {
                            // id.push("7. โคมไฟ");
                            id.push("โคมไฟ");

                        }
                    }else if(i == 9){
                        if(this.itemcheck.varssumcheck[9] == 0 ) {
                            // id.push("8. โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");
                            id.push("โคมไฟเลี้ยว โคมไฟท้าย โคมไฟหยุด");

                        }
                    }else if(i == 10){
                        if(this.itemcheck.varssumcheck[10] == 0 ) {
                            // id.push("9. โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");
                            id.push("โคมไฟส่องป้ายทะเบียน โคมไฟอื่นๆ");

                        }
                    }else if(i == 11){
                        if(this.itemcheck.varssumcheck[11] == 0 ) {
                            // id.push("10. เครื่องปัดน้ำฝน");
                            id.push("เครื่องปัดน้ำฝน");

                        }
                    }else if(i == 12){
                        if(this.itemcheck.varssumcheck[12] == 0 ) {
                            // id.push("11. กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");
                            id.push("กระจกกันลมหน้า-หลังและส่วนที่เป็นกระจก");

                        }
                    }else if(i == 13){
                        if(this.itemcheck.varssumcheck[13] == 0 ) {
                            // id.push("12. ระบบบังคับเลี้ยวและพวงมาลัย");
                            id.push("ระบบบังคับเลี้ยวและพวงมาลัย");

                        }
                    }else if(i == 14){
                        if(this.itemcheck.varssumcheck[14] == 0 ) {
                            // id.push("13. ล้อและยาง");
                            id.push("ล้อและยาง");

                        }
                    }else if(i == 15){
                        if(this.itemcheck.varssumcheck[15] == 0 ) {
                            // id.push("14. ถังเชื้อเพลิงและท่อส่ง");
                            id.push("ถังเชื้อเพลิงและท่อส่ง");

                        }
                    }else if(i == 16){
                        if(this.itemcheck.varssumcheck[16] == 0 ) {
                            // id.push("15. เครื่องล่าง");
                            id.push("เครื่องล่าง");

                        }
                    }else if(i == 17){
                        if(this.itemcheck.varssumcheck[17] == 0 ) {
                            // id.push("16. สีรถ");
                            id.push("สีรถ");

                        }
                    }else if(i == 18){
                        if(this.itemcheck.varssumcheck[18] == 0 ) {
                            // id.push("17. โครงสร้างและตัวถัง");
                            id.push("โครงสร้างและตัวถัง");

                        }
                    }else if(i == 19){
                        if(this.itemcheck.varssumcheck[19] == 0 ) {
                            // id.push("18. ประตูและพื้นรถ");
                            id.push("ประตูและพื้นรถ");

                        }
                    }else if(i == 20){
                        if(this.itemcheck.varssumcheck[20] == 0 ) {
                            // id.push("19. ขนาดของรถ");
                            id.push("ขนาดของรถ");

                        }
                    }else if(i == 21){
                        if(this.itemcheck.varssumcheck[21] == 0 ) {
                            // id.push("20. ที่นั่งและจานวนที่นั่ง");
                            id.push("ที่นั่งและจานวนที่นั่ง");

                        }
                    }else if(i == 22){
                        if(this.itemcheck.varssumcheck[22] == 0 ) {
                            // id.push("21. เข็มขัดนิรภัย");
                            id.push("เข็มขัดนิรภัย");

                        }
                    }else if(i == 23){
                        if(this.itemcheck.varssumcheck[23] == 0 ) {
                            // id.push("22. อื่นๆ");
                            id.push("อื่นๆ");

                        }
                    }
                }
                return id;
            }
        },
    },
    watch: {},
    components:{},
    beforeCreate(){
    },
    created(){
        // time ticker
        setInterval(this.getNow, 1000);
        this.getini();
        // this.getsh();
    },
    beforeMount(){},
    mounted(){},
    beforeUpdate(){},
    updated(){},
    beforeDestroy(){},
    destroyed(){},
    
}

</script>