
<template>

    <div class="container-fluid">
        <Breadcrumbs main="แดชบอร์ด"/>

        <div class="row starter-main">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body text-center">
                <img src="../../assets/images/dlt/system/dlt_logo_dashboard.png" class="mt-4 img-fluid">
                <h5 class="mt-4">{{ hellonow }}</h5>
                <h5>{{ datenow }}</h5>
                <h4><span class="badge rounded-pill badge-primary mt-4 mb-2">ขณะนี้เวลา {{ timenow }} น.</span></h4>
              </div>
            </div>
          </div>
          <div class="col-xxl-9 box-col-12">
            <div class="modal fade" id="model-popup" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 v-show=" datapopup.title"><b>{{ datapopup.title }}</b></h5>
                            <button type="button" @click="closeModalpopup()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div v-show="datapopup.image"><img class="img-fluid" :src="storeAuth.UrlImagesPopup+datapopup.image" alt=""></div>
                            <div class="p-25">
                                <p v-show="datapopup.detail" class="text-muted mb-0" v-html="datapopup.detail"></p>
                            </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="closeModalpopup()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header card-no-border">
                    <h5>ข้อมูล 7 วันล่าสุด</h5>
                </div>
                <div class="card-body pt-0">
                    <div class="row m-0 overall-card overview-card">
                        <div class="col-xl-9 col-md-8 col-sm-7 p-0 box-col-7">
                            <div class="chart-right">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="card-body p-0">
                                            <ul class="balance-data">
                                                <li>
                                                    <span class="circle bg-primary"> </span><span class="f-light ms-1">ผลการตรวจรถตามกฎหมายว่าด้วยรถยนต์</span>
                                                </li>
                                                <li>
                                                    <span class="circle bg-secondary"></span><span class="f-light ms-1">ผลการตรวจรถตามกฎหมายว่าด้วยรถขนส่ง</span>
                                                </li>
                                            </ul>
                                            <div class="current-sale-container order-container">
                                                <div class="overview-wrapper">
                                                    <apexchart v-if="showChart" height="265" type="line" :options="ecomDashboard.options" :series="ecomDashboard.series"></apexchart>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-sm-5 p-0 box-col-5">
                            <div v-if="showChart" class="row g-sm-3 g-2">
                                <div class="col-md-12" v-for="item in orderOverview" :key="item">
                                    <div class="light-card balance-card widget-hover">
                                        <div class="svg-box">
                                            <svg class="svg-fill">
                                                <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${item.svgIcon}`"></use>
                                            </svg>
                                        </div>
                                        <div>
                                            <span class="f-light">{{ item.title }}</span>
                                            <h6 class="mt-1 mb-0">{{ item.overviewInNumber }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref,onMounted,inject,reactive } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import { Modal } from "bootstrap";

const state = reactive({
    modal_popup: null,
})
function openModalpopup(){ state.modal_popup.show()}
function closeModalpopup(){ state.modal_popup.hide()}

var datenow = ref()
var timenow = ref()
var hellonow = ref()
getdatetimerun();
var datacar = ref([]);
var datatruck = ref([]);
const datapopup = ref('')
var showChart = ref(false)
var orderOverview = ref()
var ecomDashboard = ref()
const Swal = inject('$swal')

function getdatetimerun() {
    const months_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม", ];
    const today = new Date();
    const date = 'วันที่ ' + today.getDate() + ' ' + months_th[(today.getMonth())] +' '+ ' พ.ศ. ' +(today.getFullYear() + 543);
    const time = today.getHours() + ":" + (today.getMinutes() < 10? '0'+today.getMinutes():today.getMinutes()) + ":" + (today.getSeconds() < 10? '0'+today.getSeconds():today.getSeconds());
    datenow.value = date;
    timenow.value = time;

    // เช็ค สวัสดี
    let hour = today.getHours();
    const data = [[22, 'สวัสดีตอนดึก'],[18, 'สวัสดีตอนเย็น'],[12, 'สวัสดีตอนบ่าย'],[5,  'สวัสดีตอนเช้า'],[0,  'สวัสดีวันใหม่']]

    for (var i = 0; i < data.length; i++) {
        if (hour >= data[i][0]) {
            hellonow = data[i][1]
            break;
        }
    }
}

async function getdata(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`dashboard`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id
  });

  if(res.data.status == 1){
    if(res.data.dataspopup[0].status == 1){
        datapopup.value = JSON.parse(JSON.stringify(res.data.dataspopup[0]));
        openModalpopup();
    }
    console.log(res.data.datascar)
    if(res.data.datascar.length > 0){
        datacar.value.push(res.data.datascar[0].total,res.data.datascar[0].c0,res.data.datascar[0].c1,res.data.datascar[0].c2,res.data.datascar[0].c3,res.data.datascar[0].c4,res.data.datascar[0].c5,res.data.datascar[0].c6)
    }else{
        datacar.value.push(0,0,0,0,0,0,0,0)
    }

    if(res.data.datastruck.length > 0){
        datatruck.value.push(res.data.datastruck[0].total,res.data.datastruck[0].c0,res.data.datastruck[0].c1,res.data.datastruck[0].c2,res.data.datastruck[0].c3,res.data.datastruck[0].c4,res.data.datastruck[0].c5,res.data.datastruck[0].c6)
    }else{
        datatruck.value.push(0,0,0,0,0,0,0,0)
    }

    orderOverview.value = [{
        svgIcon: "car",
        title: "ผลการตรวจรถยนต์ทั้งหมด",
        overviewInNumber: datacar.value[0]
    },
    {
        svgIcon: "truck",
        title: "ผลการตรวจรถขนส่งทั้งหมด",
        overviewInNumber: datatruck.value[0]
    },
    ]

    var dateNow1 = new Date();var dateNow2 = new Date();var dateNow3 = new Date();var dateNow4 = new Date();var dateNow5 = new Date();var dateNow6 = new Date();var dateNow7 = new Date();

    ecomDashboard.value = {
   
        options: {

            chart: {
                height: 300,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    color: '#000',
                    opacity: 0.08
                }
            },
            stroke: {
                width: [2, 2, 2],
                curve: 'smooth'
            },
            grid: {
                show: true,
                borderColor: 'var(--chart-border)',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            colors: ["#7064F5", "#FF3364"],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.4,
                    opacityTo: 0,
                    stops: [0, 100]
                }
            },
            labels: [new Date(dateNow1.setDate(dateNow1.getDate() - 6)).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10), new Date(dateNow2.setDate(dateNow2.getDate() - 5)).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10), new Date(dateNow3.setDate(dateNow3.getDate() - 4)).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10), new Date(dateNow4.setDate(dateNow4.getDate() - 3)).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10), new Date(dateNow5.setDate(dateNow5.getDate() - 2)).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10), new Date(dateNow6.setDate(dateNow6.getDate() - 1)).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10), dateNow7.toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ).substring(0, 10)
            ],
            markers: {
                discrete: [{
                        seriesIndex: 0,
                        dataPointIndex: 0,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 0,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                    {
                        seriesIndex: 0,
                        dataPointIndex: 1,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 1,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                    {
                        seriesIndex: 0,
                        dataPointIndex: 2,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 2,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                    {
                        seriesIndex: 0,
                        dataPointIndex: 3,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 3,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                    {
                        seriesIndex: 0,
                        dataPointIndex: 4,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 4,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                    {
                        seriesIndex: 0,
                        dataPointIndex: 5,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 5,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                    {
                        seriesIndex: 0,
                        dataPointIndex: 6,
                        fillColor: '#7064F5',
                        strokeColor: 'var(--white)',
                        size: 5,
                        sizeOffset: 3
                    }, {
                        seriesIndex: 1,
                        dataPointIndex: 6,
                        fillColor: '#FF3364',
                        strokeColor: 'var(--white)',
                        size: 5,
                    },
                ],
                hover: {
                    size: 5,
                    sizeOffset: 0
                }
            },
            xaxis: {
                type: 'category',
                tickAmount: 4,
                tickPlacement: 'between',
                tooltip: {
                    enabled: false,
                },
                axisBorder: {
                    color: 'var(--chart-border)',
                },
                axisTicks: {
                    show: false
                }
            },
            legend: {
                show: false,
            },
            yaxis: {
                min: 0,
                tickAmount: 6,
                tickPlacement: 'between',
            },
            tooltip: {
                shared: false,
                intersect: false,
            },
            responsive: [{
                breakpoint: 1200,
                options: {
                    chart: {
                        height: 250,
                    }
                },
            }]

        },
        series: [{
                name: 'ผลการตรวจรถยนต์',
                type: 'area',
                data: [datacar.value[7], datacar.value[6], datacar.value[5], datacar.value[4], datacar.value[3], datacar.value[2], datacar.value[1]]
            },
            {
                name: 'ผลการตรวจรถขนส่ง',
                type: 'area',
                data: [datatruck.value[7], datatruck.value[6], datatruck.value[5], datatruck.value[4], datatruck.value[3], datatruck.value[2], datatruck.value[1]]
            },
        ],
        
        
        

        }

    showChart.value = true;
    Swal.close();
  }else{
    Swal.close();
  }
}

onMounted( async () => {
    setInterval(() => {
        getdatetimerun()
    }, 1000)
    state.modal_popup = new Modal('#model-popup', {});
    await getdata();
})
</script>