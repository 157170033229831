<template>

  <div class="container-fluid">
      <Breadcrumbs main="รายละเอียดข้อมูลการตรวจสภาพรถตามกฏหมายรถยนต์"/>

      <div v-if="datas" class="row starter-main">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
                <a :href="'/user/datacarpdf/'+route.params.id" target="_blank" class="btn btn-pill btn-info ml-4">ไฟล์ PDF (ผลการตรวจ)</a>
              </div>
              <div class="card-body" >
                <div class="row">
                  <div class="col">
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลำดับ</label>
                      <div class="col-sm-9">
                        <input class="form-control" :value="datas.ID" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เวลาที่ตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHECK_DATETIME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เวลาที่ส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.SEND_DATETIME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขที่ใบอนุญาต</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_ID" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชื่อสถานตรวจสภาพรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ได้รับใบอนุญาต</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.TRO_OWNER_NAME" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_LIC_ID_CHAR+ ' ' +datas.CAR_LIC_ID_NUM + ' ' + datas.names" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">วันที่จดทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CAR_REG_DATE_1 + ' ' + mountthai(datas.CAR_REG_DATE_2) + ' ' + datas.CAR_REG_DATE_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ตรวจที่ 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.USER1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผู้ตรวจที่ 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.USER2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">PC IP</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PC_IP" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">PC MAC</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.PC_MAC" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลการตรวจ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.STATUS_CHECK == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">รูปภาพ</label>
                      <div class="col-sm-9">
                        <p>
                          <a :href="storeAuth.UrlImagesTruck + datas.PIC_CAR" target="_blank" class="previewImage">
                          <img :src="storeAuth.UrlImagesTruck + datas.PIC_CAR" width="200"  />
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขตัวถังรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.CHASSIS_NO" style="text-transform: uppercase;" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ชนิดโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_10_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ลักษณะรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="typecar(datas.CAR_TYPE)" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ประเภทการขนส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="typetruck(datas.PRB_TYPE)" type="text" readonly>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.ENGINE_NO" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เลขถังแก๊ส</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.GAS_NUMBER" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">1.ประสิทธิภาพห้ามล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลตรวจประสิทธิภาพห้ามล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_5" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_6" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_7" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_8" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_9" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_10" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_11" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_13" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 3</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_15" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 4</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_17" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แรงลงเพลา 5</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_19" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง (%) เพลา 1</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_12" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง (%) เพลา 2</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_14" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง (%) เพลา 3</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_16" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง (%) เพลา 4</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_18" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลต่าง (%) เพลา 5</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_1_20" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">2.ประสิทธิภาพห้ามล้อมือ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_0_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ผลการตรวจประสิทธิภาพห้ามล้อมือ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_0 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 1 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 2 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_5" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 3 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_6" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_7" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 4 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_8" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ซ้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_9" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">เพลา 5 ขวา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_2_10" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">3.เครื่องวัดความดันลมหรือสัญญาณเตือน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_3 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">4.ห้ามล้อฉุกเฉิน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_4 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">5.ชนิดเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_5 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">6.ตำแหน่งเลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_6 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">7.จำนวนสูบและซีซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_7 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">8.แรงม้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_8 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">9.ฝาครอบเลขเครื่องยนต์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_9 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">10.ตำแหน่งเลขตัวถังหรือโครงคัสซี</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_11 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">11.ระบบไอเสีย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_12 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">12.ระดับเสียง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_13 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ระดับเสียง(dB(A) ที่(0.5 ม.))</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_13_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">13.ระบบสตาร์ท</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_14 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">14.สัญญาณแตร</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_15 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">สัญญาณแตร(dB(A))</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_15_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">15.แบตเตอรี่</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_16 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">16.สายไฟฟ้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_17 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">17.เครื่องปัดน้ำฝน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_18 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">18.โคมไฟ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งไกล ซ้าย (องศา)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งไกล ขวา (องศา)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ความสว่าง ซ้าย (kcd)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ความสว่าง ขวา (kcd)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_4" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งต่ำ ซ้าย (องศา)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_5" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">แสงพุ่งต่ำ ขวา (องศา)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_6" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ความสว่าง ซ้าย (kcd)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_7" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ความสว่าง ขวา (kcd)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_19_8" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">19.โคมไฟส่องป้ายแสดงเส้นทาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_20 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">20.โคมไฟแสดงส่วนสูง ส่วนกว้าง และประเภทรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_21 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">21.โคมไฟจอด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_22 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">22.โคมไฟท้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_23 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">23.โคมไฟหยุด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_24 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">24.โคมไฟส่องป้ายทะเบียน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_25 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">25.โคมไฟเลี้ยว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_26 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">26.วัตถุสะท้อนแสง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_27 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">27.โคมไฟภายใน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_28 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">28.โคมไฟอื่นๆ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_29 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">29.กริ่งสัญญาณหยุดรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_30 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">30.เครื่องดับเพลิง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_31 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">31.กระจกเงาสำหรับมองหลัง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_32 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">32.กระจกกันลมหน้า หลัง และตัวถังส่วนที่เป็นกระจก</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_33 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">33.ศูนย์ล้อหน้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_34 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ศูนย์ล้อหน้า(m/km)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_34_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">34.จำนวน เพลาและยาง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_35 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">35.ยางและขนาดยาง หน้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_36 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">36.กันชน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_37 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">37.แผ่นบังโคลน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_38 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">38.อุปกรณ์ต่อพ่วง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_39 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">39.เพลาล้อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_40 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">40.สปริงและเครื่องผ่อนคลายกันสะเทือน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_41 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">41.เพลาส่งกำลัง ห่วงรองรับ และข้อต่อ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_42 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">42.ถังเชื้อเพลิงและท่อส่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_43 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">43.ระบบบังคับเลี้ยว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_44 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">44.คลัทซ์ เฟือง เฟืองท้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_45 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">45.เครื่องบันทึกความเร็ว ชนิด</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_46 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">46.เครื่องวัดความเร็ว</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_47 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ระยะทางวิ่ง(เลขไมล์)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_47_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">47.ตัวถังรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_48 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">48.เครื่องหมาย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_49 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">49.ประตูขึ้นลง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_50 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">50.ประตูฉุกเฉิน</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_51 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">51.ราวยึดเหนี่ยว ราวประตู</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_52 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">52.อุปกรณ์ใช้เสียงและประชาสัมพันธ์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_53 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">53.พื้นรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_54 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">54.ที่นั่งผู้ขับรถ ที่กั้นห้องผู้ขับรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_55 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">55.ที่นั่งผู้โดยสาร</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_56 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">56.หน้าต่าง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_57 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">57.หลังคารถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_58 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">58.เครื่องปรับอากาศ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_59 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">59.พัดลมดูดอากาศ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_60 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">60.ที่เตรียมอาหาร และเครื่องดื่ม</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_61 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">61.ห้องสุขภัณฑ์</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_62 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">62.ที่เก็บสัมภาระ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_63 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">63.จำนวนผู้โดยสารนั่ง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_64 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">64.น้ำหนักรถ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_65 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">65.น้ำหนักบรรทุก/น้ำหนังเพลา</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_66 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">66.จำนวนถังบรรทุก</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_67 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">67.ปริมาตรถังบรรทุก(รวม)</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_68 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">68.น้ำหนักลงเพลา หน้า/ท้าย</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_69 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">69.ค่าความเข้มของฟิล์มกรองแสง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_70 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ที่กระจกกันลมหน้า</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_70_1" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ที่กระจกกันลมหลัง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_70_2" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">ที่หน้าต่างที่ด้านข้าง</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_70_3" type="text" readonly>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">70.อื่นๆ</label>
                      <div class="col-sm-9">
                      <input class="form-control" :value="datas.opt_71 == 0?'ไม่ผ่าน':'ผ่าน'" type="text" readonly>
                      </div>
                    </div>
                
                    
                  </div>
                </div>
              </div>
              <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
              <div class="card-footer text-center">
                <a href="javascript:window.open('','_self').close();" class="btn btn-pill btn-danger">ปิดหน้านี้</a>
              </div>
            </div>
          </div>
        </div>
      </div>


  </template>
  
  <script setup> 
  import { ref,onMounted,inject } from 'vue';
  import { useRoute } from 'vue-router'
  import { storeAuth } from "../../store/modules/auth";
  import axios from "../../axios";

  const route = useRoute()
  let datas = ref();
  const Swal = inject('$swal')

  onMounted( async () => {
    await getdata_table();
  })
  async function getdata_table(){
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`gettruckdetail`,{
      rid : route.params.id,
      uid : storeAuth.id,
      gid : storeAuth.group_id
    });
    if(res.data.status == 1){
      datas.value = res.data.datas[0];
      Swal.close();
    }else{
      Swal.close();
    }
  }
function mountthai(id) {
  if(id == 1){
    return 'มกราคม';
  }else if(id == 2){
      return 'กุมภาพันธ์';
  }else if(id == 3){
      return 'มีนาคม';
  }else if(id == 4){
      return 'เมษายน';
  }else if(id == 5){
      return 'พฤษภาคม';
  }else if(id == 6){
      return 'มิถุนายน';
  }else if(id == 7){
      return 'กรกฎาคม';
  }else if(id == 8){
      return 'สิงหาคม';
  }else if(id == 9){
      return 'กันยายน';
  }else if(id == 10){
      return 'ตุลาคม';
  }else if(id == 11){
      return 'พฤศจิกายน';
  }else if(id == 12){
      return 'ธันวาคม';
  }
}
function typecar(id) {
  if(id == 1){
        return 'รถโดยสารมาตรฐาน '+datas.value.CAR_TYPE_OPTION_1_1+datas.value.CAR_TYPE_OPTION_1_2;
    }else if(id == 2){
        return 'รถบรรทุก ลักษณะ '+datas.value.CAR_TYPE_OPTION_2;
    }
  
}

function typetruck(id) {
  if(id == 1){ 
    return "ประจำทาง";
  }else if(id == '2'){
    return "ไม่ประจำทาง";
  }else{
    return"ส่วนบุคคล";
  }
}

  </script>
  