
<template>
    <div class="container-fluid">
      <Breadcrumbs main="กำหนดค่ามาตรฐาน"/>

      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                <div class="row">
                    <b>กำหนดค่าเครื่องวัดระดับแรงเบรค</b>
                </div>
                <div class="row">
                <div class="col-md-12">
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">รูปแบบการรับค่าจากเครื่องทดสอบแรงห้ามล้อ</label>
                        <div class="col-sm-3">
                        <select class="form-control" v-model="basetype" >
                            <option value="0">กรุณาเลือกรายการสำหรับ OCR/LAN</option>
                            <option value="ocr">OCR</option>
                            <option value="udp">LAN</option>
                        </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" v-if="basetype=='ocr'">
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ตำแหน่งรับค่ารูปภาพ</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="ocr_path: c:\printer path" v-model="ocr_path">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ชื่อไฟล์รูปภาพสำหรับรับค่าเบรคของรถยนต์</label>
                            <div class="col-sm-3">
                        <input class="form-control" type="text" placeholder="ocr_file: Capture.tif" v-model="ocr_file">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ชื่อไฟล์รูปภาพสำหรับรับค่าเบรคของรถขนส่ง</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="ocr_file1: Capture1.tif"    v-model="ocr_file1">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ชื่อไฟล์รูปภาพสำหรับทดสอบตั้งค่าเบรคของรถยนต์</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="ocr_testfile: CalibrateValue.tif" v-model="ocr_testfile">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ชื่อไฟล์รูปภาพสำหรับทดสอบตั้งค่าเบรคของรถขนส่ง</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="ocr_testfile: CalibrateValue1.tif" v-model="ocr_testfile1">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ฟังชั่นไฟล์รูปภาพขนาดใหญ่</label>
                        <div class="col-sm-3">
                            <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                                <input id="inline-1" type="checkbox" v-model="ocr_bigtiff" class="form-check-input"> 
                                <label for="inline-1" class="form-check-label">ติ๊กเพื่อเปิดฟังชั่นแปลงไฟล์รูป</label>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">ชื่อไฟล์รูปใหม่ที่แปลงแล้ว</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="ocr_outputfile: ocroutputfile.png" v-model="ocr_outputfile">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">นามสกุลไฟล์สำหรับ OCR</label>
                        <div class="col-sm-3">
                            <select class="form-control" v-model="ocr_type" >
                                <!-- <option value="tif">tif</option> -->
                                <!-- <option value="png">png</option> -->
                                <!-- <option value="jpg">jpg</option> -->
                                <option value="b64">b64</option>
                                <!-- <option value="data">data</option> -->
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="basetype=='udp'"> 
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">IP Address เครื่องทดสอบแรงห้ามล้อ</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="xxx.xxx.xxx.xxx" v-model="base_ip">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">พอร์ตเครื่องทดสอบแรงห้ามล้อ</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" v-model="base_lanport">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">พอร์ตรับค่าแรงห้ามล้อ</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" v-model="base_localport">
                        </div>
                    </div>
                </div>                      
                <div class="col-md-12" v-if="basetype=='0'">
                    <span>กรุณาเลือกรูปแบบเครื่องรับค่าวัดระดับแรงเบรค</span>
                </div>                      
            </div>
            <hr class="mt-3 mb-3"/>
            <div class="row">
                <b>กำหนดค่าเครื่องวัดระดับเสียง CO และ HC</b>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">รูปแบบการรับค่าจากเครื่องวัดระดับเสียง</label>
                        <div class="col-sm-3">
                        <select class="form-control" v-model="servertype" >
                            <option value="0">กรุณาเลือกรายการสำหรับ RS232/LAN</option>
                            <option value="serial">RS232</option>
                            <option value="udp">LAN</option>
                        </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="servertype=='serial'">
                <div class="col-md-12">
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">RS232 Timeout</label>
                        <div class="col-sm-3">
                            <select class="form-control" v-model="comporttimeout" >
                                <option value="1">1 วินาที</option>
                                <option value="2">2 วินาที</option>
                                <option value="3">3 วินาที</option>
                                <option value="4">4 วินาที</option>
                                <option value="5">5 วินาที</option>
                                <option value="6">6 วินาที</option>
                                <option value="7">7 วินาที</option>
                                <option value="8">8 วินาที</option>
                                <option value="9">9 วินาที</option>
                                <option value="10">10 วินาที</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <b>รับค่าระดับเสียง dBA</b>
            </div>
                <div class="row">
                    <div class="col-md-12" v-if="servertype=='serial'"> 
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">COMPORT รับค่า เสียง</label>
                            <div class="col-sm-3">
                            <select class="form-control" v-model="dba_com" >
                                <option value="">กรุณาเลือก COMPORT รับค่าระดับเสียง</option>
                                <option value="COM1">COM1</option>
                                <option value="COM2">COM2</option>
                                <option value="COM3">COM3</option>
                                <option value="COM4">COM4</option>
                                <option value="COM5">COM5</option>
                                <option value="COM6">COM6</option>
                                <option value="COM7">COM7</option>
                                <option value="COM8">COM8</option>
                                <option value="COM9">COM9</option>
                                <option value="COM10">COM10</option>
                                <option value="COM11">COM11</option>
                                <option value="COM12">COM12</option>
                                <option value="COM13">COM13</option>
                                <option value="COM14">COM14</option>
                                <option value="COM15">COM15</option>
                            </select>
                            </div>
                        </div>
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">พอร์ตรับค่าระดับเสียง</label>
                            <div class="col-sm-3">
                                <input class="form-control" type="text" placeholder="bund: 96000" v-model="dba_bund" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="servertype=='udp'"> 
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">IP เครื่องทดสอบเสียง</label>
                            <div class="col-sm-3">
                                <input class="form-control" type="text" placeholder="xxx.xxx.xxx.xxx" v-model="dba_ip">
                            </div>
                        </div>
                            <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">พอร์ตเครื่องทดสอบเสียง</label>
                            <div class="col-sm-3">
                                <input class="form-control" type="text" placeholder="port: 1200" v-model="dba_lanport">
                            </div>
                        </div>
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">พอร์ตรับค่าระดับเสียง</label>
                            <div class="col-sm-3">
                                <input class="form-control" type="text" placeholder="local port: 2002" v-model="dba_localport">
                            </div>
                        </div>
                    </div>                      
                    <div class="col-md-12" v-if="servertype=='0'">
                        <span>กรุณาเลือกรูปแบบเครื่องรับค่าวัดระดับเสียง</span>
                    </div>                      
                </div>
                <div class="row">
                    <b>รับค่า CO/HC</b>
                </div>
                <div class="row">
                    <div class="col-md-12" v-if="servertype=='serial'"> 
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">COMPORT รับค่า CO/HC</label>
                            <div class="col-sm-3">
                            <select class="form-control" v-model="cohc_com" >
                                <option value="">กรุณาเลือก COMPORT รับค่า CO/HC</option>
                                <option value="COM1">COM1</option>
                                <option value="COM2">COM2</option>
                                <option value="COM3">COM3</option>
                                <option value="COM4">COM4</option>
                                <option value="COM5">COM5</option>
                                <option value="COM6">COM6</option>
                                <option value="COM7">COM7</option>
                                <option value="COM8">COM8</option>
                                <option value="COM9">COM9</option>
                                <option value="COM10">COM10</option>
                                <option value="COM11">COM11</option>
                                <option value="COM12">COM12</option>
                                <option value="COM13">COM13</option>
                                <option value="COM14">COM14</option>
                                <option value="COM15">COM15</option>
                            </select>
                            </div>
                        </div>
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">พอร์ตรับค่า CO/HC</label>
                            <div class="col-sm-3">
                                <input class="form-control" type="text" placeholder="bund: 96000" v-model="cohc_bund" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="servertype=='udp'"> 
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">IP เครื่องทดสอบ CO/HC</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="xxx.xxx.xxx.xxx" v-model="cohc_ip">
                        </div>
                    </div>
                        <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">พอร์ตเครื่องทดสอบ CO/HC</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="port: 1200" v-model="cohc_lanport">
                        </div>
                    </div>
                    <div class="mb-2 mt-2 row">
                        <label class="col-sm-3 col-form-label">พอร์ตรับค่า CO/HC</label>
                        <div class="col-sm-3">
                            <input class="form-control" type="text" placeholder="local port: 2002" v-model="cohc_localport">
                        </div>
                    </div>
                    </div>                      
                    <div class="col-md-12" v-if="servertype=='0'">
                        <span>กรุณาเลือกรูปแบบเครื่องรับค่าวัดระดับ CO/HC</span>
                    </div>                      
                </div>
                <hr class="mt-3 mb-3"/>
                <div class="row">
                    <b>กำหนดค่าเครื่องวัดควันดำ</b>
                </div>
                <div class="row">
                        <div class="col-md-12">
                        <div class="col">
                            <div class="mb-3 m-t-15 custom-radio-ml">
                                <div class="form-check radio radio-primary">
                                    <input class="form-check-input" id="radio11" v-model="smokefilter" type="radio" name="radio3" value="50">
                                    <label class="form-check-label" for="radio11">ระบบกระดาษกรอง Filter ค่าควันดำไม่เกินร้อยละ 50</label>
                                </div>
                                <div class="form-check radio radio-primary">
                                    <input class="form-check-input" id="radio22" v-model="smokefilter" type="radio" name="radio3" value="45">
                                    <label class="form-check-label" for="radio22">ระบบวัดความทึบแสง Opacity ค่าควันดำไม่เกินร้อยละ 45</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="basetype=='udp'">
                    <b>*สำหรับตั้งค่าการแปลงหน่วยของค่าเบรคของยี่ห้อต่างๆ <span class="text-warning">(หากรับค่าได้ปกติไม่จำเป็นต้องเปลี่ยนการตั้งค่าไปเป็นแบบอื่น)</span></b>
                </div>
                <div class="row" v-if="basetype=='udp'">
                        <div class="col-md-12">
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label">รูปแบบของหน่วยค่าเบรค</label>
                            <div class="col-sm-3">
                                <select class="form-control" v-model="muller" >
                                    <option value="0">หน่วยแบบทั่วไป</option>
                                    <option value="1">หน่วยแบบยี่ห้อ Muller</option>
                                    <option value="2">หน่วยแบบยี่ห้อ Altus</option>
                                    <option value="3">หน่วยแบบยี่ห้อ Vamag</option>
                                    <option value="4">หน่วยแบบยี่ห้อ JA</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <b>หมุนกล้องถ่ายรูป</b>
                </div>
                <div class="row">
                        <div class="col-md-12">
                        <div class="col">
                            <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                                <input id="inline-2" type="checkbox" v-model="camera_setting_turn" class="form-check-input"> 
                                <label for="inline-2" class="form-check-label">ติ๊กถูก(หากต้องการหมุนภาพในกล้องถ่ายรูป)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="mt-3 mb-3"/>
                <div class="row">
                    <b>กำหนด HTTP PORT</b>
                </div>
                <div class="row">
                        <div class="col-md-12">
                        <div class="mb-2 mt-2 row">
                            <label class="col-sm-3 col-form-label text-danger">ไม่ควรตั้งค่า HTTP PORT เอง</label>
                            <div class="col-sm-3">
                            <select class="form-control" v-model="httpport">
                                <option value="8080">8080</option>
                                <option value="2274" disabled>2274</option>
                                <option value="8081" disabled>8081</option>
                                <option value="8082" disabled>8082</option>
                                <option value="8083" disabled>8083</option>
                                <option value="8084" disabled>8084</option>
                                <option value="8085" disabled>8085</option>
                            </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                        <div class="col-md-12"><button class="btn btn-primary" type="button" @click="save">บันทึกการกำหนดค่า</button></div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            ocr_bigtiff:false,
            ocr_outputfile:'ocroutputfile.png',
            servertype: '1',  //serial udp
            serverport:'8080',
            httpport:'8080',
            comporttimeout:'5',

            dba_com:'COM5',  // com1-com15
            dba_bund: '9600', // 9600
            dba_ip: '192.168.1.10', // 192.168.1.20
            dba_lanport:'1200',  // 1200
            dba_localport:'2002', // 2002

            cohc_com: 'COM5',
            cohc_bund: '9600',
            cohc_ip: '127.0.0.1',
            cohc_lanport :'1200',
            cohc_localport :'2002',

            basetype: 'udp',  //udp / ocr
            base_ip: '127.0.0.1',
            base_lanport: '1200',
            base_localport: '2002',
            
            smokefilter: 50,
            muller:0,
            camera_setting_turn:0,

            ocr_type:'b64',
            ocr_path:`c:\\printer path`,
            ocr_file:'Capture.tif',
            ocr_file1:'Capture1.tif',
            ocr_testfile:'CalibrateValue.tif',
            ocr_testfile1:'CalibrateValue1.tif',
            ocr_setting:[],

        }
    }, 
    methods:{
      save(){
        let chk = true
        if(this.servertype==0){
          chk = false;
          this.$swal({
            title: "คำเตือน",
            text: "กรุณาตั้งค่า Server Type",
            icon: "warning"
          });
          return
        }
        if(this.basetype==0){
          chk = false
          this.$swal({
            title: "คำเตือน",
            text: "กรุณาตั้งค่า Base Type",
            icon: "warning"
          });
          return
        }
        if(chk){
          let data = {};
          let server = {};
          let ocr = {};
          server.httpport = String(this.httpport);
          server.comporttimeout = String(this.comporttimeout);
          server.type = this.servertype;
          server.dba = this.dba_ip+":"+ this.dba_lanport;
          server.dba_localport = ":" + this.dba_localport;
          server.dba_bund = this.dba_bund;
          server.dba_com = this.dba_com;

          server.cohc = this.cohc_ip+":"+ this.cohc_lanport;
          server.cohc_localport = ":" + this.cohc_localport;
          server.cohc_bund = this.cohc_bund;
          server.cohc_com = this.cohc_com;

          server.basetype = this.basetype;
          server.base = this.base_ip+":"+this.base_lanport;
          server.base_localport = ":" + this.base_localport;
          server.smokefilter = this.smokefilter;
          if(!this.muller){
            server.muller = '0';
          }else{
            server.muller = this.muller;
          }
          if(this.camera_setting_turn){
            server.tf1 = '1';
          } else {
            server.tf1 = '0';
          }
          data.server = server;
          ocr.ocr_bigtiff = String(this.ocr_bigtiff ? '1':'0');
          ocr.ocr_outputfile = this.ocr_outputfile;
          ocr.ocr_type = this.ocr_type;

          ocr.ocr_path = this.ocr_path;
          ocr.ocr_file = this.ocr_file;
          ocr.ocr_file1 = this.ocr_file1;
          ocr.ocr_testfile = this.ocr_testfile;
          ocr.ocr_testfile1 = this.ocr_testfile1;
          ocr.ocr_setting = this.ocr_setting;

          data.ocr = ocr;
          this.setini(data);
       }
      },
      getini(){
        fetch('http://127.0.0.1:8080/api/getini', {
            method: "GET"
        })
        .then((response) => {
            response.json().then((rs) => {
                let server = rs.server;
                this.httpport = String(server.httpport || '8080');
                this.comporttimeout = String(server.comporttimeout || 5);
                this.servertype = server.type;
                this.dba_com = server.dba_com; 
                this.dba_bund = server.dba_bund;
                let dbas = String(server.dba).split(":");
                this.dba_ip = dbas[0];
                if(!server.muller){
                    this.muller  = '0';
                }else{
                    this.muller  = server.muller;
                }
                this.camera_setting_turn = server.tf1==1?true:false;
                this.dba_lanport = dbas[1];
                
                let dbalocal = String(server.dba_localport).split(':');
                this.dba_localport =  dbalocal[1] != undefined ? dbalocal[1] : dbalocal[0];
                
                this.cohc_com = server.cohc_com;
                this.cohc_bund = server.cohc_bund;
                let cohcs = String(server.cohc).split(':');
                this.cohc_ip = cohcs[0];
                this.cohc_lanport = cohcs[1];
                let cohclocal = String(server.cohc_localport).split(':');
                this.cohc_localport = cohclocal[1]!= undefined ? cohclocal[1] : cohclocal[0];
                
                this.basetype = server.basetype;
                let  bases = String(server.base).split(':');
                this.base_ip = bases[0];
                this.base_lanport = bases[1];
                let baselocal = String(server.base_localport).split(':');
                this.base_localport = baselocal[1] !=undefined ? baselocal[1] : baselocal[0];

                if(server.smokefilter==undefined){
                this.smokefilter = 50;
                } else {
                this.smokefilter = server.smokefilter;
                }

                let ocr = rs.ocr;
                this.ocr_path = ocr.ocr_path;
                this.ocr_file = ocr.ocr_file;
                this.ocr_file1 = ocr.ocr_file1;
                this.ocr_testfile = ocr.ocr_testfile;
                this.ocr_testfile1 = ocr.ocr_testfile1;
                this.ocr_setting = ocr.ocr_setting;

                this.ocr_type = ocr.ocr_type || 'b64';
                if(ocr.ocr_bigtiff == '1'){
                    this.ocr_bigtiff = true
                }else{
                    this.ocr_bigtiff = false
                }
                this.ocr_outputfile = ocr.ocr_outputfile || 'ocroutputfile.png';
            });
         
        }).catch((err) => {
            this.$swal({
                title: "ไม่สำเร็จ",
                text: "เรียกข้อมูลไม่ได้ กรุณาเปิดโปรแกรม v6(หน้าจอดำ) ค้างไว้ และลองอีกครั้ง",
                icon: "error"
            });
        });
      },
      setini(data){
            fetch('http://127.0.0.1:8080/api/setini', {
              method: "POST",
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then((rs) => {
              this.getini();
              this.$swal({
                title: 'ตั้งค่าข้อมูลเรียบร้อยแล้ว !',
                text: "ระบบจะทำการรีเซ็ทค่าให้โดยอัตโนมัติ",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#664284',
                confirmButtonText: 'ตกลง'
                }).then((result) => {
                    this.getini()
                })
          }).catch( (err) => {
                  this.$swal({
                    title: "ไม่สำเร็จ",
                    text: "เกิดข้อผิดพลาดในการบันทึกช้อมูล",
                    icon: "error"
                });
          });
      }
    }, 
    computed:{}, 
    watch: {}, 
    components:{}, 
    beforeCreate(){}, 
    created(){
      this.getini();
    }, 
    beforeMount(){}, 
    mounted(){}, 
    beforeUpdate(){}, 
    updated(){}, 
    beforeDestroy(){}, 
    destroyed(){}, 
}
</script>