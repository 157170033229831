<template>
    <div>
  
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 p-0">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                  </a>
                  <h5 class="text-center mb-4">ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ</h5>
                </div>
                <div class="col-xl-12">
                  <div class="login-main"> 
                      <div class="text-center">
                        <h4>ลงทะเบียนผู้ตรวจสภาพรถของสถานตรวจสภาพรถ</h4>
                        <p>กรุณากรอกข้อมูลให้ครบถ้วน</p>
                      </div>
                      <hr>
                      <div class="theme-form"> 
                        <div class="form-group">
                          <label class="col-form-label">กำหนดชื่อผู้ใช้งาน <br> <b class="text-danger">เช่น user1234567 (ไม่มีช่องว่าง ไม่ใช่อีเมลล์ ไม่ใช่ภาษาไทย)</b></label>
                          <input class="form-control" type="text"  v-model="datas.username" onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)" placeholder="กำหนดชื่อผู้ใช้งานใหม่สำหรับเจ้าหน้าที่ตรวจสภาพ">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">กำหนดรหัสผ่าน</label>
                          <input class="form-control" type="password" v-model="datas.password" onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)" placeholder="กำหนดรหัสผ่าน">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">กำหนดรหัสผ่านอีกครั้ง</label>
                          <input class="form-control" type="password" v-model="datas.passwordcon" onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)" placeholder="กำหนดรหัสผ่านอีกครั้ง">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">คำนำหน้านาม</label>
                          <div class="card-body animate-chk">
                              <div class="row">
                                  <div class="col">
                                      <label class="d-block" for="edo-ani">
                                      <input class="radio_animated" id="edo-ani" type="radio" v-model="datas.prefix" value="นาย">นาย
                                      </label>
                                      <label class="d-block" for="edo-ani1">
                                      <input class="radio_animated" id="edo-ani1" type="radio" v-model="datas.prefix" value="นาง">นาง
                                      </label>
                                      <label class="d-block" for="edo-ani2">
                                      <input class="radio_animated" id="edo-ani2" type="radio" v-model="datas.prefix" value="นางสาว">นางสาว
                                      </label>
                                      <label class="d-block" for="edo-ani13">
                                      <input class="radio_animated" id="edo-ani13" type="radio" v-model="datas.prefix" value="อื่นๆ">อื่นๆ
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <input class="form-control" type="text" v-model="datas.prefix_other" placeholder="โปรดระบุ">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">ชื่อ</label>
                          <input class="form-control" type="text"  v-model="datas.first_name">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">นามสกุล</label>
                          <input class="form-control" type="text"  v-model="datas.last_name">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">เลขที่ใบอนุญาต (เลขที่ตามบัตรที่กรมขนส่งออกให้ เช่น 551000999 )</label>
                          <input class="form-control" type="number" v-model="datas.inspector_num" required>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">ประเภทของผู้ตรวจสภาพรถ  <b class="text-danger">*</b></label>
                          <select class="selectpicker col-sm-12" data-live-search="true" v-model="datas.license_type" required>
                                  <option value="">กรุณาเลือก</option>
                                  <option value ='1'>ผู้ควบคุมการตรวจสภาพรถ</option>
                                  <option value ='2'>เจ้าหน้าที่ตรวจสภาพรถ</option>
                                  <option value ='3'>ผู้ควบคุมการตรวจสภาพรถจักรยานยนต์</option>
                                  <option value ='4'>เจ้าหน้าที่ตรวจสภาพรถจักรยานยนต์</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">เลขที่บัตรประชาชน <b class="text-danger">*</b></label>
                          <input class="form-control"  v-model="datas.idcard" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==13) return false;">
                        </div>
                        <div class="mt-1">
                          <label class="col-form-label">ปัจจุบันทำงานอยู่ที่ สถานตรวจสภาพรถ <b class="text-danger">*</b></label>
                          <multiselect :options="tro_name_id_value" :searchable="true" v-model="datas.manageinspectioncenter_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                        </div>
                        <div class="mt-1">
                          <label class="col-form-label">จังหวัด/เขต ที่ตั้ง สถานตรวจสภาพรถ</label>
                          <multiselect :options="searchcounty_value" :searchable="true" v-model="datas.county_id" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">เริ่มทำงานตั้งแต่วันที่ <b class="text-danger">(ปี ค.ศ.)</b></label>
                          <VueDatePicker locale="th" v-model="datas.manageinspectioncenter_date" auto-apply :min-date="new Date(new Date().setFullYear(new Date().getFullYear() - 11))" :max-date="new Date(new Date().setFullYear(new Date().getFullYear() + 5))" :enable-time-picker="false"/>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">วันที่ได้รับอนุญาต <b class="text-danger">(ปี ค.ศ.)</b></label>
                          <VueDatePicker locale="th" v-model="datas.authorized_date" auto-apply :min-date="new Date(new Date().setFullYear(new Date().getFullYear() - 11))" :max-date="new Date(new Date().setFullYear(new Date().getFullYear() + 5))" :enable-time-picker="false"/>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">วันสิ้นอายุใบอนุญาต <b class="text-danger">(ปี ค.ศ.)</b></label>
                          <VueDatePicker locale="th" v-model="datas.exp_date" auto-apply :min-date="new Date(new Date().setFullYear(new Date().getFullYear() - 11))" :max-date="new Date(new Date().setFullYear(new Date().getFullYear() + 5))" :enable-time-picker="false"/>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">วุฒิการศึกษา <b class="text-danger">*</b></label>
                          <select class="selectpicker col-sm-12" data-live-search="true" v-model="datas.educational" required>
                                  <option value="">กรุณาเลือก</option>
                                  <option  value ='1'>มัธยมศึกษาตอนต้น(ม.3)</option><option  value ='2'>มัธยมศึกษาตอนปลาย(ม.6)</option><option  value ='3'>ประกาศณียบัตรวิชาชีพ (ปวช)</option><option  value ='4'>ประกาศณียบัตรวิชาชีพชั้นสูง (ปวส)</option><option  value ='5'>ประกาศณียบัตรวิชาชีพทางเทคนิค (ปวท.)</option><option  value ='6'>ประกาศณียบัตรประโยคมัธยมศึกษาตอนปลายสายอาชีพ</option><option  value ='7'>อนุปริญญา</option><option  value ='8'>ปริญญาตรี</option><option  value ='9'>ปริญญาโท</option><option  value ='10'>ปริญญาเอก</option><option  value ='11'>อื่น ๆ</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">วุฒิการศึกษาอื่น ๆ โปรดระบุ</label>
                          <input class="form-control" type="text" v-model="datas.educational_other">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">สาขา  <b class="text-danger">*</b></label>
                          <select class="selectpicker col-sm-12" data-live-search="true" v-model="datas.educational_branch" required>
                                  <option value="">กรุณาเลือก</option>
                                  <option value ='1'>วิศวกรรมยานยนต์</option><option  value ='2'>วิศวกรรมเครื่องกล</option><option  value ='3'>วิศวกรรมขนส่ง</option><option  value ='4'>วิศวกรรมสิ่งแวดล้อม</option><option  value ='5'>วิศวกรรมเครื่องกล</option><option  value ='6'>วิศวกรรมไฟฟ้า</option><option  value ='7'>วิศวกรรมอิเลกทรอนิกส์</option><option  value ='8'>วิศวกรรมอุตสาหการ</option><option  value ='9'>ช่างยนต์</option><option  value ='10'>ช่างกลโรงงาน</option><option  value ='11'>ช่างไฟฟ้า</option><option  value ='12'>ช่างอิเลกทรอนิกส์</option><option  value ='13'>ด้านเทคนิคยานยนต์</option><option  value ='14'>เทคนิคเขียนแบบวิศวกรรมเครื่องกลหรือช่างยนต์</option><option  value ='15'>อื่น ๆ</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">สาขาอื่น ๆ โปรดระบุ <b class="text-danger">*</b></label>
                          <input class="form-control" type="text" v-model="datas.educational_branch_other">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">กรณีได้มีการศึกษาเพิ่มเติม หลักสูตร</label>
                          <select class="selectpicker col-sm-12" data-live-search="true" v-model="datas.course">
                                  <option value="">กรุณาเลือก</option>
                                  <option value="1">ระยะสั้น ประเภทวิชาช่างอุตสาหกรรม สาขาวิชา ช่างยนต์ของวิทยาลัยสารพัดช่าง สังกัดสำนักงานคณะกรรมการอาชีวศึกษา</option>
                                  <option value="2">ระยะสั้น แผนกช่างยนต์ วิชาช่างเครื่องยนต์ ระยะเวลาเรียน 600 ชม. ของโรงเรียนฝึกอาชีพกรุงเทพมหานคร (ดินแดง ๑)</option>
                                  <option value="3">ระยะสั้น ประเภทวิชาช่างอุตสาหกรรม สาขาวิชา ช่างยนต์ของวิทยาลัยเทคนิค สังกัดสำนักงานคณะกรรมการอาชีวศึกษา</option>
                                  <option value="4">อื่นๆ</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">หลักสูตรอื่น ๆ โปรดระบุ</label>
                          <input class="form-control" type="text" v-model="datas.course_other">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">สถาบันที่ศึกษาเพิ่มเติม</label>
                          <input class="form-control" type="text" v-model="datas.institute_info">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">ที่อยู่/บ้านเลขที่ของผู้ตรวจสภาพรถ  <b class="text-danger">*</b></label>
                          <textarea class="form-control" rows="5" cols="5" v-model="datas.car_inspector_address" ></textarea>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">รูปโปรไฟล์ช่างผู้ตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                          <div class="alert alert-info inverse alert-dismissible fade show" role="alert"><i class="icon-alert"></i>
                            <p><b>แนะนำ!</b> แนบภาพหน้าตรง ไม่สวมแว่นตา แต่งกายชุดสุภาพ ถ่ายไม่เกิน 6 เดือน และต้อง Upload ภาพถ่ายเข้าในระบบด้วย.</p>
                            <img src="../../assets/images/dlt/user-card/excample_prifile_register.png" width="70%" class="img-fluid mx-auto d-block" alt="Responsive image">
                          </div>
                          <input class="form-control" type="file" @change="B64img1"  accept="image/*">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">รูปบัตรช่างผู้ตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                          <div class="alert alert-info inverse alert-dismissible fade show" role="alert"><i class="icon-alert"></i>
                            <p><b>แนะนำ!</b> แนบภาพบัตรช่างตรวจสภาพรถที่มีข้อมูลถูกต้อง ครบถ้วนและชัดเจน</p>
                            <img src="../../assets/images/dlt/user-card/excample_register.png" width="70%" class="img-fluid mx-auto d-block" alt="Responsive image">
                          </div>
                          <input class="form-control" type="file" @change="B64img2"  accept="image/*">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">เบอร์โทรศัพท์</label>
                          <input class="form-control" type="text" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" v-model="datas.tel">
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">มือถือ</label>
                          <input class="form-control" type="text" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" v-model="datas.phone">
                        </div>
                          <div class="form-group mb-0 text-center">
                              <div class="ccol d-flex justify-content-center mb-4 mt-4">
                                  <RecaptchaV2
                                    @load-callback="handleLoadCallback"
                                  />
                              </div>

                              <div class="d-grid gap-2 col-11 mx-auto">
                                <button class="btn btn-primary" @click="addregister">ลงทะเบียน</button>
                                <router-link to="/auth/login" class="btn btn-info">เข้าสู่ระบบ</router-link>
                                <router-link to="/" class="btn btn-secondary" type="button" title="กลับสู้หน้าหลัก">กลับสู้หน้าหลัก</router-link>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { ref,inject,onMounted } from 'vue';
  import axios from "../../axios";
  import { RecaptchaV2 } from "vue3-recaptcha-v2";

  const Swal = inject('$swal')

  onMounted(() => {
    getdata();
  })

  const datas = ref({
  });
  let RecaptchaCheck = '';

  const searchcounty_value = ref([{id:'',names:''}]);
  function searchcounty_select({names}) {return `${names}`}
  const tro_name_id_value = ref([]);
  function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}

  async function getdata(){
    Swal.fire({
        title: 'ระบบกำลังส่งข้อมูล',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
    Swal.showLoading()
    
    const dataget = {
      tid : 1,
      datas : '',
    }
    const res = await axios.post(`register`,dataget);
    if(res.data.status == 1){
      searchcounty_value.value = res.data.datas.ctarea;
      tro_name_id_value.value = res.data.datas.g4;
    }
    Swal.close()
  }

  function  B64img1(event) {
    let input = event.target;
    if (input.files && input.files[0]) {
      if((input.files[0].size/1024/1024).toFixed(2) <= 2){
        let reader = new FileReader();
          reader.onload = (e) => {
            datas.value.manageusers_pic = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }else{
        Swal.fire({
            title: "คำเตือน",
            text: `ขนาดรูปเกิน 2 MB`,
            icon: "warning",
        });
      }
    }
  }

  function  B64img2(event) {
    let input = event.target;
    if (input.files && input.files[0]) {
      if((input.files[0].size/1024/1024).toFixed(2) <= 2){
        let reader = new FileReader();
          reader.onload = (e) => {
            datas.value.manageusers_pic_card = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }else{
        Swal.fire({
            title: "คำเตือน",
            text: `ขนาดรูปเกิน 2 MB`,
            icon: "warning",
        });
      }
    }
  }

  async function addregister(){
    if(RecaptchaCheck){
      Swal.fire({
            title: 'ระบบกำลังส่งข้อมูล',
            html: 'กรุณารอสักครู่ ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        Swal.showLoading()

        if(!datas.value.manageinspectioncenter_date){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกวันที่ ที่เริ่มที่ ตรอ. ที่ทำงานอยู่ในปัจจุบัน`,
              icon: "warning",
          });
        }else if(!datas.value.authorized_date){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกวันที่ได้รับอนุญาต`,
              icon: "warning",
          });
        }else if(!datas.value.exp_date){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกวันสิ้นอายุใบอนุญาต`,
              icon: "warning",
          });
        }else if(Date.parse(new Date(datas.value.exp_date).toLocaleString("en-US")) < Date.parse(new Date().toLocaleString("en-US"))){
          Swal.fire({
              title: "คำเตือน",
              text: `บัตรของท่านถึงกำหนดวันสิ้นอายุใบอนุญาตแล้ว`,
              icon: "warning",
          });
        }else if(!datas.value.inspector_num){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกเลขที่ใบอนุญาต`,
              icon: "warning",
          });
        }else if(!datas.value.idcard){
          Swal.fire({
              title: "คำเตือน",
              text: `กรุณากรอกเลขบัตรประจำตัวประชาชน`,
              icon: "warning",
          });
        }else if(datas.value.inspector_num.toString().length != 9){
          Swal.fire({
              title: "คำเตือน",
              text: `เลขที่ใบอนุญาตไม่ครบ 9 หลัก`,
              icon: "warning",
          });
        }else if(datas.value.idcard.toString().length != 13){
          Swal.fire({
              title: "คำเตือน",
              text: `เลขบัตรประจำตัวประชาชนไม่ครบ 13 หลัก`,
              icon: "warning",
          });
        }else if(!datas.value.manageusers_pic){
          Swal.fire({
              title: "คำเตือน",
              text: `ยังไม่แนบรูปโปรไฟล์ช่างผู้ตรวจสภาพรถ`,
              icon: "warning",
          });
        }else if(!datas.value.manageusers_pic_card){
          Swal.fire({
              title: "คำเตือน",
              text: `ยังไม่แนบรูปบัตรช่างผู้ตรวจสภาพรถ`,
              icon: "warning",
          });
        }else{
          const datainsert = {
            tid : 2,
            datas : datas.value,
          }
          const res = await axios.post(`register`, datainsert);
          if(res.data.status == 1){
            Swal.fire({
              title: "สำเร็จ",
              text: `ส่งข้อมูลเรียบร้อยแล้ว`,
              icon: "success",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "ตกลง",
            }).then((result) => {
              location.reload();
            });
          }else if(res.data.status == 3){
            Swal.fire({
                title: "ไม่สำเร็จ",
                text: `Username นี้มีผู้บันทึกไว้แล้ว`,
                icon: "error",
            });
          }else if(res.data.status == 4){
            Swal.fire({
                title: "ไม่สำเร็จ",
                text: `เลขที่ใบอนุญาต นี้มีผู้บันทึกไว้แล้ว`,
                icon: "error",
            });
          }else if(res.data.status == 5){
            Swal.fire({
                title: "ไม่สำเร็จ",
                text: `สถานที่ทำงานไม่ตรงกับที่สมัครมาก่อนหน้านี้`,
                icon: "error",
            });
          }else{
            Swal.fire({
                title: "ไม่สำเร็จ",
                text: `เกิดข้อผิดพลาดในการส่งข้อมูล`,
                icon: "error",
            });
          }
        }
    }else{
      Swal.fire({
          title: "คำเตือน",
          text: `กรุณาคลิกเพื่อยืนยันว่าคุณไม่ใช่บอท`,
          icon: "warning",
      });
    }
  }
  const handleLoadCallback = (response) => {
    RecaptchaCheck = response
  };

  </script>
  