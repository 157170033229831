<template>
  <div class="header-wrapper row m-0">
    <Logo />

    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <!-- <div class="notification-slider"> -->
      <!-- <swiper :slidesPerView="1" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :modules="modules" class="notification-slider" direction="vertical">
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/giftools.gif" alt="gif">
            <h6 class="mb-0 f-w-400">
              <span class="font-primary">Don't Miss Out! </span><span class="f-light">Out new update has been
                release.</span>
            </h6><i class="icon-arrow-top-right f-light"></i>
          </div>
        </swiper-slide>
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/giftools.gif" alt="gif">
            <h6 class="mb-0 f-w-400">
              <span class="f-light">Something you love is now on sale! </span>
            </h6><a class="ms-1" href="https://1.envato.market/3GVzd" target="_blank">Buy now !</a>
          </div>
        </swiper-slide>
      </swiper> -->

      <!-- </div> -->
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li class="onhover-dropdown">
          <button :class="'btn mb-0 me-0 btn-pill text-lg ' + class_status" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="status_server_detail"><i class="fa fa-spinner fa-spin"></i> {{status_server}}</button>
        </li>
        <li>
          <Mode />
        </li>
        <Notifications />

        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Notifications from './notifications.vue';
import Mode from './mode';
import Profile from './profile';
import Logo from './logo.vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";
import { Tooltip } from 'bootstrap'

export default {
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  components: {
    Notifications,
    Mode,
    Profile,
    Logo,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      class_status: '',
      status_server_detail: '',
      status_server: '',
      bookmark: false,
    };
  },
  computed: {
    ...mapState({
      megamenuItems: (state) => state.menu.megamenu,
    }),
  },
  methods: {
    async getdataStatus(){
      const res = await axios.post(`setting`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 3,
      });
      if(res.data.status == 1){
        if(res.data.datas[0].status_server == 1){
          this.status_server = 'ปกติ';
          this.class_status = 'btn-primary';
        }else if(res.data.datas[0].status_server == 2){
          this.status_server = 'มีปัญหาในบางส่วน';
          this.class_status = 'btn-warning';
        }else if(res.data.datas[0].status_server == 3){
          this.status_server = 'ไม่ปกติ';
          this.class_status = 'btn-danger';
        }else if(res.data.datas[0].status_server == 4){
          this.status_server = 'แจ้งเตือน';
          this.class_status = 'btn-info';
        }
        this.status_server_detail = res.data.datas[0].status_server_detail
      }
    }
  },
  created: function () {
    this.getdataStatus()
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>
