
<template>

    <div class="container-fluid">
        <Breadcrumbs main="รายการการลงโทษสถานตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12">
              <!-- model search -->
              <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ดูข้อมูล</b>
                            <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                                <div class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                  <div class="col-sm-8">
                                    <input type="text" class="form-control" :value="license_id_e" readonly>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">ประเภทการลงโทษ</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_type_e" disabled>
                                              <option value="0" selected>กรุณาเลือกประเภทการลงโทษ</option>
                                              <option value="1">การตักเตือน</option>
                                              <option value="2">การระงับการดำเนินการตรวจสภาพรถเป็นการชั่วคราว</option>
                                              <option value="4">การเพิกถอนใบอนุญาต</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_type_e == 1"  class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">ข้อหาความผิดที่ได้รับ</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_type_detail_e" disabled>
                                              <option value="0" selected>กรุณาเลือกข้อหาความผิดที่ได้รับ</option>
                                              <option value="1">(1) ไม่ปรับเทียบ ตรวจสอบ หรือบำรุงรักษาเครื่องตรวจสภาพรถและอุปกรณ์หรือสิ่งอำนวยความสะดวกในการตรวจสภาพรถและการดำเนินงานของสถานตรวจสภาพรถให้ถูกต้องเที่ยงตรงและพร้อมใช้งานได้ดี</option>
                                              <option value="2">(2) เปลี่ยนหรือใช้เครื่องตรวจสภาพ ซึ่งเครื่องตรวจสภาพนั้นเป็นชนิดและแบบหรือรุ่นที่กรมการขนส่งทางบกเห็นชอบแล้ว โดยยังไม่ได้รับอนุญาตจากนายทะเบียน</option>
                                              <option value="3">(3) ไม่จัดให้มีป้ายชื่อสถานตรวจสภาพรถ ข้อความหรือเครื่องหมายแสดงประเภทลักษณะ หรือขนาดของรถที่รับบริการตรวจสภาพ สัญลักษณ์สถานตรวจสภาพรถ ป้ายแสดงอัตราค่าบริการ วันและเวลาที่ให้บริการตรวจสภาพรถข้อความเตือนมีให้บุคคลที่ไม่เกี่ยวข้องเข้าไปบริเวณที่ทำการตรวจสภาพ หรือมีแต่ชำรุด ลบเลือน</option>
                                              <option value="4">(4) ไม่แจ้งวันและเวลาที่ให้บริการเป็นหนังสือต่อนายทะเบียนก่อนวันที่เริ่มให้บริการตรวจสภาพรถครั้งแรกหรือก่อนการเปลี่ยนแปลงวันและเวลาที่ให้บริการ</option>
                                              <option value="5">(5) เปลี่ยนแปลงรอยตราประทับประจำสถานตรวจสภาพรถโดยไม่มีการแจ้งให้นายทะเบียนทราบล่วงหน้า</option>
                                              <option value="6">(6) ไม่ส่งลายมือชื่อผู้ได้รับมอบอำนาจให้ทำการแทนผู้ได้รับใบอนุญาตก่อนวันที่ปฏิบัติหน้าที่</option>
                                              <option value="7">(7) ไม่แจ้งรายชื่อผู้ควบคุมการตรวจสภาพรถและเจ้าหน้าที่ตรวจสภาพรถต่อนายทะเบียนก่อนวันที่เริ่มปฏิบัติหน้าที่</option>
                                              <option value="8">(8) ไม่ควบคุม กำกับ ดูแลให้ผู้ควบคุมการตรวจสภาพรถหรือเจ้าหน้าที่ตรวจสภาพรถแต่งกายสุภาพเหมาะสม และติดบัตรประจำตัวตลอดเวลาในขณะปฏิบัติหน้าที่</option>
                                              <option value="9">(9) ชักชวนหรือแนะนำ หรือยินยอมให้ผู้อื่นชักชวนหรือแนะนำให้ผู้นำรถเข้ารับการตรวจสภาพรถไปเข้ารับการตรวจ ซ่อม หรือ ปรับแต่ง ณ ประกอบการแห่งหนึ่งแห่งใด</option>
                                              <option value="10">(10) ไม่ประทับตราชื่อ ที่อยู่สถานตรวจสภาพรถ ไม่ลงวันที่/ครั้งที่ตรวจ ไม่ลงลายมือชื่อในใบบันทึกการตรวจสภาพรถให้ครบถ้วน</option>
                                              <option value="11">(11) ใช้ใบรับรองการตรวจสภาพรถโดยไม่เรียงตามลำดับเล่มที่หรือเลขที่</option>
                                              <option value="12">(12) ไม่จัดทำรายงานการตรวจสภาพรถ หรือไม่จัดส่งรายงานการตรวจสภาพรถสำหรับให้เจ้าหน้าที่ตรวจสอบ</option>
                                              <option value="13">(13) ไม่จัดให้มีอุปกรณ์หรือสิ่งอำนวยความสะดวก หรือเครื่องือที่ใช้ในการดำเนินงานและการควบคุมกำกับดูแลสถานตรวจสภาพรถที่ไม่เกี่ยวข้องกับระบบตรวจสภาพรถ เช่น เครื่องพิมพ์ เครื่องดับเพลิง</option>
                                              <option value="14">(14) ดำเนินการอื่นในสถานตรวจสภาพรถ จนเป็นเหตุให้ไม่สามารถตรวจสภาพรถได้โดยสะดวก</option>
                                              <option value="15">(15) ไม่ดูแลและปรับปรุงอาคารสถานที่ ลานจอดรถ และพื้นที่ในบริเวณสถานตรวจสภาพรถ ให้เหมาะสม ปลอดภัย และสะอาดเรียบร้อย</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_type_e == 1"  class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">การตักเตือน</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_count_e" disabled>
                                              <option value="0" selected>กรุณาเลือกครั้งที่ถูกลงโทษ</option>
                                              <option value="1">ครั้งที่ 1</option>
                                              <option value="2">ครั้งที่ 2</option>
                                              <option value="3">ครั้งที่ 3 (ระงับชั่วคราว 7 วัน)</option>
                                              <option value="4">ครั้งที่ 4 (ระงับชั่วคราว 15 วัน)</option>
                                              <option value="5">ครั้งที่ 5 (ระงับชั่วคราว 15 วัน)</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_type_e == 2"  class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">ข้อหาความผิดที่ได้รับ</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_type_detail_e" disabled>
                                              <option value="0" selected>กรุณาเลือกข้อหาความผิดที่ได้รับ</option>
                                              <option value="16">(1) เครื่องตรวจสภาพรถและอุปกรณ์หรือสิ่งอำนวยความสะดวกในการตรวจสภาพรถไม่สามารถปรับเทียบความเที่ยงตรงหรือชำรุดใช้งานไม่ได้</option>
                                              <option value="17">(2) ไม่ควบคุม กำกับ ดูแลผู้ควบคุมการตรวจสภาพรถและเจ้าหน้าที่ตรวจสภาพรถให้ดำเนินการตรวจสภาพรถและวินิจฉัยผลการตรวจสภาพรถตามหลักเกณฑ์และวิธีการที่กำหนด</option>
                                              <option value="18">(3) ไม่จัดทำการรับรองการตรวจสภาพรถ บันทึกการตรวจสภาพรถ รายงานการตรวจสภาพรถ และเครื่องหมายหรือเอกสารอื่นใดตามกำหนด</option>
                                              <option value="19">(4) ไม่จัดให้มีเครื่องตรวจสภาพรถหรืออุปกรณ์หรือสิ่งอำนวยความสะดวกในการตรวจสภาพรถ หรือเครื่องตรวจสภาพรถอื่นใดเพิ่มเติมตามที่กำหนด</option>
                                              <option value="20">(5) เปลี่ยนแปลงอาคารสถานที่ ลานจอดรถ พื้นที่สำหรับรถรอเข้าตรวจสภาพทางเข้าและทางออก โดยไม่ได้รับอนุญาต</option>
                                              <option value="21">(6) เปลี่ยนหรือใช้เครื่องตรวจสภาพรถเป็นชนิดและแบบ/รุ่นที่ไม่เป็นไปตามที่กรมการขนส่งทางบกกำหนด หรือให้ความเห็นชอบ</option>
                                              <option value="22">(7) ไม่จัดเก็บค่าบริการตรวจสภาพรถตามอัตราที่กำหนด</option>
                                              <option value="23">(8) ผู้ได้รับใบอนุญาต ผู้ได้รับมอบอำนาจผู้ควบคุมการตรวจสภาพรถหรือเจ้าหน้าที่การตรวจสภาพรถ ลงลายมือชื่อไว้ล่วงหน้าในใบรับรองการตรวจสภาพรถ หรือบันทึกการตรวจสภาพรถ</option>
                                              <option value="24">(9) อนุญาตให้บุคคลอื่นนอกจากผู้ควบคุมการตรวจสภาพรถหรือเจ้าหน้าที่ตรวจสภาพรถเข้าควบคุมการตรวจสภาพรถหรือทำหน้าที่ตรวจสภาพรถ</option>
                                              <option value="25">(10) ไม่ใช้แบบพิมพ์ใบรับรองการตรวจสภาพรถ ใบบันทึกการตรวจสภาพรถ และใบรายงานการตรวจสภาพรถที่จัดทำโดยกรมการขนส่งทางบก</option>
                                              <option value="26">(11) ไม่จัดให้มีผู้ควบคุมการตรวจสภาพรถและเจ้าหน้าที่ตรวจสภาพรถที่มีคุณสมบัติและผ่านการอบรมและทดสอบตามที่กำหนด</option>
                                              <option value="27">(12) ไม่จัดให้มีผู้ควบคุมการตรวจสภาพรถและเจ้าหน้าที่ตรวจสภาพรถตามจำนวนที่กำหนด</option>
                                              <option value="28">(13) ไม่จัดให้มีผู้ควบคุมการตรวจสภาพรถและเจ้าหน้าที่ตรวจสภาพรถประจำสถานตรวจสภาพรถตลอดเวลาทำการ</option>
                                              <option value="29">(14) ไม่ทำการตรวจสภาพรถภายในพื้นที่ตรวจสภาพรถได้รับอนุญาต</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_type_e == 2"  class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">การระงับการดำเนินการตรวจสภาพรถเป็นการชั่วคราว</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_count_e" disabled>
                                              <option value="0" selected>กรุณาเลือกครั้งที่ถูกลงโทษ</option>
                                              <option value="6">ระงับชั่วคราว 7 วัน</option>
                                              <option value="7">ระงับชั่วคราว 15 วัน</option>
                                              <option value="8">ระงับชั่วคราว 30 วัน</option>
                                              <option value="9">ระงับ(เพิกถอนใบอนุญาต)</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_type_e == 4"  class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">ข้อหาความผิดที่ได้รับ</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_type_detail_e" disabled>
                                              <option value="0" selected>กรุณาเลือกข้อหาความผิดที่ได้รับ</option>
                                              <option value="30">(1) ถูกระงับการดำเนินการครบ ๔ ครั้ง</option>
                                              <option value="31">(2) ทุจริตหรือจงใจออกใบรับรองตรวจสภาพรถโดยไม่ตรงตามความเป็นจริง หรือ ออกใบรับรองการตรวจสภาพรถโดยไม่มีการตรวจสภาพ</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_type_e == 4" class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">การเพิกถอนใบอนุญาต</label>
                                  <div class="col-sm-8">
                                      <select class="form-select" v-model="punishment_count_e" disabled>
                                              <option value="0" selected>กรุณาเลือกครั้งที่ถูกลงโทษ</option>
                                              <option value="10">เพิกถอนใบอนุญาต</option>
                                      </select>
                                  </div>
                                </div>
                                <div v-if="punishment_count_e == 3 || punishment_count_e == 4 || punishment_count_e == 5 || punishment_count_e == 6 || punishment_count_e == 7 || punishment_count_e == 8" class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">ตั้งแต่วันที่</label>
                                  <div class="col-sm-3">
                                    <VueDatePicker locale="th" v-model="punishment_start_e" auto-apply :max-date="new Date()" :enable-time-picker="false" disabled/>
                                  </div>
                                  <label class="col-sm-1 col-form-label">ถึงวันที่</label>
                                  <div class="col-sm-3">
                                    <VueDatePicker locale="th" v-model="punishment_end_e" auto-apply :max-date="new Date()" :enable-time-picker="false" disabled/>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-4 form-label text-right">รายละเอียดเพิ่มเติม</label>
                                  <div class="col-sm-8">
                                    <textarea class="form-control" rows="5" cols="5" v-model="punishment_detail_e" disabled></textarea>
                                  </div>
                                </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>วันที่ทำรายการ</th>
                                <th>ชื่อสถานตรวจสภาพรถ</th>
                                <th>เลขที่ใบอนุญาต</th>
                                <th class="none">จังหวัดที่ตั้งสถานตรวจสภาพรถ</th>
                                <th>ประเภทการลงโทษ</th>
                                <th>รายละเอียดการลงโทษ</th>
                                <th>วันที่ได้รับอนุญาตจัดตั้ง</th>
                                <th>วันที่สิ้นสุดอนุญาตจัดตั้ง</th>
                                <th>จัดการ</th>
                              </tr>
                            </thead>
                            <template #column-9="props">
                              <div class="btn-group">
                                <button @click="geteditdata(props.cellData)" type="button" class="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject, watchEffect } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_edit: null,
})
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);

const license_id_e = ref(); 
const punishment_type_e = ref(0);
const punishment_type_detail_e = ref(0);
const punishment_count_e = ref(0);
const punishment_start_e = ref('');
const punishment_end_e = ref('');
const punishment_detail_e = ref('');
const dataeditid = ref('');

onMounted(() => {
  getdata_table();
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`punishment`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      let punish_type = '';
      if(res.data.datas[i].punishment_type == 0){
          punish_type = '-';
      }else if(res.data.datas[i].punishment_type == 1){
          punish_type = 'การตักเตือน';
      }else if(res.data.datas[i].punishment_type == 2){
          punish_type = 'การระงับการดำเนินการตรวจสภาพรถเป็นการชั่วคราว';
      }else if(res.data.datas[i].punishment_type == 3){
          punish_type = 'การระงับการดำเนินการตรวจสภาพรถเป็นการชั่วคราว(รอการแก้ไข)';
      }else{
          punish_type = 'การเพิกถอนใบอนุญาต';
      }
      let punish_detail = '';
      if(res.data.datas[i].punishment_count == '0'){
          punish_detail = '-';
      }else if(res.data.datas[i].punishment_count == '1'){
          punish_detail = 'ตักเตือน ครั้งที่ 1';
      }else if(res.data.datas[i].punishment_count == '2'){
          punish_detail = 'ตักเตือน ครั้งที่ 2';
      }else if(res.data.datas[i].punishment_count == '3'){
          punish_detail = 'ตักเตือน ครั้งที่ 3 (ระงับชั่วคราว 7 วัน)';
      }else if(res.data.datas[i].punishment_count == '4'){
          punish_detail = 'ตักเตือน ครั้งที่ 4 (ระงับชั่วคราว 15 วัน)';
      }else if(res.data.datas[i].punishment_count == '5'){
          punish_detail = 'ตักเตือน ครั้งที่ 5 (ระงับชั่วคราว 15 วัน)';
      }else if(res.data.datas[i].punishment_count == '6'){
          punish_detail = 'ระงับชั่วคราว 7 วัน';
      }else if(res.data.datas[i].punishment_count == '7'){
          punish_detail = 'ระงับชั่วคราว 15 วัน';
      }else if(res.data.datas[i].punishment_count == '8'){
          punish_detail = 'ระงับชั่วคราว 30 วัน';
      }else if(res.data.datas[i].punishment_count == '9'){
          punish_detail = 'ระงับ(เพิกถอนใบอนุญาต)';
      }else{
          punish_detail = 'เพิกถอนใบอนุญาต';
      }
      datatable.value.push([res.data.datas[i].id,new Date(res.data.datas[i].create_at).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].nameaddresscar,res.data.datas[i].license_number,res.data.datas[i].names,punish_type,punish_detail,new Date(res.data.datas[i].authorized_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),new Date(res.data.datas[i].exp_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),res.data.datas[i].id]);
    }
    Swal.close();
  }else{
    Swal.close()
  }
}

async function geteditdata(id) {

  license_id_e.value  = ''; 
  punishment_type_e.value  = '';
  punishment_type_detail_e.value  = '';
  punishment_count_e.value  = '';
  punishment_start_e.value  = '';
  punishment_end_e.value  = '';
  punishment_detail_e.value  = '';

  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const resData = await axios.post(`punishment`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 4,
        datas: [id]
      });
    if(resData.data.status == 1){
      license_id_e.value  =  resData.data.datas[0].nameaddresscar + ' / ' +resData.data.datas[0].license_number; 
      punishment_type_e.value  = resData.data.datas[0].punishment_type;
      punishment_type_detail_e.value  = resData.data.datas[0].punishment_type_detail;
      punishment_count_e.value  = resData.data.datas[0].punishment_count;
      punishment_start_e.value  = resData.data.datas[0].punishment_start;
      punishment_end_e.value  = resData.data.datas[0].punishment_end;
      punishment_detail_e.value  = resData.data.datas[0].punishment_detail;
      Swal.close();
      openModaledit()
    }
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '10%', targets: 0 },
        { width: '10%', targets: 3 },
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>